import React, { useState } from "react";

const ResponsiveTable = ({ json }) => {
  const [jsonData, setJsonData] = useState({
    ...json,
    email: json?.primary_member_id?.email,
  });
  const selectedKeys = [
    "family_name",
    "family_code",
    "primary_member",
    "email",
    "gender",
    "aadhar_card",
    "date_of_birth",
    "blood_group",
    "address",
    "pin_code",
    "city",
    "state",
    "country",
    "birth_place",
    "qualification",
    "certificate_upload",
  ];
  // {JSON.stringify(currentRecord?.primary_member_id?.email)}

  return (
    <div className="p-1">
      <table className="min-w-full text-sm border-collapse">
        <tbody>
          {selectedKeys.map((key, index) => (
            <tr
              key={index}
              className={`hover:bg-gray-400 ${
                index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
              }`}
            >
              <td className="p-2 font-bold capitalize border">
                {key.replace(/_/g, " ")}
              </td>
              <td className="p-2 border">
                {key === "certificate_upload" && jsonData[key] ? (
                  <a
                    href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${jsonData[key]}`}
                    className="inline-block px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Document
                  </a>
                ) : (
                  jsonData[key]?.toString()
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveTable;
