import React, { useRef } from "react";

import CardBackground from "./CardBackground";
import PostComponent from "./PostComponent";

const TimelineSection = () => {
  return (
    <>
      <PostComponent />
    </>
  );
};

export default TimelineSection;
