import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import AddFinancialYearChild from "./AddFinancialYearChild";

const AddFinancialYear = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <AddFinancialYearChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default AddFinancialYear;
