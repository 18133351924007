import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { indian_states } from "../../../components/Data/IndianStates";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const DioceseUpdateChild = ({ dioceseData }) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({ ...dioceseData });
  const [dsegmentOption, setDsegmentOption] = useState();
  const [cityOptions, setCityOptions] = useState();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleDioceseUpdate = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/dioceses/update/${dioceseData._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Diocese Updated Successfully.");
        navigate("/dioceseslist");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "DioceseUpdateChild", "/dioceseupdate");
        // console.log(error);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/dsegment/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.dsegments;
          const updatedData = oldData.map((data) => {
            return {
              value: data._id,
              label: data.segment_name,
            };
          });

          setDsegmentOption(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  const statusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]">
        {/* {JSON.stringify(formData)} */}
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        <h2 className="mb-3 text-bold">Update Diocese</h2>
        {/* {JSON.stringify(formData)} */}
        {/* {JSON.stringify(cityOptions)} */}
        <form onSubmit={(e) => handleDioceseUpdate(e)} autoComplete="off">
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="diocese_code"
              type="text"
              required
              placeholder="Enter Diocese Code"
              autoComplete="new-password"
              onChange={handleChange}
              disabled
              value={formData.diocese_code && formData.diocese_code}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="diocese_name"
              type="text"
              required
              placeholder="Enter Diocese Name"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.diocese_name && formData.diocese_name}
            />
          </div>
          <div className="mt-4">
            {/* {JSON.stringify(formData?.segment)} */}
            <Select
              name="segment"
              onChange={dropDownChange}
              options={dsegmentOption}
              className="w-full outline-none"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              value={
                formData?.segment &&
                dsegmentOption?.find((d) => d.value === formData.segment._id)
              }
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="country"
              type="text"
              required
              placeholder="Enter Country"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.country && formData.country}
            />
          </div>
          <div className="mt-4 ">
            <Select
              name="state"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  [dropdown.name]: selected.value,
                  city: null,
                });

                //console.log(indian_states[selected.value]);
                setCityOptions(
                  indian_states[selected.value].map((d, i) => {
                    return { value: d, label: d };
                  })
                );
              }}
              value={
                formData.state && {
                  value: formData.state,
                  label: formData.state,
                }
              }
              placeholder="Select State"
              options={indianStatesOption}
              className="w-full outline-none"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
          <div className="mt-4 ">
            <Select
              name="city"
              onChange={dropDownChange}
              options={cityOptions}
              className="w-full outline-none"
              placeholder="Select City"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              value={
                formData.city && { value: formData.city, label: formData.city }
              }
            />
          </div>
          <div className="mt-4 ">
            <Select
              name="status"
              onChange={dropDownChange}
              options={statusOptions}
              className="w-full outline-none"
              placeholder="Select Status"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              value={statusOptions.find((d) => d.value === formData.status)}
            />
          </div>
          <div className="mt-4 ">
            <textarea
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="address"
              type="textarea"
              row="4"
              required
              placeholder="Enter Address"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.address && formData.address}
            />
          </div>

          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              type="submit"
            >
              Update Diocese
            </button>
          </div>
        </form>
        {/* {JSON.stringify(indian_states)} */}
      </div>
    </div>
  );
};

export default DioceseUpdateChild;
