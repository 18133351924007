import React from "react";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import MainTemplate from "../../components/MainTemplate/MainTemplate";

const Dashboard = () => {
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);

  return <MainTemplate>{JSON.stringify(user)}</MainTemplate>;
};

export default Dashboard;
