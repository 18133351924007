import React from "react";
import ReactToPrint from "react-to-print";
import Card from "./SubscriberCard";
import ReceiptCard from "./ReceiptCard";

const ReceiptView = ({ currentRecord, subscriberRecord }) => {
  return (
    <div className="flex flex-col items-center p-5 ">
      <Card data={subscriberRecord} />
      <ReceiptCard data={currentRecord}  users={subscriberRecord} />
    </div>
  );
};

export default ReceiptView;
