import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const MemberOutstandingPaymentChild = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [outstandingMonth, setOutstandingMonth] = useState(0);
  const [outstandingAmt, setOutstandingAmt] = useState(0);
  const [receipt, setReceipt] = useState([]);
  const [doj, setDoj] = useState(true);

  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/subscriber/id/${user?._id}`;

      console.log(urlLink);

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.subscriber);
          const subscriber = response.data.subscriber;

          const updatedData = {
            ...subscriber,
            id: subscriber?._id,
            date: subscriber?.date_of_joining,
            name:
              subscriber?.subscriber_id?.first_name +
              " " +
              subscriber?.subscriber_id?.last_name,
            role: subscriber?.subscriber_id?.user_type,
            monthly_subscription: subscriber?.monthly_subscription,
          };

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    getDataFromServer();
  }, []);

  useEffect(() => {
    const fetchLastPayment = (user_id) => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/receipt/last_payment/${user_id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          const last_payment = response.data.receipts;
          const newDate = new Date(moment(last_payment.payment_date));
          setReceipt({ ...last_payment, paid_upto: newDate });
        })
        .catch(function (err) {
          console.log("error occurred.");
        });
    };
    fetchLastPayment(user?._id);
  }, []);

  function calculateSubscriptionCost(subscriptionObj, memberObj) {
    const currentDate = new Date();
    let startDate;
    let monthlyAmount;

    if (
      (Array.isArray(subscriptionObj) && subscriptionObj?.length === 0) ||
      subscriptionObj === null
    ) {
      // Use date_of_joining from member object
      startDate = new Date(memberObj?.date_of_joining);
      monthlyAmount = parseInt(memberObj?.monthly_subscription, 10);
    } else {
      // Use paid_upto from subscription object
      const paidUpToDate = new Date(subscriptionObj?.paid_upto);
      startDate = new Date(
        paidUpToDate.getFullYear(),
        paidUpToDate.getMonth() + 1,
        1
      );
      monthlyAmount = parseInt(subscriptionObj?.amount, 10);
    }

    const monthsDiff = monthDifference(startDate, currentDate);
    setOutstandingMonth(monthsDiff);
    return monthsDiff * monthlyAmount;
  }

  function monthDifference(dateFrom, dateTo) {
    let months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
    months -= dateFrom.getMonth();
    months += dateTo.getMonth();
    return months <= 0 ? 0 : months;
  }

  useEffect(() => {
    if (data && receipt) {
      const cost = calculateSubscriptionCost(receipt, data);
      setOutstandingAmt(cost);
    }
  }, [data, receipt]);

  useEffect(() => {
    if (outstandingMonth) {
      setDoj(false);
    } else {
      setDoj(true);
    }
  }, [outstandingMonth]);

  return (
    <div className="w-full">
      <table class="min-w-full table-auto">
        <thead class="bg-gray-100">
          <tr className="justify-start">
            <th colSpan="2" className="px-4 py-2 ">
              {user?.name}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <td className="px-4 py-2 border">Outstanding Amount:</td>
            <td className="px-4 py-2 border">
              {outstandingAmt ? outstandingAmt : 0}
            </td>
          </tr>
          <tr className="bg-gray-50">
            <td className="px-4 py-2 border">Outstanding Month:</td>
            <td className="px-4 py-2 border">
              {" "}
              {outstandingMonth ? outstandingMonth : 0}
            </td>
          </tr>
          <tr className="bg-white">
            <td className="px-4 py-2 border">Pay Online</td>
            <td className="px-4 py-2 border">
              <div className="md:w-1/3">
                <button
                  onClick={() => navigate("/member/memberpayments")}
                  className="block w-full h-10 p-2 text-xs text-white bg-blue-400 rounded-md md:m-2"
                  disabled={doj}
                >
                  + Pay Outstanding
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MemberOutstandingPaymentChild;
