import React from "react";
import { useNavigate } from "react-router-dom";
import MainAdminComponent from "./MainAdminComponent";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState, fYear } from "../../components/shared/GlobalState";
import ChurchAdminComponent from "./ChurchAdminComponent";
import { isNull } from "lodash";

const RightDropDownMenu = ({ setMenuState, menuState }) => {
  let navigate = useNavigate();
  const [{ user, handleLogout }] = useAtom(gbState);
  const [, setFinancialYear] = useAtom(fYear);
  return (
    <div
      className={`absolute right-0 z-10 ${
        menuState ? "hidden" : null
      } w-42 mt-4 bg-white rounded-md shadow-lg`}
      onMouseLeave={() => setMenuState(!menuState)}
    >
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => navigate("/updateProfile")}
      >
        Change Profile
      </button>
      {user?.role === "CHURCH_ADMIN" && (
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => navigate("/church/profile", { state: user })}
        >
          Church Profile
        </button>
      )}
      {user?.role === "CHURCH_ADMIN" && (
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => navigate("/closure/history")}
        >
          Closure History
        </button>
      )}
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => navigate("/updatePassword")}
      >
        Change Password
      </button>
      {/* Administrator MENU access component */}
      {user?.role === "FULL_ACCESS" && (
        <MainAdminComponent setMenuState={setMenuState} menuState={menuState} />
      )}

      {/* <ChurchAdminComponent setMenuState={setMenuState} menuState={menuState} /> */}

      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          handleLogout();
          setFinancialYear(null);
        }}
      >
        Logout
      </button>
    </div>
  );
};

export default RightDropDownMenu;
