import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ProfileComponent from "../../ChurchMemberPages/Dashboard/ProfileComponent";
import ContextMenuSection from "../../ChurchMemberPages/Dashboard/ContextMenuSection";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import ListOtherPayments from "./ListOtherPayments";

const OtherPaymentsChild = () => {
  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  return (
    <div className="w-full">
      <div className="flex flex-grow bg-gray-100">
        <div className="w-full bg-gray-100 md:flex md:flex-grow">
          <div className="hidden w-1/5 md:block">
            <div className="sticky top-0 h-screen overflow-y-hidden">
              <ProfileComponent member={user} />
              <ContextMenuSection />
            </div>
          </div>
          <div className="md:w-4/5 md:block">
            <div className="sticky top-0 h-screen overflow-y-hidden">
              <CardBackground>
                <div className="w-full">
                  <ListOtherPayments />
                </div>
              </CardBackground>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherPaymentsChild;
