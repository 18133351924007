import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChurchRegistrationPieChart = ({ data }) => {
  // Aggregate church registrations per state
  const stateCount = data.reduce((acc, item) => {
    const state = item.state;
    acc[state] = (acc[state] || 0) + 1;
    return acc;
  }, {});

  // Data for the pie chart
  const chartData = {
    labels: Object.keys(stateCount),
    datasets: [
      {
        label: "Number of Churches per State",
        data: Object.values(stateCount),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#E7E9ED",
          "#4BC0C0",
          "#F7464A",
          "#949FB1",
          "#4D5360",
          // More colors can be added here if needed
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#E7E9ED",
          "#4BC0C0",
          "#F7464A",
          "#949FB1",
          "#4D5360",
          // More hover colors can be added here if needed
        ],
      },
    ],
  };

  return (
    <div className="p-1 text-xs">
      <h2>Church Registration by State</h2>
      <Pie data={chartData} />
      <div className="mt-4">
        <h3 className="mb-2 font-bold text-md">State-wise Church Details</h3>
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="text-white bg-gray-800">
              <th className="px-2 py-1 border">State</th>
              <th className="px-2 py-1 border">Churches</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(stateCount).map(([state, count], index) => (
              <tr
                key={index}
                className="even:bg-silver odd:bg-white hover:bg-gray-200"
              >
                <td className="px-4 py-2 border">{state}</td>
                <td className="px-4 py-2 border">{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChurchRegistrationPieChart;
