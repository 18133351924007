import React, { useState } from "react";
import IncomeReportTable from "./IncomeReportTable";

const IncomeReportChild = ({ incomeData }) => {
  const [incomes] = useState(incomeData);

  return (
    <div className="flex items-center justify-center w-full ">
      <IncomeReportTable data={incomeData} />
    </div>
  );
};

export default IncomeReportChild;
