import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import AddMarriageChild from "./AddMarriageChild";

const AddMarriage = () => {
  return (
    <MainTemplate>
      <ErrorBoundaryRoutes route="/marriage/add" page="AddMarriage">
        <div className="relative flex flex-col p-10 m-3 space-y-10 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-3">
          <AddMarriageChild />
        </div>
      </ErrorBoundaryRoutes>
    </MainTemplate>
  );
};

export default AddMarriage;
