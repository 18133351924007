import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import PasswordUpdateChild from "./PasswordUpdateChild";
import { useLocation } from "react-router-dom";

const PasswordUpdate = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <PasswordUpdateChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default PasswordUpdate;
