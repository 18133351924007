import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { indian_states } from "../../../components/Data/IndianStates";
import { countries } from "../../../components/Data/Countries";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const FamilyUpdateChild = ({ familyDetails }) => {
  let navigate = useNavigate();
  const [cityOptions, setCityOptions] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [cityStateInput, setCityStateInput] = useState(
    familyDetails?.state ? true : false
  );
  const [churchList, setChurchList] = useState();
  const inputWidth = "230px";
  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput, bloodgroupOptions }] = useAtom(gbState);
  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const [formData, setFormData] = useState({
    ...familyDetails,
    ...familyDetails.primary_member_id,
    ...familyDetails.church_id,
    // family_name: null,
    // first_name: null,
    // last_name: null,
    // aadhar_card: null,
    // mobile: null,
    email: familyDetails?.email,
    // gender: null,
    // password: null,
    // confirm_password: null,
    // parent_church_id: null,
    // date_of_birth: null,
    // head_of_family: null,
    // communicant_member: null,
    // date_of_joining: null,
    // date_of_birth: null,
    address: familyDetails?.address,
    pin_code: familyDetails?.pin_code,
    city: familyDetails?.city,
    state: familyDetails?.state,
    country: familyDetails?.country,
    blood_group: familyDetails?.blood_group,
    qualification: familyDetails?.qualification,
    birth_place: familyDetails?.birth_place,
  });

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/churches/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        const churches = response.data.churches;
        setChurchList(
          churches.map((church) => ({
            value: church._id,
            label: church.church_name + " (" + church.city + ")",
          }))
        );
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "ChurchList", "/churches");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });
  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const countriesOption = countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "nonbinary", label: "Non-binary" },
    { value: "other", label: "Other" },
  ];

  const dayOptions = [];
  for (var i = 1; i <= 31; i++) {
    if (i < "10") i = "0" + i;

    dayOptions.push({ value: i, label: i });
  }
  const monthOptions = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const binaryOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  useEffect(() => {
    if (formData?.country === "India") {
      setCityStateInput(true);
    } else {
      setCityStateInput(false);
    }
  }, [formData?.country]);

  useEffect(() => {
    //console.log(indian_states);
    setCityOptions(
      indian_states[formData?.state]?.map((d, i) => {
        return { value: d, label: d };
      })
    );
  }, [formData?.state]);

  const handleFamilyUpdate = () => {
    if (!formData.head_of_family) {
      toast.error("Please select head of family");
      return;
    }
    if (!formData.communicant_member) {
      toast.error("Please select communicant member");
      return;
    }
    if (!formData.birth_day) {
      toast.error("Please select birth day");
      return;
    }
    if (!formData.birth_month) {
      toast.error("Please select birth month");
      return;
    }
    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/family/update/${familyDetails._id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, certificate_upload: selectedImage },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Family Updated Successfully.");
        navigate("/families");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error?.response?.data));
        errorLogApi(error, "FamilyUpdateChild", "/family/update");
        // console.log(error);
      });
  };
  const statusOptions = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>{" "}
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
            onClick={handleFamilyUpdate}
          >
            Save
          </button>
        </div>
        <fieldset className="p-2 whitespace-normal border w-100">
          <legend className="float-none w-auto p-2 fs-5">
            Register Family
          </legend>
          {/* <div className="flex flex-col"> */}
          <div className="max-w-md mx-auto">
            <form>
              {/* <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Family Name
                </label>

                <input
                  type="text"
                  name="family_name"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.family_name}
                />
              </div> */}
              <div className="flex mb-0">
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Family name
                  </label>
                  <input
                    type="text"
                    name="family_name"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.family_name}
                  />
                </div>{" "}
                &nbsp;
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Status {formData?.status}
                  </label>
                  <Select
                    name="status"
                    onChange={(selected, dropdown) => {
                      setFormData({
                        ...formData,
                        status: selected.value,
                      });
                    }}
                    options={statusOptions}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select Status")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                    value={statusOptions?.find(
                      (s) => s.value === Number(formData?.status)
                    )}
                  />
                </div>
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">Name</label>
                <div className="flex mb-4">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.first_name}
                  />{" "}
                  &nbsp;
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.last_name}
                  />
                </div>
                <div className="flex mb-0">
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Date of Birth
                    </label>
                    <Select
                      name="birth_day"
                      onChange={dropDownChange}
                      options={dayOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("--Day--")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={dayOptions?.find(
                        (noOfDays) => noOfDays.value == formData?.birth_day
                      )}
                    />
                  </div>
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      &nbsp;
                    </label>
                    <Select
                      name="birth_month"
                      onChange={dropDownChange}
                      options={monthOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("--Month--")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={monthOptions?.find(
                        (months) => months.value === formData?.birth_month
                      )}
                    />
                  </div>
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      &nbsp;
                    </label>
                    <DatePicker
                      className="w-full mb-2 h-[38px]"
                      name="birth_year"
                      onChange={(date, dateString) => {
                        setFormData({
                          ...formData,
                          birth_year: dateString,
                        });
                      }}
                      disabledDate={(current) => {
                        let customDate = dayjs().format("YYYY");
                        return current && current > dayjs(customDate, "YYYY");
                      }}
                      showToday={true}
                      picker="year"
                      placeholder="--Year--"
                      value={
                        formData?.birth_year
                          ? dayjs(formData?.birth_year, "YYYY")
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="flex mb-0">
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Gender
                    </label>
                    <Select
                      name="gender"
                      onChange={dropDownChange}
                      options={genderOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("Select Gender")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={genderOptions?.find(
                        (gender) => gender.value === formData?.gender
                      )}
                    />
                  </div>
                </div>
                <div className="flex mb-0">
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Aadhaar Card
                    </label>
                    <input
                      type="text"
                      name="aadhar_card"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                      value={formData?.aadhar_card}
                    />
                  </div>{" "}
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Mobile
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                      value={formData?.mobile}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    autoComplete="new-password"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.email}
                    disabled
                  />
                </div>
                {/* <div className="flex mb-0">
                  <div className="w-full mb-4">
                    <label className="block mb-2 text-sm text-gray-500">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                    />
                  </div>{" "}
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirm_password"
                      autoComplete="off"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                    />
                  </div>
                </div> */}
                <div className="flex mb-0">
                  <div className="w-full mb-4">
                    <label className="block mb-2 text-sm text-gray-500">
                      Head of Family
                    </label>
                    <Select
                      name="head_of_family"
                      onChange={dropDownChange}
                      options={binaryOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("Select HOF")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={binaryOptions?.find(
                        (hof) => hof.value === Number(formData?.head_of_family)
                      )}
                    />
                  </div>{" "}
                  &nbsp;
                  <div className="w-full mb-4">
                    <label className="block mb-2 text-sm text-gray-500">
                      Communicant Member
                    </label>
                    <Select
                      name="communicant_member"
                      onChange={dropDownChange}
                      options={binaryOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("Select member type")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={binaryOptions?.find(
                        (b) => b.value === Number(formData?.communicant_member)
                      )}
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Parent Church
                  </label>
                  <Select
                    name="parent_church_id"
                    onChange={dropDownChange}
                    options={churchList}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select Church")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                    value={churchList?.find(
                      (church) => church.value === formData?.parent_church_id
                    )}
                  />
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Country
                  </label>
                  <Select
                    name="country"
                    onChange={(selected, dropdown) => {
                      if (selected.value === "IN") {
                        setCityStateInput(true);
                        setFormData({
                          ...formData,
                          country: selected.value,
                          state: null,
                          city: null,
                        });
                      } else {
                        setCityStateInput(false);
                        setFormData({
                          ...formData,
                          country: selected.value,
                          state: null,
                          city: null,
                        });
                      }
                    }}
                    options={countriesOption}
                    className={`w-[${inputWidth}] outline-none h-11 `}
                    placeholder={placeHolderText("Select Country")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    value={countriesOption?.find(
                      (c) => c.value == formData?.country
                    )}
                  />
                </div>
                {formData?.country === "IN" ? (
                  <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      State
                    </label>
                    <Select
                      name="state"
                      onChange={(selected, dropdown) => {
                        setFormData({
                          ...formData,
                          [dropdown.name]: selected.value,
                          city: null,
                        });
                        setCityOptions(
                          indian_states[selected.value].map((d, i) => {
                            return { value: d, label: d };
                          })
                        );
                      }}
                      placeholder={placeHolderText("Select State")}
                      options={indianStatesOption}
                      classNamePrefix="my-select"
                      className={`w-[${inputWidth}] outline-none h-10`}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={indianStatesOption?.find(
                        (state) => state.label === formData?.state
                      )}
                    />
                  </div>
                ) : (
                  <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      State
                    </label>
                    <input
                      className={inputElementWidthStyle}
                      name="state"
                      type="text"
                      required
                      placeholder="Enter State"
                      autoComplete="new-password"
                      onChange={handleChange}
                      value={formData?.state && formData?.state}
                    />
                  </div>
                )}

                {formData?.country === "IN" ? (
                  <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      City
                    </label>
                    <Select
                      name="city"
                      onChange={dropDownChange}
                      options={cityOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("Select City")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      value={cityOptions?.find(
                        (city) => city.label === formData?.city
                      )}
                      styles={style}
                    />
                  </div>
                ) : (
                  <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      City
                    </label>
                    <input
                      className={inputElementWidthStyle}
                      name="city"
                      type="text"
                      required
                      placeholder="Enter City"
                      autoComplete="new-password"
                      onChange={handleChange}
                      value={formData?.city && formData?.city}
                    />
                  </div>
                )}
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.address && formData?.address}
                  />
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Pincode
                  </label>
                  <input
                    type="text"
                    name="pin_code"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.pin_code}
                  />
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Qualification
                  </label>
                  <input
                    type="text"
                    name="qualification"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.qualification}
                  />
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Blood Group
                  </label>
                  {/* <input
                    type="text"
                    name="blood_group"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.blood_group}
                  /> */}
                  <Select
                    name="blood_group"
                    onChange={dropDownChange}
                    options={bloodgroupOptions}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select Blood Group")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                    value={
                      formData?.blood_group &&
                      bloodgroupOptions.find(
                        (b) => b.value === formData?.blood_group
                      )
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Birth Place
                  </label>
                  <input
                    type="text"
                    name="birth_place"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.birth_place}
                  />
                </div>
                <div className="mt-0 ">
                  <label
                    className="block mb-2 text-sm text-gray-500"
                    style={{ fontWeight: "bold" }}
                  >
                    Upload Transfer Certificate / Church Letter
                  </label>
                  <input
                    className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                    name="certificate_upload"
                    type="file"
                    onChange={handleImageChange}
                    accept=".jpeg, .jpg, .png, .pdf, .docx"
                  />
                </div>
              </div>
              {/* Render the remaining fields in a similar manner */}
            </form>
          </div>
          {/* {JSON.stringify(formData)} */}
          {/* </div> */}
          <br />
        </fieldset>
      </div>
    </div>
  );
};

export default FamilyUpdateChild;
