import React from "react";

const ChurchView = ({ currentRecord }) => {
  const currentRecordExpanded = {
    ...currentRecord,
    ...currentRecord.church_license,
    ...currentRecord.church_bank_account_primary,
    ...currentRecord.diocese_id,
    ...currentRecord.segment_id,
  };
  const churchFields = [
    "diocese_name",
    "church_code",
    "church_name",
    "city",
    "state",
    "country",
    "address",
    "email",
    "phone_number",
    "mobile_number",
    "website",
    "status",
    "segment_name",
    "no_of_member_licenses",
    "subscription_status",
    "subscription_start_date",
    "subscription_end_date",
    "account_type",
    "account_number",
    "bank_name",
    "ifsc_code",
    "opening_date",
    "opening_balance",
  ];

  const active_status = (
    <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-green-600 rounded-md text-bold">
      ACTIVE
    </span>
  );

  const inactive_status = (
    <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-red-600 rounded-md text-bold">
      INACTIVE
    </span>
  );

  const customizeField = (field) => {
    if (field === "status") {
      return currentRecordExpanded[field] ? active_status : inactive_status;
    }
    if (field === "subscription_status") {
      if (currentRecordExpanded[field] === "Active") {
        return (
          <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-green-600 rounded-md text-bold">
            Active
          </span>
        );
      }
      if (currentRecordExpanded[field] === "Suspended") {
        return (
          <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-red-600 rounded-md text-bold">
            Suspended
          </span>
        );
      }
      if (currentRecordExpanded[field] === "Archived") {
        return (
          <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-gray-500 rounded-md text-bold">
            Archived
          </span>
        );
      }
    }

    if (
      typeof currentRecordExpanded[field] === "object" &&
      currentRecordExpanded[field] instanceof Date
    ) {
      return "test";
    }

    return currentRecordExpanded[field];
  };

  return (
    <div className="w-full overflow-y-auto">
      {/* {JSON.stringify(currentRecordExpanded)} */}
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          {churchFields.map((field) => {
            return (
              <tr>
                <td
                  className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap"
                  style={{ textTransform: "capitalize" }}
                >
                  {field.replace("_", " ")}
                </td>
                <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
                  {customizeField(field)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChurchView;
