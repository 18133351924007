import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import MemberPaymentChild from "./MemberOnlinePaymentsChild";

const MemberPayments = () => {
  return (
    <MainTemplate>
      <MemberPaymentChild />
    </MainTemplate>
  );
};

export default MemberPayments;
