import React from "react";

const DioceseSegmentView = ({ currentRecord, title }) => {
  return (
    <div className="w-full overflow-y-auto">
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr key={currentRecord._id}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Segment Name
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.segment_name}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DioceseSegmentView;
