import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
const EventAdd = ({ currentRecord, setModel }) => {
  let navigate = useNavigate();
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    _id: currentRecord?._id ?? null,
    title: currentRecord?.title ?? null,
    description: currentRecord?.description ?? null,
    church_id: user?.church_id,
    createdBy: user?._id,
  });
  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleEvent = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    let config = {};
    if (formData._id) {
      config = {
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/news/update/${formData._id}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedImage },
      };
    } else {
      config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/news/add`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedImage },
      };
    }
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("News Created Successfully.");
        // Close the modal after saving
        navigate("/newsletters");
        setModel(false);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateNewsChild", "/newsletters");
        // console.log(error);
      });
  };
  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[1000px]">
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleEvent(e)} autoComplete="off">
          <div className="flex justify-end">
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
              type="submit"
            >
              Save
            </button>
          </div>
          <div className="mt-0 ">
            <label htmlFor="role_name" style={{ fontWeight: "bold" }}>
              Upload Newsletter Flyer
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="event_image"
              type="file"
              onChange={handleImageChange}
              accept="image/gif, image/jpeg, image/png , application/pdf"
              required={!formData._id ? true : false}
            />
          </div>
          <div className="mt-4 ">
            <label htmlFor="role_name" style={{ fontWeight: "bold" }}>
              Newsletter Title
            </label>
            <input
              className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="title"
              type="text"
              required
              placeholder="Enter Title"
              onChange={handleChange}
              value={formData.title}
            />
          </div>
          <div className="mt-4 mb-4 ">
            <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
              Description
            </label>
            <textarea
              className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="description"
              rows={3}
              type="text"
              required
              placeholder="Enter Event Description"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.description}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventAdd;
