import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate, useLocation, json } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import axios from "axios";
import FamilyMemberView from "../List/FamilyMemberView";
import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState, fMember } from "../../../components/shared/GlobalState";
import { toast } from "react-hot-toast";

const FamilyMembersList = ({
  familyMembers,
  headOfFamily,
  setFormData,
  setEnableAddButton,
  familyId,
  getDataFromServer,
}) => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [familyMember] = useAtom(fMember);
  const [hof, setHof] = useState(headOfFamily);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState(
    familyMembers.map((m, index) => ({
      ...m,
      id: m.member_id._id,
      full_name:
        m.member_id.first_name +
        " " +
        (m.member_id.last_name ? m.member_id.last_name : " "),
      first_name: m.member_id.first_name,
      last_name: m.member_id.last_name,
      relation: m.relation,
      gender: m.gender,
      mobile: m.member_id.mobile,
      email: m.member_id.email.includes("@randomemail.com")
        ? ""
        : m.member_id.email,
      date_of_birth: m.date_of_birth,
      blood_group: m.blood_group,
      qualification: m.qualification,
      birth_place: m.birth_place,
      member_index: index,
    }))
  );

  useEffect(() => {
    setData(
      familyMember.map((m, index) => ({
        ...m,
        id: m.member_id._id,
        full_name:
          m.member_id.first_name +
          " " +
          (m.member_id.last_name ? m.member_id.last_name : " "),
        first_name: m.member_id.first_name,
        last_name: m.member_id.last_name,
        relation: m.relation,
        gender: m.gender,
        mobile: m.member_id.mobile,
        email: m.member_id.email.includes("@randomemail.com")
          ? ""
          : m.member_id.email,
        date_of_birth: m.date_of_birth,
        blood_group: m.blood_group,
        qualification: m.qualification,
        birth_place: m.birth_place,
        member_index: index,
      }))
    );
  }, [familyMember]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);
  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Family ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      data: { ...row },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/family/member/delete/${familyId}/${row.member_index}`,
        config
      )
      .then((response) => {
        // alert(JSON.stringify(response));
        toast.success("Sub member deleted sucessfully");
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "FamilyListChild", "/family/delete");
      });
  };

  const editRecord = (rec) => {
    if (rec?.email.includes("@randomemail.com")) {
      setFormData({
        ...rec,
        email: "",
        update: true,
      });
    } else {
      setFormData({
        ...rec,
        update: true,
      });
    }
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => {
                  setEnableAddButton(false);
                  editRecord(params.row);
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => handleDelete(params.row)}
                className="my-overlay"
                style={{
                  position: "relative",
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "full_name",
      headerName: "Name",
      width: 180,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "relation",
      headerName: "Relation",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell: (params) => JSON.parse(params?.row?.relation)?.label,
    },
    {
      field: "gender",
      headerName: "Gender",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      width: 160,
    },
    {
      field: "date_of_birth",
      headerName: "Date of birth",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      renderCell: (params) => dateFormatter(params.row.date_of_birth),
    },
    {
      field: "birth_place",
      headerName: "Birth Place",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
    },
    // {
    //   field: "member_index",
    //   headerName: "Index",
    //   sortable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   width: 140,
    //   align: "center",
    // },
  ];

  return (
    <fieldset className="p-2 whitespace-normal border w-100">
      <legend className="float-none w-auto p-2 fs-5">
        Family Members List
      </legend>
      <div className="w-[340px]">
        <div className="flex flex-row ml-2 bg-gray-150">
          <div className="w-1/2 text-sm text-bold ">Family Code</div>
          <div className="w-1/2 ml-1 text-sm">{hof.family_code}</div>
        </div>
        <div className="flex flex-row ml-2 bg-gray-150">
          <div className="w-1/2 text-sm text-bold ">Family Name</div>
          <div className="w-1/2 ml-1 text-sm">{hof.family_name}</div>
        </div>
        <div className="flex flex-row ml-2">
          <div className="w-1/2 text-sm text-bold">Head Of Family</div>
          <div className="w-1/2 ml-1 text-sm">
            {hof?.primary_member_id?.first_name} &nbsp;
            {hof?.primary_member_id?.last_name}
          </div>
        </div>
        <div className="flex flex-row ml-2">
          <div className="w-1/2 text-sm text-bold">Email</div>
          <div className="w-1/2 ml-1 text-sm">
            {hof?.primary_member_id?.email}
          </div>
        </div>
        <div className="flex flex-row ml-2">
          <div className="w-1/2 text-sm text-bold">Church Name</div>
          <div className="w-1/2 ml-1 text-sm">
            {hof?.church_id?.church_name}
          </div>
        </div>
      </div>
      {/* {JSON.stringify(currentRecord)} */}
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Family Member Details</h2>
            {/* {JSON.stringify(currentRecord)} */}
            <FamilyMemberView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Family Members"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </fieldset>
  );
};

export default FamilyMembersList;
