import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import {
  incomeSourceOptions,
  paymentStatusOption,
} from "../../../components/Data/DropDownData";

const IncomeDuesUpdateChild = ({ selectedRecord }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      boxShadow: "none", // This line disables the blue border
    }),
  };

  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [churchMembers, setChurchMembers] = useState([]);
  const [categoryList, setCategoryList] = useState();

  const [useOtherNonMember, setUseOtherNonMember] = useState(false);
  const [formData, setFormData] = useState({
    category_id: selectedRecord?.category_id?._id,
    dues_title: selectedRecord?.dues_title,
    description: selectedRecord?.description,
    member_id: selectedRecord?.member_id?._id,
    start_date: selectedRecord?.start_date,
    pledged_amount: selectedRecord?.pledged_amount,
    preferred_payment_mode: selectedRecord?.preferred_payment_mode,
    status: selectedRecord?.status,
    financial_year: financial_year?.value,
  });

  //Load categories
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/category/income/${user?.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then((response) => {
          const oldData = response.data.Categorys;
          const updatedData = oldData.map((data) => ({
            value: data?._id,
            label: data?.category_name,
          }));
          setCategoryList(updatedData);
        })
        .catch((err) => {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  //Load members
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/church/${user?.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        const members = response?.data?.users;
        setChurchMembers(
          members.map((member) => ({
            value: member._id,
            label: member.first_name + " " + member.last_name,
          }))
        );
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        errorLogApi(error, "ChurchMemberList", "/income");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUpdateIncome = (e) => {
    e.preventDefault();

    if (!formData?.pledged_amount) return toast.error("Please enter amount");

    let userToken = localStorage.getItem("token");

    const finData = { ...formData, financial_year: financial_year?.label };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/incomedues/update/${selectedRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: finData,
    };

    axios(config)
      .then((response) => {
        toast.success("Dues Created Successfully.");
        navigate("/dues-income");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "IncomeduesAddChild", "/incomedues/add");
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div className="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={(e) => handleUpdateIncome(e)}
          >
            Update
          </button>
        </div>
        <h2 className="mb-3 text-bold">Update Dues Income</h2>
        <form autoComplete="off">
          <div className="m-1 overflow-x-auto outline-none">
            <div className="mt-4">
              <Select
                name="category_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    category_id: selected.value,
                  });
                }}
                options={categoryList}
                placeholder={placeHolderText("Select Category")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                value={
                  formData?.category_id &&
                  categoryList?.find((c) => c.value === formData?.category_id)
                }
              />
            </div>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="dues_title"
                type="text"
                required
                placeholder="Dues Title"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.dues_title}
              />
            </div>
            <div className="mt-4">
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="start_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    start_date: dateString,
                  });
                }}
                showToday={true}
                value={
                  formData?.start_date &&
                  dayjs(formData?.start_date, "YYYY-MM-DD")
                }
              />
            </div>
            <div className="mt-4">
              <Select
                name="member_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    member_id: selected?.value,
                    other_non_member: null,
                  });
                }}
                options={churchMembers}
                placeholder={placeHolderText("Select Member")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                isClearable
                value={
                  formData?.member_id &&
                  churchMembers?.find((m) => m.value === formData?.member_id)
                }
              />
            </div>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="pledged_amount"
                type="number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.pledged_amount}
              />
            </div>
            <div className="mt-4">
              <Select
                name="status"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    status: selected.value,
                  });
                }}
                options={paymentStatusOption}
                placeholder={placeHolderText("Select Status")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                value={
                  formData?.status &&
                  paymentStatusOption.find((p) => p.value === +formData?.status)
                }
              />
            </div>
            <div className="mt-4">
              <Select
                name="preferred_payment_mode"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    preferred_payment_mode: selected.value,
                  });
                }}
                options={incomeSourceOptions}
                placeholder={placeHolderText("Preferred Mode")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                value={
                  formData?.preferred_payment_mode &&
                  incomeSourceOptions?.find(
                    (s) => s.value === +formData?.preferred_payment_mode
                  )
                }
              />
            </div>
            <div className="mt-4">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="description"
                type="text"
                rows={3}
                placeholder="Description"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.description}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IncomeDuesUpdateChild;
