import React from "react";

import CardBackground from "./CardBackground";

import ChurchComponent from "./ChurchComponent";

const TimelineSection = () => {
  return (
    <>
      <ChurchComponent />

      {/* <PostComponent /> */}
    </>
  );
};

export default TimelineSection;
