import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import EventsReportsChild from "./EventsReportsChild";

const EventReports = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <EventsReportsChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default EventReports;
