import React from "react";

const FinancialView = ({ currentRecord }) => {
  return (
    <div>
      {/* {JSON.stringify(currentRecord)} */}
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr className="odd:bg-white even:bg-gray-200">
            <td className="px-6 py-4 text-sm font-bold text-left whitespace-nowrap">
              Church Name
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord?.church_name}
            </td>
          </tr>
          <tr className="odd:bg-white even:bg-gray-200">
            <td className="px-6 py-4 text-sm font-bold text-left whitespace-nowrap">
              Financial Year
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord?.financial_year}
            </td>
          </tr>
          <tr className="odd:bg-white even:bg-gray-200">
            <td className="px-6 py-4 text-sm font-bold text-left whitespace-nowrap">
              Starting Date
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord?.starting_date.slice(0, 10)}
            </td>
          </tr>
          <tr className="odd:bg-white even:bg-gray-200">
            <td className="px-6 py-4 text-sm font-bold text-left whitespace-nowrap">
              Ending Date
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord?.ending_date.slice(0, 10)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FinancialView;
