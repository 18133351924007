import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import WorshipRegisterChild from "./WorshipRegisterChild";

const WorshipRegister = () => {
  return (
    <MainTemplate>
      <div className="relative flex flex-col p-10 m-3 space-y-10 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-3">
        <ErrorBoundaryRoutes route="/worship/list" page="WorshipList">
          <WorshipRegisterChild />
        </ErrorBoundaryRoutes>
      </div>
    </MainTemplate>
  );
};

export default WorshipRegister;
