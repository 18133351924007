import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const Card = ({ data, index }) => {
  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);
  const [church, setChurch] = useState();
  // Determine the background color based on the index
  const backgroundColor = index % 2 === 0 ? "bg-gray-200" : "bg-white";

  //Get the current church details
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/churches/${user?.church_id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.church;
        setChurch(oldData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);

  return (
    <div className={`w-full`}>
      {/* {JSON.stringify(church)} */}
      <div className={`px-6 py-4 bg-gray-200`} style={{ textAlign: "center" }}>
        <div className="mb-2 text-xl font-bold ">{church?.church_name} </div>
        {/* { data.name } */}
        <p className="text-base text-gray-700">{church?.address}</p>
        {/* {data.role} */}
        <p>&nbsp;</p>
        <div className="mb-2 text-xl font-bold ">RECEIPT</div>
      </div>
    </div>
  );
};

export default Card;
