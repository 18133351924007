import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import ChurchView from "./ChurchView";

const ChurchesListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/churches/delete/${id}`, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "ChurchesListChild", "/churches/delete");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/churches/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.churches;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            church_code: data.church_code,
            church_name: data.church_name,
            city: data.city,
            state: data.state,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/churches/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.churches;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              id: data._id,
              church_code: data.church_code,
              church_name: data.church_name,
              city: data.city,
              state: data.state,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => handleDelete(params.row.id)}
                className="my-overlay"
                style={{
                  position: "relative",
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => navigate("/churchupdate", { state: params.row })}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "church_code",
      headerName: "Church Code",
      width: 180,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        return params.row.status ? (
          <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-green-600 rounded-md text-bold">
            ACTIVE
          </span>
        ) : (
          <span className="inline-block px-2 py-1 text-xs text-gray-200 bg-red-600 rounded-md text-bold">
            INACTIVE
          </span>
        );
      },
    },
    {
      field: "church_name",
      headerName: "Church Name",
      width: 230,
    },
    {
      field: "state",
      headerName: "State",
      width: 200,
    },
    {
      field: "city",
      headerName: "City",
      width: 200,
    },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/churchadd")}
        >
          Add Church
        </button>
      </div>
      <h2>Church List</h2>
      <ErrorBoundaryRoutes route="/churches" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Church Detail</h2>
            <ChurchView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Churches"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default ChurchesListChild;
