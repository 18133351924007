import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import DioceseSegmentView from "./DioceseSegmentView";

const DioceseSegmentListChild = ({ data, handleDelete }) => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  // const [data, setData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const columns = [
    {
      field: "Action",
      headerName: "Segment Name",
      width: 300,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View EMR/Visit details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div onClick={() => handleDelete(params.row)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>{" "}
            &nbsp;&nbsp;
            {params.row.segment_name}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Diocese Segment Detail</h2>
            <DioceseSegmentView
              currentRecord={currentRecord}
              title="Segment View"
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default DioceseSegmentListChild;
