import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import BankAddChild from "./BankAddChild";

const BankAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <BankAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default BankAdd;
