import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  CircularProgress,
  Button,
  TextField,
  Typography,
  Container,
  Box,
} from "@mui/material";

const BankPaymentPage = ({ church_id, amount, completePaymentTransaction }) => {
  const [bank, setBank] = useState();
  const [qrcode, setQrcode] = useState();
  const [receipt, setReceipt] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/banks/${church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then((response) => {
          const oldData = response.data.banks;
          const filteredData = oldData?.find(
            (b) => b.payment_gateway_status === true
          );
          setBank(filteredData);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error api call ", err?.response?.data);
          setLoading(false);
        });
    };
    getDataFromServer();
  }, [church_id]);

  useEffect(() => {
    if (bank?.upi_id) {
      const config = {
        method: "post",
        url: `https://upiqr.in/api/qr`,
        headers: {
          "Content-type": "application/json",
        },
        data: { name: bank?.bank_name, vpa: bank?.upi_id },
      };
      axios(config)
        .then((response) => {
          //toast.success("QR Created Successfully.");
          setQrcode(response?.data);
        })
        .catch((error) => {
          toast.error(JSON.stringify(error.response.data));
        });
    }
  }, [bank?.upi_id]);

  const handleReceiptUpload = (event) => {
    setReceipt(event.target.files[0]);
  };

  const handleSubmit = () => {
    // Handle the submission of the receipt and payment confirmation
    completePaymentTransaction(receipt);
    //toast.success("Payment and receipt submitted successfully.");
  };

  return (
    <Container maxWidth="sm">
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={4}>
          <Typography variant="h4" gutterBottom align="center">
            Complete Your Payment
          </Typography>
          <Box
            mt={2}
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h6" gutterBottom>
              Amount: {amount}
            </Typography>
            <Typography variant="body1">
              Bank Name: {bank?.bank_name}
            </Typography>
            <Typography variant="body1">UPI ID: {bank?.upi_id}</Typography>
          </Box>
          {bank?.upi_id && (
            <Box mt={2} mb={2} display="flex" justifyContent="center">
              {qrcode ? (
                <img
                  id="qrCode"
                  alt="QR Code"
                  width="200px"
                  src={`data:image/svg+xml;utf8,${qrcode}`}
                />
              ) : (
                <Typography>Loading QR...</Typography>
              )}
            </Box>
          )}
          <Box mt={2} mb={2}>
            <TextField
              type="file"
              onChange={handleReceiptUpload}
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              helperText="Upload transaction receipt"
            />
          </Box>
          <Box mt={2} mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              disabled={!receipt}
            >
              Submit Payment
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default BankPaymentPage;
