import React from "react";
import { Routes, Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";
import Login from "../../pages/Login/Login";
import UsersList from "../../pages/Users/List/UsersList";
import RoleList from "../../pages/Roles/List/RoleList";
import CategoryCreate from "../../pages/Category/Create/CategoryCreate";
import CategoryList from "../../pages/Category/List/CategoryList";
import Dashboard from "../../pages/Dashboard/Dashboard";
import RoleAdd from "../../pages/Roles/Create/RoleAdd";
import UserAdd from "../../pages/Users/Create/UserAdd";
import CategoryAdd from "../../pages/Category/Create/CategoryCreate";
import CategoryUpdate from "../../pages/Category/Update/CategoryUpdate";
import DiocesesList from "../../pages/Dioceses/List/DiocesesList";
import DioceseSegmentAdd from "../../pages/DioceseSegments/Create/DioceseSegmentAdd";
import DioceseAdd from "../../pages/Dioceses/Create/DioceseAdd";
import DioceseUpdate from "../../pages/Dioceses/Update/DioceseUpdate";
import ChurchesList from "../../pages/Churches/List/ChurchesList";
import ChurchAdd from "../../pages/Churches/Create/ChurchAdd";
import ChurchUpdate from "../../pages/Churches/Update/ChurchUpdate";
import Unauthorised from "../../pages/404/Unauthorised";
import Page404 from "../../pages/404/Page404";
import Page403 from "../../pages/403/Page403";
import UserUpdate from "../../pages/Users/Update/UserUpdate";
import ChurchAdmin from "../../pages/Dashboard/ChurchAdmin/ChurchAdmin";
import FamilyList from "../../pages/Family/List/FamilyList";
import FamilyAdd from "../../pages/Family/Create/FamilyAdd";
import FamilyAlbum from "../../pages/Family/Create/FamilyAlbum";
import FamilyUpdate from "../../pages/Family/Update/FamilyUpdate";
import FamilyMemberAdd from "../../pages/Family/Create/FamilyMemberAdd";
import FamilyMemberView from "../../pages/ChurchMemberPages/Family/MemberFamiliesChild";
import AssetManagementList from "../../pages/AssetManagement/List/AssetManagementList";
import AssetAdd from "../../pages/AssetManagement/Create/AssetAdd";
import AssetUpdate from "../../pages/AssetManagement/Update/AssetUpdate";
import AssetInventory from "../../pages/AssetInventory/Inventory/AssetInventory";
import AssetInward from "../../pages/AssetInventory/AssetInward/AssetInward";
import AssetOutward from "../../pages/AssetInventory/AssetOutward/AssetOutward";
import AssetOutwardHistory from "../../pages/AssetInventory/AssetOutward/AssetOutwardHistory";
import AssetInwardHistory from "../../pages/AssetInventory/AssetInward/AssetInwardHistory";
import AssetInwardUpdate from "../../pages/AssetInventory/AssetInward/AssetInwardUpdate";
import IncomeList from "../../pages/IncomeManagement/List/IncomeList";
import IncomeAdd from "../../pages/IncomeManagement/Create/IncomeAdd";
import IncomeUpdate from "../../pages/IncomeManagement/Update/IncomeUpdate";
import Employees from "../../pages/Employee/List/EmployeeList";
import EmployeeAdd from "../../pages/Employee/Create/EmployeeAdd";
import EmployeeUpdate from "../../pages/Employee/Update/EmployeeUpdate";
import EmployeesList from "../../pages/Employee/List/EmployeeList";
import NoticeList from "../../pages/Notice/List/NoticeList";
import EventList from "../../pages/Event/List/EventList";
import NewsList from "../../pages/NewsLetter/List/NewsList";
import MemberNoticeList from "../../pages/ChurchMemberPages/Notice/NoticeList";
import MemberEventList from "../../pages/ChurchMemberPages/Events/EventList";
import MemberNewsList from "../../pages/ChurchMemberPages/Newletter/NewsList";

import ExpenseList from "../../pages/ExpenseManagement/List/ExpenseList";
import ExpenseAdd from "../../pages/ExpenseManagement/Create/ExpenseAdd";
import ExpenseUpdate from "../../pages/ExpenseManagement/Update/ExpenseUpdate";

import SalaryList from "../../pages/Salary/List/SalaryList";
import SalaryAdd from "../../pages/Salary/Create/SalaryCreate";
import SalaryUpdate from "../../pages/Salary/Update/SalaryUpdate";

import SubscriberList from "../../pages/SubscriberManagement/List/SubscriberList";
import SubscriberAdd from "../../pages/SubscriberManagement/Create/SubscriberAdd";
import SubscriptionCollection from "../../pages/SubscriberManagement/Create/SubscriptionCollectionAdd";
import ReceiptList from "../../pages/ReceiptManagement/List/ReceiptList";
import ReceiptAdd from "../../pages/ReceiptManagement/Create/ReceiptAdd";
import ReceiptUpdate from "../../pages/ReceiptManagement/Update/ReceiptUpdate";
import MemberDashboard from "../../pages/ChurchMemberPages/Dashboard/MemberDashboard";
import MemberFamilies from "../../pages/ChurchMemberPages/Family/MemberFamilies";
import FamilyMembers from "../../pages/ChurchMemberPages/Member/FamilyMembers";
import MemberPaymentsDues from "../../pages/ChurchMemberPages/Payments/MemberPaymentsDues";
import MemberOutstandingPayments from "../../pages/ChurchMemberPages/Payments/MemberOutstandingPayments";
import MemberPaymentsList from "../../pages/ChurchMemberPages/Payments/MemberOnlinePayments";
import ProfileUpdate from "../../pages/Profile/ProfileUpdate";
import PasswordUpdate from "../../pages/Profile/PasswordUpdate";
import AssetSettlementHistory from "../../pages/AssetInventory/Inventory/AssetSettlementHistory";
import IncomeReport from "../../pages/IncomeManagement/Report/IncomeReport";
import ExpenseReport from "../../pages/ExpenseManagement/Report/ExpenseReport";
import IncomeReports from "../../pages/Reports/IncomeReports/IncomeReports";
import SubscriptionReports from "../../pages/Reports/SubscriptionReports/SubscriptionReports";
import ExpenseReports from "../../pages/Reports/ExpenseReports/ExpenseReports";
import EventReports from "../../pages/Reports/EventReports/EventReports";
import EmployeeReports from "../../pages/Reports/EmployeeReports/EmployeeReports";
import MonthlyStatement from "../../pages/Reports/MonthlyStatement/MonthlyStatement";
import BankList from "../../pages/Banks/List/BankList";
import BankAdd from "../../pages/Banks/Create/BankAdd";
import BankUpdate from "../../pages/Banks/Update/BankUpdate";
import BalanceList from "../../pages/BankBalances/List/BalanceList";
import AddBalance from "../../pages/BankBalances/Create/AddBalance";
import FinancialList from "../../pages/FinancialYear/List/FinancialList";
import AddFinancialYear from "../../pages/FinancialYear/Create/AddFinancialYear";
import ChurchProfileUpdate from "../../pages/Churches/Update/ChurchProfileUpdate";
import UpdateBalance from "../../pages/BankBalances/Update/UpdateBalance";
import ListMonthlyStatements from "../../pages/MonthlyStatements/List/ListMonthlyStatements";
import CreateMonthlyStatement from "../../pages/MonthlyStatements/Create/CreateMonthlyStatement";
import MarriageRegister from "../../pages/MarriageRegister/List/MarriageRegister";
import AddMarriage from "../../pages/MarriageRegister/Create/AddMarriage";
import UpdateMarriage from "../../pages/MarriageRegister/Update/UpdateMarriage";
import WorshipRegister from "../../pages/WorshipRegister/List/WorshipRegister";
import AddWorship from "../../pages/WorshipRegister/Create/AddWorship";
import UpdateWorship from "../../pages/WorshipRegister/Update/UpdateWorship";
import BurialRegister from "../../pages/BurialRegister/List/BurialRegister";
import AddBurial from "../../pages/BurialRegister/Create/AddBurial";
import UpdateBurial from "../../pages/BurialRegister/Update/UpdateBurial";
import BaptismRegister from "../../pages/BaptismRegister/List/BaptismRegister";
import AddBaptism from "../../pages/BaptismRegister/Create/AddBaptism";
import UpdateBaptism from "../../pages/BaptismRegister/Update/UpdateBaptism";
import ConfirmationRegister from "../../pages/ConfirmationRegister/List/ConfirmationRegister";
import AddConfirmation from "../../pages/ConfirmationRegister/Create/AddConfirmation";
import UpdateConfirmation from "../../pages/ConfirmationRegister/Update/UpdateConfirmation";
import SuperAdminDashboard from "../../pages/Dashboard/SuperAdminDashboard";
import OnboardingCompleted from "../../pages/Dashboard/OnboardingCompleted";
import OtherPayments from "../../pages/ChurchMemberPages/OtherPayments/OtherPayments";
import ListClosureHistory from "../../pages/ClosureHistory/List/ListClosureHistory";
import ListIncomeDues from "../../pages/IncomeDuesManagement/List/ListIncomeDues";
import IncomeDuesAdd from "../../pages/IncomeDuesManagement/Create/IncomeDuesAdd";
import IncomeDuesUpdate from "../../pages/IncomeDuesManagement/Update/IncomeDuesUpdate";
import IncomeDuesReceipt from "../../pages/IncomeDuesManagement/Create/IncomeDuesReceipt";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route path="/" name="Login" element={<Login />} />
      <Route path="/login" name="Login" element={<Login />} />
      <Route
        path="/error404"
        name="Error: Page not found"
        element={<Unauthorised />}
      />
      <Route element={<PrivateRoutes />}>
        <Route
          path="/error404"
          name="Error: Page not found"
          element={<Page404 />}
        />
        <Route
          path="/error403"
          name="Error: Permission denied"
          element={<Page403 />}
        />
        <Route element={<PrivateRoutes roleRequired={["FULL_ACCESS"]} />}>
          {/* Church Onboarding */}
          <Route
            path="/onboarding-completed"
            name="Onboarding Completed"
            element={<OnboardingCompleted />}
          />
          {/* Manage Financial Year */}
          {/* List Route */}
          <Route
            path="/financials"
            name="FY List"
            element={<FinancialList />}
          />
          {/*  Create Route */}
          <Route
            path="/financial/add"
            name="Add FY"
            element={<AddFinancialYear />}
          />
          <Route
            path="/superadmin"
            name="Super Admin Dashboard"
            element={<SuperAdminDashboard />}
          />
          {/* Roles Managenment */}
          <Route path="/roles" name="Roles List" element={<RoleList />} />
          <Route path="/roleadd" name="Add Role" element={<RoleAdd />} />
          <Route
            path="/catcreate"
            name="Create category"
            element={<CategoryCreate />}
          />
          <Route
            path="/dioceseslist"
            name="List Dioceses"
            element={<DiocesesList />}
          />
          <Route
            path="/dsadd"
            name="Add Diocese Segement"
            element={<DioceseSegmentAdd />}
          />
          <Route
            path="/dioceseadd"
            name="Add Diocese"
            element={<DioceseAdd />}
          />
          <Route
            path="/dioceseupdate"
            name="Update Diocese"
            element={<DioceseUpdate />}
          />
          <Route
            path="/churchupdate"
            name="Update Church"
            element={<ChurchUpdate />}
          />
          <Route path="/churchadd" name="Add Church" element={<ChurchAdd />} />
          <Route
            path="/churches"
            name="List Churches"
            element={<ChurchesList />}
          />

          <Route path="/users" name="List Users" element={<UsersList />} />
          <Route
            path="/user/update"
            name="Update User"
            element={<UserUpdate />}
          />
          <Route path="/useradd" name="Add Users" element={<UserAdd />} />
        </Route>
        <Route
          element={
            <PrivateRoutes
              roleRequired={["CHURCH_ADMIN", "CHURCH_MEMBER", "FULL_ACCESS"]}
            />
          }
        >
          <Route
            path="/church/profile"
            name="Church Profile"
            element={<ChurchProfileUpdate />}
          />
          <Route
            path="/dashboard-ca"
            name="Dashboard Church Admin"
            element={<ChurchAdmin />}
          />
          <Route
            path="/reports/income"
            name="Income Reports"
            element={<IncomeReports />}
          />
          <Route
            path="/reports/subscription"
            name="Subscription Reports"
            element={<SubscriptionReports />}
          />
          <Route
            path="/reports/expense"
            name="Expense Reports"
            element={<ExpenseReports />}
          />
          <Route
            path="/reports/events"
            name="Event Reports"
            element={<EventReports />}
          />
          <Route
            path="/reports/employee"
            name="Employee Reports"
            element={<EmployeeReports />}
          />
          <Route
            path="/families"
            name="List of Families"
            element={<FamilyList />}
          />
          <Route
            path="/assets"
            name="List of Assets"
            element={<AssetManagementList />}
          />
          <Route
            path="/assets/add"
            name="Add of Assets"
            element={<AssetAdd />}
          />
          <Route
            path="/assets/update"
            name="Update of Assets"
            element={<AssetUpdate />}
          />
          <Route
            path="/assets/inventory"
            name="Assets Inventory"
            element={<AssetInventory />}
          />
          <Route
            path="/assets/inventory/add"
            name="Assets Inventory Add"
            element={<AssetInward />}
          />
          <Route
            path="/assets/inventory/update"
            name="Assets Inventory Update"
            element={<AssetInwardUpdate />}
          />
          <Route
            path="/assets/inventory/issue"
            name="Assets Inventory Issue"
            element={<AssetOutward />}
          />
          <Route
            path="/assets/inventory/issue/history"
            name="Assets Inventory Issue History"
            element={<AssetOutwardHistory />}
          />
          <Route
            path="/assets/inventory/inward/history"
            name="Assets Inventory Inward History"
            element={<AssetInwardHistory />}
          />
          <Route
            path="/assets/settlement/history"
            name="Assets Inventory Settlement History"
            element={<AssetSettlementHistory />}
          />
          <Route path="/family/add" name="Add Family" element={<FamilyAdd />} />
          <Route
            path="/family/member/addAlbum"
            name="Add Family Album"
            element={<FamilyAlbum />}
          />
          <Route
            path="/family/update"
            name="Update Family Details"
            element={<FamilyUpdate />}
          />
          <Route
            path="/family/member/add"
            name="Family Member Add"
            element={<FamilyMemberAdd />}
          />
          <Route
            path="/catlist"
            name="List Category"
            element={<CategoryList />}
          />
          <Route
            path="/categoryadd"
            name="Add Category"
            element={<CategoryAdd />}
          />
          <Route
            path="/category/update"
            name="Update Category"
            element={<CategoryUpdate />}
          />
          {/* Bank List */}
          <Route path="/banks" name="List Banks" element={<BankList />} />
          <Route path="/bank/add" name="Add Bank" element={<BankAdd />} />
          <Route
            path="/bank/update"
            name="Update Bank"
            element={<BankUpdate />}
          />
          {/* Bank Balances */}
          <Route
            path="/bank/balance"
            name="List Balance"
            element={<BalanceList />}
          />
          <Route
            path="/balance/add"
            name="Add Bank Balance"
            element={<AddBalance />}
          />
          <Route
            path="/balance/update"
            name="Update Bank Balance"
            element={<UpdateBalance />}
          />
          {/* Marriage Register */}
          <Route
            path="/marriages/list"
            name="Marriages"
            element={<MarriageRegister />}
          />
          <Route
            path="/marriage/add"
            name="Add Marriage"
            element={<AddMarriage />}
          />
          <Route
            path="/marriage/update"
            name="Update Marriage"
            element={<UpdateMarriage />}
          />
          {/* Worship Register */}
          <Route
            path="/worship/list"
            name="Worships"
            element={<WorshipRegister />}
          />
          <Route
            path="/worship/add"
            name="Add Worship"
            element={<AddWorship />}
          />
          <Route
            path="/worship/update"
            name="Update Worship"
            element={<UpdateWorship />}
          />
          {/* Burial Register */}
          <Route
            path="/burial/list"
            name="Burial List"
            element={<BurialRegister />}
          />
          <Route path="/burial/add" name="Add Burial" element={<AddBurial />} />
          <Route
            path="/burial/update"
            name="Burial Update"
            element={<UpdateBurial />}
          />
          {/* Baptism Register */}
          <Route
            path="/baptism/list"
            name="Baptism List"
            element={<BaptismRegister />}
          />
          <Route
            path="/baptism/add"
            name="Baptism Add"
            element={<AddBaptism />}
          />
          <Route
            path="/baptism/update"
            name="Baptism Update"
            element={<UpdateBaptism />}
          />
          {/* Confirmation Register */}
          <Route
            path="/confirmation/list"
            name="Confirmation List"
            element={<ConfirmationRegister />}
          />
          <Route
            path="/confirmation/add"
            name="Confirmation Add"
            element={<AddConfirmation />}
          />
          <Route
            path="/confirmation/update"
            name="Confirmation Update"
            element={<UpdateConfirmation />}
          />
          {/* Closure History */}
          <Route
            path="/closure/history"
            name="Closure History"
            element={<ListClosureHistory />}
          />
          {/* Monthly Statements */}
          <Route
            path="/monthly/statements"
            name="List Monthly Statements"
            element={<ListMonthlyStatements />}
          />
          <Route
            path="/monthly/statement/add"
            name="Add Monthly Statements"
            element={<CreateMonthlyStatement />}
          />
          <Route path="/income" name="List Incomes" element={<IncomeList />} />
          <Route path="/income/add" name="Add Income" element={<IncomeAdd />} />
          <Route
            path="/income/update"
            name="Update Income"
            element={<IncomeUpdate />}
          />
          <Route
            path="/income/report"
            name="Income Report"
            element={<IncomeReport />}
          />
          <Route
            path="/yearly/statement"
            name="Yearly Statement Report"
            element={<MonthlyStatement />}
          />
          <Route
            path="/expenses"
            name="List Expense"
            element={<ExpenseList />}
          />
          <Route path="/salaries" name="List Salary" element={<SalaryList />} />
          <Route
            path="/expense/add"
            name="Add Expense"
            element={<ExpenseAdd />}
          />
          <Route
            path="/expense/update"
            name="Update Expense"
            element={<ExpenseUpdate />}
          />
          <Route path="/salary/add" name="Add Salary" element={<SalaryAdd />} />
          <Route
            path="/salary/update"
            name="Update Salary"
            element={<SalaryUpdate />}
          />
          <Route
            path="/expense/report"
            name="Expense Report"
            element={<ExpenseReport />}
          />
          <Route
            path="/subscribers"
            name="List Subscribers"
            element={<SubscriberList />}
          />
          <Route
            path="/subscriber/add"
            name="Add Subscribers"
            element={<SubscriberAdd />}
          />
          <Route
            path="/subscriber/subscription_collection"
            name="Subscription Collection"
            element={<SubscriptionCollection />}
          />
          <Route
            path="/receipts"
            name="List Receipts"
            element={<ReceiptList />}
          />
          <Route
            path="/receipt/add"
            name="Add Receipts"
            element={<ReceiptAdd />}
          />
          <Route
            path="/receipt/update"
            name="Add Update"
            element={<ReceiptUpdate />}
          />
          <Route
            path="/updateProfile"
            name="Update Profile"
            element={<ProfileUpdate />}
          />
          <Route
            path="updatePassword"
            name="Update Password"
            element={<PasswordUpdate />}
          />
          <Route path="/dashboard" name="Dashboard" element={<Dashboard />} />
          <Route
            path="/member/dashboard"
            name="Member Dashboard"
            element={<MemberDashboard />}
          />
          <Route
            path="/member/families"
            name="Member Families"
            element={<MemberFamilies />}
          />
          <Route
            path="/family/members"
            name="Family Members"
            element={<FamilyMembers />}
          />
          <Route
            path="/family/members"
            name="Family Members"
            element={<FamilyMemberView />}
          />

          <Route
            path="/member/payments"
            name="Member Payments & Dues"
            element={<MemberPaymentsDues />}
          />
          <Route
            path="/member/outstanding"
            name="Member Outstanding Payments"
            element={<MemberOutstandingPayments />}
          />
          <Route
            path="/member/memberpayments"
            name="Member Payments"
            element={<MemberPaymentsList />}
          />
          <Route
            path="/member-other/payments"
            name="Member Other Payments"
            element={<OtherPayments />}
          />
        </Route>
        <Route
          path="/employees"
          name="List Users"
          element={<EmployeesList />}
        />
        <Route
          path="/employee/update"
          name="Update Employee"
          element={<EmployeeUpdate />}
        />
        <Route
          path="/Employeeadd"
          name="Add Employee"
          element={<EmployeeAdd />}
        />
        <Route path="/notices" name="List Notice" element={<NoticeList />} />
        <Route path="/events" name="List Event" element={<EventList />} />
        <Route path="/newsletters" name="List News" element={<NewsList />} />

        <Route
          path="/memberNotices"
          name="List Notice"
          element={<MemberNoticeList />}
        />
        <Route
          path="/memberEvents"
          name="List Event"
          element={<MemberEventList />}
        />
        <Route
          path="/memberNewsletters"
          name="List News"
          element={<MemberNewsList />}
        />
        <Route
          path="/dues-income"
          name="Dues income"
          element={<ListIncomeDues />}
        />
        <Route
          path="/dues-income/add"
          name="Dues income Add"
          element={<IncomeDuesAdd />}
        />
        <Route
          path="/dues-income/update"
          name="Dues income Update"
          element={<IncomeDuesUpdate />}
        />
        <Route
          path="/dues-income/receipt"
          name="Dues income Receipt"
          element={<IncomeDuesReceipt />}
        />
      </Route>
      <Route element={<PrivateRoutes roleRequired={["CHURCH_MEMBER"]} />}>
        <Route
          path="/member/dashboard"
          name="Member Dashboard"
          element={<MemberDashboard />}
        />
      </Route>
      <Route
        path="/member/dashboard"
        name="Member Dashboard"
        element={<MemberDashboard />}
      />
      <Route path="/dashboard" name="Dashboard" element={<Dashboard />} />
    </Routes>
  );
};

export default ProtectedRoutes;
