import React, { useState } from "react";
import ExpenseReportTable from "./ExpenseReportTable";

const ExpenseReportChild = ({ expenseRecord }) => {
  const [expenses] = useState(expenseRecord);
  return (
    <div className="flex items-center justify-center w-full ">
      <ExpenseReportTable data={expenses} />
    </div>
  );
};

export default ExpenseReportChild;
