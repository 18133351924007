import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import "react-datepicker/dist/react-datepicker.css";

const SalaryCreateChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [financial_year] = useAtom(fYear);
  const [churchMembers, setChurchMembers] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [formData, setFormData] = useState({
    employee_id: null,
    description: null,
    date: null,
    amount: null,
    status: null,
    transfer_source: null,
  });

  //   Church Members
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/church/expense/employee/${user?.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        const members = response.data.members;
        console.log(members);
        setChurchMembers(
          members.map((member) => ({
            value: member?._id,
            label:
              member?.first_name +
              " " +
              member?.last_name +
              " (" +
              member?.user_type +
              ")",
          }))
        );
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "ExpenseAddChild", "/expense/add");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleAddExpense = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    if (!user?.current_financial_year) {
      toast.error("Please select financial year");
      return;
    }
    const finData = {
      ...formData,
      financial_year: user?.current_financial_year,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/salary/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: finData,
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Salary Created Successfully.");
        navigate("/salaries");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "SalaryAddChild", "/salary/add");
        // console.log(error);
      });
  };

  const incomeSourceOptions = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Online Transfer" },
    { value: 4, label: "Demand Draft" },
  ];

  const paymentStatusOption = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Settled" },
    { value: 3, label: "Declined" },
  ];
  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(financial_year)} */}
        <form onSubmit={(e) => handleAddExpense(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Salary</h2>

          <div className="m-1 overflow-x-auto outline-none">
            <div className="mt-4 ">
              <Select
                name="employee_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    employee_id: selected.value,
                  });
                }}
                options={churchMembers}
                placeholder={placeHolderText("Select Member")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="mt-4 ">
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date: dateString,
                  });
                }}
                // disabledDate={(current) => {
                //   let customDate = moment().format("YYYY-MM-DD");
                //   return current && current < moment(customDate, "YYYY-MM-DD");
                // }}
                showToday={true}
              />
            </div>
            <div className="mt-4 ">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="amount"
                type="Number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4 ">
              <Select
                name="transfer_source"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    transfer_source: selected.label,
                  });
                }}
                options={incomeSourceOptions}
                placeholder={placeHolderText("Select Source")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="mt-4 ">
              <Select
                name="status"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    status: selected.value,
                  });
                }}
                options={paymentStatusOption}
                placeholder={placeHolderText("Select Status")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="mt-4 ">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="description"
                type="text"
                rows={3}
                placeholder="Salary Description (If any)"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default SalaryCreateChild;
