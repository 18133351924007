import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BankCardComponent = ({ data }) => {
  let navigate = useNavigate();

  return (
    <div className="flex items-center max-w-sm p-6 mx-auto space-x-4 bg-white shadow-md rounded-xl">
      <div className="flex-1">
        <h2 className="text-lg font-bold">{data?.bank_name}</h2>
        <p className="text-gray-500">Account Number: {data?.account_number}</p>
        <p className="text-gray-500">Account Type: {data?.account_type}</p>
        <p className="text-gray-500">
          Date of Opening: {new Date(data?.date_of_opening).toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default BankCardComponent;
