import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import AddBurialChild from "./AddBurialChild";

const AddBurial = () => {
  return (
    <MainTemplate>
      <ErrorBoundaryRoutes route="/worship/add" page="AddWorship">
        <div className="relative flex flex-col p-10 m-3 space-y-10 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-3">
          <AddBurialChild />
        </div>
      </ErrorBoundaryRoutes>
    </MainTemplate>
  );
};

export default AddBurial;
