import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const ReceiptCard = ({ data, setFormData, formData }) => {
  let navigate = useNavigate();
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const paymentOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Complete" },
    { value: 2, label: "Decline" },
  ];

  const updatePaymentStatus = () => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/receipt/update/${formData?._id}`;

      const config = {
        method: "PATCH",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { status: formData?.status },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          toast.success("The receipt is updated");
          navigate(-1);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  };

  return (
    <div
      className={`w-full max-w-xs shadow-md rounded-bottom-lg overflow-hidden `}
    >
      <div className={`px-6 py-4 bg-gray-50 w-full`}>
        <div className="flex flex-row justify-between">
          <div className="text-sm text-gray-600 text-start">Receipt No#</div>
          <div className="justify-end ml-5 text-sm text-gray-600">
            {data?.receipt_no}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="text-sm text-gray-600 text-start">Amount</div>
          <div className="justify-end ml-5 text-sm text-gray-600">
            {data?.payment_amount}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-sm text-gray-600">No of months</div>
          <div className="justify-end ml-5 text-sm text-gray-600">
            {data?.payment_months}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-sm text-gray-600">Monthly Subscription</div>
          <div className="justify-end ml-5 text-sm text-gray-600">
            {data?.monthly_subscription}
          </div>
        </div>
        <div className="flex flex-row justify-between ">
          <div className="text-sm text-gray-600">Year</div>
          <div className="justify-end ml-5 text-sm text-gray-600">
            {data?.financial_year}
          </div>
        </div>
      </div>
      <div className={`px-6 py-4 bg-gray-50`}>
        <p className="text-sm text-gray-600">Payment Status</p>
        <p className="text-sm text-gray-600">
          {" "}
          <Select
            name="status"
            onChange={(selected, dropdown) => {
              setFormData({
                ...formData,
                payment_status: selected?.value,
              });
            }}
            options={paymentOptions}
            placeholder={placeHolderText("Select Source")}
            className={`w-[${inputWidth}] outline-none h-11 mt-3`}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            // value={formData?.status && }
            //styles={style}
            value={
              formData?.payment_status >= 0 &&
              paymentOptions.find((p) => p.value === parseInt(formData?.payment_status))
            }
          />
        </p>
      </div>
      <div className="flex justify-end w-full p-5 ">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={updatePaymentStatus}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ReceiptCard;
