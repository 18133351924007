import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import useFetch from "../../../components/shared/useFetch";
//Global state Access
import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";
import toast from "react-hot-toast";
import { method } from "lodash";

const CreateMonthlyStatementChild = ({ selectedMonth }) => {
  let navigate = useNavigate();

  const [{ errorLogApi, user }] = useAtom(gbState);
  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [incomeData, setIncomeData] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [salaryData, setSalaryData] = useState(null);
  const [incomeTypeOthers, setIncomeTypesOther] = useState(null);
  const [incomeTypeCash, setIncomeTypeCash] = useState(null);
  const [groupIncomeTypeCash, setGroupIncomeTypeCash] = useState(null);
  const [incomeTotalCash, setIncomeTotalCash] = useState(null);
  const [incomeTotalOther, setIncomeTotalOther] = useState(null);
  const [incomeTotalCashSubs, setIncomeTotalCashSubs] = useState(null);
  const [incomeTotalCashSalary, setIncomeTotalCashSalary] = useState(null);
  const [incomeTotalOtherSubs, setIncomeTotalOtherSubs] = useState(null);
  const [incomeTotalOtherSalary, setIncomeTotalOtherSalary] = useState(null);
  const [expenseTypeCash, setExpenseTypeCash] = useState(null);
  const [subscriptionTypeCash, setSubscriptionTypeCash] = useState(null);
  const [salaryTypeCash, setSalaryTypeCash] = useState(null);
  const [groupExpenseTypeCash, setGroupExpenseTypeCash] = useState(null);
  const [expenseTypeOthers, setExpenseTypesOther] = useState(null);
  const [subscriptionTypeOthers, setSubscriptionTypesOther] = useState(null);
  const [salaryTypesOther, setSalaryTypesOther] = useState(null);
  const [subscriptionTypeOthersData, setSubscriptionTypesOtherData] =
    useState(null);
  const [salaryTypeOthersData, setSalaryTypesOtherData] = useState(null);
  const [expenseTotalCash, setExpenseTotalCash] = useState(null);
  const [expenseTotalOther, setExpenseTotalOther] = useState(null);
  const [receiptTotalCash, setReceiptTotalCash] = useState(null);
  const [salaryTotalCash, setSalaryTotalCash] = useState(null);
  const [incomeDate, setIncomeDate] = useState(null);
  const [unfilteredData, setUnfilteredData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [searchInput, setSearchInput] = useState(null);

  const [activeBanks, setActiveBanks] = useState();
  const [openingClosingBalances, setOpeningClosingBalances] = useState([]);
  const [openingBalTotal, setOpeningBalTotal] = useState();
  const [closingBalTotal, setClosingBalTotal] = useState();

  const [netTotalLeft, setNetTotalLeft] = useState();
  const [netTotalRight, setNetTotalRight] = useState();
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  let urlLink = `${process.env.REACT_APP_API_URL}/income/${user.church_id}`;

  //Fetching income data for the page
  const { data, loading, error, refetch } = useFetch(urlLink);

  useEffect(() => {
    setIncomeData(
      data?.incomes?.filter((d) => d?.financial_year === financial_year?.value)
    );
  }, [data, financial_year]);

  //Remove and set cash Records
  useEffect(() => {
    // setIncomeTypeCash(incomeData?.filter((d) => d?.income_source === "Cash"));

    setIncomeTypeCash(
      incomeData?.filter((d) => {
        const date = new Date(d?.date);
        return (
          d?.income_source === "Cash" &&
          date.getMonth() + 1 === +selectedMonth?.value // +1 because getMonth() returns month from 0-11
        );
      })
    );

    // console.log(incomeTypeCash);
    let tempIncomeTypeOther = incomeData?.filter((d) => {
      const date = new Date(d?.date);
      return (
        d?.income_source !== "Cash" &&
        date.getMonth() + 1 === +selectedMonth?.value // +1 because getMonth() returns month from 0-11
      );
    });
    setIncomeTypesOther(tempIncomeTypeOther);
  }, [incomeData]);

  //Group Income Type Cash
  useEffect(() => {
    function summarizeByCategory(data) {
      const summary = {};

      data?.forEach((item) => {
        const category_name = item?.category_id?.category_name;
        const amount = item?.amount;

        if (summary[category_name]) {
          summary[category_name] += amount;
        } else {
          summary[category_name] = amount;
        }
      });

      return Object.entries(summary).map(([category, total_amount]) => ({
        category,
        total_amount,
      }));
    }
    // if (incomeData?.length) {
    setGroupIncomeTypeCash(summarizeByCategory(incomeTypeCash));
    //}
  }, [incomeTypeCash]);

  //Add total of groupedcash
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.total_amount, 0);
    }
    setIncomeTotalCash(calculateTotalAmount(groupIncomeTypeCash));
  }, [groupIncomeTypeCash]);

  //Add Total Income Others
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.amount, 0);
    }
    setIncomeTotalOther(calculateTotalAmount(incomeTypeOthers));
  }, [incomeTypeOthers]);

  // Fecthing member subscribtion collection list--
  urlLink = `${process.env.REACT_APP_API_URL}/receipt/church/${user?.church_id}`;
  const {
    data: subData,
    loading: subLoading,
    error: subError,
    refetch: subRefetch,
  } = useFetch(urlLink);

  // Handling Subscription Data --
  useEffect(() => {
    setSubscriptionData(
      subData?.filter((d) => d?.financial_year === financial_year?.value)
    );

    setSubscriptionData(
      subData?.filter((d) => {
        const date = new Date(d?.createdAt);
        return (
          d?.financial_year === financial_year?.value &&
          // d?.month === selectedMonth?.label
          date.getMonth() + 1 === +selectedMonth?.value
        );
      })
    );
  }, [subData, financial_year]);

  //Remove and set Subscribe cash Records subscriptionTypeCash,subscriptionTypesOther
  useEffect(() => {
    setSubscriptionTypeCash(
      subscriptionData?.filter((d) => d?.payment_mode == "1")
    );
    let tempExpenseTypeOther = subscriptionData?.filter(
      (d) => d?.payment_mode != "1"
    );
    setSubscriptionTypesOther(tempExpenseTypeOther);
  }, [subscriptionData, financial_year]);

  useEffect(() => {
    const sum = subscriptionTypeCash?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.amount),
      0
    );
    setReceiptTotalCash([
      {
        category: "Subscription",
        total_amount: parseInt(sum),
      },
    ]);
    setIncomeTotalCashSubs(parseInt(sum));
  }, [subscriptionTypeCash]);

  useEffect(() => {
    const data = {};
    const dataArray = subscriptionTypeOthers?.map((subs) => {
      return {
        ...data,
        description:
          subs?.subscriber_id?.first_name +
          " " +
          subs?.subscriber_id?.last_name,
        type: subs?.month,
        head: "Subscription",
        amount: subs?.amount,
      };
    });
    setSubscriptionTypesOtherData(dataArray);
    const sum = subscriptionTypeOthers?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.amount),
      0
    );
    setIncomeTotalOtherSubs(parseInt(sum));
  }, [subscriptionTypeOthers]);

  // Fecthing member salary collection list--
  urlLink = `${process.env.REACT_APP_API_URL}/salary/${user?.church_id}`;
  const {
    data: salariesData,
    loading: salaryLoading,
    error: salaryError,
    refetch: salaryRefetch,
  } = useFetch(urlLink);

  useEffect(() => {
    setSalaryData(
      salariesData?.salaries?.filter((d) => {
        return (
          d?.financial_year === financial_year?.value &&
          d?.salary_month === selectedMonth?.label
        );
      })
    );
  }, [salariesData, financial_year]);

  useEffect(() => {
    setSalaryTypeCash(salaryData?.filter((d) => d?.status == "1"));

    let tempExpenseTypeOther = salaryData?.filter((d) => d?.status != "1");
    setSalaryTypesOther(tempExpenseTypeOther);
  }, [salaryData, financial_year]);

  useEffect(() => {
    const sum = salaryTypeCash?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.amount),
      0
    );
    setSalaryTotalCash([
      {
        category: "Salary",
        total_amount: parseInt(sum),
      },
    ]);
    setIncomeTotalCashSalary(parseInt(sum));
  }, [salaryTypeCash]);

  useEffect(() => {
    const data = {};
    const dataArray = salaryTypesOther?.map((sals) => {
      return {
        ...data,
        description:
          sals?.employee_id?.first_name + " " + sals?.employee_id?.last_name,
        type: sals?.salary_month,
        head: "Salary",
        amount: sals?.amount,
      };
    });
    setSalaryTypesOtherData(dataArray);
    const sum = salaryTypesOther?.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.amount),
      0
    );
    setIncomeTotalOtherSalary(parseInt(sum));
  }, [salaryTypesOther]);

  //Fecthing Expense data for the page
  urlLink = `${process.env.REACT_APP_API_URL}/expense/${user?.church_id}`;

  const {
    data: expData,
    loading: expLoading,
    error: expenseError,
    refetch: expenseRefetch,
  } = useFetch(urlLink);

  //set setExpenseData, expenseData
  // useEffect(() => {
  //   setExpenseData(
  //     expData?.expenses?.filter(
  //       (d) => d?.financial_year === financial_year?.value
  //     )
  //   );

  //   setExpenseData(
  //     expData?.expenses?.filter((d) => {
  //       const date = new Date(d?.date);
  //       return (
  //         d?.financial_year === financial_year?.value &&
  //         date.getMonth() + 1 === +selectedMonth?.value
  //       );
  //     })
  //   );
  // }, [expData, financial_year]);
  useEffect(() => {
    if (!expData?.expenses || !financial_year?.value) return;

    const filteredExpenses = expData.expenses.filter((d) => {
      const date = new Date(d?.date);
      const expenseMonth = date.getMonth() + 1; // 1-12
      const expenseYear = date.getFullYear();

      console.log(
        `Expense: ${d.expense_title}, Date: ${d.date}, Month: ${expenseMonth}, Year: ${expenseYear}, Financial Year: ${d.financial_year}`
      );

      const isCorrectFinancialYear = d?.financial_year === financial_year.value;
      const isCorrectMonth =
        !selectedMonth?.value ||
        expenseMonth === parseInt(selectedMonth.value, 10);

      console.log(
        `Correct Financial Year: ${isCorrectFinancialYear}, Correct Month: ${isCorrectMonth}`
      );

      return isCorrectFinancialYear && isCorrectMonth;
    });

    console.log(`Filtered ${filteredExpenses.length} expenses`);
    setExpenseData(filteredExpenses);
  }, [expData, financial_year, selectedMonth]);

  //Remove and set Expense cash Records expenseTypeCash, expenseTypesOther
  useEffect(() => {
    setExpenseTypeCash(
      expenseData?.filter((d) => d?.transfer_source === "Cash")
    );

    let tempExpenseTypeOther = expenseData?.filter(
      (d) => d?.transfer_source !== "Cash"
    );
    setExpenseTypesOther(tempExpenseTypeOther);
  }, [expenseData]);

  //Group Expense Type Cash
  useEffect(() => {
    function summarizeByCategory(data) {
      const summary = {};
      data?.forEach((item) => {
        const category_name = item?.category_id?.category_name;
        const amount = item?.amount;

        if (summary[category_name]) {
          summary[category_name] += amount;
        } else {
          summary[category_name] = amount;
        }
      });

      return Object.entries(summary).map(([category, total_amount]) => ({
        category,
        total_amount,
      }));
    }
    setGroupExpenseTypeCash(summarizeByCategory(expenseTypeCash));
  }, [expenseTypeCash]);

  //Add Total Grouped Expense Cash
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.total_amount, 0);
    }
    setExpenseTotalCash(calculateTotalAmount(groupExpenseTypeCash));
  }, [groupExpenseTypeCash]);

  //Add Total Expense Others
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.amount, 0);
    }
    setExpenseTotalOther(calculateTotalAmount(expenseTypeOthers));
  }, [expenseTypeOthers]);

  //Fecthing Active Banks for the page
  urlLink = `${process.env.REACT_APP_API_URL}/bank-balance/church/${user?.church_id}`;

  const {
    data: bankBalanceData,
    loading: bankBalanceLoading,
    error: bankBalanceError,
    refetch: bankBalanceRefetch,
  } = useFetch(urlLink);

  useEffect(() => {
    //console.log(bankBalanceData);
    setActiveBanks(
      bankBalanceData?.bankBalances?.filter((b) => {
        return b?.bank_id.active_status === true;
      })
    );
  }, [bankBalanceData]);

  useEffect(() => {
    setOpeningClosingBalances(
      activeBanks?.filter((bb) => {
        const selmonth = new Date(bb?.month);
        return (
          selmonth.getMonth() + 1 === +selectedMonth?.value &&
          bb?.financial_year === financial_year?.value
        );
      })
    );
  }, [activeBanks, financial_year]);

  useEffect(() => {
    // console.log(openingClosingBalances);
    setOpeningBalTotal(
      openingClosingBalances?.reduce((accumulator, current) => {
        return accumulator + (current?.opening_balance || 0);
      }, 0)
    );

    setClosingBalTotal(
      openingClosingBalances?.reduce((accumulator, current) => {
        return accumulator + (current?.closing_balance || 0);
      }, 0)
    );
  }, [openingClosingBalances]);

  const proceedMonthlyClosure = () => {
    if (netTotalLeft === netTotalRight) {
      confirmAlert({
        title: "",
        message: "Are you sure to proceed for closure ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => confirmClosure(),
          },
          {
            label: "No",
            //onClick: () => alert('Click No')
          },
        ],
      });
    } else {
      toast.error(
        `Unable to proceed Monthly Closure, please match the final total!`
      );
    }

    const confirmClosure = () => {
      //Call the closure API
      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/closure/monthly`,
        headers: { Authorization: `Bearer ${userToken}` },
        data: { month: selectedMonth, financial_year: financial_year?.value },
      };
      axios(config)
        .then((response) => {
          console.log(response);
          toast.success(`Monthly Closure Completed Sucessfully`);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
          errorLogApi(err, "CreateMonthlyStatementChild", "/monthly/closure");
        });
    };
  };

  useEffect(() => {
    setNetTotalLeft(
      incomeTotalCash +
        incomeTotalOther +
        incomeTotalCashSubs +
        incomeTotalOtherSubs +
        openingBalTotal
    );
  }, [
    incomeTotalCash,
    incomeTotalOther,
    incomeTotalCashSubs,
    incomeTotalOtherSubs,
    openingBalTotal,
  ]);

  useEffect(() => {
    setNetTotalRight(
      expenseTotalCash +
        expenseTotalOther +
        incomeTotalCashSalary +
        incomeTotalOtherSalary +
        closingBalTotal
    );
  }, [
    expenseTotalCash,
    expenseTotalOther,
    incomeTotalCashSalary,
    incomeTotalOtherSalary,
    closingBalTotal,
  ]);

  const downloadPDF = async () => {
    const input = document.getElementById("pdf-content");
    if (!input) {
      console.error("Element not found or not rendered yet");
      return;
    }

    const canvas = await html2canvas(input, {
      scale: 2, // Increased scale for better quality
      logging: false, // Disable logging for production
      useCORS: true,
      windowHeight: input.scrollHeight,
      windowWidth: input.scrollWidth,
    });

    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4",
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    // Calculate scaling factors to fit the image within the PDF page
    const scaleX = pdfWidth / imgWidth;
    const scaleY = pdfHeight / imgHeight;
    const scale = Math.min(scaleX, scaleY);

    // Calculate centered position
    const offsetX = (pdfWidth - imgWidth * scale) / 2;
    const offsetY = (pdfHeight - imgHeight * scale) / 2;

    pdf.addImage(
      canvas.toDataURL("image/jpeg", 1.0),
      "JPEG",
      offsetX,
      offsetY,
      imgWidth * scale,
      imgHeight * scale,
      undefined,
      "FAST"
    );

    // Generate timestamp for filename
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    pdf.save(`monthly-statement-${timestamp}.pdf`);
  };

  //   console.log(expenseTypeOthers);
  return (
    <div className="w-full">
      <div className="flex flex-row justify-end w-full">
        <div className="">
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 mb-1 text-white bg-gray-600 rounded-md hover:bg-gray-900"
          >
            Back
          </button>
        </div>
        &nbsp;
        <div className="">
          <button
            onClick={proceedMonthlyClosure}
            className="px-4 py-2 mb-1 text-white bg-blue-600 rounded-md hover:bg-blue-900"
          >
            Monthly Closure
          </button>{" "}
        </div>
        &nbsp;
        <div>
          <button
            onClick={downloadPDF}
            className="px-4 py-2 mb-1 text-white bg-red-600 rounded-md hover:bg-red-900"
          >
            Download PDF
          </button>
        </div>
      </div>
      <div className="min-w-full" id="pdf-content">
        <div className="flex flex-row w-full">
          <div className="flex items-center justify-center w-full mb-4">
            <span className="text-2xl uppercase text-bold">
              {selectedMonth?.label}({selectedMonth?.value})
            </span>
          </div>
        </div>
        {/* {JSON.stringify(openingClosingBalances)} */}
        {/* {JSON.stringify(expenseData)} */}
        <table className="min-w-full border border-collapse border-gray-800 table-auto">
          <thead>
            <tr>
              <th className="border border-gray-600">Receipts (Cash)</th>
              <th className="border border-gray-600">Payments (Cash)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-top ">
                <OpeningBalance
                  data={openingClosingBalances}
                  openingBalTotal={openingBalTotal}
                />
                <MonthlyStatementCashTables
                  data={groupIncomeTypeCash}
                  data1={receiptTotalCash}
                  total={incomeTotalCash}
                />
              </td>
              <td className="align-top ">
                <MonthlyStatementCashTables
                  data={groupExpenseTypeCash}
                  data1={salaryTotalCash}
                  total={expenseTotalCash}
                />
              </td>
            </tr>
            <tr>
              <th className="border border-gray-600">Receipts (Others)</th>
              <th className="border border-gray-600">Payments (Others)</th>
            </tr>
            <tr>
              <td className="align-top ">
                <MonthlyStatementOtherTables
                  data={incomeTypeOthers}
                  dataSubs={subscriptionTypeOthersData}
                  total={[incomeTotalOther + incomeTotalOtherSubs]}
                />
              </td>
              <td className="align-top ">
                <MonthlyStatementOtherTables
                  data={expenseTypeOthers}
                  dataSubs={salaryTypeOthersData}
                  total={expenseTotalOther + incomeTotalOtherSalary}
                />
                <ClosingBalance
                  data={openingClosingBalances}
                  closingBalTotal={closingBalTotal}
                />
              </td>
            </tr>
            <tr>
              <td className="text-right align-top border-t border-r border-gray-600">
                <NetTotalTable
                  total={
                    incomeTotalCash +
                    incomeTotalOther +
                    incomeTotalCashSubs +
                    incomeTotalOtherSubs +
                    openingBalTotal
                  }
                />{" "}
              </td>
              <td className="text-right align-top border-t border-r border-gray-600">
                <NetTotalTable
                  total={
                    expenseTotalCash +
                    expenseTotalOther +
                    incomeTotalCashSalary +
                    incomeTotalOtherSalary +
                    closingBalTotal
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* {JSON.stringify(incomeTotalOther)}
        {JSON.stringify(expenseTotalOther)} */}
    </div>
  );
};

const NetTotalTable = ({ total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <tbody>
        <tr>
          <td className="border border-gray-600 text-bold">Total</td>
          <td className="border border-gray-600">{total}</td>
        </tr>
      </tbody>
    </table>
  );
};

const MonthlyStatementCashTables = ({ data, data1, total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Description
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Amount
          </th>
        </tr>
      </thead>
      {/* {JSON.stringify(data)} */}
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.category}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.total_amount}
              </td>
            </tr>
          ))}
        {data1 &&
          data1.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.category}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.total_amount}
              </td>
            </tr>
          ))}
        <tr className="hover:bg-gray-300">
          <td className="px-2 py-1 font-bold text-right border-l border-r border-gray-600">
            Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">
            {total + data1?.[0]?.total_amount ?? 0}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const MonthlyStatementOtherTables = ({ data, dataSubs, total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Description
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">Type</th>
          <th className="px-2 py-1 border-b border-r border-gray-600">Head</th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.member_id?.first_name} {item?.member_id?.last_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.income_title ? item?.income_title : item?.expense_title}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.category_id?.category_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.amount}
              </td>
            </tr>
          ))}
        {dataSubs &&
          dataSubs.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.description}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.type}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.head}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.amount}
              </td>
            </tr>
          ))}
        <tr className="hover:bg-gray-300">
          <td
            colSpan={3}
            className="px-2 py-1 font-bold text-right border-l border-r border-gray-600"
          >
            Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">{total}</td>
        </tr>
      </tbody>
    </table>
  );
};
const OpeningBalance = ({ data, openingBalTotal }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Bank Name
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Account/Head
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Opening
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.bank_id?.bank_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.bank_id?.account_number}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.opening_balance}
              </td>
            </tr>
          ))}

        <tr className="hover:bg-gray-300">
          <td
            colSpan={2}
            className="px-2 py-1 font-bold text-right border-l border-r border-gray-600"
          >
            Opening Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">
            {openingBalTotal}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ClosingBalance = ({ data, closingBalTotal }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Bank Name
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Account/Head
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Opening
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.bank_id?.bank_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.bank_id?.account_number}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.closing_balance}
              </td>
            </tr>
          ))}

        <tr className="hover:bg-gray-300">
          <td
            colSpan={2}
            className="px-2 py-1 font-bold text-right border-l border-r border-gray-600"
          >
            Closing Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">
            {closingBalTotal}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CreateMonthlyStatementChild;
