import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const IncomeUpdateChild = ({ incomeRecord }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      boxShadow: "none", // This line disables the blue border
    }),
  };

  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [churchMembers, setChurchMembers] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [useOtherNonMember, setUseOtherNonMember] = useState(
    !!incomeRecord?.other_non_member
  );
  const [formData, setFormData] = useState({
    category_id: incomeRecord?.category_id?._id,
    income_title: incomeRecord?.income_title,
    description: incomeRecord?.description,
    member_id: incomeRecord?.member_id?._id,
    date: incomeRecord?.date,
    amount: incomeRecord?.amount,
    income_source: incomeRecord?.income_source,
    status: incomeRecord?.status,
    other_non_member: incomeRecord?.other_non_member,
  });

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/category/income/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then((response) => {
          const oldData = response.data.Categorys;
          const updatedData = oldData.map((data) => ({
            value: data._id,
            label: data.category_name,
          }));
          setCategoryList(updatedData);
        })
        .catch((err) => {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/church/${user?.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        const members = response.data.users;
        setChurchMembers(
          members.map((member) => ({
            value: member._id,
            label: member.first_name + " " + member.last_name,
          }))
        );
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "IncomeList", "/income");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleCheckboxChange = () => {
    setUseOtherNonMember(!useOtherNonMember);
    setFormData({
      ...formData,
      member_id: useOtherNonMember ? null : formData.member_id,
      other_non_member: useOtherNonMember ? "" : null,
    });
  };

  const handleMemberSelect = (selected) => {
    setFormData({
      ...formData,
      member_id: selected ? selected.value : null,
      other_non_member: selected ? null : formData.other_non_member,
    });
  };

  const handleUpdateIncome = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/income/update/${incomeRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then((response) => {
        toast.success("Receipt Updated Successfully.");
        navigate("/income");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "IncomeUpdateChild", "/income/update");
      });
  };

  const incomeSourceOptions = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Online Transfer" },
    { value: 4, label: "Demand Draft" },
  ];

  const paymentStatusOption = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Settled" },
    { value: 3, label: "Declined" },
  ];

  return (
    <div className="flex justify-center w-full">
      <div className="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={(e) => handleUpdateIncome(e)}
          >
            Update
          </button>
        </div>
        <form autoComplete="off">
          <h2 className="mb-3 text-bold">Update Receipt</h2>

          <div className="m-1 overflow-x-auto outline-none">
            <div className="mt-4">
              <Select
                name="category_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    category_id: selected.value,
                  });
                }}
                options={categoryList}
                placeholder={placeHolderText("Select Category")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  formData?.category_id &&
                  categoryList?.find((c) => c.value === formData?.category_id)
                }
                styles={style}
              />
            </div>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="income_title"
                type="text"
                required
                placeholder="Income Title"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.income_title}
              />
            </div>
            <div className="mt-4">
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date: dateString,
                  });
                }}
                showToday={true}
                value={dayjs(formData?.date, "YYYY-MM-DD")}
              />
            </div>
            <div className="mt-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={useOtherNonMember}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2">Other Non Member</label>
              </div>
              {!useOtherNonMember ? (
                <Select
                  name="member_id"
                  onChange={(selected, dropdown) =>
                    handleMemberSelect(selected)
                  }
                  options={churchMembers}
                  placeholder={placeHolderText("Select Member")}
                  className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={
                    formData?.member_id &&
                    churchMembers?.find((m) => m?.value === formData?.member_id)
                  }
                  styles={style}
                  isClearable
                />
              ) : (
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="other_non_member"
                  type="text"
                  required
                  placeholder="Enter Non Member"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData?.other_non_member}
                />
              )}
            </div>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="amount"
                type="number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.amount}
              />
            </div>
            <div className="mt-4">
              <Select
                name="income_source"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    income_source: selected.label,
                  });
                }}
                options={incomeSourceOptions}
                placeholder={placeHolderText("Select Source")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  formData?.income_source &&
                  incomeSourceOptions?.find(
                    (i) => i.label === formData?.income_source
                  )
                }
                styles={style}
              />
            </div>
            <div className="mt-4">
              <Select
                name="status"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    status: selected.value,
                  });
                }}
                options={paymentStatusOption}
                placeholder={placeHolderText("Select Status")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  formData?.status &&
                  paymentStatusOption.find(
                    (p) => p.value === parseInt(formData?.status)
                  )
                }
                styles={style}
              />
            </div>
            <div className="mt-4">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="description"
                type="text"
                rows={3}
                placeholder="Income Description"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.description}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IncomeUpdateChild;
