import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const BankAddChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    bank_name: null,
    account_number: null,
    account_type: null,
    branch: null,
    date_of_opening: null,
    upi_id: null,
    ifsc_code: null,
  });

  const handleBankRegistration = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/banks/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Bank Created Successfully.");
        navigate("/banks");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateBankChild", "/bank/add");
        // console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const accountTypeOptions = [
    { value: 1, label: "Current" },
    { value: 2, label: "Savings" },
  ];

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        <form onSubmit={(e) => handleBankRegistration(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }} // Added the preventDefault function
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Bank</h2>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="bank_name"
              type="text"
              required
              placeholder="Bank Name"
              autoComplete="off"
              onChange={handleChange}
              value={formData.bank_name}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="account_number"
              type="text"
              required
              placeholder="Account Number"
              autoComplete="off"
              onChange={handleChange}
              value={formData.account_number}
            />
          </div>
          <div className="mt-4 ">
            <Select
              name="account_type"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  account_type: selected.label,
                });
              }}
              options={accountTypeOptions}
              placeholder={placeHolderText("Select Account Type")}
              className={`w-[${inputWidth}] outline-none h-11 mt-3`}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="branch"
              type="text"
              required
              placeholder="Branch"
              autoComplete="off"
              onChange={handleChange}
              value={formData.branch}
            />
          </div>
          <div className="mt-4 ">
            <DatePicker
              className="w-full p-3 h-[38px]"
              name="date_of_opening"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  date_of_opening: date,
                });
              }}
              showToday={true}
              placeholder="---Select Date---"
              format="DD/MM/YYYY"
              defaultValue={
                formData?.date_of_opening && dayjs(formData?.date_of_opening)
              }
            />
          </div>
          <div className="mt-4">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="upi_id"
              type="text"
              required
              placeholder="UPI ID..."
              autoComplete="off"
              onChange={handleChange}
              value={formData.upi_id}
            />
          </div>
          <div className="mt-4">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="ifsc_code"
              type="text"
              required
              placeholder="IFSC code"
              autoComplete="off"
              onChange={handleChange}
              value={formData.ifsc_code}
            />
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default BankAddChild;
