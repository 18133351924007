import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import SubscriberView from "./SubscriberView";
import StatusComponent from "./StatusComponent";

const SubscriberListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Family ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/subscriber/delete/${id}`,
        config
      )
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "AssetListChild", "/income/delete");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/subscriber/church/${user?.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response?.data?.subscribers;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            date: data?.date_of_joining,
            name:
              data?.subscriber_id?.first_name +
              " " +
              data?.subscriber_id?.last_name,
            role: data?.subscriber_id?.user_type,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/subscriber/church/${user?.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response?.data?.subscribers;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
              date: data?.date_of_joining,
              name:
                data?.subscriber_id?.first_name +
                " " +
                data?.subscriber_id?.last_name,
              role: data?.subscriber_id?.user_type,
              monthly_subscription: data?.monthly_subscription,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    getDataFromServer();
  }, []);

  const viewReceipts = (row) => {
    navigate("/receipts", { state: row });
  };

  const paymentStatusOption = ["Pending", "Settled", "Declined"];
  const statusTxtColor = ["text-white", "text-gray-800", "text-white"];
  const statusBgColor = ["bg-orange-400", "bg-green-400", "bg-red-400"];
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params?.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Add/View Receipt(s)">
              <div onClick={() => viewReceipts(params?.row)}>
                <i
                  className="mdi mdi-view-headline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => handleDelete(params?.row?.id)}
                className="my-overlay"
                style={{
                  position: "relative",
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Joining Date",
      width: 115,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => params?.row?.date?.slice(0, 10),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "monthly_subscription",
      headerName: "Monthly Subscription",
      width: 250,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "role",
      headerName: "User Title",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 250,
      align: "center",
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <StatusComponent subscriber_id={params?.row?.subscriber_id?._id} />
      ),
    },
  ];

  const pOptions = [0, 1, 2];
  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 mr-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <button
          className="px-4 py-2 mr-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/subscriber/add")}
        >
          + Add Subscriber
        </button>
        {data.length > 0 && (
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate("/subscriber/subscription_collection")}
          >
            + Member Subscription Collection
          </button>
        )}
      </div>
      <h2>Subscribers List</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <SubscriberView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Income List"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>

      <br />
      {pOptions.map((p) => (
        <span
          className={`inline-block mr-1 px-2 py-1 text-xs font-semibold ${statusBgColor[p]} ${statusTxtColor[p]} rounded-full`}
        >
          &nbsp;&nbsp;
          {paymentStatusOption[p]}
          &nbsp;&nbsp;
        </span>
      ))}

      {/* {JSON.stringify(data)} */}
      {/* <div className="mt-2">
          <TableComponent data={data} />
        </div> */}
    </div>
  );
};

export default SubscriberListChild;
