import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import "react-datepicker/dist/react-datepicker.css";

const ExpenseAddChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [churchMembers, setChurchMembers] = useState(null);
  const [categoryList, setCategoryList] = useState();
  const [churchList, setChurchList] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [formData, setFormData] = useState({
    category_id: null,
    expense_title: null,
    description: null,
    member_id: null,
    date: null,
    amount: null,
    status: null,
    transfer_source: null,
  });

  //Category
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/category/expense/${user?.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          const oldData = response?.data?.Categorys;
          const updatedData = oldData?.map((data) => {
            return {
              value: data?._id,
              label: data?.category_name,
            };
          });

          setCategoryList(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data?.message);
        });
    };
    getDataFromServer();
  }, []);

  //   Church Members
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/church/expense/${user?.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        //console.log(response.data);
        const members = response.data.members;
        setChurchMembers(
          members.map((member) => ({
            value: member?._id,
            label:
              member?.first_name +
              " " +
              member?.last_name +
              " (" +
              member?.user_type +
              ")",
          }))
        );
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "ExpenseAddChild", "/expense/add");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleAddExpense = (e) => {
    e.preventDefault();

    if (!formData?.transfer_source)
      return toast.error("Please select an Transfer source");

    if (!formData?.status) return toast.error("Please select status");

    if (!formData?.amount) return toast.error("Please select expense amount");
    let userToken = localStorage.getItem("token");

    const finData = { ...formData, financial_year: financial_year?.label };

    console.log(finData);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/expense/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: finData,
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Voucher Created Successfully.");
        navigate("/expenses");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "ExpenseAddChild", "/expense/add");
        // console.log(error);
      });
  };

  const incomeSourceOptions = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Online Transfer" },
    { value: 4, label: "Demand Draft" },
  ];

  const paymentStatusOption = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Settled" },
    { value: 3, label: "Declined" },
  ];
  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(financial_year)} */}
        <form onSubmit={(e) => handleAddExpense(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Voucher</h2>

          <div className="m-1 overflow-x-auto outline-none">
            <div className="mt-4 ">
              <Select
                name="category_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    category_id: selected.value,
                  });
                }}
                options={categoryList}
                placeholder={placeHolderText("Select Category")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="mt-4 ">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="expense_title"
                type="text"
                required
                placeholder="Expense Title"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            {/* <div className="w-full mt-4">
              <DatePicker
                selected={startDate}
                className="flex h-[38px] border border-gray-300 p-5 rounded-md"
                onChange={(date) => setStartDate(date)}
                style={{ width: "100%" }}
              />
            </div> */}
            <div className="mt-4 ">
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date: dateString,
                  });
                }}
                // disabledDate={(current) => {
                //   let customDate = moment().format("YYYY-MM-DD");
                //   return current && current < moment(customDate, "YYYY-MM-DD");
                // }}
                showToday={true}
              />
            </div>
            <div className="mt-4 ">
              <Select
                name="member_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    member_id: selected.value,
                  });
                }}
                options={churchMembers}
                placeholder={placeHolderText("Select Member")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>

            <div className="mt-4 ">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="amount"
                type="Number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4 ">
              <Select
                name="transfer_source"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    transfer_source: selected.label,
                  });
                }}
                options={incomeSourceOptions}
                placeholder={placeHolderText("Select Source")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="mt-4 ">
              <Select
                name="status"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    status: selected.value,
                  });
                }}
                options={paymentStatusOption}
                placeholder={placeHolderText("Select Status")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="mt-4 ">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="description"
                type="text"
                rows={3}
                placeholder="Expense Description"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default ExpenseAddChild;
