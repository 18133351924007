import React, { useEffect, useState } from "react";
import axios from "axios";
import ProgressBar from "./ProgressBar";

const StatusComponent = ({ subscriber_id }) => {
  const [data, setData] = useState([]);
  //console.log(subscriber_id);
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/receipt/${subscriber_id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.receipts;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
            };
          });

          setData(updatedData);
          //console.log(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    if (subscriber_id) {
      getDataFromServer();
    }
  }, []);
  return <ProgressBar data={data} />;
};

export default StatusComponent;
