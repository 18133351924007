import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import NoticeUserListChild from "./NoticeUserList";
const NoticeAdd = ({ currentRecord, setModel }) => {
  let navigate = useNavigate();
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    _id: currentRecord?._id ?? null,
    title: currentRecord?.title ?? null,
    description: currentRecord?.description ?? null,
    send_to: currentRecord?.sent_for ?? null,
    users: currentRecord?.mail_sent_to ?? [],
    church_id: user?.church_id,
    createdBy: user?._id,
  });
  const [sendData, setSendData] = useState();

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const handleSelected = ({ currentTarget: input }) => {
    setSendData(input.value);
  };
  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleNotice = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    let config = {};
    if (formData._id) {
      config = {
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/notice/update/${formData._id}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData },
      };
    } else {
      config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/notice/add`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData },
      };
    }
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Notice Send Successfully.");
        // Close the modal after saving
        navigate("/notices");
        setModel(false);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateNoticeChild", "/notice/add");
        // console.log(error);
      });
  };
  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-full">
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleNotice(e)} autoComplete="off">
          <div className="flex justify-end">
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
              type="submit"
            >
              Send
            </button>
          </div>
          <div className="mt-0 ">
            <label htmlFor="role_name" style={{ fontWeight: "bold" }}>
              Title
            </label>
            <input
              className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="title"
              type="text"
              required
              placeholder="Enter Title"
              onChange={handleChange}
              value={formData.title}
            />
          </div>

          <div className="mt-4 mb-4 ">
            <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
              Sent To
            </label>
            <br />
            <div className="mt-0 ">
              <input
                className="w-full h-full p-3 mt-2 outline-none"
                name="send_to"
                type="radio"
                required
                value="all"
                checked={formData?.send_to == "all" ? "checked" : ""}
                onChange={handleSelected}
                onClick={handleChange}
                style={{ height: "20px", width: "40px" }} // Set the height inline
              />
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                All
              </label>
              <input
                className="w-full p-3 mt-2 border-gray-300"
                name="send_to"
                type="radio"
                required
                value="members"
                checked={formData?.send_to == "members" ? "checked" : ""}
                onChange={handleSelected}
                onClick={handleChange}
                style={{ height: "20px", width: "40px" }} // Set the height inline
              />
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                Members
              </label>
              <input
                className="w-full p-3 mt-2 border-gray-300"
                name="send_to"
                type="radio"
                required
                value="families"
                checked={formData?.send_to == "families" ? "checked" : ""}
                onChange={handleSelected}
                onClick={handleChange}
                style={{ height: "20px", width: "40px" }} // Set the height inline
              />
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                Families
              </label>
              <input
                className="w-full p-3 mt-2 border-gray-300"
                name="send_to"
                type="radio"
                required
                value="employees"
                checked={formData?.send_to == "employees" ? "checked" : ""}
                onChange={handleSelected}
                onClick={handleChange}
                style={{ height: "20px", width: "40px" }} // Set the height inline
              />
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                Employees
              </label>
            </div>
            <div className="w-full mt-2 mb-2">
              <NoticeUserListChild
                sendData={currentRecord ? formData.send_to : sendData}
                formData={formData}
                setFormData={setFormData}
              ></NoticeUserListChild>
            </div>
          </div>
          <div className="mt-4 mb-4 ">
            <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
              Description
            </label>
            <textarea
              className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="description"
              rows={3}
              type="text"
              required
              placeholder="Enter Notice Description"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.description}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoticeAdd;
