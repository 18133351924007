import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import EventListChild from "./EventListChild";

const EventList = () => {
  return (
    <MainTemplate>
        <EventListChild />
    </MainTemplate>
  );
};

export default EventList;
