import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ChurchAdminChild from "./ChurchAdminChild";

const ChurchAdmin = () => {
  return (
    <MainTemplate>
      <ChurchAdminChild />
    </MainTemplate>
  );
};

export default ChurchAdmin;
