import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ReportsComponent = () => {
  let navigate = useNavigate();
  let hoverTimer;
  const [reportMenuStatus, setReportMenuStatus] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseLeave = () => {
    clearTimeout(hoverTimer);
    setIsHovered(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => setReportMenuStatus(!reportMenuStatus)}
        // onMouseLeave={() => setCatMenuStatus(!catMenuStatus)}
        // onMouseEnter={handleMouseEnter}
        //   onMouseLeave={handleMouseLeave}
        className="block mt-4 mr-4 text-sm text-gray-800 cursor-pointer lg:inline-block lg:mt-0 hover:text-gray-500"
      >
        Reports ▼
      </div>
      <div
        className={`absolute right-0 left-2 z-10 ${
          reportMenuStatus ? "hidden" : null
        } w-52 mt-6 bg-white rounded-md shadow-lg`}
        onMouseLeave={() => setReportMenuStatus(!reportMenuStatus)}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/reports/income");
          }}
        >
          Income Reports
        </button>
        {/* <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/reports/subscription");
          }}
        >
          Subcription Reports
        </button> */}
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/reports/expense");
          }}
        >
          Expense Reports
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/monthly/statements");
          }}
        >
          Monthly Statements
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/yearly/statement");
          }}
        >
          Yearly Statement
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/reports/events");
          }}
        >
          Event Reports
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/reports/employee");
          }}
        >
          Employee Reports
        </button>
      </div>
    </div>
  );
};

export default ReportsComponent;
