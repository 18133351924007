import React from "react";
import { useLocation } from "react-router-dom";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import BankUpdateChild from "./BankUpdateChild";

const BankUpdate = () => {
  const { state } = useLocation();
  const selectedBank = state;
  return (
    <MainTemplate>
      <CardBackground>
        <BankUpdateChild selectedBank={selectedBank} />
      </CardBackground>
    </MainTemplate>
  );
};

export default BankUpdate;
