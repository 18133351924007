import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const EmployeeAddChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    designation: null,
    mobile: null,
    email: null,
    password: null,
    confirm_password: null,
    gender: null,
    date_of_birth: null,
    address: null,
    date_of_joining: null,
    date_of_leaving: null,
    employee_status: null,
    bank_name: null,
    branch_name: null,
    ifsc_code: null,
    account_number: null,
    user_type: null,
    church_id: user?.church_id,
    account_status: true,
    createdBy: user?._id,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "nonbinary", label: "Non-binary" },
    { value: "other", label: "Other" },
  ];

  const employeeStatusOptions = [
    { value: true, label: "Active" },
    { value: false, label: "Disabled" },
  ];

  const designationOptions = [
    { value: "administrator", label: "Administrator" },
    { value: "counselor", label: "Counselor" },
    { value: "youthassociate", label: "Youth Associate" },
    { value: "childrenassociate", label: "children's Associate" },
    { value: "nurserystaffcooridinator", label: "Nursery Staff Cooridinator" },
    { value: "musicministor", label: "Music Ministor" },
    { value: "organist", label: "Organist" },
    { value: "pianist", label: "Pianist" },
    { value: "security", label: "Security" },
    { value: "seniorpastor", label: "Senior Pastor" },
    { value: "womenministorypastor", label: "Women's Ministory Pastor" },
    { value: "3choirdirector", label: "3,Choir Director" },
  ];

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleUserRegistration = (e) => {
    e.preventDefault();
    // if (formData.password !== formData.confirm_password) {
    //   toast.error("Passwords do not match");
    //   return;
    // }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/employee/add`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, image: selectedImage },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Employee Created Successfully.");
        navigate("/employees");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateEmployeeChild", "/employee/add");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleUserRegistration(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Employee</h2>
          <div className="mt-4 ">
            <label className="block mb-2 text-sm text-gray-500">
              Upload Image
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="image"
              type="file"
              onChange={handleImageChange}
              accept="image/gif, image/jpeg, image/png"
            />
          </div>
          <div className="mt-4 ">
            <label className="block mb-2 text-sm text-gray-500">
              First Name
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="first_name"
              type="text"
              required
              placeholder="Enter First Name"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.first_name}
            />
          </div>
          <div className="mt-4 ">
            <label className="block mb-2 text-sm text-gray-500">
              Last Name
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="last_name"
              type="text"
              placeholder="Enter Last Name"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.last_name}
            />
          </div>
          <div className="m-1 mt-4 overflow-x-auto outline-none">
            <label className="block mb-2 text-sm text-gray-500">
              Designation
            </label>
            <Select
              name="designation"
              onChange={dropDownChange}
              options={designationOptions}
              placeholder={placeHolderText("Select Designation")}
              className={`w-[${inputWidth}] outline-none h-11 mt-3`}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              //styles={style}
            />
          </div>
          <div className="mt-4 ">
            <label className="block mb-2 text-sm text-gray-500">
              Mobile No
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="mobile"
              type="text"
              required
              placeholder="Enter Mobile"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.mobile}
            />
          </div>
          <div className="mt-4 ">
            <label className="block mb-2 text-sm text-gray-500">Email</label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="email"
              type="email"
              required
              placeholder="Enter Email"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.email}
            />
          </div>
          {/* <div className="flex mt-4 mb-0">
            <div className="w-full mb-0">
              <label className="block mb-2 text-sm text-gray-500">
                Password
              </label>
              <input
                type="password"
                name="password"
                autoComplete="new-password"
                onChange={handleChange}
                className={inputElementWidthStyle}
              />
            </div>{" "}
            &nbsp;
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm_password"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
              />
            </div>
          </div> */}
          <div className="flex mt-2">
            <div className="w-full mb-0">
              <label className="block mb-2 text-sm text-gray-500">Gender</label>
              <Select
                name="gender"
                onChange={dropDownChange}
                options={genderOptions}
                className={`w-[${inputWidth}] outline-none h-10`}
                placeholder={placeHolderText("Select Gender")}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
              />
            </div>
            &nbsp;
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Date of Birth
              </label>
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date_of_birth"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_birth: dateString,
                  });
                }}
                disabledDate={(current) => {
                  let customDate = dayjs().format("YYYY-MM-DD");
                  return current && current > dayjs(customDate, "YYYY-MM-DD");
                }}
                showToday={true}
              />
            </div>
          </div>
          <div className="flex mt-2">
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Date of Joining
              </label>
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date_of_joining"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_joining: dateString,
                  });
                }}
                // disabledDate={(current) => {
                //   let customDate = dayjs().format("YYYY-MM-DD");
                //   return current && current >= dayjs(customDate, "YYYY-MM-DD");
                // }}
                showToday={true}
                value={
                  formData?.date_of_joining &&
                  dayjs(formData?.date_of_joining, "YYYY-MM-DD")
                }
              />
            </div>
            &nbsp;
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Date of leaving
              </label>
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date_of_leaving"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_leaving: dateString,
                  });
                }}
                // disabledDate={(current) => {
                //   let customDate = dayjs().format("YYYY-MM-DD");
                //   return current && current >= dayjs(customDate, "YYYY-MM-DD");
                // }}
                showToday={true}
                value={
                  formData?.date_of_leaving &&
                  dayjs(formData?.date_of_leaving, "YYYY-MM-DD")
                }
              />
            </div>
          </div>
          <div className="mt-2 ">
            <label className="block mb-2 text-sm text-gray-500">
              Employee Status
            </label>
            <Select
              name="employee_status"
              onChange={dropDownChange}
              options={employeeStatusOptions}
              className={`w-[${inputWidth}] outline-none h-10`}
              placeholder={placeHolderText("Select Gender")}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              styles={style}
              value={employeeStatusOptions?.find(
                (s) => s.value === formData?.employee_status
              )}
            />
          </div>
          <div className="mt-2 ">
            <label className="block mb-2 text-sm text-gray-500">Address</label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="address"
              type="text"
              required
              placeholder="Enter Address"
              onChange={handleChange}
              value={formData.address}
            />
          </div>
          <div className="flex mt-4 mb-0">
            <div className="w-full mb-4">
              <label className="block mb-2 text-sm text-gray-500">
                Bank Name
              </label>
              <input
                type="text"
                name="bank_name"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
              />
            </div>{" "}
            &nbsp;
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Branch Name
              </label>
              <input
                type="text"
                name="branch_name"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
              />
            </div>
          </div>
          <div className="flex mb-2">
            <div className="w-full mb-4">
              <label className="block mb-2 text-sm text-gray-500">IFSC</label>
              <input
                type="text"
                name="ifsc_code"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
              />
            </div>{" "}
            &nbsp;
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Account
              </label>
              <input
                type="text"
                name="account_number"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
              />
            </div>
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default EmployeeAddChild;
