import React from "react";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import MemberFamiliesChild from "./MemberFamiliesChild";

const MemberFamilies = () => {
  return (
    <MainTemplate>
      <MemberFamiliesChild />
    </MainTemplate>
  );
};

export default MemberFamilies;
