import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import placeholderImage from "../../../images/placeholder.png";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import NewsView from "./NewsView";
import NewsAdd from "../Create/NewsAdd";

const NewsListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);

  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleAddModal = () => {
    setAddShowModal(!addShowModal);
  };
  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/news/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.news;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            title: data.title,
            description: data.description,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/news/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.news;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              title: data.title,
              description: data.description,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, [addShowModal]);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/news/delete/${row.id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "NewsListChild", "/news");
      });
  };
  const handleDownloadClick = (pdfFile) => {
    // Replace 'your-pdf-file.pdf' with the path to your PDF file
    const pdfFilePath = `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/news/${pdfFile}`;

    // Use the 'a' element to trigger the download
    const link = document.createElement('a');
    link.href = pdfFilePath;
    link.download = {pdfFile}; // You can customize the downloaded file name
    link.target = '_blank'; // Open the link in a new tab

    // Dispatch a click event on the 'a' element to start the download
    link.dispatchEvent(new MouseEvent('click'));
  };
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => {
                  toggleAddModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", use: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (params.row.image) {
          if(params.row.image.includes('.pdf'))
          {
            return (
              <div>
                <button onClick={() =>handleDownloadClick(params.row.image)} style={{color:"blue"}}>View Pdf</button>
              </div>
            );
          }else{
            return (
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/news/${params.row.image}`}
                alt="Image"
                className="w-[55px] p-1 mr-1"
              />
            );
          }
        } else {
          return (
            <img
              src={placeholderImage}
              alt="Image"
              className="w-[55px] p-1 mr-1"
            />
          );
        }
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      width: 600,
    },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => {
            toggleAddModal();
            setCurrentRecord({});
          }}
          // disabled
        >
          Add News
        </button>
        {addShowModal && (
          <Modal onClose={toggleAddModal} dialogClassName="modal-90w">
            <h2 className="mb-4 text-xl font-bold">
              {currentRecord?._id ? "Edit" : "Add"} Newsletter
            </h2>
            <hr></hr>
            <NewsAdd currentRecord={currentRecord} setModel={setAddShowModal} />
          </Modal>
        )}
      </div>
      <h2>News List</h2>
      <ErrorBoundaryRoutes route="/newss" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">News Detail</h2>
            <NewsView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="News"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default NewsListChild;
