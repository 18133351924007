import React from "react";
import ViewTemplate from "../../../components/MainTemplate/ViewTemplate";

const ViewModal = ({ currentRecord }) => {
  const data = {
    member_name: currentRecord?.member_id?.first_name
      ? currentRecord?.member_id?.first_name +
        " " +
        currentRecord?.member_id?.last_name
      : "Third Party",
    start_date: currentRecord?.start_date?.slice(0, 10),
    category_name: currentRecord?.category,
    dues_title: currentRecord?.dues_title,
    description: currentRecord?.description,
    pledged_amount: currentRecord?.pledged_amount,
    balance_amount: currentRecord?.balance_amount,
    church_name: currentRecord?.church_id?.church_name,
  };

  let componentRef = React.useRef();

  return <ViewTemplate data={data} />;
};

export default ViewModal;
