import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfPreview = ({ file_url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="pdf-preview-container">
      <div className="pdf-scroll-container">
        {file_url ? (
          <Document file={file_url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        ) : (
          <p>No PDF URL provided</p>
        )}
      </div>
      <a
        href={file_url}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center px-2 py-1 mt-3 text-sm font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
      >
        Download PDF
      </a>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <style jsx>{`
        .pdf-preview-container {
          max-width: 100%;
          width: 100%;
        }
        .pdf-scroll-container {
          max-height: 400px; /* Adjust the max-height as needed */
          overflow: auto;
        }
        .react-pdf__Page {
          margin: 0 auto;
          max-width: 100%;
        }
      `}</style>
    </div>
  );
};

export default PdfPreview;
