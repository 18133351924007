import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import MemberPaymentDuesChild from "./MemberPaymentDuesChild";

const MemberPaymentsDues = () => {
  return (
    <MainTemplate>
      <MemberPaymentDuesChild />
    </MainTemplate>
  );
};

export default MemberPaymentsDues;
