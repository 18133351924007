import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import FamilyListChild from "./FamilyListChild";

const FamilyList = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <FamilyListChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default FamilyList;
