import React from "react";

const DioceseView = ({ currentRecord }) => {
  return (
    <div className="w-full overflow-y-auto">
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
          <tr key={currentRecord.diocese_code}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Diocese ID
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.diocese_code}
            </td>
          </tr>
          <tr key={currentRecord.diocese_name}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Diocese Name
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.diocese_name}
            </td>
          </tr>
          <tr key={currentRecord.country}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Country
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.country}
            </td>
          </tr>
          <tr key={currentRecord.state}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              State
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.state}
            </td>
          </tr>
          <tr key={currentRecord.city}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              City
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.city}
            </td>
          </tr>
          <tr key={currentRecord.segment._id}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Segment
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.segment.segment_name}
            </td>
          </tr>
          <tr key={currentRecord.address}>
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Address
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.address}
            </td>
          </tr>
        </tbody>
      </table>
      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

export default DioceseView;
