import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import UserAddChild from "./UserAddChild";
import CardBackground from "../../../components/MainTemplate/CardBackground";

const UserAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <UserAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default UserAdd;
