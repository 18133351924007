import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import BankView from "./BankView";

const BankListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Bank ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/banks/delete/${row._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "BankListChild", "/banks");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/banks/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.banks;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            bank_name: data?.bank_name,
            account: data?.account_number,
            account_type: data?.account_type,
            branch: data?.branch,
            active_status: data?.active_status,
            payment_gateway_status: data?.payment_gateway_status,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err.response.data.message);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/banks/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          const oldData = response.data.banks;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
              bank_name: data?.bank_name,
              account: data?.account_number,
              account_type: data?.account_type,
              branch: data?.branch,
              active_status: data?.active_status,
              payment_gateway_status: data?.payment_gateway_status,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data);
        });
    };
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => navigate("/bank/update", { state: params.row })}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Balances">
              <div
                onClick={() => navigate("/bank/balance", { state: params.row })}
              >
                <i
                  className="mdi mdi-format-float-none"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "bank_name",
      headerName: "Banks",
      width: 250,
    },
    {
      field: "account_number",
      headerName: "Account Number",
      width: 200,
    },
    {
      field: "account_type",
      headerName: "Account Type",
      width: 180,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 180,
    },
    {
      field: "active_status",
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <span
          className={`inline-block px-2 py-1 text-xs font-semibold ${
            statusBgColor[(params.row.active_status ? 2 : 1) - 1]
          } ${
            statusTxtColor[(params.row.active_status ? 2 : 1) - 1]
          } rounded-full`}
        >
          &nbsp;&nbsp;
          {paymentStatusOption[(params.row.active_status ? 2 : 1) - 1]}
          &nbsp;&nbsp;
        </span>
      ),
    },
    {
      field: "payment_gateway_status",
      disableColumnMenu: true,
      width: 140,
      align: "center",
      headerName: "Gateway",
      renderCell: (params) => (
        <i
          className={`mdi ${
            params?.row?.payment_gateway_status
              ? "mdi-check-circle"
              : "mdi-close-circle"
          } text-2xl`}
          style={{
            color: `${params?.row?.payment_gateway_status ? "green" : "red"}`,
          }}
        ></i>
      ),
    },
  ];

  const paymentStatusOption = ["Disabled", "Enabled"];
  const statusTxtColor = ["text-white", "text-gray-800", "text-white"];
  const statusBgColor = ["bg-orange-400", "bg-green-400", "bg-red-400"];

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/bank/add")}
        >
          Add Bank
        </button>
      </div>
      <h2>Bank List</h2>
      <ErrorBoundaryRoutes route="/banks" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Bank Detail</h2>
            <BankView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Banks"
          checkboxSelection
          style={{ height: "60vh" }}
        />
      </div>
    </div>
  );
};

export default BankListChild;
