import React from "react";

const NoticeView = ({ currentRecord }) => {
  return (
    <div className="w-full overflow-y-auto">
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
        <tr key="title">
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Title
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.title}
            </td>
          </tr>
          <tr key="description">
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Description
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.description}
            </td>
          </tr> 
          <tr key="recipient">
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              Recipient
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.sent_for}
            </td>
          </tr>                             
          <tr key="createdAt">
            <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
              CreatedAt
            </td>
            <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
              {currentRecord.createdAt}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NoticeView;
