import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Select, { components } from "react-select";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AssetSettlementHistoryChild = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState();
  const [settlementOptions, setSettlementOptions] = useState({});
  const [dropDownSettlement, setDropDownSettlement] = useState(null);
  //const ddref = useRef();
  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/church/stocksettle/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.settled_stock;
        const updatedData = oldData.map((data) => {
          return {
            //   ...data,
            id: data?._id,
            settlement_id: data?.settled_stock?.settlement_id,
            settlement_date: data?.settled_stock?.settlement_date,
            asset_id: data?.settled_stock?.asset_id?._id,
            item_code: data?.settled_stock?.asset_id?.item_code,
            asset_name: data?.settled_stock?.asset_id?.asset_name,
            opening_stock: data?.settled_stock?.opening_stock,
            inward_qty: data?.settled_stock?.inward_qty,
            outward_qty: data?.settled_stock?.outward_qty,
            closing_stock: data?.settled_stock?.closing_stock,
          };
        });

        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/church/stocksettle/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.settled_stock;
          const updatedData = oldData.map((data) => {
            return {
              //   ...data,
              id: data?._id,
              settlement_id: data?.settled_stock?.settlement_id,
              settlement_date: data?.settled_stock?.settlement_date,
              asset_id: data?.settled_stock?.asset_id?._id,
              item_code: data?.settled_stock?.asset_id?.item_code,
              asset_name: data?.settled_stock?.asset_id?.asset_name,
              opening_stock: data?.settled_stock?.opening_stock,
              inward_qty: data?.settled_stock?.inward_qty,
              outward_qty: data?.settled_stock?.outward_qty,
              closing_stock: data?.settled_stock?.closing_stock,
            };
          });

          setData(updatedData);
          setUnfilteredData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    getDataFromServer();
  }, []);

  useEffect(() => {
    // Create an array of unique settlements based on settlement_id
    const uniqueSettlements = data.filter(
      (settlement, index, self) =>
        index ===
        self.findIndex((s) => s.settlement_id === settlement.settlement_id)
    );

    // Map the unique settlements to the desired format
    const settlementOptions = uniqueSettlements.map((settlement) => ({
      value: settlement.settlement_id,
      label: `${settlement.settlement_id}\n${
        settlement.settlement_date?.split("T")[0]
      }`,
    }));

    setSettlementOptions(settlementOptions);
  }, [unfilteredData]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "300px",
      marginBottom: "15px",
    }),
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props.label.split("\n")[0]}</div>
        <div style={{ fontSize: "0.8em" }}>{props.label.split("\n")[1]}</div>
      </components.Option>
    );
  };

  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div>{props.data.label.split("\n")[0]}</div>
        <div style={{ fontSize: "0.8em" }}>
          {props.data.label.split("\n")[1]}
        </div>
      </components.SingleValue>
    );
  };

  const handleSearch = (e) => {
    console.log("Search Value...", e?.toLowerCase());
    console.log("data Value...", data);
    setDropDownSettlement(e);
  };

  useEffect(() => {
    if (dropDownSettlement) {
      setData(
        unfilteredData?.filter((item) => {
          return item?.settlement_id === dropDownSettlement;
        })
      );
    } else {
      getDataFromServer();
    }
  }, [dropDownSettlement]);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const SettlementCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params.row.settlement_id}</span>
        <span id="content2">
          <small className="small-font-size">
            {params?.row?.settlement_date?.split("T")[0]}
          </small>
        </span>
      </div>
    );
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params?.row?.asset_name}</span>
        <span id="content2">
          <small className="small-font-size">{params?.row?.item_code}</small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "settlement_id",
      headerName: "Settlement Code",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: SettlementCellRenderer,
    },
    {
      field: "item_code",
      headerName: "Asset Code",
      width: 260,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: MultiLineCellRenderer,
    },
    // {
    //   field: "asset_name",
    //   headerName: "Asset Name",
    //   width: 260,
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    // },
    {
      field: "opening_stock",
      headerName: "Opening Stock",
      width: 180,
      align: "center",
    },
    {
      field: "inward_qty",
      headerName: "Inward QTY",
      width: 180,
      align: "center",
    },
    {
      field: "outward_qty",
      headerName: "Outward QTY",
      width: 180,
      align: "center",
    },
    {
      field: "closing_stock",
      headerName: "Closing Stock",
      width: 180,
      align: "center",
    },
  ];

  return (
    <div className="w-full">
      <div className="">
        <Select
          components={{ Option, SingleValue }}
          name="settlements"
          onChange={(selected, dropdown) => {
            handleSearch(selected.value);
          }}
          className="newPatientSelect"
          placeholder="Select Settlement ID..."
          styles={customStyles}
          value={
            dropDownSettlement
              ? settlementOptions.find((val) => val.id === dropDownSettlement)
              : null
          }
          isSearchable
          // ref={ddref}
          options={settlementOptions}
        />
      </div>{" "}
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Asset List"
        checkboxSelection
        style={{ height: "40vh" }}
      />
    </div>
  );
};

export default AssetSettlementHistoryChild;
