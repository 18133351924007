import React, { useEffect, useState } from "react";
import axios from "axios";
import postImage from "../../../images/t-10.jpg";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ProfileComponent from "../../ChurchMemberPages/Dashboard/ProfileComponent";
import ContextMenuSection from "../../ChurchMemberPages/Dashboard/ContextMenuSection";
import ChatBuddySection from "../Dashboard/ChatBuddySection";
import BuddyRequestsSection from "../Dashboard/BuddyRequestsSection";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import NoticeView from "../../Notice/List/NoticeView";

const NoticeListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);

  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/notice/frontend/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));

        const oldData = response.data.notices;
        const updatedData = oldData
          .filter((item) => new Date(item.createdAt) >= currentDate)
          .map((data) => {
            return {
              ...data,
              id: data._id,
              title: data.title,
              description: data.description,
              sent_for: data.sent_for,
              recipient: data.mail_sent_to.length + " " + data.sent_for,
            };
          });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };
  // Get the current date
  const currentDate = new Date();

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/notice/frontend/${user.church_id}`;
      console.log("Notice URL being called : ", urlLink);
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.notices));
          console.log(response.data.notices);
          const oldData = response.data.notices;
          const updatedData = oldData
            .filter((item) => new Date(item.createdAt) <= currentDate)
            .map((data) => {
              return {
                ...data,
                id: data._id,
                title: data.title,
                description: data.description,
                sent_for: data.sent_for,
                recipient: data.mail_sent_to.length + " " + data.sent_for,
              };
            });

          setData(updatedData);
          console.log(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, [addShowModal]);
  return (
    <div className="flex flex-grow bg-gray-100">
      <div className="hidden w-1/5 md:block">
        <div className="sticky top-0 h-screen overflow-y-auto">
          <ProfileComponent member={user} />
          <ContextMenuSection />
        </div>
      </div>
      <div className="hidden md:flex md:w-4/5">
        <div className="flex w-full overflow-y-auto">
          <div className="w-2/3">
            <ErrorBoundaryRoutes route="/notices" page="ModalView">
              {showModal && (
                <Modal onClose={toggleModal}>
                  <h2 className="mb-4 text-xl font-bold">Notice Detail</h2>
                  <NoticeView currentRecord={currentRecord} />
                </Modal>
              )}
            </ErrorBoundaryRoutes>
            <div className="mt-2">
              {data &&
                data?.map((item, index) => (
                  <CardBackground>
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          {/* {JSON.stringify(item?.created_by?.image)} */}
                          {item?.createdBy?.image !== "undefined" || null ? (
                            <img
                              crossOrigin="anonymous"
                              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/employee/${item?.createdBy?.image}`}
                              style={{ height: "60px" }}
                              className="rounded-full w-[60px] p-0 mr-1"
                            />
                          ) : (
                            <img
                              src={postImage}
                              className="rounded-full w-[100px]"
                            />
                          )}
                          <div className="">
                            <div className="text-sm">
                              {item?.createdBy?.first_name +
                                " " +
                                item?.createdBy?.last_name}{" "}
                              - Shared Notice
                            </div>
                            <div className="text-sm text-gray-400">
                              {moment(item.createdAt).fromNow()}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
                          <i className="mdi mdi-dots-horizontal" />
                        </div>
                      </div>
                      <div className="mt-3">{item.title}</div>
                      <p className="mt-3 mr-10 text-sm text-gray-400">
                        {item.description}
                      </p>
                      <div className="flex flex-row mt-2">
                        <div className="">
                          <i className="mdi mdi-calendar-blank" />
                        </div>
                        <div className="mt-1 ml-2 text-xs text-gray-500">
                          {dayjs(item.createdAt).format("MMMM DD, dddd YYYY")}
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <div className="">
                          <i className="mdi mdi-clock-outline" />
                        </div>
                        <div className="mt-1 ml-2 text-xs text-gray-500">
                          {dayjs(item.createdAt).format("hh:mm A")}
                        </div>
                      </div>
                      <div className="mt-4">
                        {item?.file_type === "image" && item?.upload_file && (
                          <img
                            src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/post/${item?.upload_file}`}
                            className="rounded-md"
                            alt=""
                            crossOrigin="anonymous"
                          />
                        )}
                      </div>
                    </div>
                  </CardBackground>
                ))}
              {data.length == "0" && (
                <CardBackground>
                  <span style={{ fontWeight: "bold" }}>
                    No notice available.
                  </span>
                </CardBackground>
              )}
            </div>
          </div>
          <div className="w-1/3">
            <BuddyRequestsSection />
            <ChatBuddySection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeListChild;
