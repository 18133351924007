import React from "react";
import { useLocation } from "react-router-dom";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import BalanceListChild from "./BalanceListChild";

const BalanceList = () => {
  const { state } = useLocation();
  const selectedBankId = state;
  return (
    <MainTemplate>
      <CardBackground>
        <BalanceListChild selectedBankId={selectedBankId} />
      </CardBackground>
    </MainTemplate>
  );
};

export default BalanceList;
