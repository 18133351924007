import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbMessageState } from "../shared/GlobalState";

const MenuComponent = ({ name, link }) => {
  let navigate = useNavigate();
  const [, setShowMessageComponent] = useAtom(gbMessageState);
  const handleClick = (link) => {
    if (name === "Dashboard") {
      setShowMessageComponent(false);
    }

    navigate(link);
  };

  return (
    <li>
      <div
        onClick={() => handleClick(link)}
        className={`block mt-4 mr-4 text-sm text-gray-800 cursor-pointer lg:inline-block lg:mt-0 hover:text-gray-500 hover:text-bold`}
      >
        {name}
      </div>
    </li>
  );
};

export default MenuComponent;
