import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import DiocesesListChild from "./DiocesesListChild";

const DiocesesList = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <DiocesesListChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default DiocesesList;
