import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import FinancialView from "./FinancialView";
import useFetch from "../../../components/shared/useFetch";
import toast from "react-hot-toast";

const FinancialListChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [selectedChurch, setSelectedChurch] = useState(null);
  const [churchList, setChurchList] = useState([]);
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { data: ChurchData } = useFetch(
    `${process.env.REACT_APP_API_URL}/churches/`
  );

  useEffect(() => {
    if (ChurchData?.churches?.length) {
      setChurchList(
        ChurchData?.churches?.map((church) => ({
          value: church?._id,
          label: church?.church_name,
        }))
      );
    }
  }, [ChurchData]);

  useEffect(() => {
    if (unfilteredData?.length) {
      if (selectedChurch?.value) {
        let filteredData = unfilteredData.filter(
          (c) => c?.church_id?._id === selectedChurch?.value
        );

        // Sort by the starting year of the financial_year
        filteredData.sort((a, b) => {
          const startYearA = parseInt(a.financial_year.split("-")[0], 10);
          const startYearB = parseInt(b.financial_year.split("-")[0], 10);
          return startYearB - startYearA;
        });

        setData(filteredData);
      } else {
        setData(unfilteredData);
      }
    }
  }, [selectedChurch, unfilteredData]);

  const getDataFromServer = async () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/financials/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    try {
      const response = await axios(config);
      const oldData = response?.data?.financialYears;
      const updatedData = oldData?.map((data) => ({
        ...data,
        id: data?._id,
        financial_year: data?.financial_year,
        church_name: data?.church_id?.church_name,
        default_status: data?.default_status,
      }));

      // Log the data to ensure it's correct
      //console.log("Fetched Data:", updatedData);

      // Sort by the starting year of the financial_year
      updatedData.sort((a, b) => {
        const startYearA = parseInt(a?.financial_year?.split("-")[0], 10);
        const startYearB = parseInt(b?.financial_year?.split("-")[0], 10);
        return startYearB - startYearA;
      });

      setData(updatedData);
      setUnfilteredData(updatedData);
    } catch (err) {
      console.log("error api call ", err);
    }
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRecord = async (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/financials/delete/${row.id}`,
        config
      );
      getDataFromServer();
    } catch (err) {
      errorLogApi(err, "FinancialListChild", "/financials");
    }
  };

  const makeThisDefault = async (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/financials/status`,
      headers: { Authorization: `Bearer ${userToken}` },
      data: { id: row?.id, church_id: row?.church_id?._id },
    };

    try {
      await axios(config);
      toast.error("Status updated successfully");
      // Re-fetch the data after status update
      const filterChurch = selectedChurch?.value;
      setSelectedChurch("");
      getDataFromServer();
      console.log(filterChurch);
      if (filterChurch) {
        setSelectedChurch(filterChurch);
        let filteredData = unfilteredData.filter(
          (c) => c?.church_id?._id === filterChurch
        );

        // Sort by the starting year of the financial_year
        filteredData.sort((a, b) => {
          const startYearA = parseInt(a.financial_year.split("-")[0], 10);
          const startYearB = parseInt(b.financial_year.split("-")[0], 10);
          return startYearB - startYearA;
        });
        setData(filteredData);
      }
    } catch (err) {
      errorLogApi(err, "FinancialListChild", "/financials");
    }
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <div className="flex flex-row">
          <Tooltip title="View details">
            <div
              onClick={() => {
                toggleModal();
                setCurrentRecord(params.row);
              }}
            >
              <i
                className="cursor-pointer mdi mdi-eye-circle-outline"
                style={{ fontSize: "15px", color: "black" }}
              ></i>
            </div>
          </Tooltip>
          <Tooltip title="Delete">
            <div
              onClick={() => {
                handleDelete(params.row);
              }}
            >
              <i
                className="mdi mdi-trash-can-outline"
                style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
              ></i>
            </div>
          </Tooltip>
          <Tooltip title="Set Status">
            <div onClick={() => makeThisDefault(params.row)}>
              <i
                className="mdi mdi-checkbox-marked-circle-outline"
                style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
              ></i>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "church_name",
      headerName: "Church Name",
      width: 300,
    },
    {
      field: "financial_year",
      headerName: "Financial Year",
      width: 300,
    },
    {
      field: "default_status",
      headerName: "Default",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      renderCell: (params) => (
        <i
          className={`mdi ${
            params?.row?.default_status
              ? "mdi-check-circle"
              : "mdi-close-circle"
          } text-2xl`}
          style={{
            color: `${params?.row?.default_status ? "green" : "red"}`,
          }}
        ></i>
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="flex items-center justify-end w-full">
        <div className="mr-5">
          <Select
            onChange={(selected) => {
              setSelectedChurch(selected);
            }}
            options={churchList}
            placeholder={placeHolderText("Select Church")}
            className={`w-[200px] outline-none h-11 mt-2`}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={selectedChurch}
          />
        </div>
        <div className="mr-3">
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={() => {
              setData(unfilteredData);
              setSelectedChurch(null);
            }}
          >
            Clear
          </button>
        </div>
        <div className="">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() =>
              navigate("/financial/add", { state: selectedChurch })
            }
          >
            Add Financial Year
          </button>
        </div>
      </div>
      <h2>Financial List</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Financial Detail</h2>
            <FinancialView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Users"
          checkboxSelection
          style={{ height: "40vh" }}
          getRowId={(row) => row.id}
        />
      </div>
    </div>
  );
};

export default FinancialListChild;
