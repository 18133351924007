import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

const AssetOutwardChild = ({ issued }) => {
  let navigate = useNavigate();
  const [issuedAsset, setIssuedAsset] = useState(issued);
  const [financialYear] = useAtom(fYear);
  const [formData, setFormData] = useState({
    asset_id: issuedAsset?.asset_id,
    item_code: issuedAsset?.item_code,
    quantity: null,
    issued_rate: null,
    issued_description: null,
    issue_type: null,
    person: null,
    mobile: null,
    expected_date_of_return: null,
    comments: null,
  });

  const outwardOptions = [
    { value: 1, label: "Sale" },
    { value: 2, label: "Donated" },
    { value: 3, label: "Write Off" },
    { value: 4, label: "Transferred" },
  ];

  const inputWidth = "230px";
  //Error Logging Service & Santize Input
  const [{ errorLogApi, user }] = useAtom(gbState);
  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;
  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleAssetIssuance = () => {
    if (!formData.asset_id) return toast.error("Cannot add blank item");
    if (!formData.quantity) return toast.error("Cannot Issue null");
    if (issuedAsset?.closing_stock < formData.quantity)
      return toast.error(
        `Cannot Issue more than ${issuedAsset?.closing_stock}`
      );

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/church/outward/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, financial_year: financialYear?.label },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Asset Issued Successfully.");
        navigate("/assets/inventory");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetOutwardChild", "/church/outward/add");
        // console.log(error);
      });
  };

  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>{" "}
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
            onClick={handleAssetIssuance}
          >
            Save
          </button>
        </div>
        <fieldset className="p-2 whitespace-normal border w-100">
          <legend className="float-none w-auto p-2 fs-5">Issue Asset</legend>
          <div className="max-w-md mx-auto">
            <form>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Item/Asset Name
                </label>
                <input
                  type="text"
                  name="asset_name"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={issued?.asset_name && issued?.asset_name}
                  disabled
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Item Code
                </label>
                <input
                  type="text"
                  name="item_code"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.item_code && formData?.item_code}
                  disabled
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Available Quantity
                </label>
                <input
                  type="text"
                  name="available_quantity"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={
                    issuedAsset?.closing_stock && issuedAsset?.closing_stock
                  }
                  disabled
                />
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Issue Type
                </label>
                <Select
                  name="issue_type"
                  onChange={(selected, dropdown) => {
                    setFormData({
                      ...formData,
                      [dropdown.name]: JSON.stringify(selected),
                    });
                  }}
                  options={outwardOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Inward type List")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Outward Quantity
                </label>
                <input
                  type="text"
                  name="quantity"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Issued Rate
                </label>
                <input
                  type="text"
                  name="issued_rate"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                />
              </div>
              <div className="mb-2">
                <div className="flex flex-row w-full">
                  <div className="w-full">
                    <label className="block mb-2 text-sm text-gray-500">
                      Person Name
                    </label>
                    <input
                      type="text"
                      name="person"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                    />
                  </div>
                  &nbsp;
                  <div className="w-full">
                    <label className="block mb-2 text-sm text-gray-500">
                      Mobile
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Expected Date of Return
                </label>
                <DatePicker
                  className="w-full mb-2 h-[38px]"
                  name="expected_date_of_return"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      expected_date_of_return: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Description
                </label>
                <textarea
                  type="text"
                  style={{ height: "150px" }}
                  rows="5" // Update the attribute to "rows" instead of "row"
                  name="issued_description"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                />
              </div>
              {/* {JSON.stringify(issuedAsset)} */}
            </form>
            <br />
          </div>
        </fieldset>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default AssetOutwardChild;
