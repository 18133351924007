import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AssetUpdateChild = ({ selectedAsset }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput, bloodgroupOptions }] = useAtom(gbState);
  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [formData, setFormData] = useState({
    asset_name: selectedAsset?.asset_name,
    description: selectedAsset?.description,
    status: selectedAsset?.status,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const handleAssetUpdate = () => {
    if (!formData.asset_name) return toast.error("Cannot add blank item");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/asset/update/${selectedAsset?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Asset Updated Successfully.");
        navigate("/assets");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error?.response?.data));
        errorLogApi(error, "AssetUpdateChild", "/asset/update");
        // console.log(error);
      });
  };

  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>{" "}
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
            onClick={handleAssetUpdate}
          >
            Save
          </button>
        </div>
        <fieldset className="p-2 whitespace-normal border w-100">
          <legend className="float-none w-auto p-2 fs-5">Update Asset</legend>
          <div className="max-w-md mx-auto">
            <form>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Asset Name
                </label>

                <input
                  type="text"
                  name="asset_name"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.asset_name}
                />
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Asset Description
                </label>
                <textarea
                  type="text"
                  style={{ height: "150px" }}
                  rows="5" // Update the attribute to "rows" instead of "row"
                  name="description"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.description}
                />
              </div>
            </form>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default AssetUpdateChild;
