import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

const ApprovalModal = ({ currentRecord, getDataFromServer }) => {
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    if (currentRecord?.transaction_receipt) {
      const extension = currentRecord.transaction_receipt
        .split(".")
        .pop()
        .toLowerCase();
      setIsImage(["jpg", "jpeg", "png", "gif", "webp"].includes(extension));
    }
  }, [currentRecord]);

  const approveTransaction = () => {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      toast.error("User is not authenticated.");
      return;
    }

    if (!currentRecord?._id) {
      toast.error("No record selected.");
      return;
    }

    const urlLink = `${process.env.REACT_APP_API_URL}/receipt/approve/`;

    const config = {
      method: "POST",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
      data: { receipt_id: currentRecord._id },
    };

    axios(config)
      .then((response) => {
        toast.success("The Receipt has been approved");

        // Optional: If you need to update the UI or state, do it here
        getDataFromServer();
      })
      .catch((err) => {
        console.error("Error API call: ", err);
        toast.error("Failed to approve the receipt.");
      });
  };

  return (
    <div className="w-full p-6 mx-auto text-center bg-white rounded-lg shadow-md">
      <h2 className="mb-4 text-2xl font-semibold text-gray-800">
        Transaction Approval
      </h2>
      {currentRecord?.transaction_receipt && (
        <div className="w-full mb-6">
          <h3 className="mb-2 text-lg font-medium text-gray-700">
            Transaction Receipt
          </h3>
          {isImage ? (
            <img
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/receipts/${currentRecord.transaction_receipt}`}
              alt="Transaction Receipt"
              crossOrigin="anonymous"
              className="h-auto w-[40%] rounded-lg shadow-sm"
            />
          ) : (
            <a
              href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/receipts/${currentRecord.transaction_receipt}`}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="inline-block px-4 py-2 text-white transition duration-300 ease-in-out bg-green-500 rounded-lg hover:bg-green-600"
            >
              Download Receipt
            </a>
          )}
        </div>
      )}
      <button
        className="w-full px-6 py-3 text-white transition duration-300 ease-in-out bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        onClick={approveTransaction}
      >
        Approve Transaction
      </button>
    </div>
  );
};

export default ApprovalModal;
