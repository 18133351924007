import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AddBurialChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    date_of_demise: null,
    deceased_name: null,
    age: null,
    profession: null,
    date_of_birth: null,
    location_of_burial: null,
    burial_done_by: null,
    remarks: null,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  function formatString(str) {
    // Remove underscores and split the string into words
    const words = str.replace(/_/g, " ").split(" ");

    // Capitalize the first letter of the first word
    const capitalizedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });

    // Join the words back into a single string
    return capitalizedWords.join(" ");
  }

  const handleBurialRegister = () => {
    const fieldToValidate = [
      "date_of_demise",
      "deceased_name",
      "profession",
      "date_of_birth",
      "location_of_burial",
      "burial_done_by",
    ];

    // Identify empty fields and filter out any undefined entries
    const emptyFields = fieldToValidate
      .filter((f) => !formData[f])
      .map(formatString); // Assuming formatString formats the string as previously discussed

    // Provide feedback if there are any empty fields
    if (emptyFields.length > 0) {
      return toast.error(`${emptyFields.join(", ")} cannot be empty`);
    }

    // Configuration for axios request
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/burial/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
      },
    };

    // Axios request
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
        navigate("/burial/list");
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
        errorLogApi(error, "AddBurial", "/burial/add");
      });
  };

  return (
    <div className="w-full">
      <div className="flex justify-end w-full mt-4">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={handleBurialRegister}
        >
          Create Record
        </button>
      </div>
      <div className="flex flex-col items-center justify-center md:flex-row">
        <fieldset className="w-full p-2 whitespace-normal border md:w-1/3 md:mr-2">
          <legend className="float-none w-auto p-2 fs-5">Burial Details</legend>
          <div className="p-5">
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Date of demise
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="date_of_demise"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      date_of_demise: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Deceased Name
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="deceased_name"
                  type="text"
                  required
                  placeholder="name"
                  onChange={handleChange}
                  value={formData.deceased_name}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">Age</label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="age"
                  type="text"
                  required
                  placeholder="Age"
                  onChange={handleChange}
                  value={formData.age}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Profession
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="profession"
                  type="text"
                  required
                  placeholder="Profession"
                  onChange={handleChange}
                  value={formData.profession}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Date of Birth
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="date_of_birth"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      date_of_birth: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Location of Burial
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="location_of_burial"
                  type="text"
                  required
                  placeholder="Location"
                  onChange={handleChange}
                  value={formData.location_of_burial}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full">
                <label className="block mb-2 text-sm text-gray-500">
                  Burial done by
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="burial_done_by"
                  type="text"
                  required
                  placeholder="Burial Done by"
                  onChange={handleChange}
                  value={formData.burial_done_by}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full">
                <label className="block mb-2 text-sm text-gray-500">
                  Remarks
                </label>
                <textarea
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="remarks"
                  type="text"
                  rows={3}
                  placeholder="Remarks"
                  onChange={handleChange}
                  value={formData.remarks}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default AddBurialChild;
