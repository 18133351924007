import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ProfileComponent from "../../ChurchMemberPages/Dashboard/ProfileComponent";
import ContextMenuSection from "../../ChurchMemberPages/Dashboard/ContextMenuSection";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import PrintButton from "./PrintButton";
import SubscriberCard from "../../ReceiptManagement/List/SubscriberCard";
import { toast } from "react-hot-toast";
import MemberOutstandingPaymentChild from "./MemberOutstandingPaymentsChild";

const MemberPaymentDuesChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState(null);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);
  // get user details
  // console.log(user);
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = "";
    if (user?.role === "CHURCH_ADMIN")
      urlLink = `${process.env.REACT_APP_API_URL}/user/${user?._id}`;
    else
      urlLink = `${process.env.REACT_APP_API_URL}/family/primaryMember/${user?._id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (user?.user?.role === "CHURCH_ADMIN") {
          const oldData = response?.data?.users;
          setData(oldData);
        } else {
          const oldData = response.data.families;
          setUserData(oldData[0]);
        }
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, [user]);
  // console.log(userData);
  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/receipt/overall_receipt/${user?._id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.users)); return;
        const oldData = response.data.receipts;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/receipt/overall_receipt/${user?._id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users)); return;

          const oldData = response.data.receipts;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    getDataFromServer();
  }, []);

  const editPaymentReceipts = (row) => {
    if (parseInt(row?.payment_status) === 1) {
      toast.error("The Payment is already settled");
      return;
    }
    // navigate("/receipt/update", {
    //   state: { receipt: row, subRecord: subscriberRecord },
    // });
  };

  const dateConvertUnixTime = (unixTimestamp) => {
    // Create a new date object from the Unix timestamp
    var date = new Date(unixTimestamp);

    // Convert the date to IST by adding the time difference
    // IST is UTC+5:30, so add 5 hours and 30 minutes to UTC time
    var istOffset = 5.5 * 60 * 60 * 1000; // Convert 5.5 hours to milliseconds
    var istDate = new Date(date.getTime() + istOffset);

    // Extract the date parts
    var day = istDate.getDate();
    var month = istDate.getMonth() + 1; // Months are zero-based in JavaScript
    var year = istDate.getFullYear();

    // Pad the day and month with a leading zero if they are less than 10
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    // Return the formatted IST date
    return day + "-" + month + "-" + year;
  };

  const paymentStatusOption = ["Pending", "Settled", "Declined"];
  const statusTxtColor = ["text-white", "text-gray-800", "text-white"];
  const statusBgColor = ["bg-orange-400", "bg-green-400", "bg-red-400"];
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="Print View">
              <div
                onClick={() => {
                  if (params.row?.payment_status === 0) {
                    toast.error(
                      "Pending payment needs to be verified by admin"
                    );
                  } else {
                    setCurrentRecord(params.row);
                    toggleModal();
                  }
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-cloud-print-outline"
                  style={{ fontSize: "18px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "financial_year",
      headerName: "Year",
      width: 90,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.row.financial_year.trim(),
    },
    {
      field: "receipt_no",
      headerName: "Receipt Code",
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "monthly_subscription",
      headerName: "Month Subscription",
      width: 160,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "payment_months",
      headerName: "Dues",
      width: 80,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "paid_upto_date",
      headerName: "Paid Upto",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => dateConvertUnixTime(params.row.paid_upto_date),
    },
    {
      field: "payment_amount",
      headerName: "Amount",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "payment_status",
      headerName: "Status",
      renderCell: (params) => (
        <span
          className={`inline-block px-2 py-1 text-xs font-semibold ${
            params.row.payment_status >= 0 &&
            statusBgColor[parseInt(params.row.payment_status)]
          } ${
            params.row.payment_status >= 0 &&
            statusTxtColor[parseInt(params.row.payment_status)]
          } rounded-full`}
        >
          &nbsp;&nbsp;
          {params.row.payment_status >= 0 &&
            paymentStatusOption[parseInt(params.row.payment_status)]}
          &nbsp;&nbsp;
        </span>
      ),
    },
  ];

  return (
    <div className="w-full">
      {/* <h2>Subscribers List</h2> */}
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            {/* <h2 className="mb-4 text-xl font-bold"></h2> */}
            <PrintButton
              currentRecord={currentRecord}
              subscriberRecord={user}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="flex flex-grow bg-gray-100">
        <div className="w-full bg-gray-100 md:flex md:flex-grow">
          <div className="hidden w-1/5 md:block">
            <div className="sticky top-0 h-screen overflow-y-hidden">
              <ProfileComponent member={user} />
              <ContextMenuSection />
            </div>
          </div>
          <div className="md:w-4/5 md:block">
            <div className="sticky top-0 h-screen overflow-y-hidden">
              <CardBackground>
                <div className="w-full">
                  <div className="w-full mb-3">
                    <MemberOutstandingPaymentChild />
                  </div>
                  <div className="w-full">
                    <DataGrid
                      rows={data}
                      disableSelectionOnClick
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      displayTitle="Income List"
                      style={{ height: "40vh" }}
                    />
                  </div>
                </div>
              </CardBackground>
            </div>
          </div>
        </div>
      </div>

      {/* {JSON.stringify(subscriberRecord)}
      {JSON.stringify(data)} */}
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

export default MemberPaymentDuesChild;
