import React from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

const ChurchForm = ({
  handleChange,
  dropDownChange,
  formData,
  setFormData,
  indianStatesOption,
  setCityOptions,
  indian_states,
  cityOptions,
  dsegmentOption,
  subscriptionOption,
  dioceseOption,
}) => {
  const inputWidth = "230px";
  const inputElementWidthStyle = `w-[${inputWidth}] p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  return (
    <div className="flex flex-col items-center whitespace-normal md:flex-row md:flex-wrap">
      <div className="m-1 overflow-x-auto outline-none">
        <Select
          name="diocese_id"
          onChange={dropDownChange}
          options={dioceseOption}
          placeholder={placeHolderText("Select Diocese")}
          className={`w-[${inputWidth}] outline-none h-11 `}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
          value={
            formData?.diocese_id &&
            dioceseOption?.find((d) => d.value === formData.diocese_id)
          }
          isDisabled={true}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="church_code"
          type="text"
          required
          placeholder="Enter Church Code"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.church_code && formData?.church_code}
          disabled={true}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="church_name"
          type="text"
          required
          placeholder="Enter Church Name"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.church_name && formData?.church_name}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <Select
          name="segment_id"
          onChange={dropDownChange}
          placeholder={placeHolderText("Select Segment diocese")}
          options={dsegmentOption}
          className={`w-[${inputWidth}] outline-none h-10`}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
          value={
            formData?.segment_id &&
            dsegmentOption?.find((d) => d.value === formData?.segment_id)
          }
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="email"
          type="text"
          required
          placeholder="Enter Email"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.email && formData?.email}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="phone_number"
          type="text"
          required
          placeholder="Enter Phone"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.phone_number && formData?.phone_number}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="mobile_number"
          type="text"
          required
          placeholder="Enter Mobile Number"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.mobile_number && formData?.mobile_number}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="website"
          type="text"
          required
          placeholder="Enter Website https://"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.website && formData?.website}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="country"
          type="text"
          required
          placeholder="Enter Country"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.country && formData?.country}
          disabled={true}
        />
      </div>
      <div className="m-1 overflow-x-auto outline-none">
        <Select
          name="state"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              [dropdown.name]: selected.value,
              city: null,
            });
            setCityOptions(
              indian_states[selected.value].map((d, i) => {
                return { value: d, label: d };
              })
            );
          }}
          value={
            formData?.state && {
              value: formData?.state,
              label: formData?.state,
            }
          }
          placeholder={placeHolderText("Select State")}
          options={indianStatesOption}
          classNamePrefix="my-select"
          className={`w-[${inputWidth}] outline-none`}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
          isDisabled={true}
        />
      </div>
      <div className="h-10 m-1 overflow-x-auto outline-none">
        <Select
          name="city"
          onChange={dropDownChange}
          options={cityOptions}
          className={`w-[${inputWidth}] outline-none h-10`}
          placeholder={placeHolderText("Select City")}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          value={
            formData?.city && { value: formData?.city, label: formData?.city }
          }
          styles={style}
          isDisabled={true}
        />
      </div>
      <div className="h-10 m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="address"
          type="text"
          required
          placeholder="Enter Address"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.address && formData?.address}
        />
      </div>
      {/* <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="no_of_member_licenses"
          type="number"
          required
          placeholder="Enter No. of Licenses"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.no_of_member_licenses}
        />
      </div> */}
      <div className="m-1 overflow-x-auto">
        <Select
          name="subscription_status"
          onChange={dropDownChange}
          options={subscriptionOption}
          placeholder={placeHolderText("Select Subscription")}
          className={`w-[${inputWidth}] outline-none h-10`}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
          value={
            formData?.subscription_status &&
            subscriptionOption?.find(
              (d) => d.value === formData.subscription_status
            )
          }
          isDisabled={true}
        />
      </div>
      {/* <div className="m-1 overflow-x-auto">
        <DatePicker
          className={`w-[${inputWidth}] outline-none h-10`}
          style={{ paddingLeft: "10px" }}
          name="subscription_start_date"
          placeholder="Subscription Start Date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              subscription_start_date: dateString,
            });
          }}
          value={
            formData?.subscription_start_date &&
            moment(formData?.subscription_start_date, "YYYY-MM-DD")
          }
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <DatePicker
          className={`w-[${inputWidth}] outline-none h-10`}
          style={{ paddingLeft: "10px" }}
          name="subscription_end_date"
          placeholder="Subscription End Date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              subscription_end_date: dateString,
            });
          }}
          value={
            formData?.subscription_end_date &&
            moment(formData?.subscription_end_date, "YYYY-MM-DD")
          }
        />
      </div> */}
    </div>
  );
};

export default ChurchForm;
