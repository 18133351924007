import React, { useEffect, useState, useRef } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import toast from "react-hot-toast";
import { gbState } from "../../../components/shared/GlobalState";
import CardTile from "./CardTile";

const MemberFamiliesChild = () => {
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [data, setData] = useState(null);
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/family/${user.church_id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.families;

        setData(removeFamily(oldData, user?._id));
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);

  // Remove the users family
  const removeFamily = (data, primaryMemberId) => {
    return data.filter(
      (family) => family?.primary_member_id?._id !== primaryMemberId
    );
  };

  //send request to buddy
  const addBuddyRequest = (requesterId, recipientId) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/send-buddy-request`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { requesterId, recipientId },
    };

    axios(config)
      .then(function (response) {
        toast.success("Request Sent Successfully.");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  };

  // console.log(data);
  return (
    <div className="flex items-center justify-center w-full p-10 ml-5">
      <div className="flex flex-col">
        <h1 className="mb-2">Family</h1>
        {data && (
          <CardTile
            familiesData={data}
            user={user}
            addBuddyRequest={addBuddyRequest}
            buddies={user?.buddies}
          />
        )}
        {/* {JSON.stringify(user?.buddies)} */}
      </div>
    </div>
  );
};

export default MemberFamiliesChild;
