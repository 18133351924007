import React from "react";

const AssetInwardView = ({ currentRecord }) => {
  return (
    <div class="container mx-auto">
      <table class="min-w-full bg-white border border-gray-300">
        <tbody>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">ID</span>
            </td>
            <td class="px-4 py-2 border-b text-right">{currentRecord?.id}</td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Asset Name</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.asset_name}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Item Code</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.item_code}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Inward Date</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.inward_date}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Asset Tag</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.asset_tag}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Quantity</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.quantity}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Inward Type</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {JSON.parse(currentRecord?.inward_type).label}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Inward Rate</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.inward_rate}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Inward Description</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.inward_description}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Inward Status</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.inward_status}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Comments</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.comments}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Purchaser ID</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.purchaser_id}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AssetInwardView;
