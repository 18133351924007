import React from "react";
import avatar from "../../../images/avatar-9.jpg";
import groupImage from "../../../images/e-2.jpg";
import CardBackground from "./CardBackground";
import ReceiptApprovalComponent from "./ReceiptApprovalComponent";

const HighlightsSection = () => {
  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <div className="flex flex-row">
              <i className="mdi mdi-message-outline" />
              <h2 className="mt-1 ml-2 text-xs text-gray-400">Notifications</h2>
            </div>
          </div>
          <ReceiptApprovalComponent />
        </div>
      </CardBackground>
    </div>
  );
};

const MessageComponent = () => {
  return (
    <div className="mt-7">
      <div className="flex items-center justify-between">
        <div className="flex flex-row ">
          <img src={avatar} className="rounded-full w-[40px] p-1 mr-1" />
          <div className="mt-1">
            <div className="text-xs">Anthony Daugloi</div>
            <div className="text-[9px] text-gray-400">
              Are you coming to the event ?
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-end">
          <div className="text-[10px] text-gray-400">10:20 AM</div>
          <div className="bg-blue-400 rounded-full w-4 h-4 text-[10px] px-1.5 text-white">
            1
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchComponent = () => {
  return (
    <div className="flex items-center p-2 mt-2 bg-gray-100 rounded-md">
      <svg
        className="w-5 h-5 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 18l6-6m0 0l-6-6m6 6H4"
        />
      </svg>
      <input
        type="text"
        className="w-full text-xs text-gray-500 bg-transparent border-0 outline-none"
        placeholder="Search for chats"
      />
    </div>
  );
};

export default HighlightsSection;
