import React from "react";
import ViewTemplate from "../../../components/MainTemplate/ViewTemplate";

const IncomeView = ({ currentRecord }) => {
  const data = {
    date: currentRecord?.date?.slice(0, 10),
    receipt_number: currentRecord?.receipt_number,
    category_name: currentRecord?.category_id?.category_name,
    income_title: currentRecord?.income_title,
    description: currentRecord?.description,
    member_name: currentRecord?.member_id?.first_name
      ? currentRecord?.member_id?.first_name +
        " " +
        currentRecord?.member_id?.last_name
      : "Third Party",
    other_non_member: currentRecord?.other_non_member,
    mobile: currentRecord?.member_id?.mobile,
    amount: currentRecord?.amount,
    income_source: currentRecord?.income_source,
    church_name: currentRecord?.church_id?.church_name,
  };

  let componentRef = React.useRef();

  return <ViewTemplate data={data} />;
};

export default IncomeView;
