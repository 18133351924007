import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ConfirmationRegisterChild from "./ConfirmationRegisterChild";

const ConfirmationRegister = () => {
  return (
    <MainTemplate>
      <ErrorBoundaryRoutes route="/worship/add" page="AddWorship">
        <div className="relative flex flex-col p-10 m-3 space-y-10 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-3">
          <ConfirmationRegisterChild />
        </div>
      </ErrorBoundaryRoutes>
    </MainTemplate>
  );
};

export default ConfirmationRegister;
