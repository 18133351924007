import React from "react";

const ProgressBar = ({ data }) => {
  const totalReceipts = data.length;

  const pendingCount = data.filter((receipt) => receipt.status === 0).length;
  const settledCount = data.filter((receipt) => receipt.status === 1).length;
  const declinedCount = data.filter((receipt) => receipt.status === 2).length;

  const pendingPercent = Math.round((pendingCount / totalReceipts) * 100);
  const settledPercent = Math.round((settledCount / totalReceipts) * 100);
  const declinedPercent = Math.round((declinedCount / totalReceipts) * 100);

  return (
    <div className="relative w-full pt-1">
      <div className="flex h-6 mb-4 overflow-hidden text-xs bg-gray-200 rounded">
        <div
          style={{ width: `${settledPercent}%` }}
          className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
        >
          {settledPercent > 0 && `${settledPercent}%`}
        </div>
        <div
          style={{ width: `${pendingPercent}%` }}
          className="flex flex-col justify-center text-center text-white bg-yellow-500 shadow-none whitespace-nowrap"
        >
          {pendingPercent > 0 && `${pendingPercent}%`}
        </div>
        <div
          style={{ width: `${declinedPercent}%` }}
          className="flex flex-col justify-center text-center text-white bg-red-500 shadow-none whitespace-nowrap"
        >
          {declinedPercent > 0 && `${declinedPercent}%`}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
