import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ReceiptUpdateChild from "./ReceiptUpdateChild";
import { useLocation } from "react-router-dom";

const ReceiptUpdate = () => {
  const { state } = useLocation();
  const receiptRecord = state?.receipt;
  const subRecord = state?.subRecord;
  return (
    <MainTemplate>
      <CardBackground>
        <ReceiptUpdateChild
          receiptRecord={receiptRecord}
          subRecord={subRecord}
        />
      </CardBackground>
    </MainTemplate>
  );
};

export default ReceiptUpdate;
