import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import postImage from "../../../images/no-image.png";
import toast from "react-hot-toast";

const CardTile = ({ familiesData, user, addBuddyRequest, buddies }) => {
  const cardData = [];
  familiesData?.forEach((item, index) => {
    cardData.push({
      id: item._id,
      title: item.family_name,
      name: `${item.primary_member_id?.first_name} ${item.primary_member_id?.last_name}`,
      image: item.image,
      primary_member_id: item?.primary_member_id?._id,
    });
  });
  let navigate = useNavigate();
  const handleIconClick = (member, id) => {
    user?.buddies?.includes(member)
      ? navigate("/family/members", { state: id })
      : toast.error("Request as buddy to access this family");
  };

  const addBuddyfromList = (buddyId, userId) => {
    confirmAlert({
      title: "",
      message: "Are you sure to initiate buddy request?",
      buttons: [
        {
          label: "Yes",
          onClick: () => addBuddyRequest(buddyId, userId),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div className="flex flex-wrap gap-4">
      {cardData.map((fData, index) => (
        <div
          key={index}
          className="w-full p-3 bg-white border border-gray-300 rounded-md shadow-md min-w-[200px] sm:min-w-[300px] md:w-1/2 lg:w-1/3 xl:w-1/5"
        >
          <div className="flex justify-center w-full">
            {fData?.image?.length ? (
              <img
                crossOrigin="anonymous"
                src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${fData.image}`}
                className="rounded-md"
                alt=""
                style={{ height: "200px" }}
              />
            ) : (
              <img
                src={postImage}
                className="rounded-full"
                alt=""
                style={{ height: "200px" }}
              />
            )}
          </div>
          {/* {!fData.image && (
            
          )} */}
          <div className="flex flex-col items-center justify-center">
            <h5
              className="mt-4 text-sm "
              style={{ fontWeight: "bold", fontSize: "smaller" }}
            >
              {fData?.title}
            </h5>
            <div className="text-xs">({fData?.name})</div>
            <button
              onClick={() =>
                handleIconClick(fData?.primary_member_id, fData.id)
              }
              className="block w-full h-8 mt-4 text-xs text-gray-400 bg-gray-100 rounded-md"
            >
              View Members
            </button>
            <button
              onClick={() =>
                addBuddyfromList(user?._id, fData?.primary_member_id)
              }
              className={`block w-full h-8 mt-4 text-xs  ${
                buddies.includes(fData?.primary_member_id)
                  ? "bg-gray-300 text-gray-500"
                  : "bg-blue-400 text-white"
              } rounded-md`}
              disabled={buddies.includes(fData?.primary_member_id)}
            >
              + Add Buddy Request
            </button>
          </div>{" "}
          {/* {JSON.stringify(fData)} */}
        </div>
      ))}
    </div>
  );
};

export default CardTile;
