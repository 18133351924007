import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { indian_states } from "../../../components/Data/IndianStates";
import Select from "react-select";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import ChurchForm from "./ChurchForm";

import useFetch from "../../../components/shared/useFetch";

const ChurchProfileUpdateChild = ({ church_id }) => {
  let navigate = useNavigate();
  const [dsegmentOption, setDsegmentOption] = useState();
  const [cityOptions, setCityOptions] = useState();
  const [dioceseOption, setDioceseOption] = useState();
  const [finacialYearOptions, setFinancialYearOptions] = useState();
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [formData, setFormData] = useState();

  const { data: financial_years } = useFetch(
    `${process.env.REACT_APP_API_URL}/financials/${church_id}`
  );

  useEffect(() => {
    setFinancialYearOptions(
      financial_years?.financialYears?.map((fy) => ({
        value: fy?.financial_year,
        label: fy?.financial_year,
      }))
    );
  }, [financial_years]);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleChurchRegistration = () => {
    // e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/churches/update/${church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Church Updated Successfully.");
        //navigate("/churches");
        getDataFromServer();
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "ChurchUpdateChild", "/churchupdate");
        // console.log(error);
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/churches/${church_id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const churchData = response.data.church;
        setFormData({
          diocese_id: churchData?.diocese_id?._id,
          church_code: churchData?.church_code,
          church_name: churchData?.church_name,
          city: churchData?.city,
          state: churchData?.state,
          country: churchData?.country,
          address: churchData?.address,
          current_financial_year: churchData?.current_financial_year,
          segment_id: churchData?.segment_id,
          email: churchData?.email,
          phone_number: churchData?.phone_number,
          mobile_number: churchData?.mobile_number,
          website: churchData?.website,
        });
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/churches/${church_id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const churchData = response.data.church;
          setFormData({
            diocese_id: churchData?.diocese_id?._id,
            church_code: churchData?.church_code,
            church_name: churchData?.church_name,
            city: churchData?.city,
            state: churchData?.state,
            country: churchData?.country,
            address: churchData?.address,
            current_financial_year: churchData?.current_financial_year,
            segment_id: churchData?.segment_id,
            email: churchData?.email,
            phone_number: churchData?.phone_number,
            mobile_number: churchData?.mobile_number,
            website: churchData?.website,
          });
          //   setFormData(resData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/dsegment/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.dsegments;
          const updatedData = oldData.map((data) => {
            return {
              value: data._id,
              label: data.segment_name,
            };
          });

          setDsegmentOption(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/dioceses/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.dioceses;
          const updatedData = oldData.map((data) => {
            return {
              value: data._id,
              label: <DioceseDescription ddata={data} />,
            };
          });

          setDioceseOption(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: ChangeDropDownFontSize(data) };
  });

  const subscriptionOption = [
    { value: "Active", label: "Active" },
    { value: "Suspended", label: "Suspended" },
    { value: "Archived", label: "Archived" },
  ];

  return (
    <div>
      <div className="flex w-full">
        <div class="p-4 w-full">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>{" "}
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
              onClick={handleChurchRegistration}
            >
              Save
            </button>
          </div>
          <fieldset className="p-2 whitespace-normal border w-100">
            <legend className="float-none w-auto p-2 fs-5">
              Set Current Financial Year (Globally)
            </legend>
            <div className="m-1 overflow-x-auto outline-none">
              <Select
                name="current_financial_year"
                onChange={dropDownChange}
                placeholder={placeHolderText("Select Financial Year")}
                options={finacialYearOptions}
                classNamePrefix="my-select"
                className={`w-[200px] outline-none`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  formData?.current_financial_year &&
                  finacialYearOptions?.find(
                    (fy) => fy?.value === formData?.current_financial_year
                  )
                }
              />
            </div>
          </fieldset>

          <fieldset className="p-2 whitespace-normal border w-100">
            <legend className="float-none w-auto p-2 fs-5">
              Register Church
            </legend>
            <div className="flex flex-col">
              <ChurchForm
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
                indianStatesOption={indianStatesOption}
                dropDownChange={dropDownChange}
                setCityOptions={setCityOptions}
                indian_states={indian_states}
                cityOptions={cityOptions}
                dsegmentOption={dsegmentOption}
                subscriptionOption={subscriptionOption}
                dioceseOption={dioceseOption}
              />
            </div>
          </fieldset>

          {/* {JSON.stringify(formData)} */}
        </div>
      </div>
    </div>
  );
};

export default ChurchProfileUpdateChild;

const DioceseDescription = ({ ddata }) => {
  return (
    <div>
      <span className="text-xs">{ddata.diocese_name}</span>
      <p className="text-[8px] text-gray-500 truncate text-light">
        {ddata.diocese_code} &nbsp; ({ddata.city},{ddata.state})
      </p>
    </div>
  );
};

const ChangeDropDownFontSize = (text) => {
  return <span className="text-sm text-light">{text}</span>;
};
