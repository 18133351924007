import React from "react";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import MemberDashboardChild from "./MemberDashboardChild";

const MemberDashboard = () => {
  return (
    <MainTemplate>
      <MemberDashboardChild />
    </MainTemplate>
  );
};

export default MemberDashboard;
