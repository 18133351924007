import React from "react";

const EventView = ({ currentRecord }) => {
  return (
    <div className="w-full overflow-y-auto">
      <div className="flex flex-col ">
        <div className="flex items-center justify-center w-full">
          {currentRecord?.image && !currentRecord?.image.includes('.pdf') && (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/news/${currentRecord?.image}`}
              alt="Image"
              className="w-[600px] p-1 mr-1"
            />
          )}
        </div>
        <div className="w-full mt-3">
          <table className="w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr key="title">
                <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
                  Title
                </td>
                <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
                  {currentRecord.title}
                </td>
              </tr>
              <tr key="description">
                <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
                  Description
                </td>
                <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
                  {currentRecord.description}
                </td>
              </tr>
              <tr key="createdAt">
                <td className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap">
                  CreatedAt
                </td>
                <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
                  {currentRecord.createdAt}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EventView;
