import React, { useEffect, useState } from "react";
import avatar from "../../../images/avatar-9.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import CardBackground from "./CardBackground";
import { confirmAlert } from "react-confirm-alert";
import { useAtom } from "jotai";
import {
  gbState,
  globalUserStatus,
} from "../../../components/shared/GlobalState";

const BuddyRequestsSection = () => {
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [userGlobal, setUserGlobal] = useAtom(globalUserStatus);
  const [buddies, setBuddies] = useState();
  const [updatedUser, setUpdatedUser] = useState();

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/buddies-details`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { buddies: userGlobal?.buddyRequests },
    };

    axios(config)
      .then(function (response) {
        //toast.success("Request Sent Successfully.");

        setBuddies(response?.data);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  }, [userGlobal]);

  const updateUserDetailRealtime = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/refresh-user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { userId: userGlobal?._id },
    };

    axios(config)
      .then(function (response) {
        //toast.success("Request Sent Successfully.");

        setUpdatedUser(response?.data);
        setUserGlobal({ ...userGlobal, ...response?.data });
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  };

  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <div className="flex flex-row">
              <i className="mdi mdi-account-plus" />
              <h2 className="mt-1 ml-2 text-xs text-gray-400">Requests</h2>
            </div>
          </div>
          <SearchComponent />
          {buddies &&
            buddies?.map((b) => (
              <RequestComponent
                buddy={b}
                user={userGlobal}
                errorLogApi={errorLogApi}
                updateUserDetailRealtime={updateUserDetailRealtime}
              />
            ))}

          {/* {JSON.stringify(updatedUser)} */}
          {/* <RequestComponent />
          {JSON.stringify(buddies)} */}
          {/* {JSON.stringify(userGlobal?.user)} */}
        </div>
      </CardBackground>
    </div>
  );
};

const RequestComponent = ({
  buddy,
  user,
  errorLogApi,
  updateUserDetailRealtime,
}) => {
  let navigate = useNavigate();

  const acceptBuddy = (buddy_Id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/accept-buddy-request`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { userId: buddy_Id, requesterId: user?._id },
    };

    axios(config)
      .then(function (response) {
        updateUserDetailRealtime();
        toast.success("Buddy added Successfully.");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  };

  const acceptBuddyRequest = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to Add as Buddy?",
      buttons: [
        {
          label: "Yes",
          onClick: () => acceptBuddy(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <div className="mt-1">
      <div className="flex items-center justify-between">
        <div className="flex flex-row ">
          <img src={avatar} className="rounded-full w-[40px] p-1 mr-1" />
          <div className="mt-2">
            <div className="text-xs">
              {buddy?.first_name} &nbsp;{buddy?.last_name}
            </div>
            <div className="text-[9px] text-gray-400">
              Requesting Approval
              {/* ({buddy?._id}) */}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end justify-end">
          <div className="text-[10px] text-gray-400">
            <button
              onClick={() => acceptBuddyRequest(buddy?._id)}
              className="block w-full h-8 p-2 m-1 mt-4 text-xs text-white bg-blue-400 rounded-full hover:bg-blue-600"
            >
              Approve
            </button>
          </div>
          &nbsp;
          <div>
            <button
              onClick={() => {}}
              className="block w-full h-8 p-2 m-1 mt-4 text-xs text-white bg-gray-400 rounded-full hover:bg-gray-600"
            >
              Deny
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchComponent = () => {
  return (
    <div className="flex items-center p-2 mt-2 bg-gray-100 rounded-md">
      <svg
        className="w-5 h-5 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 18l6-6m0 0l-6-6m6 6H4"
        />
      </svg>
      <input
        type="text"
        className="w-full text-xs text-gray-500 bg-transparent border-0 outline-none"
        placeholder="Search Requests"
      />
    </div>
  );
};
export default BuddyRequestsSection;
