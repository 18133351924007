import React from "react";
import ResponsiveTable from "./ResponsiveTable";

const FamilyView = ({ currentRecord }) => {
    const selectedKeys = ["full_name","first_name", "last_name", "mobile" , "mobile", "email" , "blood_group" , "date_of_birth", "qualification" ,"birth_place"];
  return (
    <div className="">
        <div className="flex items-center justify-center w-full">
          {currentRecord?.image && (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${currentRecord?.image}`}
              alt="Image"
              className="w-[400px] p-1 mr-1"
            />
          )}
        </div>
        <div className="p-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {selectedKeys.map((key, index) => (
            <div key={index} className="p-2 border">
                <div className="font-bold capitalize">{key.replace(/_/g, " ")}</div>
                <div className="mt-1">{currentRecord[key]?.toString()}</div>
            </div>
            ))}
        </div>
        </div>
    </div>
  );
};

export default FamilyView;