import React from "react";
import { useLocation } from "react-router-dom";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import IncomeDuesReceiptChild from "./IncomeDuesReceiptChild";

const IncomeDuesReceipt = () => {
  const location = useLocation();
  const selectedRecord = location.state;
  return (
    <MainTemplate>
      <CardBackground>
        <IncomeDuesReceiptChild selectedRecord={selectedRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default IncomeDuesReceipt;
