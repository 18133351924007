import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import placeholderImage from "../../../images/placeholder.png";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import EmployeeView from "./EmployeeView";

const EmployeesListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Employee ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/employee/delete/${row._id}`,
        config
      )
      .then((response) => {
        toast.success("Employee Deleted Successfully.");
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "EmployeesListChild", "/employees");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/employee/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.employees));
        const oldData = response.data.employees;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
            role: data.role,
            status: data.user_type,
            church_name: data?.church_id,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err.response.data.message);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      //console.log(user.church_id);
      let urlLink = `${process.env.REACT_APP_API_URL}/employee/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.employees));
          const oldData = response.data.employees;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              name: `${data.first_name} ${data.last_name}`,
              email: data.email,
              role: data.designation,
              status: data.user_type,
              bank_name: data.bank_detail[0]?.bank_name,
              branch_name: data.bank_detail[0]?.branch_name,
              ifsc_code: data.bank_detail[0]?.ifsc_code,
              account_number: data.bank_detail[0]?.account_number,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() =>
                  navigate("/employee/update", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        if (params.row.image) {
          return (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/employee/${params.row.image}`}
              alt="Image"
              className="rounded-full w-[35px] p-1 mr-1"
            />
          );
        } else {
          return (
            <img
              src={placeholderImage}
              alt="Image"
              className="rounded-full w-[35px] p-1 mr-1"
            />
          );
        }
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 250,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 140,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 140,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      width: 180,
    },
  ];
  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/employeeadd")}
        >
          Add Employee
        </button>
      </div>
      <h2>All Employees</h2>
      <ErrorBoundaryRoutes route="/employees" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Employee Detail</h2>
            <EmployeeView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Employees"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default EmployeesListChild;
