import React, { useState, useEffect } from "react";

import useFetch from "../../components/shared/useFetch";
import RecordDetailsTable from "./RecordDetailsTable";

const OnboardingCompleted = ({
  churchId,
  setCurrentStep,
  setChurchId,
  setOnBoarding,
  setSavedRecordId,
}) => {
  const restartOnboarding = () => {
    setChurchId(null);
    setCurrentStep(1);
    setOnBoarding(false);
    setSavedRecordId(null);
  };
  //Get the church
  let urlLink = `${process.env.REACT_APP_API_URL}/churches/${churchId}`;
  const {
    data: churchRecord,
    loading: loading_church,
    error: error_church,
    refetch: refetch_church,
  } = useFetch(urlLink);
  const keysToShow = [
    "church_name",
    "church_code",
    "city",
    "state",
    "country",
    "diocese_id",
  ];

  //Get Financial Years
  let financialYearsUrl = `${process.env.REACT_APP_API_URL}/financials/${churchId}`;
  const {
    data: financialYears,
    loading: loading_fy,
    error: error_fy,
    refetch: refetch_fy,
  } = useFetch(financialYearsUrl);

  const fy_table_fields = ["financial_year"];

  //Get Registered User
  let churchAdminURL = `${process.env.REACT_APP_API_URL}/user/ca/${churchId}`;
  const {
    data: churchAdmin,
    loading: loading_user,
    error: error_user,
    refetch: refetch_user,
  } = useFetch(churchAdminURL);

  const user_table_fields = [
    "first_name",
    "last_name",
    "mobile",
    "email",
    "role",
  ];

  return (
    <div>
      <div className="ml-1 text-green-600 text-end">
        <i className="mdi mdi-check-circle"></i>Completed
      </div>

      {churchRecord ? (
        <RecordDetailsTable
          jdata={churchRecord?.church}
          keysToShow={keysToShow}
          title="Church Details"
        />
      ) : (
        "Church Details loading..."
      )}
      {financialYears ? (
        <RecordDetailsTable
          jdata={financialYears?.financialYears[0]}
          keysToShow={fy_table_fields}
          title="Financial Years"
        />
      ) : (
        "FY details loading..."
      )}
      {churchAdmin ? (
        <RecordDetailsTable
          jdata={churchAdmin?.users[0]}
          keysToShow={user_table_fields}
          title="Church Admin"
        />
      ) : (
        "User details loading..."
      )}

      <div className="flex justify-between mt-4">
        <button
          onClick={restartOnboarding}
          className={`px-4 py-2 font-bold text-white bg-blue-500 rounded`}
        >
          Restart
        </button>
      </div>
    </div>
  );
};

export default OnboardingCompleted;
