import React, {useState, useEffect} from "react";
import moment from "moment";

const ReceiptHtml = ({ receiptDta }) => {
    const [rctData, setRctData] = useState(null)

    useEffect(()=>{
        setRctData(receiptDta)
    }, [receiptDta])
    console.log(rctData);
    const totalAmount = rctData?.reduce((sum, item) => sum + parseInt(item.amount, 10), 0);
    const paymentMethods = {
        '1': 'Cash',
        '2': 'Cheque',
        '3': 'Online Transfer',
        '4': 'Demand Draft'
      };
   const paymentMode = paymentMethods[rctData?.[0]?.payment_mode ?? rctData];

  return (
    <>
    <table className="min-w-full table-auto">
      <thead className="bg-gray-200">
            <tr>
                <th className="px-4 py-2 text-left" colSpan={2}>Subscription for the month Of :-</th>
            </tr>
      </thead>
      <tbody>
        {rctData?.map(item => 
         (<tr
            key={item.month}
          >
            <td className="px-4 py-2" style={{ textAlign: 'left' }}>{moment(new Date(item.payment_date)).format("MMM' YYYY")}</td>
            <td className="px-4 py-2" style={{ textAlign: 'right' }}>{item.amount}</td>
          </tr>)
            ) 
          }
        </tbody>
    </table> 
    <div className="flex flex-row justify-between mt-3 ">
        <div className="text-sm text-gray-600"><span className="font-bold">Payment Mode :</span>  {paymentMode}</div>
        <div className="text-sm text-gray-600"><span className="font-bold">Total :</span>   {totalAmount}</div>
    </div>
    </>
  );
};

export default ReceiptHtml;
