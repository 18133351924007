import React from "react";
import { useLocation } from "react-router-dom";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import CreateMonthlyStatementChild from "./CreateMonthlyStatementChild";

const CreateMonthlyStatement = () => {
  const { state } = useLocation();
  const selectedMonth = state?.month;

  return (
    <MainTemplate>
      <CardBackground>
        <CreateMonthlyStatementChild selectedMonth={selectedMonth} />
      </CardBackground>
    </MainTemplate>
  );
};

export default CreateMonthlyStatement;
