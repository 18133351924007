import React, { useState, useEffect } from "react";

const NotificationList = ({ notifications, onApprove }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedNotifications, setSortedNotifications] = useState([]);
  const notificationsPerPage = 10;

  useEffect(() => {
    const sorted = [...notifications].sort(
      (a, b) => new Date(b.receipt_date) - new Date(a.receipt_date)
    );
    setSortedNotifications(sorted);
  }, [notifications]);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;
  const currentNotifications = sortedNotifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  const totalPages = Math.ceil(
    sortedNotifications.length / notificationsPerPage
  );

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="max-w-2xl p-4 mx-auto">
      <h2 className="mb-4 text-2xl font-bold">Notifications</h2>
      {currentNotifications.map((notification) => (
        <div
          key={notification._id}
          className="p-4 mb-4 bg-gray-100 border-l-4 border-blue-500 rounded-lg shadow-md"
        >
          <h3 className="text-lg font-semibold text-blue-600">
            Receipt #{notification.receipt_no}
          </h3>
          <p className="text-sm font-medium">
            Subscriber: {notification.subscriber_id.first_name}{" "}
            {notification.subscriber_id.last_name}
          </p>
          <p className="text-sm text-gray-600">
            Date: {formatDate(notification.receipt_date)}
          </p>
          <p className="text-sm">Amount Paid: ${notification.payment_amount}</p>
          <p className="text-sm">
            Payment Status:
            <span
              className={`font-semibold ${
                notification.payment_status === 0
                  ? "text-yellow-600"
                  : "text-green-600"
              }`}
            >
              {notification.payment_status === 0 ? " Pending" : " Completed"}
            </span>
          </p>
          <p className="text-sm">Remark: {notification.remark}</p>
          <div className="flex mt-2 space-x-2">
            <a
              href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/receipts/${notification.transaction_receipt}`}
              crossOrigin="anonymous"
              target="_blank"
              download
              className="text-sm text-blue-500 hover:underline"
            >
              Download Receipt
            </a>
            <button
              onClick={() => onApprove(notification._id)}
              className="px-2 py-1 text-sm text-white bg-green-500 rounded hover:bg-green-600"
            >
              Approve
            </button>
          </div>
        </div>
      ))}
      <div className="flex items-center justify-between mt-4">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span className="text-sm">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NotificationList;
