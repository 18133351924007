import React, { useEffect, useState, useRef } from "react";
import avatar from "../../../images/no-image.png";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import axios from "axios";
import CardBackground from "./CardBackground";

const ProfileComponent = ({ member }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/family/primaryMember/${member._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.families;
        setData(oldData[0]);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);
  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <ProfileStackedComponent member={member} memberData={data} />
          {/* {JSON.stringify(member)} */}
        </div>
      </CardBackground>
    </div>
  );
};

const ProfileStackedComponent = ({ member, memberData }) => {
  return (
    <div className="mt-2">
      <button className="block w-[70px] h-[20px] text-white bg-green-500 text-xs rounded-full">
        Member
      </button>
      <div className="flex flex-row justify-center w-full">
        {/* {JSON.stringify(memberData?.birth_month)} */}
        <div className="">
          {memberData?.image && (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${memberData?.image}`}
              style={{ height: "150px" }}
              className="rounded-full w-[150px]"
            />
          )}
          {!memberData?.image && (
            <img src={avatar} className="rounded-full w-[350px]" />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center mt-2 text-lg">
        <h1>{member?.name}</h1>
      </div>
      <div className="flex items-center justify-center">
        <h3 className="text-sm">{member?.email}</h3>
      </div>
      <div className="flex flex-row justify-between mt-10">
        <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
          <i className="mdi mdi-gift"> </i>
        </div>
        <div className="mt-3 ml-2 text-xs" style={{ width: "250px" }}>
          {memberData?.birth_day
            ? dayjs(
                memberData?.birth_month + "-" + memberData?.birth_day
              ).format("MMMM D") +
              (memberData?.birth_year ? " , " + memberData?.birth_year : "")
            : "N/A"}
        </div>
      </div>

      {memberData?.birth_place && (
        <div className="flex flex-row justify-between mt-3">
          <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
            <i className="mdi mdi-crosshairs-gps" />
          </div>
          <div className="mt-3 ml-2 text-xs" style={{ width: "250px" }}>
            {memberData?.birth_place ?? memberData?.birth_place}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileComponent;
