import React from "react";
import Select from "react-select";
import { DatePicker } from "antd";

const ChurchForm = ({
  handleChange,
  dropDownChange,
  formData,
  setFormData,
  indianStatesOption,
  setCityOptions,
  indian_states,
  cityOptions,
  dsegmentOption,
  countriesOption,
  cityStateInput,
  setCityStateInput,
  subscriptionOption,
  dioceseOption,
}) => {
  const inputWidth = "230px";
  const inputElementWidthStyle = `w-[${inputWidth}] p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  return (
    <div
      className="flex flex-col items-center whitespace-normal md:flex-row md:flex-wrap"
      style={{ wordBreak: "break-word" }}
    >
      {/* {JSON.stringify(formData)} */}
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="church_code"
          type="text"
          required
          placeholder="Enter Church Code"
          autoComplete="new-password"
          disabled
          value={formData?.church_code && formData?.church_code}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="church_name"
          type="text"
          required
          placeholder="Enter Church Name"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto outline-none">
        <Select
          name="diocese_id"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              diocese_id: selected.value,
              segment_id: selected.dsegment,
            });
          }}
          options={dioceseOption}
          placeholder={placeHolderText("Select Diocese")}
          className={`w-[${inputWidth}] outline-none h-11 `}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
        />
      </div>

      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="segment_id"
          type="text"
          required
          placeholder="Diocese Segment"
          disabled
          onChange={handleChange}
          value={formData?.segment_id}
        />
        {/* <Select
          name="segment_id"
          onChange={dropDownChange}
          placeholder={placeHolderText("Select Segment diocese")}
          options={dsegmentOption}
          className={`w-[${inputWidth}] outline-none h-10`}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
        /> */}
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="email"
          type="text"
          required
          placeholder="Enter Email"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="phone_number"
          type="text"
          required
          placeholder="Enter Phone"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="mobile_number"
          type="text"
          required
          placeholder="Enter Mobile Number"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="website"
          type="text"
          required
          placeholder="Enter Website https://"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        {/* <input
          className={inputElementWidthStyle}
          name="country"
          type="text"
          required
          placeholder="Enter Country"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData.country && formData.country}
        /> */}

        <Select
          name="country"
          onChange={(selected, dropdown) => {
            if (selected.value === "IN") {
              setCityStateInput(true);
              setFormData({
                ...formData,
                country: selected.value,
                state: null,
                city: null,
              });
            } else {
              setCityStateInput(false);
              setFormData({
                ...formData,
                country: selected.value,
                state: null,
                city: null,
              });
            }
          }}
          options={countriesOption}
          className={`w-[${inputWidth}] outline-none h-11 `}
          placeholder={placeHolderText("Select Country")}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      {cityStateInput ? (
        <div className="m-1 overflow-x-auto outline-none">
          <Select
            name="state"
            onChange={(selected, dropdown) => {
              setFormData({
                ...formData,
                [dropdown.name]: selected.value,
                city: null,
              });
              setCityOptions(
                indian_states[selected.value].map((d, i) => {
                  return { value: d, label: d };
                })
              );
            }}
            placeholder={placeHolderText("Select State")}
            options={indianStatesOption}
            classNamePrefix="my-select"
            className={`w-[${inputWidth}] outline-none h-10`}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            styles={style}
          />
        </div>
      ) : (
        <div className="h-10 m-1 overflow-x-auto">
          <input
            className={inputElementWidthStyle}
            name="state"
            type="text"
            required
            placeholder="Enter State"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData?.state && formData?.state}
          />
        </div>
      )}
      {cityStateInput ? (
        <div className="h-10 m-1 overflow-x-auto">
          <Select
            name="city"
            onChange={dropDownChange}
            options={cityOptions}
            className={`w-[${inputWidth}] outline-none h-10`}
            placeholder={placeHolderText("Select City")}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={
              formData.city && { value: formData.city, label: formData.city }
            }
            styles={style}
          />
        </div>
      ) : (
        <div className="h-10 m-1 overflow-x-auto ">
          <input
            className={inputElementWidthStyle}
            name="city"
            type="text"
            required
            placeholder="Enter City"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData?.city && formData?.city}
          />
        </div>
      )}
      <div className="h-10 m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="address"
          type="text"
          required
          placeholder="Enter Address"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="no_of_member_licenses"
          type="number"
          required
          placeholder="Enter No. of Licenses"
          autoComplete="new-password"
          onChange={handleChange}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <Select
          name="subscription_status"
          onChange={dropDownChange}
          options={subscriptionOption}
          placeholder={placeHolderText("Select Subscription")}
          className={`w-[${inputWidth}] outline-none h-10`}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <DatePicker
          className={`w-[${inputWidth}] outline-none h-10`}
          style={{ paddingLeft: "10px" }}
          name="subscription_start_date"
          placeholder="Subscription Start Date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              subscription_start_date: dateString,
            });
          }}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <DatePicker
          className={`w-[${inputWidth}] outline-none h-10`}
          style={{ paddingLeft: "10px" }}
          name="subscription_end_date"
          placeholder="Subscription End Date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              subscription_end_date: dateString,
            });
          }}
        />
      </div>
    </div>
  );
};

export default ChurchForm;
