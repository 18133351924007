import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import MemberOutstandingPaymentChild from "./MemberOutstandingPaymentsChild";

const MemberOutstandingPayments = () => {
  return (
    <MainTemplate>
      <MemberOutstandingPaymentChild />
    </MainTemplate>
  );
};

export default MemberOutstandingPayments;
