import React from "react";
import { useNavigate } from "react-router-dom";

const MainAdminComponent = ({ setMenuState, menuState }) => {
  let navigate = useNavigate();
  return (
    <>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/financials");
        }}
      >
        Financial Year
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/churches");
        }}
      >
        Church List
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/dioceseslist");
        }}
      >
        Diocese List
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/users");
        }}
      >
        Users Management
      </button>
      <button
        className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
        onClick={() => {
          setMenuState(!menuState);
          navigate("/roles");
        }}
      >
        Role List
      </button>
    </>
  );
};

export default MainAdminComponent;
