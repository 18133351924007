import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import {
  incomeSourceOptions,
  paymentStatusOption,
} from "../../../components/Data/DropDownData";

const IncomeDuesReceiptChild = ({ selectedRecord }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      boxShadow: "none", // This line disables the blue border
    }),
  };

  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [churchMembers, setChurchMembers] = useState([]);
  const [categoryList, setCategoryList] = useState();
  const [totalPaid, setTotalPaid] = useState();
  const [useOtherNonMember, setUseOtherNonMember] = useState(false);
  const [formData, setFormData] = useState({
    category_id: selectedRecord?.category_id?._id,
    income_title: selectedRecord?.dues_title,
    description: selectedRecord?.description,
    member_id: selectedRecord?.member_id?._id,
    income_dues_id: selectedRecord?._id,
    date: null,
    amount: null,
    status: null,
    income_source: null,
    other_non_member: null,
  });

  useEffect(() => {
    const getTotalPayment = (id) => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/incomedues/get-total-paid/${id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(response.data);
          setTotalPaid(response.data);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getTotalPayment(selectedRecord?._id);
  }, []);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/category/income/${user?.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then((response) => {
          const oldData = response.data.Categorys;
          const updatedData = oldData.map((data) => ({
            value: data?._id,
            label: data?.category_name,
          }));
          setCategoryList(updatedData);
        })
        .catch((err) => {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/church/${user?.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        const members = response?.data?.users;
        setChurchMembers(
          members.map((member) => ({
            value: member._id,
            label: member.first_name + " " + member.last_name,
          }))
        );
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        errorLogApi(error, "ChurchMemberList", "/income");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleAddIncome = (e) => {
    e.preventDefault();

    if (!formData?.income_source)
      return toast.error("Please select an income source");

    if (!formData?.status) return toast.error("Please select payment status");

    if (!formData?.amount) return toast.error("Please select payment amount");

    let userToken = localStorage.getItem("token");

    const finData = {
      ...formData,
      financial_year: financial_year?.label,
      dues_payment: true,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/income/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: finData,
    };

    axios(config)
      .then((response) => {
        toast.success("Receipt Created Successfully.");
        navigate("/income");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "IncomeAddChild", "/income/add");
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div className="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        <form onSubmit={(e) => handleAddIncome(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Receipt</h2>

          <div className="m-1 overflow-x-auto outline-none">
            <div className="mt-4">
              <Select
                name="category_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    category_id: selected.value,
                  });
                }}
                options={categoryList}
                placeholder={placeHolderText("Select Category")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                value={
                  formData?.category_id &&
                  categoryList?.find((c) => c.value === formData?.category_id)
                }
                isDisabled
              />
            </div>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="income_title"
                type="text"
                required
                placeholder="Income Title"
                autoComplete="off"
                onChange={handleChange}
                value={formData?.income_title}
                disabled
              />
            </div>
            <div className="mt-4">
              <Select
                name="member_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    member_id: selected?.value,
                    other_non_member: null,
                  });
                }}
                options={churchMembers}
                placeholder={placeHolderText("Select Member")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
                value={
                  formData?.member_id &&
                  churchMembers?.find((m) => m.value === formData?.member_id)
                }
                isDisabled
              />
            </div>
            <div className="mt-4">
              <label className="font-sans text-sm font-light">
                Pleged Amount
              </label>
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="pledged_amount"
                type="number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
                value={selectedRecord?.pledged_amount}
                disabled
              />
            </div>
            <div className="mt-4">
              <label className="font-sans text-sm font-light">
                Balance Amount
              </label>
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="balance_amount"
                type="number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
                value={
                  totalPaid
                    ? +selectedRecord?.pledged_amount - +totalPaid
                    : selectedRecord?.pledged_amount
                }
                disabled
              />
            </div>
            <div className="mt-4">
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date: dateString,
                  });
                }}
                showToday={true}
              />
            </div>
            <div className="mt-4">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="amount"
                type="number"
                required
                placeholder="Enter Amount"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <Select
                name="income_source"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    income_source: selected.label,
                  });
                }}
                options={incomeSourceOptions}
                placeholder={placeHolderText("Select Source")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
              />
            </div>
            <div className="mt-4">
              <Select
                name="status"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    status: selected.value,
                  });
                }}
                options={paymentStatusOption}
                placeholder={placeHolderText("Select Status")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={style}
              />
            </div>
            <div className="mt-4">
              <textarea
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="description"
                type="text"
                rows={3}
                placeholder="Income Description"
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IncomeDuesReceiptChild;
