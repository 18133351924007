import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent = ({ data }) => {
  const cityCount = data.reduce((acc, item) => {
    const city = item.city;
    acc[city] = (acc[city] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(cityCount),
    datasets: [
      {
        label: "Number of Churches",
        data: Object.values(cityCount),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#E7E9ED",
          "#4BC0C0",
          "#F7464A",
          "#949FB1",
          "#4D5360",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#E7E9ED",
          "#4BC0C0",
          "#F7464A",
          "#949FB1",
          "#4D5360",
        ],
      },
    ],
  };

  return (
    <div className="p-1 text-xs">
      <h2>Church Registration by City</h2>
      <Pie data={chartData} />
      <div className="mt-4">
        <h3 className="mb-2 font-bold text-md">City-wise Church Details</h3>
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className="text-white bg-gray-800">
              <th className="px-2 py-1 border">City</th>
              <th className="px-2 py-1 border">Number of Churches</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(cityCount).map(([city, count], index) => (
              <tr
                key={index}
                className="even:bg-silver odd:bg-white hover:bg-gray-200"
              >
                <td className="px-4 py-2 border">{city}</td>
                <td className="px-4 py-2 border">{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PieChartComponent;
