import { useEffect, useRef, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import rightImg from "../../images/login-image.jpg";
import logoImg from "../../images/cms-logo.png";
import ClientCaptcha from "react-client-captcha";
import io from "socket.io-client";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState, globalUserStatus } from "../../components/shared/GlobalState";

const Login = () => {
  let navigate = useNavigate();
  //let socket;
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [userGlobal, setUserGlobal] = useAtom(globalUserStatus);

  const [loggedUser, setLoggedUser] = useState(null);
  const [state, setState] = useState({ captchaCode: "", valid: "a" });
  //const { captchaCode } = state;
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const [captchaWidth, setCaptchaWidth] = useState(350);

  let usernameRef = useRef();
  let passwordRef = useRef();
  let captchaRef = useRef();
  let userToken = localStorage.getItem("token");

  // Maintain a single socket instance
  const socketRef = useRef(null);

  /*
  useEffect(() => {
    // Initialize the socket connection on component mount
    socketRef.current = io(process.env.REACT_APP_REPORTS_DOWNLOAD_URL);
    console.log("Socket initialized", socketRef.current);
    return () => {
      // Disconnect socket when the component unmounts
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);
  */

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const checkLogin = async (email, password) => {
    // console.log(process.env.REACT_APP_API_URL);
    const credentials = { email, password };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      credentials
    );

    return response;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    //alert(usernameRef.current.value + " pass: " + passwordRef.current.value);
    if (state.valid !== state.captchaCode) {
      toast.error("Unable to login, Bad Captcha");
      captchaRef.current.focus();
      return;
    }

    if (usernameRef.current.value && passwordRef.current.value) {
      try {
        const response = await checkLogin(
          usernameRef.current.value,
          passwordRef.current.value
        );
        //console.log(JSON.stringify(await response));
        localStorage.setItem("token", response.data.authToken);

        setInterval(checkToken, 1000 * 60 * 10);
        const user = jwt_decode(response.data.authToken);
        // console.log(user);
        setLoggedUser(jwt_decode(response.data.authToken));

        setUserStatus({
          ...userStatus,
          user: jwt_decode(response.data.authToken),
          handleLogout: () => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("user-details");
            setUserStatus({ ...userStatus, user: null });
            // Properly disconnect the socket
            if (socketRef.current) {
              socketRef.current.disconnect();
            }
            navigate("/login", { replace: true });
          },
        });

        setUserGlobal({ ...jwt_decode(response.data.authToken) });
        // updateOnlineUsersForChat(user);
        /*
        if (socketRef.current && user?.length) {
          socketRef.current.emit("user_connected", user?._id);

          // Listen for updates to online users
          socketRef.current.on("update_online_users", (users) => {
            setUserGlobal({ ...userGlobal, online_users: users });
          });
        } */

        if (user.role === "FULL_ACCESS") {
          navigate("/superadmin", { replace: true });
        } else if (user.role === "CHURCH_ADMIN") {
          navigate("/dashboard-ca", { replace: true });
        } else {
          navigate("/member/dashboard", { replace: true });
        }

        toast.success("Logged in successfully");
      } catch (error) {
        toast.error("Unable to login, Bad username or password");
      }
    }
  };

  /*
  useEffect(() => {
    // Initialize the socket connection
    //socketRef.current = io(process.env.REACT_APP_REPORTS_DOWNLOAD_URL);

    // Listen for socket connection
    socketRef.current.on("connect", () => {
      console.log("Socket connected");
    });

    // Listen for updates to online users
    socketRef.current.on("update_online_users", (users) => {
      setUserGlobal((prevUserGlobal) => ({
        ...prevUserGlobal,
        online_users: users,
      }));
      console.log(users);
    });

    // socketRef.current.emit("user_connected", "MYUSER12213213123");
    // Emit user_connected event when user status changes and user is not null
    if (userStatus?.user?.length) {
      console.log("Requesting socket.io ", userStatus?.user);
      socketRef.current.emit("user_connected", userStatus?.user?._id);
    }

    // Cleanup on unmount
    // return () => {
    //   if (socketRef.current) {
    //     socketRef.current.disconnect();
    //   }
    // };
  }, [userStatus?.user]);

  */

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // token has expired, do something here (e.g. log out the user)
        localStorage.removeItem("token");
        sessionStorage.removeItem("user-details");
        setUserStatus({ ...userStatus, user: null });
        navigate("/login", { replace: true });
      }
    }
  };

  return (
    <div>
      {/* Global container */}
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        {/* Card Container */}
        <div className="relative flex flex-col m-6 space-y-10 bg-white rounded-md shadow-2xl md:flex-row md:space-y-0 md:m-0">
          {/* Left Side */}
          <div className="p-6 md:p-20 md:mt-10">
            <div className="flex items-center justify-center">
              <img src={logoImg} className="w-[100px]" alt="logo" />
            </div>

            <p className="max-w-sm pl-5 mt-5 font-sans font-light text-center text-gray-400">
              Please enter your email address and password to login
            </p>
            <form onSubmit={handleLoginSubmit}>
              <div className="mt-10">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="email"
                  id="emailaddress"
                  required
                  placeholder="Enter your email"
                  autoComplete="new-password"
                  ref={usernameRef}
                />
              </div>
              <div className="mt-3">
                <input
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  type="password"
                  id="password"
                  required
                  placeholder="Password"
                  autoComplete="new-password"
                  ref={passwordRef}
                />
              </div>
              <div className="mt-3">
                <ClientCaptcha
                  //fontFamily={"'Times New Roman', serif"}
                  captchaCode={setCode}
                  width={captchaWidth}
                  className="rounded-md"
                />
              </div>
              <div className="mt-3">
                <input
                  type="text"
                  placeholder="Enter Captcha"
                  className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  onChange={(e) =>
                    setState({ ...state, valid: e.target.value })
                  }
                  name="valid"
                  ref={captchaRef}
                  onKeyPress={(e) => e.key === "Enter" && handleLoginSubmit(e)}
                  autoComplete="off"
                />
              </div>
              <div className="flex items-center justify-between mt-3">
                <div>
                  <NavLink
                    to=""
                    className="font-sans text-sm font-light text-gray-400 hover:text-gray-700"
                  >
                    Forgot your password ?
                  </NavLink>
                </div>
                <div className="ml-auto">
                  <button
                    className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </div>
              <div className="mt-10 text-[8px]">BUILD: v.220240117729.1</div>
            </form>
          </div>
          {/* Right Side */}
          <img
            src={rightImg}
            className="w-[730px] hidden md:block rounded-r-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
