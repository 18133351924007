import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import placeholderImage from "../../../images/placeholder.png";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import EventView from "./EventView";
import EventAdd from "../Create/EventAdd";

const EventListChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);

  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleAddModal = () => {
    setAddShowModal(!addShowModal);
  };
  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/event/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.events;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            title: data.title,
            description: data.description,
            event_start_date: data?.event_start_date
              ? dayjs(data?.event_start_date).format("DD-MM-YYYY")
              : "",
            view_event_start_date: data?.event_start_date
              ? dayjs(data?.event_start_date).format("DD-MM-YYYY")(
                  data?.start_time
                )
                ? dayjs(data?.start_time, "HH:mm:ss")
                : ""
              : "",
            event_end_date: data?.event_end_date
              ? dayjs(data?.event_end_date).format("DD-MM-YYYY")
              : "",
            view_event_end_date: data?.event_end_date
              ? dayjs(data?.event_end_date).format("DD-MM-YYYY")(data?.end_time)
                ? dayjs(data?.end_time, "HH:mm:ss")
                : ""
              : "",
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/event/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.events;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              title: data.title,
              description: data.description,
              event_start_date: data?.event_start_date,
              view_event_start_date: data?.event_start_date
                ? dayjs(data?.event_start_date).format("DD-MM-YYYY") +
                  " " +
                  data?.event_start_time
                : "",
              event_end_date: data?.event_end_date,
              view_event_end_date: data?.event_end_date
                ? dayjs(data?.event_end_date).format("DD-MM-YYYY") +
                  " " +
                  data?.event_end_time
                : "",
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, [addShowModal]);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this User ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/event/delete/${row.id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "EventListChild", "/events");
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => {
                  toggleAddModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", use: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        if (params.row.image) {
          return (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/event/${params.row.image}`}
              alt="Image"
              className="w-[55px] p-1 mr-1"
            />
          );
        } else {
          return (
            <img
              src={placeholderImage}
              alt="Image"
              className="w-[55px] p-1 mr-1"
            />
          );
        }
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 300,
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 600,
    // },
    {
      field: "view_event_start_date",
      headerName: "Start-Date & Time",
      width: 300,
    },
    {
      field: "view_event_end_date",
      headerName: "End-Date & Time",
      width: 300,
    },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => {
            toggleAddModal();
            setCurrentRecord({});
          }}
          // disabled
        >
          Add Event
        </button>
        {addShowModal && (
          <Modal onClose={toggleAddModal} dialogClassName="modal-90w">
            <h2 className="mb-4 text-xl font-bold">
              {currentRecord?._id ? "Edit" : "Add"} Event
            </h2>
            <hr></hr>
            <EventAdd
              currentRecord={currentRecord}
              setModel={setAddShowModal}
            />
          </Modal>
        )}
      </div>
      <h2>Event List</h2>
      <ErrorBoundaryRoutes route="/events" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Event Detail</h2>
            <EventView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Event"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default EventListChild;
