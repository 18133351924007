import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const UpdateConfirmationChild = ({ confirmationRecord }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    ...confirmationRecord,
  });

  const genderOptions = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected,
    });
  };

  function formatString(str) {
    // Remove underscores and split the string into words
    const words = str.replace(/_/g, " ").split(" ");

    // Capitalize the first letter of the first word
    const capitalizedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });

    // Join the words back into a single string
    return capitalizedWords.join(" ");
  }

  const handleConfirmationRegister = () => {
    const fieldToValidate = [
      "confirmation_date",
      "place_of_confirmation",
      "confirmed_by",
      "sex",
      "parents_name",
      "date_of_baptism",
      "date_of_birth",
    ];

    // Identify empty fields and filter out any undefined entries
    const emptyFields = fieldToValidate
      .filter((f) => !formData[f])
      .map(formatString); // Assuming formatString formats the string as previously discussed

    // Provide feedback if there are any empty fields
    if (emptyFields.length > 0) {
      return toast.error(`${emptyFields.join(", ")} cannot be empty`);
    }

    // Configuration for axios request
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/confirmation/update/${confirmationRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
      },
    };

    // Axios request
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
        navigate("/confirmation/list");
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
        errorLogApi(error, "AddConfirmation", "/confirmation/add");
      });
  };

  return (
    <div className="w-full">
      <div className="flex justify-end w-full mt-4">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={handleConfirmationRegister}
        >
          Update Record
        </button>
      </div>
      <div className="flex flex-col items-center justify-center md:flex-row">
        <fieldset className="w-full p-2 whitespace-normal border md:w-1/3 md:mr-2">
          <legend className="float-none w-auto p-2 fs-5">
            Confirmation Details
          </legend>
          <div className="p-5">
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Confirmation date
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="confirmation_date"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      confirmation_date: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                  value={moment(formData?.confirmation_date, "YYYY-MM-DD")}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">Name</label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="name"
                  type="text"
                  required
                  placeholder="Name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">Sex</label>
                <Select
                  name="sex"
                  onChange={dropDownChange}
                  options={genderOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Gender")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                  value={formData?.sex}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Parents Name
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="parents_name"
                  type="text"
                  required
                  placeholder="Parents name"
                  onChange={handleChange}
                  value={formData.parents_name}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Date of Birth
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="date_of_birth"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      date_of_birth: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                  value={moment(formData?.date_of_birth, "YYYY-MM-DD")}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Baptism Date
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="date_of_baptism"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      date_of_baptism: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                  value={moment(formData?.date_of_baptism, "YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Place of Confirmation
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="place_of_confirmation"
                  type="text"
                  required
                  placeholder="Place of confirmation"
                  onChange={handleChange}
                  value={formData.place_of_confirmation}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Confirmed By
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="confirmed_by"
                  type="text"
                  required
                  placeholder="Confirmed by"
                  onChange={handleChange}
                  value={formData.confirmed_by}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Place of Residence
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="place_of_residence"
                  type="text"
                  required
                  placeholder="Place of Residence"
                  onChange={handleChange}
                  value={formData.place_of_residence}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Presbyter
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="presbyter"
                  type="text"
                  required
                  placeholder="Presbyter"
                  onChange={handleChange}
                  value={formData.presbyter}
                />
              </div>
            </div>
            {/* bottom */}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default UpdateConfirmationChild;
