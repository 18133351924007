import CardBackground from "./CardBackground";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import ReplyIcon from "@mui/icons-material/Reply";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import Modal from "../../../components/Modal/Modal";
import ComposeMail from "./ComposeMail";
import ReplyMail from "./ReplyMail";

const MessageComponent = () => {
  const [{ user }] = useAtom(gbState);
  const [showModal, setShowModal] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [recipients, setRecipients] = useState();
  const [mailBox, setMailBox] = useState("inbox");
  const [mails, setMails] = useState();
  // Assuming the header height is 64px
  const [headerHeight, setHeaderHeight] = useState(100); // Default height

  const recipient = null;

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  const mailBoxOptions = [
    { value: "inbox", label: "Inbox" },
    { value: "sent", label: "Sent" },
  ];

  const toggleModal = (event) => {
    setShowModal(!showModal);
  };

  const toggleReplyModal = (event) => {
    setShowReply(!showReply);
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/email/to-user/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: user?.buddies,
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        const sender = {
          label: user?.name,
          value: user?._id,
        }; // Add sender to the list of recipients
        setRecipients([sender, ...response.data.recipients]);
        // console.log("Event Name recieved");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setCurrentRecord(null);
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/email/${mailBox}/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setMails(response.data.emails);
        // console.log("Event Name recieved");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [mailBox]);

  // Sample user and message data
  const getTimeSincePost = (createdAt) => {
    // Parse the createdAt string into a Date object
    const createdDate = new Date(createdAt);
    // Get the current time as a Date object
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - createdDate;
    // Convert milliseconds into days and weeks
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    // Same day: hours or minutes
    if (days < 1) {
      const hours = Math.floor(diffInMs / (1000 * 60 * 60));
      if (hours >= 1) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const minutes = Math.floor(diffInMs / (1000 * 60));
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      }
    }
    // Yesterday
    else if (days === 1) {
      return "Yesterday";
    }
    // 2-6 days ago
    else if (days < 7) {
      return `${days} days ago`;
    }
    // 1 week ago
    else if (weeks === 1) {
      return "1 week ago";
    }
    // 2-4 weeks ago
    else if (weeks <= 4) {
      return `${weeks} weeks ago`;
    }
    // 1 month ago or more
    else if (months >= 1) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    }
  };

  const users = mails?.map((m) => ({
    name: `${
      m.from_user?.first_name
        ? m.from_user?.first_name + " " + m.from_user?.last_name
        : m?.subject
    } `,
    handle: m?._id,
    is_read: m?.is_read,
    to_user: m?.recipient?.first_name + " " + m?.recipient?.last_name,
    from_user: recipients.find((r) => r.value === m?.from_user?._id)?.label,
    from_user_id: m?.from_user?._id,
    to_user_id: m?.recipient?._id,
    email: m.from_user?.email,
    lastMessage: m?.subject,
    subject: m?.subject,
    message: m?.message,
    event_id: m?.event_id,
    time: getTimeSincePost(m?.createdAt),
  }));

  const refreshEmailRecords = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/email/${mailBox}/${user?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setMails(response.data.emails);
        // console.log("Event Name recieved");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEmailRead = (user) => {
    // console.log(user);
    if (!user?.is_read) {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/email/mark-read/${user?.handle}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          refreshEmailRecords();
          // console.log(response.data);
          // console.log("Event Name recieved");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <div
      style={{ height: `calc(100vh - ${headerHeight}px)` }}
      className="flex bg-gray-100 w-[100%]"
    >
      {showModal && (
        <Modal onClose={toggleModal}>
          <h2 className="mb-4 text-xl font-bold">Compose Email</h2>
          <ComposeMail
            user={user}
            toggleModal={toggleModal}
            recipient={recipient}
          />
        </Modal>
      )}

      {showReply && (
        <Modal onClose={toggleReplyModal}>
          <h2 className="mb-4 text-xl font-bold">Reply Email</h2>
          <ReplyMail
            user={user}
            toggleReplyModal={toggleReplyModal}
            currentRecord={currentRecord}
          />
        </Modal>
      )}
      {/* Left sidebar for user list */}
      <div className="w-1/4 overflow-y-auto bg-white">
        {/* Sticky Header */}
        <div
          className="flex flex-row p-4 bg-white shadow"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <i className="mr-2 text-2xl mdi mdi-email"></i>
          {/* <h2 className="text-xl font-bold">Inbox</h2> */}
          <Select
            name="mailBox"
            onChange={(selected, dropdown) => {
              setMailBox(selected?.value);
            }}
            options={mailBoxOptions}
            placeholder={placeHolderText("Select Type")}
            className={`w-[280px] outline-none h-11 `}
            style={{ zIndex: 15 }}
            // menuPortalTarget={document.body}
            menuPosition={"fixed"}
            value={mailBox && mailBoxOptions?.find((e) => e.value === mailBox)}
          />
        </div>
        {/* List of users/messages */}
        {users?.map((user, index) => (
          <div
            key={index}
            className="p-4 cursor-pointer hover:bg-gray-50"
            onClick={() => {
              handleEmailRead(user);
              setCurrentRecord(user);
            }}
          >
            <div className="flex flex-row">
              <p className="font-semibold">{user.name} </p>
              <div
                className={`${
                  !user.is_read ? "bg-green-400" : "bg-white"
                } rounded-full w-2 h-2 text-[8px] flex items-center justify-center text-white`}
              >
                &nbsp;
              </div>
            </div>

            <p className="text-sm text-gray-500">{user.lastMessage}</p>
            <p className="text-xs text-gray-400">{user.time}</p>
          </div>
        ))}
      </div>

      {/* Main chat area */}
      <div className="flex flex-col flex-1 ml-1">
        <div className="flex flex-row justify-between p-5 bg-white shadow">
          <div className="flex flex-row">
            <i className="mr-2 text-2xl mdi mdi-email-open-outline"></i>
            <h2 className="text-xl font-bold">Messages</h2>
          </div>

          <div
            className="flex items-center justify-end cursor-pointer"
            onClick={toggleModal}
          >
            <i className="text-2xl mdi mdi-pencil-box-outline"></i>
          </div>
        </div>
        <div className="flex-1 p-2 overflow-y-auto">
          {currentRecord ? (
            <EmailMessageComponent
              msg={currentRecord}
              toggleReplyModal={toggleReplyModal}
            />
          ) : (
            <div className="flex flex-col w-full p-4 overflow-hidden bg-white rounded-lg shadow-md">
              Select the messages from left pane...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function EmailMessageComponent({ msg, toggleReplyModal }) {
  return (
    <>
      <div className="flex flex-col w-full p-4 overflow-hidden bg-white rounded-lg shadow-md">
        <div className="w-full px-4 pt-4">
          <div
            className="flex float-right cursor-pointer"
            style={{ color: "blue" }}
            onClick={() => toggleReplyModal()}
          >
            <ReplyIcon />
          </div>
          <h3 className="text-lg font-semibold text-gray-800">
            Subject: {msg?.subject}
          </h3>
          <p className="text-sm text-gray-600 ">From: {msg?.from_user}</p>
          <p className="text-sm text-gray-600 ">To: {msg?.to_user}</p>
          <p className={`mb-1 text-xs text-gray-600`} align="left">
            Date: {msg?.time}
          </p>
          <hr />
        </div>
        {/* Email Body - the actual message content */}
        <div
          className="w-full px-4 py-2"
          dangerouslySetInnerHTML={{
            __html: msg?.message.split("\n").join("<br>"),
          }}
        ></div>
      </div>

      <div className="w-full py-2">
        <p className="text-gray-800">{msg?.event_id}</p>
        {/* {msg?.event_id && <EventComponent event_id={msg.event_id} />} */}
      </div>
    </>
  );
}

export default MessageComponent;
