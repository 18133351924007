import React, { useState, useEffect } from "react";
import axios from "axios";

import CardBackground from "./CardBackground";
import PieChartComponent from "./PieChartComponent";
import ChurchListByCity from "./ChurchListByCity";
import ChurchRegistrationPieChart from "./ChurchRegistrationPieChart";

const ChurchComponent = () => {
  const [data, setData] = useState([]);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/churches/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.churches;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            church_code: data.church_code,
            church_name: data.church_name,
            city: data.city,
            state: data.state,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  return (
    <div>
      <CardBackground>
        <div className="flex flex-row justify-center w-full">
          <PieChartComponent data={data} />
          <ChurchRegistrationPieChart data={data} />
        </div>
      </CardBackground>
      <CardBackground>
        <ChurchListByCity data={data} />
      </CardBackground>
    </div>
  );
};

export default ChurchComponent;
