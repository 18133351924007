import React from "react";
import ReactToPrint from "react-to-print";
import Card from "./SubscriberCard";
import ReceiptCard from "./ReceiptCard";
import ReceiptView from "./ReceiptView";

const PrintButton = ({ subscriberRecord, currentRecord }) => {
  // Ref to the component to be printed
  const componentRef = React.useRef();

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="px-4 py-2 mt-5 font-bold text-white bg-blue-500 rounded-lg hover:bg-blue-700">
            Print
          </button>
        )}
        content={() => componentRef.current}
      />
      <div className="flex flex-col items-center p-5">
        <Card data={subscriberRecord} />
        <ReceiptCard data={currentRecord} users={subscriberRecord} />
      </div>

      <div
        style={{
          display: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* Component to be printed */}
        <div ref={componentRef}>
          <ReceiptView
            subscriberRecord={subscriberRecord}
            currentRecord={currentRecord}
          />
        </div>
      </div>
    </>
  );
};

export default PrintButton;
