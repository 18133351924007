import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import { useLocation } from "react-router-dom";

import AssetUpdateChild from "./AssetUpdateChild";

const AssetUpdate = () => {
  const { state } = useLocation();
  const selected_asset = state;
  return (
    <MainTemplate>
      <CardBackground>
        <AssetUpdateChild selectedAsset={selected_asset} />
      </CardBackground>
    </MainTemplate>
  );
};

export default AssetUpdate;
