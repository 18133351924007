import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import Select from "react-select";
import { monthOptions } from "../../../components/Data/MenuData";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";
import CreateMonthlyStatement from "../Create/CreateMonthlyStatement";

const ListMonthlyStatementsChild = () => {
  let navigate = useNavigate();
  const inputWidth = "250px";
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [selectedMonth, setSelectedMonth] = useState();
  const [data, setData] = useState([]);
  //const [financial_year] = useAtom(fYear);
  const [financial_year, setFinancial_year] = useState();
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (user) {
      setFinancial_year(user?.current_financial_year);
    }
  }, [user]);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/bank-balance/delete/${row._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "BankListChild", "/banks");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/bank-balance/${financial_year}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        const oldData = response.data.bankBalances;
        const filteredData = oldData.filter(
          (bb) => bb.financial_year === financial_year?.value
        );
        const updatedData = filteredData.map((data) => {
          return {
            ...data,
            id: data._id,
            month: new Date(data.month).toLocaleString("default", {
              month: "long",
              year: "numeric",
            }),
            opening_balance: data.opening_balance,
            closing_balance: data.closing_balance,
            cash_in_hand: data?.cash_in_hand,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  //   useEffect(() => {
  //     const getDataFromServer = () => {
  //       let userToken = localStorage.getItem("token");

  //       let urlLink = `${process.env.REACT_APP_API_URL}/bank-balance/${financial_year?.value}`;

  //       const config = {
  //         method: "GET",
  //         url: urlLink,
  //         headers: {
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       };

  //       axios(config)
  //         .then(function (response) {
  //           //   console.log(JSON.stringify(response.data));
  //           const oldData = response?.data?.bankBalances;
  //           const filteredData = oldData.filter(
  //             (bb) => bb.financial_year === financial_year?.value
  //           );
  //           const updatedData = filteredData.map((data) => {
  //             return {
  //               ...data,
  //               id: data?._id,
  //               month: new Date(data?.month)?.toLocaleString("default", {
  //                 month: "long",
  //                 year: "numeric",
  //               }),
  //               opening_balance: data?.opening_balance,
  //               closing_balance: data?.closing_balance,
  //               cash_in_hand: data?.cash_in_hand,
  //             };
  //           });

  //           setData(updatedData);
  //         })
  //         .catch(function (err) {
  //           toast.error(err?.message);
  //           console.log("error api call ", err?.response?.data);
  //         });
  //     };
  //     if (!financial_year?.value) {
  //       toast.error("Please select a Financial Year");
  //     } else {
  //       getDataFromServer();
  //     }
  //   }, [financial_year]);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => navigate("/bank/update", { state: params.row })}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Balances">
              <div
                onClick={() => navigate("/bank/balance", { state: params.row })}
              >
                <i
                  className="mdi mdi-format-float-none"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "bank_name",
      headerName: "Banks",
      width: 250,
    },
    {
      field: "account_number",
      headerName: "Account Number",
      width: 200,
    },
    {
      field: "account_type",
      headerName: "Account Type",
      width: 180,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 280,
    },
  ];

  const generateStatement = () => {
    // console.log(financial_year);
    if (!financial_year) return toast.error("Please select financial year");
    else if (!selectedMonth) {
      return toast.error("Please Select Month");
    } else {
      navigate("/monthly/statement/add", {
        state: {
          month: selectedMonth,
        },
      });
    }
  };

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      {/* {JSON.stringify(financial_year)} */}
      <div className="flex flex-row items-center justify-center w-full">
        <div className="mr-5">
          <Select
            name="month"
            onChange={(selected, dropdown) => {
              setSelectedMonth(selected);
            }}
            options={monthOptions}
            placeholder={placeHolderText("Select Month")}
            className={`w-[200px] outline-none`}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </div>
        <div className="">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded-md hover:bg-gray-900"
            onClick={generateStatement}
          >
            Generate Statement
          </button>
        </div>
      </div>
      {/* <h2>Statements List</h2>
      <ErrorBoundaryRoutes route="/banks" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Bank Detail</h2>
            <BankView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>

      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Banks"
          checkboxSelection
          style={{ height: "60vh" }}
        />
      </div> */}
    </div>
  );
};

export default ListMonthlyStatementsChild;
