import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import NoticeListChild from "./NoticeListChild";

const NoticeList = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <NoticeListChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default NoticeList;
