import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CategoryCreateChild from "./CategoryCreateChild";
import CardBackground from "../../../components/MainTemplate/CardBackground";

const CategoryCreate = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <CategoryCreateChild />
      </CardBackground>
    </MainTemplate>
  );
};  

export default CategoryCreate;
