import React, { useState, useEffect } from "react";
import TimelineSection from "./TimelineSection";
// import ChatBuddySection from "./ChatBuddySection";
import BuddyRequestsSection from "./BuddyRequestsSection";
// import MessageComponent from "./MessageComponent";

import { useAtom } from "jotai";
import { gbMessageState } from "../../components/shared/GlobalState";
import AdminMenuSection from "./AdminMenuSection";
import ChurchOnBoardComponent from "./ChurchOnBoardComponent";
const CenterStageSection = () => {
  const [showMessageComponent, setShowMessageComponent] =
    useAtom(gbMessageState);
  const [churchOnBoardShow, setChurchOnBoardShow] = useState(false);

  return (
    <>
      {showMessageComponent ? (
        <div className="w-full mt-3">{/* <MessageComponent /> */}</div>
      ) : (
        <>
          <div className="w-2/3 ">
            {churchOnBoardShow ? (
              <ChurchOnBoardComponent
                setChurchOnBoardShow={setChurchOnBoardShow}
              />
            ) : (
              <TimelineSection />
            )}
          </div>
          <div className="w-1/3">
            <AdminMenuSection setChurchOnBoardShow={setChurchOnBoardShow} />
          </div>
        </>
      )}
    </>
  );
};

export default CenterStageSection;
