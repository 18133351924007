import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AddFinancialYearChild = () => {
  let navigate = useNavigate();
  const { state: selectedChurch } = useLocation();

  const inputWidth = "230px";
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [churchList, setChurchList] = useState();

  const [formData, setFormData] = useState({
    financial_year: null,
    starting_date: null,
    ending_date: null,
    church_id: selectedChurch ? selectedChurch?.value : null,
  });

  //Get churches
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/churches/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        const churches = response.data.churches;
        setChurchList(
          churches.map((church) => ({
            value: church._id,
            label: church.church_name,
          }))
        );
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "ChurchList", "/churches");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleFinancialYearRegistration = (e) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/financials/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Record Created Successfully.");
        navigate("/financials");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.error);
        console.log(error?.response?.data?.error);
        errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  const handleFinancialYearchange = (date, dateString) => {
    // Update the formData state
    setFormData((prevState) => ({
      ...prevState,
      ending_date: dateString,
    }));
  };

  useEffect(() => {
    if (formData?.ending_date && formData?.starting_date) {
      //extract year from starting_date and ending_date
      const [start_year, sm, sd] = formData?.starting_date?.split("-");
      const [end_year, em, ed] = formData?.ending_date?.split("-");

      console.log(start_year);
      console.log(end_year);

      const f_year = `${start_year}-${end_year}`;
      setFormData((prevState) => ({ ...prevState, financial_year: f_year }));
    }
  }, [formData?.ending_date, formData?.starting_date]);

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={handleFinancialYearRegistration}
          >
            Create
          </button>
        </div>
        <h2 className="mb-3 text-bold">Add Financial Year</h2>
        <div className="mt-4 ">
          <label>Financial Year Start</label>
          <DatePicker
            className="w-full mb-2 h-[38px]"
            name="starting_date"
            onChange={(date, dateString) => {
              setFormData({
                ...formData,
                starting_date: dateString,
              });
            }}
            // picker="month"
            // format="YYYY-MM"
            showToday={true}
          />
        </div>
        <div className="mt-4 ">
          <label>Financial Year End</label>
          <DatePicker
            className="w-full mb-2 h-[38px]"
            name="ending_date"
            onChange={handleFinancialYearchange}
            disabledDate={(current) => {
              // Ensure we have a starting_date, and it is converted to a dayjs object.
              const startingDate = formData?.starting_date
                ? dayjs(formData.starting_date)
                : null;

              // If there's no starting_date or current date is not available, don't disable it.
              // Adjust this logic if you always expect a starting date.
              if (!startingDate || !current) return false;

              // Dayjs comparison: Disable dates before the starting date.
              // The 'startOf' function aligns the comparison to the start of the day for consistent comparison.
              return current
                .startOf("day")
                .isBefore(startingDate.startOf("day"));
            }}
            showToday={true}
            disabled={!formData?.starting_date} // Disabled if there's no starting_date
          />
        </div>
        <div className="mt-4 ">
          <input
            className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
            name="financial_year"
            type="text"
            required
            placeholder="Enter Financial Year"
            autoComplete="new-password"
            onChange={handleChange}
            value={formData.financial_year}
            disabled={true}
          />
        </div>
        <div className="mt-4 ">
          <Select
            name="church_id"
            onChange={(selected, dropdown) => {
              setFormData({
                ...formData,
                church_id: selected.value,
              });
            }}
            options={churchList}
            placeholder={placeHolderText("Select Church")}
            className={`w-[${inputWidth}] outline-none h-11 mt-3`}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            //styles={style}
            value={selectedChurch}
          />
        </div>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default AddFinancialYearChild;
