import React from "react";

const BaptismView = ({ currentRecord }) => {
  return (
    <div>
      <InfoTable jsonData={currentRecord} />
    </div>
  );
};

export default BaptismView;

const InfoTable = ({ jsonData }) => {
  const keysToShow = Object.keys(jsonData).filter(
    (key) =>
      ![
        "__v",
        "id",
        "createdAt",
        "church_id",
        "createdBy",
        "updatedAt",
        "_id",
      ].includes(key)
  );

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return !isNaN(date.getTime())
      ? date.toLocaleDateString("en-US", options)
      : dateString; // Return original string if conversion fails
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse table-auto">
        <tbody>
          {keysToShow.map((key, index) => {
            const isDateField = key.includes("date_of_birth");
            const value = jsonData[key];
            const displayValue =
              isDateField && typeof value === "string"
                ? formatDate(value)
                : typeof value === "object" && value !== null
                ? value.label
                : value;

            return (
              <tr
                key={key}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
              >
                <td className="px-4 py-2 font-medium text-black border">
                  {key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </td>
                <td className="px-4 py-2 text-black border">{displayValue}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
