import React, { useEffect, useState } from "react";
import avatar from "../../../images/no-img-9.jpg";
import groupImage from "../../../images/e-2.jpg";
import CardBackground from "./CardBackground";
import axios from "axios";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { useAtom } from "jotai";
import {
  gbState,
  globalUserStatus,
  gbMessageState,
} from "../../../components/shared/GlobalState";
import Modal from "../../../components/Modal/Modal";

import ComposeMail from "./ComposeMail";

const ChatBuddySection = () => {
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [userGlobal, setUserGlobal] = useAtom(globalUserStatus);
  const [showMessageComponent, setShowMessageComponent] =
    useAtom(gbMessageState);
  const [buddies, setBuddies] = useState();
  const [showModal, setShowModal] = useState(false);
  const [recipient, setRecipient] = useState();
  const list_buddies = -10; //List only 10 buddies added

  const toggleModal = (event) => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const updateBuddyList = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/user/buddies-details`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { buddies: userGlobal?.buddies },
      };

      axios(config)
        .then(function (response) {
          //toast.success("Request Sent Successfully.");
          // console.log(response?.data);
          setBuddies(response?.data);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          errorLogApi(error, "AssetInwardChild", "/asset/add");
          // console.log(error);
        });
    };
    updateBuddyList();
  }, [userGlobal?.buddies]);

  const removeBuddy = (buddyId) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/remove-buddy`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { userId: userGlobal?._id, buddyId },
    };
    axios(config)
      .then(function (response) {
        //toast.success("Request Sent Successfully.");
        setUserGlobal(response?.data);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  };

  const removeBuddyfromList = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to remove?",
      buttons: [
        {
          label: "Yes",
          onClick: () => removeBuddy(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handleRecipient = (id = null) => {
    setRecipient(id);
    setShowModal(!showModal);
  };

  return (
    <div>
      {showModal && (
        <Modal onClose={toggleModal}>
          <h2 className="mb-4 text-xl font-bold">Compose Email</h2>
          <ComposeMail
            user={user}
            toggleModal={toggleModal}
            recipient={recipient}
          />
        </Modal>
      )}
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <div
              className="flex flex-row cursor-pointer"
              onClick={() => setShowMessageComponent(true)}
            >
              <i className="text-blue-500 mdi mdi-message-outline" />
              <h2 className="mt-1 ml-2 text-xs text-blue-500">All Messages</h2>
            </div>
            <div className="flex flex-row">
              <i className="text-blue-500 mdi mdi-account-plus-outline" />
              <div
                className="mt-1 ml-1 text-xs text-blue-500 cursor-pointer"
                onClick={handleRecipient}
              >
                New Message
              </div>
            </div>
          </div>
          <SearchComponent />
          {/* {JSON.stringify(buddies)} */}
          {buddies?.length !== 0 &&
            buddies
              ?.slice(list_buddies)
              ?.map((buddy) => (
                <MessageComponent
                  buddy={buddy}
                  removeBuddyfromList={removeBuddyfromList}
                  toggleModal={toggleModal}
                  handleRecipient={handleRecipient}
                />
              ))}
          {/* {JSON.stringify(userGlobal?.online_users)} */}
          {/* <Chat userId={userGlobal?._id} /> */}
          {/* {JSON.stringify(buddies)} */}
        </div>
      </CardBackground>
    </div>
  );
};

const MessageComponent = ({ buddy, removeBuddyfromList, handleRecipient }) => {
  return (
    <div className="mt-7">
      <div className="flex items-center justify-between">
        <div className="flex flex-row ">
          {/* <img src={avatar} className="rounded-full w-[40px] p-1 mr-1" /> */}
          {buddy?.image && (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${buddy?.image}`}
              style={{ height: "40px" }}
              className="rounded-full w-[40px] p-1 mr-1"
            />
          )}
          {!buddy?.image && (
            <img src={avatar} className="rounded-full w-[40px] p-1 mr-1" />
          )}
          <div className="mt-1">
            <div className="text-xs">
              {buddy?.first_name} &nbsp;{buddy?.last_name}
            </div>
            <div
              className="text-[9px] text-blue-400 cursor-pointer"
              onClick={() => handleRecipient(buddy?._id)}
            >
              Send Message
            </div>
            {/* <div
              className="text-[9px] text-blue-400 cursor-pointer"
              onClick={() => removeBuddyfromList(buddy?._id)}
            >
              Remove Buddy ?<div className="">{buddy?._id}</div>
            </div> */}
          </div>
        </div>
        <div
          className="flex flex-col items-end justify-end cursor-pointer items-justify-middle"
          onClick={() => removeBuddyfromList(buddy?._id)}
        >
          <i className="text-red-400 mdi mdi-close-circle"></i>
        </div>
      </div>
    </div>
  );
};

const SearchComponent = () => {
  return (
    <div className="flex items-center p-2 mt-2 bg-gray-100 rounded-md">
      <svg
        className="w-5 h-5 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 18l6-6m0 0l-6-6m6 6H4"
        />
      </svg>
      <input
        type="text"
        className="w-full text-xs text-gray-500 bg-transparent border-0 outline-none"
        placeholder="Search for chats"
      />
    </div>
  );
};

export default ChatBuddySection;
