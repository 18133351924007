import React from "react";
import ViewTemplate from "../../../components/MainTemplate/ViewTemplate";

const SalaryView = ({ currentRecord }) => {
  const data = {
    payment_date: currentRecord?.date?.slice(0, 10),
    salary_receipt_number: currentRecord?.salary_voucher_number,
    employee_name: currentRecord?.employee_name,
    description: currentRecord?.description,
    mobile: currentRecord?.employee_id?.mobile,
    amount: currentRecord?.amount,
    transfer_source: currentRecord?.transfer_source,
    // church_name: currentRecord?.church_id?.church_name,
  };
  console.log(currentRecord);
  let componentRef = React.useRef();

  return <ViewTemplate data={data} />;
};

export default SalaryView;
