import React, { useState, useEffect } from "react";
import avatar from "../../../images/no-image.png";
import CardBackground from "./CardBackground";
import dayjs from "dayjs";
import moment from "moment";
import axios from "axios";
const ProfileComponent = ({ member }) => {
  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <ProfileStackedComponent member={member} />
          {/* {JSON.stringify(member)}
          hellow */}
        </div>
      </CardBackground>
    </div>
  );
};

const ProfileStackedComponent = ({ member }) => {
  const [userData, setUserData] = useState();
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    //let urlLink = `${process.env.REACT_APP_API_URL}/family/fm/${user._id}`;
    let urlLink = `${process.env.REACT_APP_API_URL}/user/${member?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const dataRecieved = response?.data?.users;
        setUserData(dataRecieved);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);
  return (
    <div className="mt-2">
      <button className="block w-[50px] h-[20px] text-white bg-green-500 text-xs rounded-full">
        Admin
      </button>
      <div className="flex flex-row justify-center w-full">
        <div className="">
          {userData?.image && (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/employee/${userData?.image}`}
              style={{ height: "200px", width: "200px" }}
              className="rounded-full w-[60px] p-0 mr-1"
            />
          )}
          {!userData?.image && (
            <img
              src={avatar}
              className="rounded-full w-[40px] p-1 mr-1"
              style={{ height: "150px", width: "200px" }}
            />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center mt-2 text-lg">
        <h1>{member?.name}</h1>
      </div>
      <div className="flex items-center justify-center">
        <h3 className="text-sm">{member?.email}</h3>
      </div>
      <div className="flex flex-row justify-between mt-10">
        <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
          <i className="mdi mdi-gift"> </i>
        </div>
        <div className="mt-3 ml-2 text-xs" style={{ width: "250px" }}>
          {/* {userData?.date_of_birth
            ? dayjs(userData?.date_of_birth).format("MMMM D, YYYY")
            : "N/A"} */}
          {userData?.date_of_birth
            ? moment(userData?.date_of_birth, "DD-MM-YYYY").format(
                "MMMM D, YYYY"
              )
            : "N/A"}
        </div>
      </div>
      <div className="flex flex-row justify-between mt-3">
        <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
          <i className="mdi mdi-crosshairs-gps" />
        </div>
        <div className="mt-3 ml-2 text-xs" style={{ width: "250px" }}>
          {userData?.address ? userData?.address : "N/A"}
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
