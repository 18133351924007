import React from "react";
import { useLocation } from "react-router-dom";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ChurchProfileUpdateChild from "./ChurchProfileUpdateChild";

const ChurchProfileUpdate = () => {
  const { state } = useLocation();
  const church_id = state?.church_id;

  return (
    <MainTemplate>
      <CardBackground>
        <ChurchProfileUpdateChild church_id={church_id} />
      </CardBackground>
    </MainTemplate>
  );
};

export default ChurchProfileUpdate;
