import React from "react";
import ViewTemplate from "../../../components/MainTemplate/ViewTemplate";

const ExpenseView = ({ currentRecord }) => {
  const data = {
    date: currentRecord?.date?.slice(0, 10),
    voucher_number: currentRecord?.voucher_number,
    category_name: currentRecord?.category_id?.category_name,
    expense_title: currentRecord?.expense_title,
    description: currentRecord?.description,
    member_name: currentRecord?.member_id?.first_name
      ? currentRecord?.member_id?.first_name +
        " " +
        currentRecord?.member_id?.last_name
      : "Third Party",
    mobile: currentRecord?.member_id?.mobile,
    amount: currentRecord?.amount,
    transfer_source: currentRecord?.transfer_source,
    church_name: currentRecord?.church_id?.church_name,
  };

  let componentRef = React.useRef();

  return <ViewTemplate data={data} />;
};

export default ExpenseView;
