import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const CategoryCreateChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, Category }] = useAtom(gbState);
  const [rolesOption, setRolesOption] = useState();
  const [churchList, setChurchList] = useState();
  const [formData, setFormData] = useState({
    category_name: null,
    category_type: null,
    createdBy: Category?._id,
  });

  useEffect(() => {
    const category_type = [{ type: "Income" }, { type: "Expense" }];
    setRolesOption(
      category_type.map((role) => ({
        value: role.type,
        label: role.type,
      }))
    );
  }, []);

  useEffect(() => {
    let userToken = localStorage.getItem("token");

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/churches/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const churches = response.data.churches;
        setChurchList(
          churches.map((church) => ({
            value: church._id,
            label: church.church_name,
          }))
        );
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "ChurchList", "/churches");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleCategoryRegistration = (e) => {
    e.preventDefault();

    if (!formData?.category_type)
      return toast.error("Please enter category type");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/Category/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Category Created Successfully.");
        navigate("/catlist");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateCategoryChild", "/Category/add");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(formData)} */}
        <form
          onSubmit={(e) => handleCategoryRegistration(e)}
          autoComplete="off"
        >
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Category</h2>

          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="category_name"
              type="text"
              required
              placeholder="Enter Category"
              autoComplete="off"
              onChange={handleChange}
              value={formData.category_name}
            />
          </div>
          <div className="m-1 overflow-x-auto outline-none">
            <Select
              name="category_type"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  category_type: selected.label,
                });
              }}
              options={rolesOption}
              placeholder={placeHolderText("Select Category")}
              className={`w-[${inputWidth}] outline-none h-11 mt-3`}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              //styles={style}
            />
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};
export default CategoryCreateChild;
