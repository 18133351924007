import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import FamilyMembersChild from "./FamilyMembersChild";

const FamilyMembers = () => {
  return (
    <MainTemplate>
      <FamilyMembersChild />
    </MainTemplate>
  );
};

export default FamilyMembers;
