import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const BankView = ({ currentRecord }) => {
  const dateOfOpening = new Date(currentRecord.date_of_opening);

  const [qrcode, setQrcode] = useState();

  useEffect(() => {
    if (currentRecord?.upi_id) {
      const config = {
        method: "post",
        url: `https://upiqr.in/api/qr`,
        headers: {
          "Content-type": "application/json",
        },
        data: { name: "Pravesh Singh", vpa: currentRecord?.upi_id },
      };
      axios(config)
        .then(function (response) {
          // console.log(response?.data);
          toast.success("QR Created Successfully.");
          setQrcode(response?.data);
        })
        .catch(function (error) {
          toast.error(JSON.stringify(error.response.data));
          // console.log(error);
        });
    }
  }, []);

  return (
    <div>
      <p>
        <strong>Bank Name:</strong> {currentRecord?.bank_name}
      </p>
      <p>
        <strong>Account Number:</strong> {currentRecord?.account_number}
      </p>
      <p>
        <strong>Account Type:</strong> {currentRecord?.account_type}
      </p>
      <p>
        <strong>Branch:</strong> {currentRecord?.branch}
      </p>
      <p>
        <strong>Date of Opening:</strong>{" "}
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(dateOfOpening)}
      </p>
      <p>
        <strong>IFSC code:</strong> {currentRecord?.ifsc_code}
      </p>
      <p>
        <strong>UPI ID:</strong> {currentRecord?.upi_id}
      </p>
      {/* show qr code */}
      {currentRecord?.upi_id && (
        <p>
          {qrcode ? (
            <img
              id="qrCode"
              alt="QR Code"
              width="200px"
              src={`data:image/svg+xml;utf8,${qrcode}`}
            />
          ) : (
            "Loading QR..."
          )}
        </p>
      )}
    </div>
  );
};

export default BankView;
