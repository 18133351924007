import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
const EventAdd = ({ currentRecord, setModel }) => {
  let navigate = useNavigate();
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [selectedValue, setSelectedValue] = useState(
    currentRecord?.day_event === true
      ? "1"
      : currentRecord?.day_event === false
      ? "0"
      : null
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    _id: currentRecord?._id ?? null,
    title: currentRecord?.title ?? null,
    description: currentRecord?.description ?? null,
    day_event: currentRecord?.day_event ?? null,
    event_start_date: currentRecord?.event_start_date ?? null,
    event_end_date: currentRecord?.event_end_date ?? null,
    event_start_time: currentRecord?.event_start_time ?? null,
    event_end_time: currentRecord?.event_end_time ?? null,
    church_id: user?.church_id,
    createdBy: user?._id,
  });
  const [sendData, setSendData] = useState();

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const handleSelected = (event) => {
    setSelectedValue(event.target.value);
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleEvent = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    let config = {};
    if (!formData.event_start_date) {
      toast.error("Event start date can't be left blank.");
      return;
    }
    if (!formData.event_end_date) {
      toast.error("Event end date can't be left blank.");
      return;
    }
    if (!formData.event_start_time) {
      toast.error("Event start time can't be left blank.");
      return;
    }
    if (!formData.event_end_time) {
      toast.error("Event end time can't be left blank.");
      return;
    }
    if (formData._id) {
      config = {
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/event/update/${formData._id}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedImage },
      };
    } else {
      config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/event/add`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedImage },
      };
    }
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Event Created Successfully.");
        // Close the modal after saving
        navigate("/events");
        setModel(false);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateEventChild", "/event/add");
        // console.log(error);
      });
  };
  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[1000px]">
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleEvent(e)} autoComplete="off">
          <div className="flex justify-end">
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
              type="submit"
            >
              Save
            </button>
          </div>
          <div className="mt-0 ">
            <label htmlFor="role_name" style={{ fontWeight: "bold" }}>
              Upload Event Flyer
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="event_image"
              type="file"
              onChange={handleImageChange}
              accept="image/gif, image/jpeg, image/png"
              required={!formData._id ? true : false}
            />
          </div>
          <div className="mt-4 ">
            <label htmlFor="role_name" style={{ fontWeight: "bold" }}>
              Event Title
            </label>
            <input
              className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="title"
              type="text"
              required
              placeholder="Enter Title"
              onChange={handleChange}
              value={formData.title}
            />
          </div>
          <div className="mt-4 mb-4 ">
            <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
              Description
            </label>
            <textarea
              className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="description"
              type="text"
              rows={3}
              required
              placeholder="Enter Event Description"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.description}
            />
          </div>
          <div className="mt-4 mb-4 ">
            <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
              Day Event
            </label>
            <br />
            <div className="mt-0 ">
              <input
                className="w-full h-full p-3 mt-2 outline-none"
                name="day_event"
                type="radio"
                required
                value="1"
                checked={selectedValue === "1"}
                onChange={handleSelected}
                onClick={handleChange}
                style={{ height: "20px", width: "40px" }} // Set the height inline
              />
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                Yes
              </label>
              <input
                className="w-full p-3 mt-2 border-gray-300"
                name="day_event"
                type="radio"
                required
                value="0"
                checked={selectedValue === "0"}
                onChange={handleSelected}
                onClick={handleChange}
                style={{ height: "20px", width: "40px" }} // Set the height inline
              />
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                No
              </label>
            </div>
          </div>
          <div className="flex mb-0">
            <div className="w-full mb-2">
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                Start Date
              </label>
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="event_start_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_start_date: dateString,
                  });
                }}
                disabledDate={(current) => {
                  let customDate = dayjs().format("YYYY-MM-DD");
                  return current && current < dayjs(customDate, "YYYY-MM-DD");
                }}
                value={
                  formData?.event_start_date
                    ? dayjs(formData?.event_start_date)
                    : null
                }
                // rules={{ required: 'Date is required' }}
                showToday={true}
              />
            </div>{" "}
            &nbsp;
            <div className="w-full mb-2">
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                End Date
              </label>
              <DatePicker
                className="w-full mb-2 h-[38px]"
                name="event_end_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_end_date: dateString,
                  });
                }}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                value={
                  formData?.event_end_date
                    ? dayjs(formData?.event_end_date)
                    : null
                }
                //value={moment(formData?.event_end_date, "YYYY-MM-DD")}
                // required
                showToday={true}
              />
            </div>
          </div>
          <div className="flex mb-0">
            <div className="w-full mb-2">
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                Start Time
              </label>
              <TimePicker
                className="w-full mb-2 h-[38px]"
                name="event_start_time"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_start_time: dateString,
                  });
                }}
                value={
                  formData?.event_start_time
                    ? dayjs(formData?.event_start_time, "HH:mm:ss")
                    : null
                }
                showToday={true}
              />
            </div>{" "}
            &nbsp;
            <div className="w-full mb-2">
              <label htmlFor="access_level" style={{ fontWeight: "bold" }}>
                End Time
              </label>
              <TimePicker
                className="w-full mb-2 h-[38px]"
                name="event_end_time"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    event_end_time: dateString,
                  });
                }}
                value={
                  formData?.event_end_time
                    ? dayjs(formData?.event_end_time, "HH:mm:ss")
                    : null
                }
                maxDetail="minute"
                showToday={true}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventAdd;
