import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import { Toaster } from "react-hot-toast";
import "@mdi/font/css/materialdesignicons.min.css";

const App = () => {
  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            fontSize: "12px",
          },
        }}
      ></Toaster>
      <ProtectedRoutes />
    </div>
  );
};

export default App;
