import React from "react";
import CardBackground from "./CardBackground";
import ChurchAddChild from "../Churches/Create/ChurchAddChild";
import MultiStageForm from "./MultiStageForm";

const ChurchOnBoardComponent = ({ setChurchOnBoardShow }) => {
  return (
    <>
      <CardBackground>
        <div className="w-full">
          <div className="w-full text-end">
            <i
              className="text-lg cursor-pointer mdi mdi-close-circle-outline"
              onClick={() => setChurchOnBoardShow(false)}
            ></i>
          </div>
          <div className="">
            {/* <ChurchAddChild /> */}
            <MultiStageForm />
          </div>
        </div>
      </CardBackground>
    </>
  );
};

export default ChurchOnBoardComponent;
