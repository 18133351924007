import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import { useLocation } from "react-router-dom";

import IncomeUpdateChild from "./IncomeUpdateChild";

const IncomeUpdate = () => {
  const { state } = useLocation();
  const incomeRecord = state;
  return (
    <MainTemplate>
      <CardBackground>
        <IncomeUpdateChild incomeRecord={incomeRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default IncomeUpdate;
