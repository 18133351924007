import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import IncomeDuesAddChild from "./IncomeDuesAddChild";

const IncomeDuesAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <IncomeDuesAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default IncomeDuesAdd;
