import React, { useState, useEffect } from "react";
import logoTop from "../../images/top-bar-logo.png";
import avatar from "../../images/no-image.png";
import axios from "axios";
import { menuItems, menuItemsMember } from "../Data/MenuData";
import MenuComponent from "./MenuComponent";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import RightDropDownMenu from "./RightDropDownMenu";
import ReportsComponent from "./ReportsComponent";
import TransactionsComponent from "./TransactionsComponent";
import ScheduleComponent from "./ScheduleComponent";
import MemberPaymentsMenu from "./MemberPaymentsMenu";

const Topbar = ({ menuState, setMenuState }) => {
  let navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [userData, setData] = useState(null);

  const [isHovered, setIsHovered] = useState(false);
  let hoverTimer;
  const [catMenuStatus, setCatMenuStatus] = useState(true);
  const [toggleBurgerMenu, setToggleBurgerMenu] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("user-details");
    setUserStatus({ ...userStatus, user: null });
    navigate("/login", { replace: true });
  };
  //console.log(userStatus);

  const handleMouseEnter = () => {
    hoverTimer = setTimeout(() => {
      setIsHovered(true);
    }, 2000);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimer);
    setIsHovered(false);
  };

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // token has expired, do something here (e.g. log out the user)
          localStorage.removeItem("token");
          sessionStorage.removeItem("user-details");
          setUserStatus({ ...userStatus, user: null });
          navigate("/login", { replace: true });
        } else {
          setUserStatus({ ...userStatus, user: decodedToken });
        }
      }
    };
    checkToken();
  }, []);

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = "";
    if (userStatus?.user?.role === "CHURCH_ADMIN") {
      urlLink = `${process.env.REACT_APP_API_URL}/user/${userStatus?.user?._id}`;
    } else {
      if (userStatus?.user?._id)
        urlLink = `${process.env.REACT_APP_API_URL}/family/primaryMember/${userStatus?.user?._id}`;
    }

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (userStatus?.user?.role === "CHURCH_ADMIN") {
          const oldData = response?.data?.users;
          setData(oldData);
        } else {
          const oldData = response?.data?.families;
          setData(oldData[0]);
        }
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);
  localStorage.setItem("user_image", userData?.image);

  return (
    <header className="bg-white shadow-xl">
      <nav className="flex justify-between items-center w-[92%] mx-auto ">
        <div className="flex items-center flex-shrink-0 mr-6 text-white">
          <img className="w-40 p-5 cursor-pointer " src={logoTop} alt="..." />
        </div>

        <div
          className={`nav-links duration-500 md:static absolute bg-white md:min-h-fit min-h-[60vh] left-0 ${
            !toggleBurgerMenu ? "top-[-100%]" : "top-[8%] z-10"
          } md:w-auto  w-full flex items-center px-5`}
        >
          {userStatus?.user?.role === "FULL_ACCESS" && (
            <div
              className="flex items-center p-1 mr-4 text-sm border border-gray-500 rounded outline-none cursor-pointer"
              onClick={() => navigate("/superadmin")}
            >
              <i
                className="mdi mdi-home-outline"
                style={{ fontSize: "18px" }}
              />{" "}
              <span className="text-[13px] ml-1">Home</span>
            </div>
          )}
          {userStatus?.user?.role === "CHURCH_ADMIN" && (
            <div
              className="flex items-center p-1 mr-4 text-sm border border-gray-500 rounded outline-none cursor-pointer"
              onClick={() => navigate("/dashboard-ca")}
            >
              <i
                className="mdi mdi-home-outline"
                style={{ fontSize: "18px" }}
              />{" "}
              <span className="text-[13px] ml-1">Home</span>
            </div>
          )}
          <ul className="flex md:flex-row flex-col md:items-center md:gap-[1vw] gap-1">
            {userStatus?.user?.role === "CHURCH_ADMIN" && (
              <div className="relative">
                <div
                  onClick={() => setCatMenuStatus(!catMenuStatus)}
                  // onMouseLeave={() => setCatMenuStatus(!catMenuStatus)}
                  // onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="block mt-4 mr-4 text-sm text-gray-800 cursor-pointer lg:inline-block lg:mt-0 hover:text-gray-500"
                >
                  Master ▼
                </div>
                <div
                  className={`absolute right-0 left-2 z-10 ${
                    catMenuStatus ? "hidden" : null
                  } w-52 mt-6 bg-white rounded-md shadow-lg`}
                  onMouseLeave={() => setCatMenuStatus(!catMenuStatus)}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                >
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/banks");
                    }}
                  >
                    Bank Master
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/catlist");
                    }}
                  >
                    Category Master
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/employees");
                    }}
                  >
                    Employee Master
                  </button>
                  <hr />

                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/baptism/list");
                    }}
                  >
                    Baptism Register
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/worship/list");
                    }}
                  >
                    Worship Register
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/marriages/list");
                    }}
                  >
                    Marriage Register
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/confirmation/list");
                    }}
                  >
                    Confirmation Register
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
                    onClick={() => {
                      setCatMenuStatus(!catMenuStatus);
                      navigate("/burial/list");
                    }}
                  >
                    Burial Register
                  </button>
                </div>
              </div>
            )}
            {/* {JSON.stringify(userStatus)} */}
            {userStatus?.user?.role === "CHURCH_ADMIN" && (
              <TransactionsComponent />
            )}
            {userStatus?.user?.role === "CHURCH_ADMIN" && <ScheduleComponent />}
            {userStatus?.user?.role === "CHURCH_ADMIN" && <ReportsComponent />}
            {userStatus?.user?.role === "CHURCH_ADMIN" &&
              menuItems.map((menu) => (
                <MenuComponent name={menu?.name} link={menu?.link} />
              ))}
            {(userStatus?.user?.role === "CHURCH_MEMBER" ||
              userStatus?.user?.role === "SUB_MEMBER") &&
              menuItemsMember.map((menu) => (
                <MenuComponent name={menu?.name} link={menu?.link} />
              ))}
            {/* MemberPaymentsMenu */}
            {(userStatus?.user?.role === "CHURCH_MEMBER" ||
              userStatus?.user?.role === "SUB_MEMBER") && (
              <MemberPaymentsMenu />
            )}
          </ul>
        </div>
        <div className="flex items-center gap-6">
          <div className="flex mr-4">
            <div className="relative">
              <div
                className="flex items-center justify-center h-10 px-4 text-sm text-gray-600 cursor-pointer hover:text-gray-500 focus:outline-none"
                onClick={() => setMenuState(!menuState)}
                //onMouseEnter={() => setMenuState(!menuState)}
              >
                {userData?.image && (
                  <img
                    crossOrigin="anonymous"
                    src={`${
                      process.env.REACT_APP_REPORTS_DOWNLOAD_URL
                    }uploads/${
                      userStatus?.user?.role === "CHURCH_ADMIN"
                        ? "employee"
                        : "family"
                    }/${userData?.image}`}
                    style={{ height: "50px", width: "50px" }}
                    className="rounded-full w-[60px] p-0 mr-1"
                  />
                )}
                {!userData?.image && (
                  <img
                    src={avatar}
                    className="rounded-full w-[40px] p-1 mr-1"
                    style={{ height: "50px", width: "70px" }}
                  />
                )}
                <span className="block truncate w-27">
                  {userStatus?.user?.name}
                  <span className="block text-xs font-light text-gray-400">
                    {userStatus?.user?.user_type}
                  </span>
                </span>
              </div>
              <RightDropDownMenu
                setMenuState={setMenuState}
                menuState={menuState}
                handleLogout={handleLogout}
              />
            </div>
          </div>
          <svg
            viewBox="0 0 100 100"
            width="12"
            height="12"
            className={`z-10 cursor-pointer ${
              !toggleBurgerMenu && "hidden"
            } mr-2`}
            onClick={() => setToggleBurgerMenu(!toggleBurgerMenu)}
          >
            <line
              x1="10"
              y1="10"
              x2="90"
              y2="90"
              stroke="black"
              stroke-width="10"
            />
            <line
              x1="90"
              y1="10"
              x2="10"
              y2="90"
              stroke="black"
              stroke-width="10"
            />
          </svg>
          <svg
            viewBox="0 0 100 80"
            width="12"
            height="12"
            className={`z-10 text-4xl cursor-pointer mr-2 ${
              toggleBurgerMenu ? "hidden" : "md:hidden "
            }`}
            onClick={() => setToggleBurgerMenu(!toggleBurgerMenu)}
          >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
