import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import CenterStageSection from "./CenterStageSection";
import TimelineSection from "./TimelineSection";
import ProfileComponent from "./ProfileComponent";
import ContextMenuSection from "./ContextMenuSection";
import avatar from "../../../images/avatar-9.jpg";

const MemberDashboardChild = () => {
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [logggedUser, setLoggedUser] = useState(user);

  return (
    <div className="flex flex-grow bg-gray-100">
      <div className="flex flex-grow bg-gray-100">
        <div className="hidden w-1/5 md:block">
          <div className="sticky top-0 h-screen overflow-y-auto overflow-y-hidden">
            <ProfileComponent member={user} />
            <ContextMenuSection />
          </div>
        </div>
        <div className="w-full md:hidden">
          <TimelineSection />
        </div>
        <div className="hidden md:flex md:w-4/5">
          <div className="flex w-full overflow-y-auto">
            <CenterStageSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDashboardChild;
