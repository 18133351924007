import React from "react";
import CardBackground from "./CardBackground";
import avatar from "../../../images/avatar-9.jpg";
import { useNavigate } from "react-router-dom";

const ContextMenuSection = () => {
  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <MenuComponent title={"Notices"} icon={"mdi-alert-circle-outline"} />
          <MenuComponent title={"Events"} icon={"mdi-calendar-today"} />
          <MenuComponent title={"Newsletters"} icon={"mdi-newspaper"} />
        </div>
      </CardBackground>
    </div>
  );
};

const MenuComponent = ({ title, icon }) => {
  let navigate = useNavigate();
  return (
    <div className="mt-1 ml-1">
      <div className="flex items-center justify-between ">
        <div onClick={() => navigate("/member"+title)} className="flex flex-row w-full p-2 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200">
          <div className="flex items-center justify-center w-4 h-4 p-4 bg-gray-500 rounded-full">
            <i className={`mdi ${icon}`} style={{ color: "white" }} />
          </div>
          <div className="mt-1 ml-2">
            <div className="text-sm">{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContextMenuSection;
