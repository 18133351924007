import React, { useEffect, useState, useRef } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import { gbState } from "../../../components/shared/GlobalState";
import CardTile from "./CardTile";
import { useLocation } from "react-router-dom";

const FamilyMembersChild = () => {
  const { state } = useLocation();

  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  let selected_hof = state;
  // if (!state) {
  //   selected_hof = user?._id;
  // }

  const [data, setData] = useState(null);
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/family/${user.church_id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.families;
        setData(oldData);
        //setData(showFamily(oldData, user?._id));
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);

  //Show members from my Family
  const showFamily = (data, primaryMemberId) => {
    return data.filter(
      (family) => family.primary_member_id._id === primaryMemberId
    );
  };

  return (
    <div className="flex items-center justify-center w-full p-10 ml-5">
      <div className="flex flex-col">
        <h1 className="mb-2">Members</h1>
        {data && (
          <CardTile
            familiesData={data}
            hof={selected_hof}
            current_hof={user?._id}
          />
        )}
        {/* {JSON.stringify(state)} */}
        {/* {JSON.stringify(user?._id)} */}
      </div>
    </div>
  );
};

export default FamilyMembersChild;
