import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AddWorshipChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    worship_date: null,
    worship_description: null,
    nature_of_service: null,
    reader: null,
    celebrant_preacher: null,
    text_subject: null,
    no_of_communicants: null,
    offerings: null,
    remarks: null,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  function formatString(str) {
    // Remove underscores and split the string into words
    const words = str.replace(/_/g, " ").split(" ");

    // Capitalize the first letter of the first word
    const capitalizedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });

    // Join the words back into a single string
    return capitalizedWords.join(" ");
  }

  const handleWorshipRegister = () => {
    const fieldToValidate = [
      "worship_date",
      "worship_description",
      "nature_of_service",
      "reader",
      "celebrant_preacher",
      "text_subject",
      "no_of_communicants",
    ];

    // Identify empty fields and filter out any undefined entries
    const emptyFields = fieldToValidate
      .filter((f) => !formData[f])
      .map(formatString); // Assuming formatString formats the string as previously discussed

    // Provide feedback if there are any empty fields
    if (emptyFields.length > 0) {
      return toast.error(`${emptyFields.join(", ")} cannot be empty`);
    }

    // Configuration for axios request
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/worship/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
      },
    };

    // Axios request
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
        navigate("/worship/list");
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
        errorLogApi(error, "AddWorship", "/worship/add");
      });
  };

  return (
    <div className="w-full">
      <div className="flex justify-end w-full mt-4">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={handleWorshipRegister}
        >
          Create Record
        </button>
      </div>
      <div className="flex flex-col items-center justify-center md:flex-row">
        <fieldset className="w-full p-2 whitespace-normal border md:w-1/3 md:mr-2">
          <legend className="float-none w-auto p-2 fs-5">
            Worship Details
          </legend>
          <div className="p-5">
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Worship date
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="worship_date"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      worship_date: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Worship description
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="worship_description"
                  type="text"
                  required
                  placeholder="Description"
                  onChange={handleChange}
                  value={formData.worship_description}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Nature of Service
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="nature_of_service"
                  type="text"
                  required
                  placeholder="Nature of Service"
                  onChange={handleChange}
                  value={formData.nature_of_service}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Reader
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="reader"
                  type="text"
                  required
                  placeholder="Reader"
                  onChange={handleChange}
                  value={formData.reader}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Celebrant Preacher
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="celebrant_preacher"
                  type="text"
                  required
                  placeholder="Celebrant preacher"
                  onChange={handleChange}
                  value={formData.celebrant_preacher}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  No of communicants
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="no_of_communicants"
                  type="text"
                  required
                  placeholder="No of Communicants"
                  onChange={handleChange}
                  value={formData.no_of_communicants}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Offerings
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="offerings"
                  type="text"
                  required
                  placeholder="Offerings"
                  onChange={handleChange}
                  value={formData.offerings}
                />
              </div>
              <div className="w-full ml-2">&nbsp;</div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full">
                <label className="block mb-2 text-sm text-gray-500">
                  Text Subject
                </label>
                <textarea
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="text_subject"
                  type="text"
                  rows={3}
                  required
                  placeholder="Text Subject"
                  onChange={handleChange}
                  value={formData.text_subject}
                />
              </div>
            </div>
            <div className="flex flex-row ">
              <div className="w-full">
                <label className="block mb-2 text-sm text-gray-500">
                  Remarks
                </label>
                <textarea
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="remarks"
                  type="text"
                  rows={3}
                  required
                  placeholder="Remarks"
                  onChange={handleChange}
                  value={formData.remarks}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default AddWorshipChild;
