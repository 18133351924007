import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import CardBackground from "./CardBackground";
import { confirmAlert } from "react-confirm-alert";
import { useAtom } from "jotai";
import { gbState, globalUserStatus } from "../../components/shared/GlobalState";

const AdminMenuSection = ({ setChurchOnBoardShow }) => {
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [userGlobal, setUserGlobal] = useAtom(globalUserStatus);
  const [buddies, setBuddies] = useState();
  const [updatedUser, setUpdatedUser] = useState();

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/buddies-details`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { buddies: userGlobal?.buddyRequests },
    };

    axios(config)
      .then(function (response) {
        //toast.success("Request Sent Successfully.");

        setBuddies(response?.data);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  }, [userGlobal]);

  const updateUserDetailRealtime = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/refresh-user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { userId: userGlobal?._id },
    };

    axios(config)
      .then(function (response) {
        //toast.success("Request Sent Successfully.");

        setUpdatedUser(response?.data);
        setUserGlobal({ ...userGlobal, ...response?.data });
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  };

  return (
    <div>
      <CardBackground>
        <div className="flex flex-col justify-center w-full">
          <div className="flex justify-between">
            <div className="flex flex-row">
              <i className="mdi mdi-menu" />
              <h2 className="mt-1 ml-2 text-xs text-gray-400">Admin Menu</h2>
            </div>
          </div>
          <button
            className="block w-full h-8 p-2 m-1 mt-4 text-xs text-white bg-blue-400 rounded-sm justify-items-center hover:bg-blue-600"
            onClick={() => setChurchOnBoardShow(true)}
          >
            <i className="text-md mdi mdi-arrow-right-bold-box-outline"></i>{" "}
            &nbsp;ONBOARD NEW CHURCH
          </button>
        </div>
      </CardBackground>
    </div>
  );
};

export default AdminMenuSection;
