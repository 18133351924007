import React from "react";
import ResponsiveTable from "./ResponsiveTable";

const FamilyView = ({ currentRecord }) => {
  return (
    <div className="">
      <div className="flex items-center justify-center w-full">
        {currentRecord?.image && (
          <img
            crossOrigin="anonymous"
            src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${currentRecord?.image}`}
            alt="Image"
            className="w-[200px] p-1 mr-1 rounded-full"
          />
        )}
      </div>
      <ResponsiveTable json={currentRecord} />
    </div>
  );
};

export default FamilyView;
