import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

const SubscriberAddChild = () => {
  let navigate = useNavigate();
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const currentDt = new Date();
  const year = currentDt.getFullYear();
  const month = (currentDt.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDt.getDate().toString().padStart(2, "0");
  const random =
    year +
    month +
    day +
    Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");

  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [churchMembers, setChurchMembers] = useState(null);
  const [monthlySubrptn, setMonthlySubs] = useState(0);
  const [paidUpto, setPaidUpto] = useState(0);
  const [outstandingMonth, setOutstandingMonth] = useState(0);
  const [outstandingAmt, setOutstandingAmt] = useState(0);
  const [paymentMonth, setPaymentMonth] = useState(0);
  const [formData, setFormData] = useState({
    financial_year: user?.current_financial_year,
    receipt_no: random,
    receipt_date: null,
    subscriber_id: null,
    monthly_subscription: null,
    paid_up_to: null,
    outstanding_months: null,
    outstanding_amount: null,
    payment_amount: null,
    payment_month: null,
    payment_mode: null,
    payment_status: null,
  });
  // Get the current year
  const currentDate = new Date(); // Get the current date
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const generateFinancialYears = () => {
    const years = [];
    for (
      let year = currentYear;
      year >= currentYear - (currentYear - previousYear);
      year--
    ) {
      years.push({
        value: year + "-" + (year + 1),
        label: year + "-" + (year + 1),
      });
    }
    return years;
  };

  useEffect(() => {
    setFormData({ ...formData, financial_year: financial_year?.value });
  }, [financial_year]);

  const financialYears = financial_year;

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/subscriber/church/${user?.church_id}`;
    const config = {
      method: "get",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        const members = response?.data?.subscribers;
        console.log(members);

        setChurchMembers(
          members.map((member) => ({
            value:
              member.subscriber_id._id +
              "#_#" +
              member.monthly_subscription +
              "#_#" +
              member.date_of_joining,
            label:
              member.subscriber_id.first_name +
              " " +
              member.subscriber_id.last_name +
              " (" +
              member.subscriber_id.user_type +
              ")",
          }))
        );
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "SubscriberAdd", "/subscriber/add");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    if (input.name === "payment_amount") {
      let total_mnth = input.value / monthlySubrptn;
      let enterVal = input.value;
      if (parseInt(enterVal) > parseInt(monthlySubrptn)) {
        if (!Number.isInteger(total_mnth)) {
          setPaymentMonth(0);
          toast.error("Please enter multiples of monthly subscription");
          return;
        }
      }
      setPaymentMonth(total_mnth);
    }
    setFormData({
      ...formData,
      [input.name]: input.value,
      payment_month: paymentMonth,
      monthly_subscription: monthlySubrptn,
      paid_up_to: paidUpto,
      outstanding_months: outstandingMonth,
      outstanding_amount: outstandingAmt,
    });
  };
  const handleDropdownChange = (event) => {
    let subIdArr = event.value.split("#_#");
    setMonthlySubs(subIdArr[1]);
    let startDate = subIdArr[2];
    let endDate = currentDate;

    setPaidUpto(startDate);
    // **************check last payment date***************
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/receipt/last_payment/${subIdArr[0]}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const newData = response?.data?.receipts;
        // console.log(newData.payment_date);
        setPaidUpto(subIdArr[2]);
        let startDate = null;
        //console.log(JSON.stringify(newData?.payment_date));
        if (newData?.payment_date) {
          const newDate = new Date(moment(newData?.payment_date));
          newDate.setMonth(newDate.getMonth() + 1);
          setPaidUpto(newDate);
          startDate = newDate;
        }
        // console.log(JSON.stringify(startDate + "   " + endDate));
        if (startDate && endDate) {
          const start = new Date(startDate);
          const end = new Date(endDate);
          const months =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());
          //console.log(months);
          setOutstandingMonth(months);
          setOutstandingAmt(months * subIdArr[1]);
        }
      })
      .catch(function (err) {
        console.log("error occured.");
      });
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const months =
        (end.getFullYear() - start.getFullYear()) * 12 +
        (end.getMonth() - start.getMonth());
      setOutstandingMonth(months);
      setOutstandingAmt(months * subIdArr[1]);
    }
    setFormData({
      ...formData,
      subscriber_id: event.value,
      receipt_date: currentDate,
    });
  };
  const handleAddSubscriber = (e) => {
    if (!formData.subscriber_id) return toast.error("Select the Subscriber");

    if (!formData.financial_year)
      return toast.error("Please select financial year");

    if (!formData.payment_amount)
      return toast.error("Please enter payment amount");

    if (
      parseInt(formData.payment_amount) <
      parseInt(formData.monthly_subscription)
    )
      return toast.error("Please enter multiples of monthly subscription");

    if (formData.payment_month === "0")
      return toast.error("Please enter multiples of monthly subscription");

    if (!formData.payment_mode)
      return toast.error("Please select payment mode");

    if (!formData.payment_status)
      return toast.error("Please select payment status");

    if (
      parseInt(formData.payment_amount) > parseInt(formData?.outstanding_amount)
    ) {
      return toast.error("The amount should be equal to outstanding");
    }

    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/receipt/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Subscriber Created Successfully.");
        navigate("/subscribers");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.error);
        errorLogApi(error, "SubscriberAddChild", "/subscriber/add");
        // console.log(error);
      });
  };
  const inputElementWidthStyle = `w-10/12 p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const incomeSourceOptions = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Online Transfer" },
    { value: 4, label: "Demand Draft" },
  ];

  const paymentStatusOption = [
    // { value: 0, label: "Pending" },
    { value: 1, label: "Settled" },
    { value: 2, label: "Declined" },
  ];
  useEffect(() => {
    setFormData({
      ...formData,
      payment_month: paymentMonth,
    });
  }, [paymentMonth]);

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[100%]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(financial_year)} */}
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={handleAddSubscriber}
          >
            Add Collection
          </button>
        </div>
        <h2 className="mt-10 mb-0 text-bold">Member Subscription Collection</h2>
        <div className="m-1 overflow-x-auto outline-none">
          <div className="flex mt-4 mb-0">
            <div className="w-full mb-0">
              <label className="block mb-2 text-sm text-gray-500">
                Financial Year
              </label>
              <input
                type="text"
                name="financial_year"
                autoComplete="off"
                className={inputElementWidthStyle}
                readOnly
                value={user?.current_financial_year}
              />
              {/* <Select
                name="financial_year"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    financial_year: selected.value,
                  });
                }}
                options={financialYears}
                placeholder={placeHolderText("Select Financial Year")}
                className={`w-10/12 outline-none h-11 mt-3`}
                menuPosition={"fixed"}
              /> */}
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Receipt No
              </label>
              <input
                type="text"
                name="receipt_no"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
                readOnly
                value={formData.receipt_no}
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Receipt Date
              </label>
              <input
                type="text"
                name="receipt_date"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
                value={moment(currentDate).format("DD-MM-YYYY")}
                readOnly
              />
            </div>
          </div>
          <div className="flex mt-4 mb-0">
            <div className="w-full mb-0">
              <label className="block mb-2 text-sm text-gray-500">Member</label>
              <Select
                name="subscriber_id"
                onChange={handleDropdownChange}
                options={churchMembers}
                placeholder={placeHolderText("Select Member")}
                className={`w-10/12 outline-none h-11 mt-3`}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Monthly Subscription
              </label>
              <input
                type="text"
                name="monthly_subscription"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
                readOnly
                value={monthlySubrptn}
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-0">
              <label className="block mb-2 text-sm text-gray-500">
                Paid Upto
              </label>
              <input
                type="text"
                name="paid_up_to"
                autoComplete="new-password"
                onChange={handleChange}
                className={inputElementWidthStyle}
                value={moment(paidUpto).format("MMMM, YYYY")}
              />
            </div>
          </div>
          <div className="flex mt-4 mb-0">
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Outstanding No of Months
              </label>
              <input
                type="text"
                name="outstanding_months"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
                value={outstandingMonth}
                readOnly
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Outstanding
              </label>
              <input
                type="text"
                name="outstanding_amount"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
                value={outstandingAmt}
                readOnly
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-0">
              <label className="block mb-2 text-sm text-gray-500">
                Payment
              </label>
              <input
                type="text"
                name="payment_amount"
                autoComplete="new-password"
                onChange={handleChange}
                className={inputElementWidthStyle}
                value={formData?.payment_amount && formData?.payment_amount}
              />
            </div>
          </div>
          <div className="flex mt-4 mb-0">
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Payment for No of Months
              </label>
              <input
                type="text"
                name="payment_month"
                autoComplete="off"
                onChange={handleChange}
                className={inputElementWidthStyle}
                value={paymentMonth}
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Mode of Payment
              </label>
              <Select
                name="payment_mode"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    payment_mode: selected.value,
                  });
                }}
                options={incomeSourceOptions}
                placeholder={placeHolderText("Select Mode of Payment")}
                className={`w-10/12 outline-none h-11 mt-3`}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
            <div className="ml-5 mr-5"></div>
            <div className="w-full mb-2">
              <label className="block mb-2 text-sm text-gray-500">
                Payment Status
              </label>
              <Select
                name="payment_status"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    payment_status: selected.value,
                  });
                }}
                options={paymentStatusOption}
                placeholder={placeHolderText("Select Mode of Payment")}
                className={`w-10/12 outline-none h-11 mt-3`}
                menuPosition={"fixed"}
                //styles={style}
              />
            </div>
          </div>
        </div>
        {/* {JSON.stringify(user)} */}
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default SubscriberAddChild;
