import React, { useState } from "react";

const RecordDetailsTable = ({ jdata, keysToShow, title }) => {
  const [data] = useState(jdata);

  function formatString(str) {
    // Replace underscores with spaces
    let formattedStr = str.replace(/_/g, " ");

    // Capitalize the first letter of the first word
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

    return formattedStr;
  }

  return (
    <div className="container p-1 mx-auto">
      <h2 className="text-2xl font-bold ">{title}</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="text-white bg-gray-800">
              <th className="px-2 py-1 border">Field</th>
              <th className="px-2 py-1 border">Details</th>
            </tr>
          </thead>
          <tbody>
            {keysToShow?.map((key, index) => (
              <tr
                key={key}
                className={`${
                  index % 2 === 0 ? "bg-gray-200" : "bg-white"
                } hover:bg-gray-300`}
              >
                <td className="px-2 py-1 border">{formatString(key)}</td>
                <td className="px-2 py-1 border">
                  {typeof data[key] === "object" && data[key] !== null
                    ? JSON.stringify(data[key]?.diocese_name, null, 2)
                    : data[key] !== undefined
                    ? data[key]
                    : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecordDetailsTable;
