import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast from "react-hot-toast";
import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import { makeStyles } from "@material-ui/core/styles";
import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

const AssetInventoryChild = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [financial_year] = useAtom(fYear);
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const dayOfMonth = today.getDate();

  const [shouldDisplayButton, setShouldDisplayButton] = useState(false);

  useEffect(() => {
    // Get the last day of the current month
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    // Check if today is one of the last two days of the month
    if (dayOfMonth === lastDayOfMonth || dayOfMonth === lastDayOfMonth - 1) {
      setShouldDisplayButton(true);
    } else {
      setShouldDisplayButton(false);
    }
  }, [dayOfMonth, currentYear, currentMonth]);

  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/asset/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        // const oldData = response.data.assets;
        const oldData = response.data.assets?.filter(
          (finFilter) => finFilter?.financial_year === financial_year?.label
        );
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            asset_name: data?.asset_name,
            asset_id: data?.asset_id,
            description: data?.description,
            status: data?.status,
            church: data?.church_id,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/church/stock/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          // const oldData = response.data.current_stock;
          const oldData = response.data.current_stock?.filter(
            (finFilter) =>
              finFilter?.current_stock?.financial_year === financial_year?.label
          );
          const updatedData = oldData.map((data) => {
            return {
              //   ...data,
              id: data?._id,
              asset_id: data?.current_stock?.asset_id?._id,
              item_code: data?.current_stock?.asset_id?.item_code,
              asset_name: data?.current_stock?.asset_id?.asset_name,
              opening_stock: data?.current_stock?.opening_stock,
              inward_qty: data?.current_stock?.inward_qty,
              outward_qty: data?.current_stock?.outward_qty,
              closing_stock: data?.current_stock?.closing_stock,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    getDataFromServer();
  }, [financial_year]);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params?.row?.asset_name}</span>
        <span id="content2">
          <small className="small-font-size">{params?.row?.item_code}</small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "item_code",
      headerName: "Asset Code",
      width: 260,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: MultiLineCellRenderer,
    },
    // {
    //   field: "asset_name",
    //   headerName: "Asset Name",
    //   width: 260,
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    // },
    {
      field: "opening_stock",
      headerName: "Opening Stock",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: "center",
    },
    {
      field: "inward_qty",
      headerName: "Inward QTY",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: "center",
    },
    {
      field: "outward_qty",
      headerName: "Outward QTY",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: "center",
    },
    {
      field: "closing_stock",
      headerName: "Closing Stock",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      align: "center",
    },
    {
      field: "issue",
      headerName: "Action",
      width: 180,
      align: "center",
      renderCell: (params) => {
        return (
          <button
            className="px-3 py-2 text-xs text-white uppercase bg-blue-600 rounded hover:bg-blue-900"
            onClick={() =>
              navigate("/assets/inventory/issue", { state: params.row })
            }
          >
            - Issue Asset
          </button>
        );
      },
    },
  ];

  const handleAssetStockSettlement = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/church/stocksettle/${user.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        getDataFromServer();
        toast.success("Asset Settled Successfully.");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.error);
        errorLogApi(error, "AssetStockSettleMent", "/church/stocksettle/");
        // console.log(error);
      });
  };

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-green-600 rounded hover:bg-green-900"
          onClick={() => navigate("/assets/settlement/history")}
        >
          Settlement History
        </button>
        &nbsp;
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/assets/inventory/issue/history")}
        >
          Outward History
        </button>
        &nbsp;
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/assets/inventory/inward/history")}
        >
          Inward History
        </button>
        &nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={() => navigate("/assets")}
        >
          Assets Master
        </button>
        &nbsp;
        <button
          className="px-4 py-2 text-white bg-purple-600 rounded hover:bg-purple-900"
          onClick={() => navigate("/assets/inventory/add")}
        >
          + Asset Inward
        </button>
        &nbsp;
        {shouldDisplayButton && (
          <button
            className="px-4 py-2 text-white bg-orange-600 rounded hover:bg-orange-900"
            onClick={handleAssetStockSettlement}
          >
            Settle Stock
          </button>
        )}
      </div>
      <h2>Asset List</h2>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Asset List"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

export default AssetInventoryChild;
