import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const SubscriberAddChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, Category, user }] = useAtom(gbState);
  const [churchMembers, setChurchMembers] = useState(null);
  const [categoryList, setCategoryList] = useState();
  const [churchList, setChurchList] = useState();
  const [formData, setFormData] = useState({
    subscriber_id: null,
    date_of_joining: null,
    comments: null,
  });

  //Category
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/category/income/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          const oldData = response.data.Categorys;
          const updatedData = oldData.map((data) => {
            return {
              value: data._id,
              label: data.category_name,
            };
          });

          setCategoryList(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err.response.data.message);
        });
    };
    getDataFromServer();
  }, []);

  //   Church Members
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/subscribers/${user.church_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const members = response.data.members;
        setChurchMembers(
          members.map((member) => ({
            value: member._id,
            label:
              member.first_name +
              " " +
              member.last_name +
              " (" +
              member.user_type +
              ")",
          }))
        );
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "SubscriberAdd", "/subscriber/add");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleAddSubscriber = (e) => {
    if (!formData.subscriber_id) return toast.error("Select the Subscriber");

    if (!formData.date_of_joining)
      return toast.error("Select the Date of joining");

    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/subscriber/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Subscriber Created Successfully.");
        navigate("/subscribers");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.error);
        errorLogApi(error, "SubscriberAddChild", "/subscriber/add");
        // console.log(error);
      });
  };

  const incomeSourceOptions = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Cheque" },
    { value: 3, label: "Online Transfer" },
    { value: 4, label: "Demand Draft" },
  ];

  const paymentStatusOption = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Settled" },
    { value: 2, label: "Declined" },
  ];
  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(churchMembers)} */}
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
            onClick={handleAddSubscriber}
          >
            Add Subscriber
          </button>
        </div>
        <h2 className="mb-3 text-bold">Add Subscriber</h2>

        <div className="m-1 overflow-x-auto outline-none">
          <div className="mt-4 ">
            <Select
              name="subscriber_id"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  subscriber_id: selected.value,
                });
              }}
              options={churchMembers}
              placeholder={placeHolderText("Select Member")}
              className={`w-[${inputWidth}] outline-none h-11 mt-3`}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              //styles={style}
            />
          </div>
          <div className="mt-4 ">
            <DatePicker
              className="w-full mb-2 h-[38px]"
              name="date_of_joining"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  date_of_joining: dateString,
                });
              }}
              // disabledDate={(current) => {
              //   let customDate = moment().format("YYYY-MM-DD");
              //   return current && current < moment(customDate, "YYYY-MM-DD");
              // }}
              showToday={true}
            />
          </div>
          <div className="mt-4 ">
            <input
                className="w-full p-3 mt-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="monthly_subscription"
                type="number"
                required
                placeholder="Enter Monthly Subscription"
                onChange={handleChange}
                value={formData.monthly_subscription}
              />
          </div>
          <div className="mt-4 ">
            <textarea
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="comments"
              type="text"
              rows={3}
              placeholder="Comments"
              autoComplete="off"
              onChange={handleChange}
            />
          </div>
        </div>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default SubscriberAddChild;
