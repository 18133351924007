import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ChurchUpdateChild from "./ChurchUpdateChild";
import { useLocation } from "react-router-dom";

const ChurchUpdate = () => {
  const { state } = useLocation();
  return (
    <MainTemplate>
      <CardBackground>
        <ChurchUpdateChild churchData={state} />
      </CardBackground>
    </MainTemplate>
  );
};

export default ChurchUpdate;
