import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import AssetInwardView from "./AssetInwardView";
import moment from "moment";
import { toast } from "react-hot-toast";

const AssetInwardHistoryChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);
  const [financial_year] = useAtom(fYear);
  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Family ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/church/inward/delete/${id}`,
        config
      )
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        toast.error("Cannot be deleted");
        errorLogApi(err, "AssetListChild", "/asset/delete");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/asset/${user?.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        // const oldData = response.data.assets;
        const oldData = response.data.assets?.filter(
          (finFilter) =>
            finFilter?.inward_register?.financial_year === financial_year?.label
        );
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            asset_name: data?.asset_name,
            asset_id: data?.asset_id,
            description: data?.description,
            status: data?.status,
            church: data?.church_id,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/church/inward/${user?.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users)); financial_year[0].label
          const oldData = response.data.purchases?.filter(
            (finFilter) =>
              finFilter?.inward_register?.financial_year ===
              financial_year?.label
          );
          const updatedData = oldData.map((data) => {
            return {
              //   ...data,
              id: data?._id,
              asset_id: data?.inward_register?.asset_id?._id,
              asset_name: data?.inward_register?.asset_id?.asset_name,
              item_code: data?.inward_register?.asset_id?.item_code,
              inward_date: data?.inward_register?.inward_date,
              asset_tag: data?.inward_register?.asset_tag,
              quantity: data?.inward_register?.quantity,
              inward_type: data?.inward_register?.inward_type,
              inward_rate: data?.inward_register?.inward_rate,
              inward_description: data?.inward_register?.inward_description,
              inward_status: data?.inward_register?.inward_status,
              comments: data?.inward_register?.comments,
              purchaser_id:
                data?.inward_register?.purchaser_id?.first_name +
                " " +
                data?.inward_register?.purchaser_id?.last_name,
            };
          });
          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };

    getDataFromServer();
  }, [financial_year]);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() =>
                  navigate("/assets/inventory/update", { state: params?.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => handleDelete(params?.row?.id)}
                className="my-overlay"
                style={{
                  position: "relative",
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "item_code",
      headerName: "Item Code",
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "asset_name",
      headerName: "Asset Name",
      width: 160,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "inward_date",
      headerName: "Inward Date",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        params?.row?.inward_date && params?.row?.inward_date?.slice(0, 10),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "inward_type",
      headerName: "Inward Type",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => JSON.parse(params?.row?.inward_type)?.label,
    },
    {
      field: "inward_rate",
      headerName: "Inward Rate",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "purchaser_id",
      headerName: "Purchaser",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 200,
    //   renderCell: (params) =>
    //     params.row.status ? (
    //       <span className="inline-block px-2 py-1 text-xs font-semibold text-green-800 bg-green-200 rounded-full">
    //         &nbsp;&nbsp;Active&nbsp;&nbsp;
    //       </span>
    //     ) : (
    //       <span className="inline-block px-2 py-1 text-xs font-semibold text-red-800 bg-red-200 rounded-full">
    //         &nbsp;&nbsp;InActive&nbsp;&nbsp;
    //       </span>
    //     ),
    // },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={() => navigate("/assets/inventory/add")}
        >
          + Add Inventory
        </button>
        &nbsp;
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <h2>Asset List</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Asset Detail</h2>
            <AssetInwardView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Asset List"
          checkboxSelection
          style={{ height: "40vh" }}
        />
        {/* {JSON.stringify(data)} */}
      </div>
    </div>
  );
};

export default AssetInwardHistoryChild;
