import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, fMember } from "../../../components/shared/GlobalState";
import FamilyMembersAlbum from "./FamilyMemberAlbum";

const FamilyMemberAddChild = ({ familyDetails }) => {
  let navigate = useNavigate();

  const inputWidth = "230px";

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput, bloodgroupOptions }] = useAtom(gbState);
  const [enableAddButton, setEnableAddButton] = useState(true);
  const [data, setData] = useState([...familyDetails.family_member]);
  const [familyMember, setFamilyMember] = useAtom(fMember);
  const [showForm, setShowForm] = useState(true);

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "nonbinary", label: "Non-binary" },
    { value: "other", label: "Other" },
  ];

  const relationOptions = [
    { value: 1, label: "Spouse" },
    { value: 2, label: "Son" },
    { value: 3, label: "Daughter" },
    { value: 4, label: "Daughter in law" },
    { value: 5, label: "Son in law" },
    { value: 6, label: "Grandson" },
    { value: 7, label: "Granddaughter" },
  ];

  useEffect(() => {
    setFamilyMember([...familyDetails.family_member]);
  }, []);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/family/fm/${familyDetails._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.family;

        setData(oldData.family_member);
        setFamilyMember(oldData.family_member);
        setEnableAddButton(true);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        {/* {JSON.stringify(formData)} */}
        <FamilyMembersAlbum
          familyMembers={data}
          headOfFamily={familyDetails}
          familyId={familyDetails._id}
        />
      </div>
    </div>
  );
};

export default FamilyMemberAddChild;
