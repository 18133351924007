import React from "react";
import { useLocation } from "react-router-dom";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import UpdateMarriageChild from "./UpdateMarriageChild";

const UpdateMarriage = () => {
  const { state } = useLocation();
  const marriageRecord = state;
  return (
    <MainTemplate>
      <ErrorBoundaryRoutes route="/marriage/update" page="UpdateMarriage">
        <div className="relative flex flex-col p-10 m-3 space-y-10 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-3">
          <UpdateMarriageChild marriageRecord={marriageRecord} />
        </div>
      </ErrorBoundaryRoutes>
    </MainTemplate>
  );
};

export default UpdateMarriage;
