import React from "react";
import { useLocation } from "react-router-dom";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import UpdateBalanceChild from "./UpdateBalanceChild";

const UpdateBalance = () => {
  const { state } = useLocation();
  const selectedRecord = state;

  return (
    <MainTemplate>
      <CardBackground>
        <UpdateBalanceChild selectedRecord={selectedRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default UpdateBalance;
