import React from "react";
import placeholderImage from "../../../images/placeholder.png";

function EmployeeView({ currentRecord }) {
  //const recordKeys = Object.keys(currentRecord);
  currentRecord = {
    ...currentRecord,
    church_name: currentRecord?.church_id?.church_name,
  };
  const recordKeys = [
    "name",
    "email",
    "role",
    "status",
    "mobile",
    "designation",
    "mobile",
    "gender",
    "date_of_birth",
    "address",
    "bank_name",
    "branch_name",
    "account_number",
    "ifsc_code",
  ];
  return (
    <div className="w-full overflow-y-auto" style={{ wordBreak: "break-word" }}>
      {/* {JSON.stringify(currentRecord)} */}

      <div className="flex flex-col w-full">
        <div className="flex items-center justify-center w-full">
          {currentRecord?.image ? (
            <img
              crossOrigin="anonymous"
              src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/employee/${currentRecord?.image}`}
              alt="Image"
              className="rounded-full w-[30%] p-1 mr-1"
            />
          ) : (
            <img
              src={placeholderImage}
              alt="Image"
              className="rounded-full w-[30%] p-1 mr-1"
            />
          )}
        </div>
        <div className="w-full mt-3">
          <table className="w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {recordKeys.map((rec, index) => {
                return (
                  <tr key={index}>
                    <td
                      className="px-6 py-1 text-sm font-medium text-gray-900 whitespace-nowrap"
                      style={{ textTransform: "capitalize" }}
                    >
                      {rec.replace("_", " ")}
                    </td>
                    <td className="px-6 py-1 text-sm text-gray-500 whitespace-nowrap">
                      {currentRecord[rec]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default EmployeeView;
