export const menuItems = [
  // { name: "Home", link: "/dashboard-ca" },
  { name: "Family", link: "/families" },
  { name: "Groups", link: "#" },
  // { name: "Notice", link: "/notices" },
  // { name: "Event", link: "/events" },
  // { name: "Newsletter", link: "/newsletters" },
];

export const menuItemsMember = [
  { name: "Dashboard", link: "/member/dashboard" },
  { name: "Church Family", link: "/member/families" },
  { name: "Family Members", link: "/family/members" },
  // { name: "Payments & Dues", link: "/member/payments" },
  // { name: "Outstanding Payments", link: "/member/outstanding" },
];

export const monthOptions = [
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "Jul" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
];
