import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import FamilyMemberAddChild from "./FamilyMemberAddChild";
import { useLocation } from "react-router-dom";

const FamilyMemberAdd = () => {
  const { state } = useLocation();
  const familyDetails = state;
  return (
    <MainTemplate>
      <CardBackground>
        <FamilyMemberAddChild familyDetails={familyDetails} />
      </CardBackground>
    </MainTemplate>
  );
};

export default FamilyMemberAdd;
