import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ChurchAddChild from "./ChurchAddChild";

const ChurchAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <ChurchAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default ChurchAdd;
