import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import DioceseSegmentList from "../List/DioceseSegmentList";

const DioceseSegmentAddChild = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    segment_name: null,
  });

  const deleteRecord = (row) => {
    console.log(row.id);
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/dsegment/delete/${row._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => console.log(err));
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/dsegment/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response.data.dsegments;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            segment_name: data.segment_name,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/dsegment/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.dsegments;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              segment_name: data.segment_name,
            };
          });

          setData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const handleDSRegistration = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/dsegment/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Diocese Segment Created Successfully.");
        getDataFromServer();
        // navigate("/dslist");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "DioceseSegmentAddChild", "/dsadd");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        <h2 className="mb-3 text-bold">Add Diocese Segment</h2>
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleDSRegistration(e)} autoComplete="off">
          <div className="mt-10 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="segment_name"
              type="text"
              required
              placeholder="Enter Diocese Segment"
              autoComplete="OFF"
              onChange={handleChange}
              value={formData.segment_name}
            />
          </div>
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              type="submit"
            >
              Add Diocese Segment
            </button>
          </div>
        </form>
        <div className="mt-4">
          <DioceseSegmentList data={data} handleDelete={deleteRecord} />
        </div>
      </div>
    </div>
  );
};

export default DioceseSegmentAddChild;
