import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { DatePicker } from "antd";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AssetInwardUpdateChild = ({ currentRecord }) => {
  let navigate = useNavigate();
  const [assetData, setAssetData] = useState();

  const inputWidth = "230px";
  //Error Logging Service & Santize Input
  const [{ errorLogApi, user }] = useAtom(gbState);
  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [formData, setFormData] = useState({
    asset_id: null,
    item_code: null,
    asset_tag: null,
    quantity: null,
    inward_type: null,
    inward_rate: null,
    inward_description: null,
    comments: null,
  });

  useEffect(() => {
    if (currentRecord) {
      setFormData({ ...currentRecord, old_quantity: currentRecord?.quantity });
    }
  }, [currentRecord]);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/asset/${user.church_id}`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.assets;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              value: data?._id,
              label: data?.asset_name + " (" + data?.item_code + ")",
              item_code: data?.item_code,
            };
          });
          setAssetData(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const handleAssetUpdate = () => {
    if (!formData.asset_id) return toast.error("Cannot add blank item");
    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/church/inward/update/${currentRecord?.id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Asset Update Successfully.");
        navigate("/assets/inventory/inward/history");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AssetInwardChild", "/asset/add");
        // console.log(error);
      });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const inwardOptions = [
    { value: 1, label: "Purchase" },
    { value: 2, label: "Donation" },
    { value: 3, label: "Transferred" },
  ];

  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>{" "}
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
            onClick={handleAssetUpdate}
          >
            Save
          </button>
        </div>
        <fieldset className="p-2 whitespace-normal border w-100">
          <legend className="float-none w-auto p-2 fs-5">Update Asset</legend>
          <div className="max-w-md mx-auto">
            <form>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Item/Asset Name
                </label>
                <Select
                  name="asset_id"
                  onChange={(selected, dropdown) => {
                    setFormData({
                      ...formData,
                      [dropdown.name]: selected.value,
                      item_code: selected.item_code,
                    });
                  }}
                  options={assetData}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Asset from List")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                  value={
                    formData?.asset_name &&
                    assetData?.find((d) => {
                      return d.label.includes(formData?.item_code);
                    })
                  }
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Item Code
                </label>
                <input
                  type="text"
                  name="item_code"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.item_code && formData?.item_code}
                  disabled
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Asset Tag
                </label>
                <input
                  type="text"
                  name="asset_tag"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.asset_tag && formData?.asset_tag}
                />
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Inward Type
                </label>
                <Select
                  name="inward_type"
                  onChange={(selected, dropdown) => {
                    setFormData({
                      ...formData,
                      [dropdown.name]: JSON.stringify(selected),
                    });
                  }}
                  options={inwardOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Inward type List")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                  value={
                    formData?.inward_type && JSON.parse(formData?.inward_type)
                  }
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Inward Quantity
                </label>
                <input
                  type="text"
                  name="quantity"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.quantity && formData?.quantity}
                />
              </div>
              <div className="w-full mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Inward Rate
                </label>
                <input
                  type="text"
                  name="inward_rate"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.inward_rate && formData?.inward_rate}
                />
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Description
                </label>
                <textarea
                  type="text"
                  style={{ height: "150px" }}
                  rows="5" // Update the attribute to "rows" instead of "row"
                  name="inward_description"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={
                    formData?.inward_description && formData?.inward_description
                  }
                />
              </div>
              {/* {JSON.stringify(formData)} */}
            </form>
            {/* {JSON.stringify(assetData)} */}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default AssetInwardUpdateChild;
