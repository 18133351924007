import React from "react";
import { gbState, fYear } from "../../../components/shared/GlobalState";
import { useAtom } from "jotai";

const ReceiptsTable = ({ data }) => {
  // Get total amounts for each status
  const [financial_year] = useAtom(fYear);
  data = data.filter(
    (finFilter) => finFilter?.financial_year === financial_year?.label
  );
  const pendingAmount = data
    .filter((receipt) => receipt.status === 0)
    .reduce((acc, receipt) => acc + parseInt(receipt.amount), 0);
  const settledAmount = data
    .filter((receipt) => receipt.status === 1)
    .reduce((acc, receipt) => acc + parseInt(receipt.amount), 0);
  const declinedAmount = data
    .filter((receipt) => receipt.status === 2)
    .reduce((acc, receipt) => acc + parseInt(receipt.amount), 0);

  const paymentStatusOption = ["Pending", "Settled", "Declined"];
  const statusTxtColor = ["text-white", "text-gray-800", "text-white"];
  const statusBgColor = ["bg-orange-400", "bg-green-400", "bg-red-400"];
  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th className="px-4 py-2">Status</th>
          <th className="px-4 py-2">Total Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-4 py-2 border">
            <span
              className={`inline-block mr-1 px-2 py-1 text-xs font-semibold ${statusBgColor[1]} ${statusTxtColor[1]} rounded-full`}
            >
              &nbsp;&nbsp;
              {paymentStatusOption[1]}
              &nbsp;&nbsp;
            </span>
          </td>
          <td className="px-4 py-2 text-sm border">{settledAmount}</td>
        </tr>
        <tr>
          <td className="px-4 py-2 border">
            <span
              className={`inline-block mr-1 px-2 py-1 text-xs font-semibold ${statusBgColor[0]} ${statusTxtColor[0]} rounded-full`}
            >
              &nbsp;&nbsp;
              {paymentStatusOption[0]}
              &nbsp;&nbsp;
            </span>
          </td>
          <td className="px-4 py-2 text-sm border">{pendingAmount}</td>
        </tr>
        <tr>
          <td className="px-4 py-2 border">
            <span
              className={`inline-block mr-1 px-2 py-1 text-xs font-semibold ${statusBgColor[2]} ${statusTxtColor[2]} rounded-full`}
            >
              &nbsp;&nbsp;
              {paymentStatusOption[2]}
              &nbsp;&nbsp;
            </span>
          </td>
          <td className="px-4 py-2 text-sm border">{declinedAmount}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReceiptsTable;
