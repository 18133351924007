import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const UserAdd = ({ churchId, setSavedRecordId, setOnBoarding }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [rolesOption, setRolesOption] = useState();
  const [churchList, setChurchList] = useState();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    password: null,
    confirm_password: null,
    role: "CHURCH_ADMIN",
    user_type: "Church Admin",
    church_id: churchId,
    account_status: true,
    createdBy: user?._id,
  });

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/roles/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const roles = response.data.roles;
        setRolesOption(
          roles.map((role) => ({
            value: role.access_level,
            label: role.role_name,
          }))
        );
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "RolesList", "/roles");
      });
  }, []);

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/churches/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const churches = response.data.churches;
        setChurchList(
          churches.map((church) => ({
            value: church._id,
            label: church.church_name,
          }))
        );
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "ChurchList", "/churches");
      });
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUserRegistration = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/register`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        toast.success("User Created Successfully.");
        setOnBoarding(true);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateUserChild", "/user/register");
        // console.log(error);
      });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {/* {JSON.stringify(formData)} */}
        <form onSubmit={(e) => handleUserRegistration(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add User</h2>

          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="first_name"
              type="text"
              required
              placeholder="Enter First Name"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.first_name}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="last_name"
              type="text"
              placeholder="Enter Last Name"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.last_name}
            />
          </div>
          <div className="m-1 overflow-x-auto outline-none">
            <Select
              name="role"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  role: selected.value,
                  user_type: selected.label,
                });
              }}
              options={rolesOption}
              value={
                formData?.role &&
                rolesOption?.find((r) => r.value === formData?.role)
              }
              placeholder={placeHolderText("Select Role")}
              className={`w-[${inputWidth}] outline-none h-11 mt-3`}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              isDisabled={formData?.role}
            />
          </div>
          {formData.role !== "FULL_ACCESS" && (
            <div className="m-1 overflow-x-auto outline-none">
              <Select
                name="church_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    church_id: selected.value,
                  });
                }}
                options={churchList}
                placeholder={placeHolderText("Select Church")}
                className={`w-[${inputWidth}] outline-none h-11 mt-3`}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  churchId && churchList?.find((c) => c?.value === churchId)
                }
                isDisabled={churchId}
              />
            </div>
          )}
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="mobile"
              type="text"
              required
              placeholder="Enter Mobile"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.mobile}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="email"
              type="email"
              required
              placeholder="Enter Email"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.email}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="password"
              type="password"
              required
              placeholder="Enter Password"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.password}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="confirm_password"
              type="password"
              required
              placeholder="Confirm Password"
              autoComplete="new-password"
              onChange={handleChange}
              value={formData.confirm_password}
            />
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default UserAdd;
