import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import OtherPaymentsChild from "./OtherPaymentsChild";

const OtherPayments = () => {
  return (
    <MainTemplate>
      <OtherPaymentsChild />
    </MainTemplate>
  );
};

export default OtherPayments;
