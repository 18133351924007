import React, { useEffect, useState } from "react";
import Select from "react-select";

import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { indian_states } from "../../components/Data/IndianStates";
import { countries } from "../../components/Data/Countries";
import { gbState } from "../../components/shared/GlobalState";

const ProfileUpdate = () => {
    const [{ errorLogApi, user ,bloodgroupOptions }] = useAtom(gbState);
    let navigate = useNavigate();
    const familyDetails=[];
    const [formData, setFormData] = useState({});
    const [userData, setUserData] = useState({});
    const [fmData, setFmData] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [cityOptions, setCityOptions] = useState();
    const [cityStateInput, setCityStateInput] = useState(false);
    const [churchList, setChurchList] = useState();
    const inputWidth = "230px";
    //Error Logging Service & Santize Input
    const placeHolderText = (text) => (
      <span className="text-sm text-light">{text}</span>
    );
    const style = {
      control: (base) => ({
        ...base,
        border: "1px solid lightgray",
        // This line disable the blue border
        boxShadow: "none",
      }),
    };
    useEffect(()=>{
        let userToken = localStorage.getItem("token");
        const urlLink = `${process.env.REACT_APP_API_URL}/user/${user._id}`;    
        const config = {
          method: "GET",
          url: urlLink,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
        axios(config)
          .then(function (response) {
                const dataRecieved=response?.data?.users;
                setUserData(dataRecieved);
          })
          .catch(function (err) {
            console.log("error api call ", err);
          });  
    },[]);
    useEffect(() => {
        setFormData({
            ...formData,
            ...userData,
        });
    },[userData]);

    const handleChange = ({ currentTarget: input }) => {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    };
    const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

    const handleFamilyUpdate = () => {
    let userToken = localStorage.getItem("token");

    if (!formData.email) {
            toast.error("Please enter email ID");
            return;
        }
    if (!formData.password) {
            toast.error("Please enter password");
            return;
        }
        if (!formData.confirm_password) {
            toast.error("Please enter confirm password");
            return;
        }
        if (formData.password !== formData.confirm_password && formData.password) {
            toast.error("Passwords do not match");
            return;
          }
        let config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/employee/update/${formData._id}`,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedImage },
        };
        axios(config)
        .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Record Updated Successfully.");
        navigate("/dashboard-ca");
        })
        .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error?.response?.data));
        errorLogApi(error, "FamilyUpdateChild", "/updateProfile");
        // console.log(error);
        });
    };

    return (
      <div className="flex w-full">
        <div class="p-4 w-full">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>{" "}
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
              onClick={handleFamilyUpdate}
            >
              Save
            </button>
          </div>
          <fieldset className="p-2 whitespace-normal border w-100">
            <legend className="float-none w-auto p-2 fs-5">
              Update Password
            </legend>
            {/* <div className="flex flex-col"> */}
            <div className="max-w-md mx-auto">
            <form>
                <div className="mb-2">
                <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500" style={{ fontWeight: "bold" }}>
                    Email
                    </label>
                    <input
                    type="text"
                    name="email"
                    autoComplete="new-password"
                    className={inputElementWidthStyle}
                    value={formData?.email}
                    disabled
                    />
                </div>
                <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500" style={{ fontWeight: "bold" }}>
                    Password
                    </label>
                    <input
                        type="password"
                        name="password"
                        autoComplete="new-password"
                        onChange={handleChange}
                        className={inputElementWidthStyle}
                    />
                </div>
                <div className="mb-2">
                    <label className="block mb-2 text-sm text-gray-500" style={{ fontWeight: "bold" }}>
                    Confirm Password
                    </label>
                    <input
                        type="password"
                        name="confirm_password"
                        autoComplete="off"
                        onChange={handleChange}
                        className={inputElementWidthStyle}
                    />
                </div>
                </div>
                {/* Render the remaining fields in a similar manner */}
            </form>
            </div>
            <br />
          </fieldset>
        </div>
      </div>
    );
};
export default ProfileUpdate;
