import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import "react-confirm-alert/src/react-confirm-alert.css";
import { DatePicker, Space } from "antd";
import Tooltip from "@mui/material/Tooltip";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import useFetch from "../../../components/shared/useFetch";
import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

const { RangePicker } = DatePicker;

const MonthlyStatementChild = () => {
  let navigate = useNavigate();
  const [financial_year] = useAtom(fYear);
  const [{ errorLogApi, user }] = useAtom(gbState);
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [incomeData, setIncomeData] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [salaryData, setSalaryData] = useState(null);
  const [incomeTypeOthers, setIncomeTypesOther] = useState(null);
  const [incomeTypeCash, setIncomeTypeCash] = useState(null);
  const [groupIncomeTypeCash, setGroupIncomeTypeCash] = useState(null);
  const [incomeTotalCash, setIncomeTotalCash] = useState(null);
  const [incomeTotalOther, setIncomeTotalOther] = useState(null);
  const [incomeTotalCashSubs, setIncomeTotalCashSubs] = useState(null);
  const [incomeTotalCashSalary, setIncomeTotalCashSalary] = useState(null);
  const [incomeTotalOtherSubs, setIncomeTotalOtherSubs] = useState(null);
  const [incomeTotalOtherSalary, setIncomeTotalOtherSalary] = useState(null);
  const [expenseTypeCash, setExpenseTypeCash] = useState(null);
  const [subscriptionTypeCash, setSubscriptionTypeCash] = useState(null);
  const [salaryTypeCash, setSalaryTypeCash] = useState(null);
  const [groupExpenseTypeCash, setGroupExpenseTypeCash] = useState(null);
  const [expenseTypeOthers, setExpenseTypesOther] = useState(null);
  const [subscriptionTypeOthers, setSubscriptionTypesOther] = useState(null);
  const [salaryTypesOther, setSalaryTypesOther] = useState(null);
  const [subscriptionTypeOthersData, setSubscriptionTypesOtherData] = useState(null);
  const [salaryTypeOthersData, setSalaryTypesOtherData] = useState(null);
  const [expenseTotalCash, setExpenseTotalCash] = useState(null);
  const [expenseTotalOther, setExpenseTotalOther] = useState(null);
  const [receiptTotalCash, setReceiptTotalCash] = useState(null);
  const [salaryTotalCash, setSalaryTotalCash] = useState(null);
  const [incomeDate, setIncomeDate] = useState(null);
  const [unfilteredData, setUnfilteredData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [searchInput, setSearchInput] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  let urlLink = `${process.env.REACT_APP_API_URL}/income/${user.church_id}`;

  //Fetching income data for the page
  const { data, loading, error, refetch } = useFetch(urlLink);

  useEffect(() => {
    setIncomeData(
      data?.incomes?.filter((d) => d?.financial_year === financial_year?.value)
    );
  }, [data, financial_year]);

  //Remove and set cash Records
  useEffect(() => {
    setIncomeTypeCash(incomeData?.filter((d) => d?.income_source === "Cash"));

    let tempIncomeTypeOther = incomeData?.filter(
      (d) => d?.income_source !== "Cash"
    );
    setIncomeTypesOther(tempIncomeTypeOther);
  }, [incomeData]);

  //Group Income Type Cash
  useEffect(() => {
    function summarizeByCategory(data) {
      const summary = {};

      data?.forEach((item) => {
        const category_name = item?.category_id?.category_name;
        const amount = item?.amount;

        if (summary[category_name]) {
          summary[category_name] += amount;
        } else {
          summary[category_name] = amount;
        }
      });

      return Object.entries(summary).map(([category, total_amount]) => ({
        category,
        total_amount,
      }));
    }
   // if (incomeData?.length) {
      setGroupIncomeTypeCash(summarizeByCategory(incomeTypeCash));
    //}
  }, [incomeTypeCash]);

  //Add total of groupedcash
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.total_amount, 0);
    }
    setIncomeTotalCash(calculateTotalAmount(groupIncomeTypeCash));
  }, [groupIncomeTypeCash]);

  //Add Total Income Others
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.amount, 0);
    }
    setIncomeTotalOther(calculateTotalAmount(incomeTypeOthers));
  }, [incomeTypeOthers]);


  // Fecthing member subscribtion collection list--
  urlLink = `${process.env.REACT_APP_API_URL}/receipt/church/${user?.church_id}`;
  const {
     data: subData,
     loading: subLoading,
     error: subError,
     refetch: subRefetch,
   } = useFetch(urlLink);
   useEffect(() => {
     setSubscriptionData(
       subData?.filter(
         (d) => d?.financial_year === financial_year?.value
       )
     );
   }, [subData, financial_year]);

  //Remove and set Subscribe cash Records subscriptionTypeCash,subscriptionTypesOther
  useEffect(() => {
    setSubscriptionTypeCash(
      subscriptionData?.filter((d) => d?.payment_mode == "1")
    );
    let tempExpenseTypeOther = subscriptionData?.filter(
      (d) => d?.payment_mode != "1"
    );
    setSubscriptionTypesOther(tempExpenseTypeOther);
  }, [subscriptionData,financial_year]);
  useEffect(() => {
   const sum = subscriptionTypeCash?.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
    setReceiptTotalCash([{
      category: 'Subscription',
      total_amount: parseInt(sum),
    }]);
    setIncomeTotalCashSubs(parseInt(sum));
  }, [subscriptionTypeCash]);

  useEffect(() => {
    const data={};
    const dataArray = subscriptionTypeOthers?.map((subs)=>{
      return {
        ...data,
        description:subs?.subscriber_id?.first_name+' '+subs?.subscriber_id?.last_name,
        type:subs?.month,
        head:'Subscription',
        amount:subs?.amount,
      }
    });
    setSubscriptionTypesOtherData(dataArray);
    const sum = subscriptionTypeOthers?.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
    setIncomeTotalOtherSubs(parseInt(sum));
   }, [subscriptionTypeOthers]);

  // Fecthing member salary collection list--
  urlLink = `${process.env.REACT_APP_API_URL}/salary/${user?.church_id}`;
  const {
    data: salariesData,
    loading: salaryLoading,
    error: salaryError,
    refetch: salaryRefetch,
  } = useFetch(urlLink);
  useEffect(() => {
    setSalaryData(
      salariesData?.salaries?.filter(
        (d) => d?.financial_year === financial_year?.value
      )
    );
  }, [salariesData,financial_year]);

  useEffect(() => {
    setSalaryTypeCash(
      salaryData?.filter((d) => d?.status == "1")
    );
    let tempExpenseTypeOther = salaryData?.filter(
      (d) => d?.status != "1"
    );
    setSalaryTypesOther(tempExpenseTypeOther);
  }, [salaryData,financial_year]);

  useEffect(() => {
    const sum = salaryTypeCash?.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
     setSalaryTotalCash([{
       category: 'Salary',
       total_amount: parseInt(sum),
     }]);
     setIncomeTotalCashSalary(parseInt(sum));
   }, [salaryTypeCash]);
   useEffect(() => {
    const data={};
    const dataArray = salaryTypesOther?.map((sals)=>{
      return {
        ...data,
        description:sals?.employee_id?.first_name+' '+sals?.employee_id?.last_name,
        type:sals?.salary_month,
        head:'Salary',
        amount:sals?.amount,
      }
    });
    setSalaryTypesOtherData(dataArray);
    const sum = salaryTypesOther?.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
    setIncomeTotalOtherSalary(parseInt(sum));
   }, [salaryTypesOther]);
   
  //Fecthing Expense data for the page
  urlLink = `${process.env.REACT_APP_API_URL}/expense/${user?.church_id}`;

  const {
    data: expData,
    loading: expLoading,
    error: expenseError,
    refetch: expenseRefetch,
  } = useFetch(urlLink);

  //set setExpenseData, expenseData
  useEffect(() => {
    setExpenseData(
      expData?.expenses?.filter(
        (d) => d?.financial_year === financial_year?.value
      )
    );
  }, [expData, financial_year]);

  //Remove and set Expense cash Records expenseTypeCash, expenseTypesOther
  useEffect(() => {
    setExpenseTypeCash(
      expenseData?.filter((d) => d?.transfer_source === "Cash")
    );

    let tempExpenseTypeOther = expenseData?.filter(
      (d) => d?.transfer_source !== "Cash"
    );
    setExpenseTypesOther(tempExpenseTypeOther);
  }, [expenseData]);

  //Group Expense Type Cash
  useEffect(() => {
    function summarizeByCategory(data) {
      const summary = {};
      data?.forEach((item) => {
        const category_name = item?.category_id?.category_name;
        const amount = item?.amount;

        if (summary[category_name]) {
          summary[category_name] += amount;
        } else {
          summary[category_name] = amount;
        }
      });

      return Object.entries(summary).map(([category, total_amount]) => ({
        category,
        total_amount,
      }));
    }
    setGroupExpenseTypeCash(summarizeByCategory(expenseTypeCash));
  }, [expenseTypeCash]);

  //Add Total Grouped Expense Cash
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.total_amount, 0);
    }
    setExpenseTotalCash(calculateTotalAmount(groupExpenseTypeCash));
  }, [groupExpenseTypeCash]);

  //Add Total Expense Others
  useEffect(() => {
    function calculateTotalAmount(data) {
      return data?.reduce((acc, item) => acc + item?.amount, 0);
    }
    setExpenseTotalOther(calculateTotalAmount(expenseTypeOthers));
  }, [expenseTypeOthers]);
  console.log(expenseTypeOthers);
  return (
    <div className="w-full">
      {/* {JSON.stringify(expenseData)} */}
      <table className="min-w-full border border-collapse border-gray-800 table-auto">
        <thead>
          <tr>
            <th className="border border-gray-600">Receipts (Cash)</th>
            <th className="border border-gray-600">Payments (Cash)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top ">
              <MonthlyStatementCashTables
                data={groupIncomeTypeCash}
                data1={receiptTotalCash}
                total={incomeTotalCash}
              />
            </td>
            <td className="align-top ">
              <MonthlyStatementCashTables
                data={groupExpenseTypeCash}
                data1={salaryTotalCash}
                total={expenseTotalCash}
              />
            </td>
          </tr>
          <tr>
            <th className="border border-gray-600">Receipts (Others)</th>
            <th className="border border-gray-600">Payments (Others)</th>
          </tr>
          <tr>
            <td className="align-top ">
              <MonthlyStatementOtherTables
                data={incomeTypeOthers}
                dataSubs={subscriptionTypeOthersData}
                total={[incomeTotalOther+incomeTotalOtherSubs]}
              />
            </td>
            <td className="align-top ">
              <MonthlyStatementOtherTables
                data={expenseTypeOthers}
                dataSubs={salaryTypeOthersData}
                total={expenseTotalOther+incomeTotalOtherSalary}
              />
            </td>
          </tr>
          <tr>
            <td className="text-right align-top border-t border-r border-gray-600">
              <NetTotalTable total={incomeTotalCash + incomeTotalOther + incomeTotalCashSubs + incomeTotalOtherSubs} />
            </td>
            <td className="text-right align-top border-t border-r border-gray-600">
              <NetTotalTable total={expenseTotalCash + expenseTotalOther + incomeTotalCashSalary + incomeTotalOtherSalary} />
            </td>
          </tr>
        </tbody>
      </table>
      {/* {JSON.stringify(incomeTotalOther)}
      {JSON.stringify(expenseTotalOther)} */}
    </div>
  );
};

const NetTotalTable = ({ total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <tbody>
        <tr>
          <td className="border border-gray-600 text-bold">Total</td>
          <td className="border border-gray-600">{total}</td>
        </tr>
      </tbody>
    </table>
  );
};

const MonthlyStatementCashTables = ({ data,data1, total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Description
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Amount
          </th>
        </tr>
      </thead>
      {/* {JSON.stringify(data)} */}
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.category}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.total_amount}
              </td>
            </tr>
          ))}
          {data1 &&
          data1.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.category}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.total_amount}
              </td>
            </tr>
          ))}
        <tr className="hover:bg-gray-300">
          <td className="px-2 py-1 font-bold text-right border-l border-r border-gray-600">
            Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">{total+data1?.[0]?.total_amount?? 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

const MonthlyStatementOtherTables = ({ data, dataSubs, total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Description
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">Type</th>
          <th className="px-2 py-1 border-b border-r border-gray-600">Head</th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.member_id?.first_name} {item?.member_id?.last_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.income_title ? item?.income_title : item?.expense_title}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.category_id?.category_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.amount}
              </td>
            </tr>
          ))}
        {dataSubs &&
          dataSubs.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.description}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.type}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.head}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.amount}
              </td>
            </tr>
          ))}
        <tr className="hover:bg-gray-300">
          <td
            colSpan={3}
            className="px-2 py-1 font-bold text-right border-l border-r border-gray-600"
          >
            Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">{total}</td>
        </tr>
      </tbody>
    </table>
  );
};
const OpeningClosingBalance = ({ data, dataSubs, total }) => {
  return (
    <table className="min-w-full border border-collapse border-gray-800 table-auto">
      <thead>
        <tr>
          <th className="px-2 py-1 border-b border-l border-r border-gray-600">
            Description
          </th>
          <th className="px-2 py-1 border-b border-r border-gray-600">Type</th>
          <th className="px-2 py-1 border-b border-r border-gray-600">Head</th>
          <th className="px-2 py-1 border-b border-r border-gray-600">
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.member_id?.first_name} {item?.member_id?.last_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.income_title ? item?.income_title : item?.expense_title}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.category_id?.category_name}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.amount}
              </td>
            </tr>
          ))}
        {dataSubs &&
          dataSubs.map((item, index) => (
            <tr
              key={index}
              className={`border-b border-gray-600 ${
                index % 2 === 0 ? "bg-white" : "bg-gray-200"
              } hover:bg-gray-300`}
            >
              <td className="px-2 py-1 border-l border-r border-gray-600">
                {item?.description}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.type}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.head}
              </td>
              <td className="px-2 py-1 border-r border-gray-600">
                {item?.amount}
              </td>
            </tr>
          ))}
        <tr className="hover:bg-gray-300">
          <td
            colSpan={3}
            className="px-2 py-1 font-bold text-right border-l border-r border-gray-600"
          >
            Total
          </td>
          <td className="px-2 py-1 border-r border-gray-600">{total}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default MonthlyStatementChild;
