import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast from "react-hot-toast";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import IncomeView from "./IncomeView";
import TableComponent from "../../../components/MainTemplate/TableComponent";

const IncomeListChild = () => {
  let navigate = useNavigate();
  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Receipt ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/income/delete/${id}`, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "AssetListChild", "/income/delete");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/income/${user?.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response?.data?.incomes?.filter(
          (finFilter) => finFilter?.financial_year === financial_year?.label
        );
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            category: data?.category_id?.category_name,
            description: data?.description,
            receipt_number: data?.receipt_number,
            date: data?.date,
            amount: data?.amount,
            income_title: data?.income_title,
            income_source: data?.income_source,
            monthly_record_close_status: data?.monthly_record_close_status,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, [financial_year]);

  const paymentStatusOption = ["Pending", "Settled", "Declined"];
  const statusTxtColor = ["text-white", "text-gray-800", "text-white"];
  const statusBgColor = ["bg-orange-400", "bg-green-400", "bg-red-400"];
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() => {
                  if (params.row.monthly_record_close_status) {
                    return toast.error(
                      "Monthly record is already closed for this month."
                    );
                  } else {
                    return navigate("/income/update", { state: params.row });
                  }
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  if (params.row.monthly_record_close_status) {
                    return toast.error(
                      "Cannot be deleted! Monthly record is already closed for this month."
                    );
                  } else {
                    return handleDelete(params.row.id);
                  }
                }}
                className="my-overlay"
                style={{
                  position: "relative",
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.row.date?.slice(0, 10),
    },
    {
      field: "category",
      headerName: "Category",
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "income_title",
      headerName: "Income Title",
      width: 200,
    },
    {
      field: "receipt_number",
      headerName: "Receipt Number",
      width: 200,
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "amount",
      headerName: "Amount",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      field: "income_source",
      headerName: "Recieve Type",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <span
          className={`inline-block px-2 py-1 text-xs font-semibold ${
            params.row.status && statusBgColor[parseInt(params.row.status) - 1]
          } ${
            params.row.status && statusTxtColor[parseInt(params.row.status) - 1]
          } rounded-full`}
        >
          &nbsp;&nbsp;
          {params.row.status &&
            paymentStatusOption[parseInt(params.row.status) - 1]}
          &nbsp;&nbsp;
        </span>
      ),
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      field: "monthly_record_close_status",
      headerName: "Record Status",
      renderCell: (params) =>
        params.row.monthly_record_close_status ? (
          <LockIcon style={{ color: "red", fontSize: "18px" }} />
        ) : (
          <LockOpenIcon style={{ color: "green", fontSize: "18px" }} />
        ),
    },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-end w-full">
        {/* <button
          className="px-4 py-2 mr-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={() => navigate("/income/report", { state: data })}
        >
          Generate Report
        </button> */}
        <button
          className="px-4 py-2 mr-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/subscribers")}
        >
          Subscription Management
        </button>
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/income/add")}
        >
          + Add Receipt
        </button>
      </div>
      <h2>Income List</h2>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Income Detail</h2>
            <IncomeView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Income List"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
      <div className="mt-2">
        <TableComponent data={data} />
      </div>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

export default IncomeListChild;
