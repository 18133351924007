import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CategoryUpdateChild from "./CategoryUpdateChild";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import { useLocation } from "react-router-dom";

const CategoryUpdate = () => {
    const { state } = useLocation();
    const categoryDetails = state;
    return (
    <MainTemplate>
      <CardBackground>
        <CategoryUpdateChild categoryDetails={categoryDetails} />
      </CardBackground>
    </MainTemplate>
  );
};  

export default CategoryUpdate;
