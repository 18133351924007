import React from "react";
import { useLocation } from "react-router-dom";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import UserUpdateChild from "./UserUpdateChild";

const UserUpdate = () => {
  const { state } = useLocation();
  const selectedUser = state;
  return (
    <MainTemplate>
      <CardBackground>
        <UserUpdateChild selectedUser={selectedUser} />
      </CardBackground>
    </MainTemplate>
  );
};

export default UserUpdate;
