const ExpenseReportTable = ({ data }) => {
  const aggregateData = (data) => {
    const aggregation = {};

    data.forEach((item) => {
      const categoryName = item.category_id.category_name;
      const incomeSource = item.transfer_source;
      const amount = item.amount;

      if (!aggregation[categoryName]) {
        aggregation[categoryName] = {};
      }

      if (!aggregation[categoryName][incomeSource]) {
        aggregation[categoryName][incomeSource] = 0;
      }

      aggregation[categoryName][incomeSource] += amount;
    });

    return aggregation;
  };
  const aggregatedData = aggregateData(data);

  return (
    <table className="min-w-full table-auto">
      <thead className="bg-gray-200">
        <tr>
          <th className="px-4 py-2 text-left">Category</th>
          <th className="px-4 py-2 text-left">Expense Source</th>
          <th className="px-4 py-2 text-left">Amount</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(aggregatedData).map(([category, sources], catIndex) =>
          Object.entries(sources).map(([source, amount], index) => (
            <tr
              key={`${category}-${source}-${index}`}
              className={`${catIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
            >
              <td className="px-4 py-2">{category}</td>
              <td className="px-4 py-2">{source}</td>
              <td className="px-4 py-2">{amount}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default ExpenseReportTable;
