import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";

import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import SubscriberCard from "./SubscriberCard";
import ReceiptCard from "./ReceiptCard";

const ReceiptUpdateChild = ({ receiptRecord, subRecord }) => {
  const [formData, setFormData] = useState({ ...receiptRecord });
  return (
    <div className="flex flex-col items-center w-full">
      <div className="">
        <div className="mt-2 w-[400px]">
          <SubscriberCard data={subRecord} />
        </div>
        <ReceiptCard
          data={receiptRecord}
          formData={formData}
          setFormData={setFormData}
        />
      </div>
    </div>
  );
};

export default ReceiptUpdateChild;
