import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast from "react-hot-toast";

import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

const ListClosureHistoryChild = () => {
  let navigate = useNavigate();
  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  const [financial_year] = useState({
    value: user?.current_financial_year,
    label: user?.current_financial_year,
  });
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to revoke this closure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/closure/revoke/${id}`,
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios(config)
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "ListClosureHistoryChild", "/closure/revoke");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/closure/church`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users));
        const oldData = response?.data;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            financial_year: data?.financial_year,
            closure_month: JSON.parse(data?.closure_month).label,
            closure_date: data?.closure_date,
            church_name: data?.church_id?.church_name,
            church_code: data?.church_id?.church_code,
            closed_by: `${data?.closed_by?.first_name} ${data?.closed_by?.last_name}`,
            closure_status: data?.closure_status,
          };
        });
        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            {/* <Tooltip title="View">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip> */}
            {/* <Tooltip title="Edit">
              <div
                onClick={() => {
                  if (params.row.monthly_record_close_status) {
                    return toast.error(
                      "Monthly record is already closed for this month."
                    );
                  } else {
                    return navigate("/income/update", { state: params.row });
                  }
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip> */}
            <Tooltip title="Revoke Closure">
              <div
                onClick={() => handleDelete(params.row.id)}
                className="my-overlay"
                style={{
                  position: "relative",
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "financial_year",
      headerName: "Financial Year",
      width: 140,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "closure_month",
      headerName: "Month",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
    },
    {
      field: "church_name",
      headerName: "Church Name",
      width: 200,
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "church_code",
      headerName: "Code",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      field: "closed_by",
      headerName: "Closed By",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      field: "closure_status",
      headerName: "Closure Status",
      renderCell: (params) =>
        params.row.closure_status ? (
          <LockIcon style={{ color: "red", fontSize: "18px" }} />
        ) : (
          <LockOpenIcon style={{ color: "green", fontSize: "18px" }} />
        ),
    },
    {
      field: "closure_date",
      headerName: "Closure Date",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.row.closure_date?.slice(0, 10),
    },
  ];

  return (
    <div className="w-full">
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Closure Detail</h2>
            {/* <IncomeView currentRecord={currentRecord} /> */}
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Income List"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default ListClosureHistoryChild;
