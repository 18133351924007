import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import placeholderImage from "../../../images/placeholder.png";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import useFetch from "../../../components/shared/useFetch";
import BaptismView from "./BaptismView";

const BaptismRegisterChild = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const url = `${process.env.REACT_APP_API_URL}/baptism/`;
  const { data: baptisms, loading, error, refetch } = useFetch(url);

  useEffect(() => {
    // console.log(worships);
    if (baptisms?.length) {
      const updatedData = baptisms?.map((data) => {
        return {
          ...data,
          id: data._id,
          record_no: data?.record_no,
          baptism_date: data?.baptism_date?.slice(0, 10),
          name: data?.name,
          parents_name: data?.parents_name,
          date_of_birth: data.date_of_birth.slice(0, 10),
          god_parents: data.god_parents,
          remarks: data.remarks,
        };
      });
      setData(updatedData);
    }
  }, [baptisms]);

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Register ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/baptism/delete/${row._id}`,
        config
      )
      .then((response) => {
        toast.success("Register Deleted Successfully.");
        refetch();
      })
      .catch((err) => {
        errorLogApi(err, "worshipChild", "/worship");
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() =>
                  navigate("/baptism/update", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "record_no",
      headerName: "Record No.",
      width: 140,
    },
    {
      field: "baptism_date",
      headerName: "Baptism Date",
      width: 180,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "parents_name",
      headerName: "Parents name",
      width: 250,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      width: 250,
    },
  ];

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex justify-end w-full">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate("/baptism/add")}
        >
          Add Baptism
        </button>
      </div>
      <h2>Baptism Register</h2>
      <ErrorBoundaryRoutes route="/baptism/" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Baptism Detail</h2>
            <BaptismView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Employees"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default BaptismRegisterChild;
