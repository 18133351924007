import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, fMember } from "../../../components/shared/GlobalState";
import FamilyMembersList from "./FamilyMembersList";

const FamilyMemberAddChild = ({ familyDetails }) => {
  let navigate = useNavigate();

  const inputWidth = "230px";

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput, bloodgroupOptions }] = useAtom(gbState);
  const [enableAddButton, setEnableAddButton] = useState(true);
  const [data, setData] = useState([...familyDetails.family_member]);
  const [familyMember, setFamilyMember] = useAtom(fMember);
  const [createUser, setCreateUser] = useState(false);
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    gender: null,
    password: null,
    confirm_password: null,
    relation: null,
    birth_day: null,
    birth_month: null,
    birth_year: null,
    blood_group: null,
    qualification: null,
    birth_place: null,
  });
  const [showForm, setShowForm] = useState(true);

  const fnameRef = useRef();
  const lnameRef = useRef();
  const genderRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const relationRef = useRef();
  const qualificationRef = useRef();
  const birthPlaceRef = useRef();
  const bloodgroupRef = useRef();
  const calDayRef = useRef();
  const calMonthRef = useRef();
  const calYearRef = useRef();

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const inputElementWidthStyle = `w-full p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "nonbinary", label: "Non-binary" },
    { value: "other", label: "Other" },
  ];

  const relationOptions = [
    { value: 1, label: "Spouse" },
    { value: 2, label: "Son" },
    { value: 3, label: "Daughter" },
    { value: 4, label: "Daughter in law" },
    { value: 5, label: "Son in law" },
    { value: 6, label: "Grandson" },
    { value: 7, label: "Granddaughter" },
  ];

  const dayOptions = [];
  for(var i=1;i<=31;i++){
    if(i < '10')
      i='0'+i;

    dayOptions.push({ value: i, label: i });
  }
  const monthOptions = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  useEffect(() => {
    setFamilyMember([...familyDetails.family_member]);
  }, []);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/family/fm/${familyDetails._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.family;

        setData(oldData.family_member);
        setFamilyMember(oldData.family_member);
        setFormData({
          first_name: null,
          last_name: null,
          mobile: null,
          email: null,
          gender: null,
          password: null,
          confirm_password: null,
          relation: null,
          birth_day: null,
          birth_month: null,
          birth_year: null,
          blood_group: null,
          qualification: null,
          birth_place: null,
        });
        const refs = [
          fnameRef,
          lnameRef,
          genderRef,
          relationRef,
          calDayRef,
          calMonthRef,
          calYearRef,
          mobileRef,
          emailRef,
          passwordRef,
          confirmPasswordRef,
          qualificationRef,
          birthPlaceRef,
          bloodgroupRef,
        ];

        refs.forEach((ref) => {
          if (ref?.current) {
            ref.current.value = "";
          }
        });
        setEnableAddButton(true);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleAddFamilyMember = () => {
    if (!formData.first_name) {
      return toast.error("First Name required");
    }

    if (!formData.birth_day) {
      toast.error("Please select birth day");
      return;
    }
    
    if (!formData.birth_month) {
      toast.error("Please select birth month");
      return;
    }

    if (createUser) {
      if (
        !formData.password ||
        formData.password !== formData.confirm_password
      ) {
        toast.error("Passwords do not match");
        return;
      }
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/family/member/add/${familyDetails._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, createUser },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Member Added Successfully.");
        getDataFromServer();
        //navigate("/families");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "FamilyMemberAddChild", "/family/member/add");
        // console.log(error);
      });
  };

  const handleUpdateFamilyMember = () => {
    if (createUser) {
      if (formData.password) {
        if (formData.password !== formData.confirm_password) {
          toast.error("Passwords do not match");
          return;
        }
      }
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/family/member/update/${familyDetails._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, update: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Member Updated Successfully.");
        getDataFromServer();
        setCreateUser(false);
        //navigate("/families");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "FamilyMemberUpdateChild", "/family/member/update");
        // console.log(error);
      });
  };

  const cancelUpdate = () => {
    setFormData({
      first_name: null,
      last_name: null,
      mobile: null,
      email: null,
      gender: null,
      password: null,
      confirm_password: null,
      relation: null,
      birth_day: null,
      birth_month: null,
      birth_year: null,
      blood_group: null,
      qualification: null,
      birth_place: null,
    });

    const refs = [
      fnameRef,
      lnameRef,
      genderRef,
      relationRef,
      calDayRef,
      calMonthRef,
      calYearRef,
      mobileRef,
      emailRef,
      passwordRef,
      confirmPasswordRef,
      qualificationRef,
      birthPlaceRef,
      bloodgroupRef,
    ];

    refs.forEach((ref) => {
      if (ref?.current) {
        ref.current.value = "";
      }
    });
    setEnableAddButton(true);
  };

  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>{" "}
          &nbsp;&nbsp;
          {enableAddButton && (
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
              onClick={handleAddFamilyMember}
              onMouseEnter={() => {
                if (!showForm) {
                  setShowForm(true);
                }
              }}
            >
              + Add
            </button>
          )}
          {!enableAddButton && (
            <>
              <button
                className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
                onClick={handleUpdateFamilyMember}
                onMouseEnter={() => {
                  if (!showForm) {
                    setShowForm(true);
                  }
                }}
              >
                Update
              </button>{" "}
              &nbsp;&nbsp;
              <button
                className="px-4 py-2 text-white bg-yellow-600 rounded hover:bg-yellow-900"
                onClick={cancelUpdate}
                onMouseHoer
              >
                Cancel
              </button>
            </>
          )}
        </div>
        <fieldset className="p-2 whitespace-normal border w-100">
          <legend className="float-none w-auto p-2 fs-5">
            Register Family Member{" "}
            {!showForm && (
              <i
                className="mdi mdi-plus-circle"
                onClick={() => setShowForm(true)}
                style={{ cursor: "pointer", color: "green" }}
              ></i>
            )}
            {showForm && (
              <i
                className="mdi mdi-minus-circle"
                onClick={() => setShowForm(false)}
                style={{ cursor: "pointer", color: "red" }}
              ></i>
            )}
          </legend>
          {showForm && (
            <div className="max-w-md mx-auto">
              <div className="mb-2">
                <label className="block mb-2 text-sm text-gray-500">Name</label>
                <div className="flex mb-4">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.first_name}
                    ref={fnameRef}
                  />
                </div>
              </div>
              <div className="flex mb-0">
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.last_name}
                    ref={lnameRef}
                  />
                </div>
                &nbsp;
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Gender
                  </label>
                  <Select
                    name="gender"
                    onChange={dropDownChange}
                    options={genderOptions}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select Gender")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                    value={
                      formData?.gender &&
                      genderOptions.find((g) => g.value === formData?.gender)
                    }
                    ref={genderRef}
                  />
                </div>
              </div>
              <div className="flex mb-0">
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Date of Birth
                    </label>
                    <Select
                      name="birth_day"
                      onChange={dropDownChange}
                      options={dayOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("--Day--")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={ formData?.birth_day && dayOptions?.find(
                        (noOfDays) => noOfDays.value == formData?.birth_day
                      )}
                      ref={calDayRef}
                    />
                  </div>
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                    &nbsp;
                    </label>
                    <Select
                      name="birth_month"
                      onChange={dropDownChange}
                      options={monthOptions}
                      className={`w-[${inputWidth}] outline-none h-10`}
                      placeholder={placeHolderText("--Month--")}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={style}
                      value={ formData?.birth_month && monthOptions?.find(
                        (months) => months.value === formData?.birth_month
                      )}
                      ref={calMonthRef}
                    />
                  </div>
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                    &nbsp;
                    </label>
                    <DatePicker
                      className="w-full mb-2 h-[38px]"
                      name="birth_year"
                      onChange={(date, dateString) => {
                        setFormData({
                          ...formData,
                          birth_year: dateString,
                        });
                      }}
                      disabledDate={(current) => {
                        let customDate = dayjs().format("YYYY");
                        return (
                          current && current > dayjs(customDate, "YYYY")
                        );
                      }}
                      showToday={true}
                      picker="year"
                      placeholder="--Year--"
                      value={
                        formData?.birth_year &&
                        dayjs(formData?.birth_year).isValid()
                          ? dayjs(formData?.birth_year)
                          : null
                      }
                      ref={calYearRef}
                    />
                  </div>
              </div>
              <div className="flex mb-0">
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Blood Group
                  </label>
                  {/* <input
                  type="text"
                  name="blood_group"
                  onChange={handleChange}
                  className={inputElementWidthStyle}
                  value={formData?.blood_group && formData?.blood_group}
                  ref={bloodgroupRef}
                /> */}
                  <Select
                    name="blood_group"
                    onChange={dropDownChange}
                    options={bloodgroupOptions}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select Blood Group")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                    ref={bloodgroupRef}
                    value={
                      formData?.blood_group &&
                      bloodgroupOptions.find(
                        (b) => b.value === formData?.blood_group
                      )
                    }
                  />
                </div>{" "}
                &nbsp;
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Relationship
                  </label>
                  <Select
                    name="relation"
                    onChange={(selected, dropdown) => {
                      setFormData({
                        ...formData,
                        [dropdown.name]: JSON.stringify(selected),
                      });
                    }}
                    options={relationOptions}
                    className={`w-[${inputWidth}] outline-none h-10`}
                    placeholder={placeHolderText("Select Relation")}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={style}
                    value={formData?.relation && JSON.parse(formData?.relation)}
                    ref={relationRef}
                  />
                </div>
              </div>
              <div className="flex mb-0">
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Qualification
                  </label>
                  <input
                    type="text"
                    name="qualification"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.qualification && formData?.qualification}
                    ref={qualificationRef}
                  />
                </div>{" "}
                &nbsp;
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Birthplace
                  </label>
                  <input
                    type="text"
                    name="birth_place"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.birth_place && formData?.birth_place}
                    ref={birthPlaceRef}
                  />
                </div>
              </div>
              <div className="flex mb-0">
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Mobile
                  </label>
                  <input
                    type="text"
                    name="mobile"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.mobile && formData?.mobile}
                    ref={mobileRef}
                  />
                </div>
                &nbsp;
                <div className="w-full mb-2">
                  <label className="block mb-2 text-sm text-gray-500">
                    Email &nbsp;{" "}
                    <input
                      type="checkbox"
                      className="w-3 h-3 text-blue-600 form-checkbox"
                      checked={createUser}
                      onChange={() => setCreateUser(!createUser)}
                    />
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    className={inputElementWidthStyle}
                    value={formData?.email && formData?.email}
                    ref={emailRef}
                    disabled={!createUser}
                  />
                </div>
              </div>
              {createUser && (
                <div className="flex mb-0">
                  <div className="w-full mb-4">
                    <label className="block mb-2 text-sm text-gray-500">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                      ref={passwordRef}
                    />
                  </div>{" "}
                  &nbsp;
                  <div className="w-full mb-2">
                    <label className="block mb-2 text-sm text-gray-500">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirm_password"
                      autoComplete="off"
                      onChange={handleChange}
                      className={inputElementWidthStyle}
                      ref={confirmPasswordRef}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </fieldset>
        {/* {JSON.stringify(data)} */}
        <FamilyMembersList
          familyMembers={data}
          headOfFamily={familyDetails}
          setFormData={setFormData}
          setEnableAddButton={setEnableAddButton}
          familyId={familyDetails._id}
          getDataFromServer={getDataFromServer}
        />
      </div>
    </div>
  );
};

export default FamilyMemberAddChild;
