import React from "react";
import { useLocation } from "react-router-dom";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import EmployeeUpdateChild from "./EmployeeUpdateChild";


const UserUpdate = () => {
  const { state } = useLocation();
  const selectedEmployee = state;
  console.log(selectedEmployee);
  return (
    <MainTemplate>
      <CardBackground>
        <EmployeeUpdateChild emp_details={selectedEmployee} />
      </CardBackground>
    </MainTemplate>
  );
};

export default UserUpdate;
