import React from "react";

const AssetOutwardView = ({ currentRecord }) => {
  return (
    <div class="container mx-auto">
      <table class="min-w-full bg-white border border-gray-300">
        <tbody>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">ID</span>
            </td>
            <td class="px-4 py-2 border-b text-right">{currentRecord?.id}</td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Issued ID</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.issued_id}
            </td>
          </tr>

          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Asset Name</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.asset_name}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Item Code</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.item_code}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Issued Date</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.issued_date.slice(0, 10)}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Quantity</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.quantity}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Issue Type</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {JSON.parse(currentRecord?.issue_type).label}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Issued Rate</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.issued_rate}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Issued Description</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.issued_description}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Issuer</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.issuer_id}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Allocated To</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.allocated_to}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Allocated Mobile</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.allocated_mobile}
            </td>
          </tr>
          <tr>
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Expected Date of Return</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.expected_date_of_return
                ? currentRecord?.expected_date_of_return
                : "-"}
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-4 py-2 border-b">
              <span class="font-bold">Comments</span>
            </td>
            <td class="px-4 py-2 border-b text-right">
              {currentRecord?.comments ? currentRecord?.comments : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AssetOutwardView;
