import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { useAtom } from "jotai";
import { gbState, fYear } from "../../../components/shared/GlobalState";

import BankCardComponent from "./BankCardComponent";

const AddBalanceChild = ({ selectedBank }) => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [financial_year, setFinancial_year] = useState(
    user?.current_financial_year
  );
  const [data, setData] = useState();
  const [formData, setFormData] = useState({
    bank_id: selectedBank?._id,
    opening_balance: null,
    closing_balance: null,
    financial_year: financial_year,
    month: null,
  });

  //console.log("FinancialYear: ", financial_year);
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/bank-balance/last/${selectedBank?._id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          //   console.log(JSON.stringify(response.data));
          const oldData = response?.data?.bankBalances;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
              month: new Date(data?.month)?.toLocaleString("default", {
                month: "long",
                year: "numeric",
              }),
              opening_balance: data?.opening_balance,
              closing_balance: data?.closing_balance,
            };
          });
          console.log(updatedData[0]);
          setData(updatedData[0]);
        })
        .catch(function (err) {
          toast.error(err?.message);
          console.log("error api call ", err?.response?.data);
        });
    };
    getDataFromServer();
  }, []);

  useEffect(() => {
    // Check if data?.month is a valid date string
    const month =
      data?.month && dayjs(data.month, "MMM YYYY").isValid()
        ? dayjs(data.month, "MMM YYYY").format("MMM YYYY")
        : undefined;

    setFormData({
      ...formData,
      opening_balance: data?.closing_balance,
      month: month,
    });
  }, [data]);

  const handleBalanceAdd = (e) => {
    e.preventDefault();

    if (!formData?.month) return toast.error("Please select the month");
    if (!financial_year) return toast.error("Please select a Financial Year");

    // Extract month and year from formData.month
    const [monthName, year] = formData.month.split(" ");
    const selectedYear = parseInt(year, 10);
    const financialYearRange = financial_year.split("-").map(Number);

    // Validate the selected year is within the financial year range
    if (
      selectedYear < financialYearRange[0] ||
      selectedYear > financialYearRange[1]
    ) {
      return toast.error(
        "The selected month and year do not match the Financial Year"
      );
    }

    // Validate month is within the financial year's April to March range
    const monthIndex = new Date(`${monthName} 1`).getMonth(); // Convert month name to index (0-11)
    const isMonthValid =
      (selectedYear === financialYearRange[0] && monthIndex >= 3) ||
      (selectedYear === financialYearRange[1] && monthIndex <= 2);

    if (!isMonthValid) {
      return toast.error(
        "Invalid month. Please select a month between April and March within the selected Financial Year"
      );
    }

    // Proceed with balance addition
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/bank-balance/add`,
      headers: { Authorization: `Bearer ${userToken}` },
      data: { ...formData, financial_year: financial_year?.value },
    };

    axios(config)
      .then((response) => {
        toast.success("Balance Added Successfully.");
        navigate("/bank/balance", { state: selectedBank });
      })
      .catch((error) => {
        toast.error(JSON.stringify(error?.response?.data?.error));
        errorLogApi(error, "CreateBankBalance", "/bank/balance");
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]" style={{ wordBreak: "break-word" }}>
        {" "}
        <h2 className="mb-3 text-bold">Add Balance</h2>
        <div className="">
          <BankCardComponent data={selectedBank} />
        </div>
        <form onSubmit={(e) => handleBalanceAdd(e)} autoComplete="off">
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }} // Added the preventDefault function
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Create
            </button>
          </div>
          <div className="mt-4">
            <label className="block mb-2 text-sm text-gray-500">Month</label>
            <DatePicker
              className="w-full h-[38px]"
              name="month"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  month: dateString,
                });
              }}
              showToday={true}
              picker="month"
              placeholder="--Month--"
              format="MMM YYYY"
              defaultValue={
                formData.month
                  ? dayjs(formData.month, "MMM YYYY").toDate()
                  : null
              }
            />
          </div>

          <div className="mt-4">
            <label className="block mb-2 text-sm text-gray-500">
              Opening Balance
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="opening_balance"
              type="number"
              min="0" // This disables negative values
              required
              placeholder="Opening Balance"
              autoComplete="off"
              onChange={handleChange}
              value={formData.opening_balance}
            />
          </div>
          <div className="mt-4">
            <label className="block mb-2 text-sm text-gray-500">
              Closing Balance
            </label>
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="closing_balance"
              type="number"
              min="0" // This disables negative values
              placeholder="Closing Balance"
              autoComplete="off"
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (Number.isInteger(value) && value >= 0) {
                  handleChange(e);
                } else {
                  e.preventDefault(); // prevent entry of negative numbers
                }
              }}
              value={formData.closing_balance}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBalanceChild;
