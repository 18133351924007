import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";

import AssetAddChild from "./AssetAddChild";

const AssetAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <AssetAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default AssetAdd;
