import React, { useState, useEffect } from "react";
import axios from "axios";
import ReceiptHtml from "./ReceiptInnerHtml";
import { useNavigate } from "react-router-dom";
import numberToWords from "number-to-words";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const ReceiptCard = ({ data, users, setFormData, formData }) => {
  const [childReceiptData, setChildReceiptData] = useState([]);
  let navigate = useNavigate();
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/receipt/child_receipt/${data?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //  console.log(JSON.stringify(response.data)); return;
        setChildReceiptData(response.data);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const numberToWordsWithCapital = (number) => {
    const words = numberToWords.toWords(number);
    return capitalizeFirstLetter(words);
  };
  const amountInWords = numberToWordsWithCapital(data?.payment_amount);
  return (
    <div className={`w-full`}>
      <div className={`px-6 py-4 bg-gray-50 w-full mb-5`}>
        <div className="flex flex-row justify-between">
          <div className="text-sm font-bold text-gray-600 text-start">
            No. {data?.receipt_no}{" "}
          </div>
          <div className="justify-end ml-5 text-sm font-bold text-gray-600">
            Date. {new Date(data.receipt_date).toDateString()}
          </div>
        </div>
        <p>&nbsp;</p>
        <div className="flex flex-row justify-between w-full">
          <div className="text-sm text-gray-600 text-start ">
            <span className="font-bold">Received with thanks from</span>{" "}
            {users.name}
          </div>
          <div className="justify-end ml-5 text-sm text-gray-600"></div>
        </div>
        <p>&nbsp;</p>
        <div className="flex flex-row justify-between w-full">
          <div className="text-sm text-gray-600 text-start ">
            <span className="font-bold">Rupees</span> {amountInWords}
          </div>
          <div className="justify-end ml-5 text-sm text-gray-600"></div>
        </div>
        <p>&nbsp;</p>
        <ReceiptHtml receiptDta={childReceiptData} />
        <p>&nbsp;</p>
        <div className="flex flex-col justify-center w-full">
          <div className="w-full text-sm font-bold text-gray-600 ">
            This is a computer generated receipt, no need Of signature.
          </div>
          <div className="justify-center w-full text-sm font-bold text-gray-600">
            Printed On : {new Date().toDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiptCard;
