import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import ExpenseListChild from "./ExpenseListChild";

const ExpenseList = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <ExpenseListChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default ExpenseList;
