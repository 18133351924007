import React, { useRef, useState } from "react";
import CardBackground from "./CardBackground";
import PostComponent from "./PostComponent";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState, postState } from "../../../components/shared/GlobalState";
import { includes } from "lodash";
//Error Logging Service & Santize Input

const TimelineSection = () => {
  const cards = [
    { title: "Card 1", description: "Description 1" },
    { title: "Card 2", description: "Description 2" },
    { title: "Card 3", description: "Description 3" },
    { title: "Card 1", description: "Description 1" },
    { title: "Card 2", description: "Description 2" },
    { title: "Card 3", description: "Description 3" },
    { title: "Card 1", description: "Description 1" },
    { title: "Card 2", description: "Description 2" },
    { title: "Card 3", description: "Description 3" },
    // Add more cards as needed
  ];
  return (
    <>
      <CardBackground>
        <CreatePost />
      </CardBackground>
      <PostComponent />
    </>
  );
};

const CreatePost = () => {
  let navigate = useNavigate();
  const [postData, setPostData] = useAtom(postState);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFileExtension, setFileExtension] = useState(null);
  const [selectedUploadFile, setSelectedUploadFile] = useState(null);

  const handleIconClick = (type) => {
    setFileExtension(type);
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Extract file extension from the file name
      const fileNameParts = file.name.split(".");
      const extension = fileNameParts[fileNameParts.length - 1];
      if (selectedFileExtension == "video") {
        let image_permission = ["mp4", "3gp"];
        if (!image_permission.includes(extension)) {
          setSelectedImage("");
          setSelectedUploadFile(null);
          toast.error("File should be [mp4,3gp] format only.");
          return;
        }
      } else {
        let image_permission = ["gif", "jpeg", "jpg", "png"];
        if (!image_permission.includes(extension)) {
          setSelectedImage("");
          setSelectedUploadFile(null);
          toast.error("Please select image format [gif,jpeg,jpg,png].");
          return;
        }
      }
      setSelectedImage(URL.createObjectURL(file));
      setSelectedUploadFile(file);
    }
  };
  const [formData, setFormData] = useState({
    _id: null,
    title: null,
    description: null,
    upload_file: null,
    file_type: null,
    church_id: user?.church_id,
    createdBy: user?._id,
  });
  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const handleEvent = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    let config = {};
    if (!formData.title) {
      toast.error("Title can't be left blank.");
      return;
    }
    if (!formData.description) {
      toast.error("Post content can't be left blank.");
      return;
    }
    formData.file_type = selectedFileExtension;
    if (formData._id) {
      config = {
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/event/update/${formData._id}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedUploadFile },
      };
    } else {
      config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/post/add`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, image: selectedUploadFile },
      };
    }
    axios(config)
      .then(function (response) {
        toast.success("Event Created Successfully.");
        navigate("/dashboard-ca");
        setFormData({
          _id: null,
          title: null,
          description: null,
          upload_file: null,
          file_type: null,
          church_id: user?.church_id,
          createdBy: user?._id,
        });
        setSelectedImage("");
        setSelectedUploadFile(null);
        getPostDataFromServer();
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreatePost", "/post/add");
        // console.log(error);
      });
  };

  const getPostDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/post/${user.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.posts;
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            title: data.title,
            description: data.description,
            file_type: data.file_type,
            upload_file: data.upload_file,
          };
        });

        setPostData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  return (
    <div className="w-full">
      <form onSubmit={(e) => handleEvent(e)} autoComplete="off">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-10 h-10 p-4 mr-3 bg-gray-100 rounded-full">
            <i className="mdi mdi-account-edit" />
          </div>
          <div className="flex justify-start w-4/12">
            <span className="text-sm">Create&nbsp;post</span>
          </div>
          {/* {JSON.stringify(formData)} */}
          <div className="flex justify-end w-8/12 mt-4">
            <button className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900">
              Post
            </button>
          </div>
        </div>
        <div className="w-full mt-4">
          <input
            name="title"
            className="w-full p-2 border rounded bg-border-gray-400 focus:outline-none"
            placeholder="Title"
            onChange={handleChange}
            value={formData.title ?? ""}
          />
        </div>
        <div className="w-full mt-4">
          <textarea
            name="description"
            rows={3}
            className="w-full p-2 border rounded bg-border-gray-400 focus:outline-none"
            onChange={handleChange}
            value={formData?.description ?? ""}
          />
        </div>
        {selectedImage && selectedFileExtension == "image" && (
          <div>
            <img src={selectedImage} alt="Selected" />
          </div>
        )}
        {selectedImage && selectedFileExtension == "video" && (
          <div>
            <video src={selectedImage} alt="Selected" />
          </div>
        )}
        <div className="flex items-center">
          {/* <div className="mr-2">
            <i className="text-2xl text-red-400 mdi mdi-message-video" />
          </div>
          <span className="mr-4 text-sm">Live Video</span> */}
          <div className="mr-2">
            <i className="text-2xl text-green-500 mdi mdi-image" />
          </div>
          <span
            onClick={() => handleIconClick("image")}
            style={{ cursor: "pointer" }}
            className="mr-4 text-sm"
          >
            Photo
          </span>
          <input
            type="file"
            className="w-full p-2 border rounded bg-border-gray-400 focus:outline-none"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept={
              selectedFileExtension == "image"
                ? "image/*"
                : "image/mp4 ,image/3gp"
            }
            onChange={handleFileChange}
          />
          <div className="mr-2">
            <i className="text-2xl text-blue-500 mdi mdi-camera" />
          </div>
          <span
            className="text-sm"
            onClick={() => handleIconClick("video")}
            style={{ cursor: "pointer" }}
          >
            Video
          </span>
        </div>
      </form>
    </div>
  );
};

export default TimelineSection;
