import React from "react";
import postImage from "../../../images/no-image.png";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
const CardTile = ({ familiesData, hof, current_hof }) => {
  //console.log(hof);
  let navigate = useNavigate();
  const cardData = [];
  const filteredData = hof
    ? familiesData.filter((fHead) => fHead?._id === hof)
    : familiesData.filter(
        (family) => family?.primary_member_id?._id === current_hof
      );

  const headOfFamily = filteredData[0]?.primary_member_id;

  cardData.push({
    id: headOfFamily?._id,
    title: headOfFamily?.first_name + " " + headOfFamily?.last_name,
    image: headOfFamily?.image,
    email: headOfFamily?.email,
    date_of_birth: headOfFamily?.date_of_birth,
    birth_place: headOfFamily?.birth_place,
  });

  filteredData?.forEach((item, index) => {
    const memberData = item.family_member;
    memberData?.forEach((mItem, mIndex) => {
      let lastName = mItem.member_id?.last_name ?? "";
      cardData.push({
        id: mItem._id,
        title: mItem.member_id.first_name + " " + lastName,
        image: mItem.image,
        email: mItem.member_id.email,
        date_of_birth: mItem.date_of_birth,
        birth_place: mItem.birth_place,
      });
    });
  });
  return (
    <div className="flex flex-wrap gap-4">
      {/* {JSON.stringify(headOfFamily)} */}
      {cardData?.length ? (
        cardData.map((card, index) => (
          <div
            key={index}
            className="w-full p-3 bg-white border border-gray-300 rounded-md shadow-md min-w-[200px] sm:min-w-[300px] md:w-1/2 lg:w-1/3 xl:w-1/5"
          >
            <div className="flex flex-col items-center justify-center mt-2 mb-5">
              {card?.image?.length ? (
                <img
                  crossOrigin="anonymous"
                  src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${card.image}`}
                  className="rounded-full"
                  alt=""
                  style={{ height: "200px" }}
                />
              ) : (
                <img
                  src={postImage}
                  className="rounded-full"
                  alt=""
                  style={{ height: "200px" }}
                />
              )}
              <div className="mt-4 text-sm ">{card?.title}</div>
              <div className="mt-1 text-xs">
                {!card?.email?.includes("randomemail.com") ? card?.email : ""}
              </div>
              <div className="flex flex-col justify-start">
                {card?.date_of_birth && (
                  <div className="flex flex-row justify-start mt-10">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
                      <i className="mdi mdi-gift"> </i>
                    </div>
                    &nbsp;&nbsp;
                    <div className="mt-3 text-xs">
                      {card?.date_of_birth
                        ? dayjs(card?.date_of_birth).format("MMMM D, YYYY")
                        : "N/A"}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                )}
                {card?.birth_place && (
                  <div className="flex flex-row justify-start mt-3">
                    <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
                      <i className="mdi mdi-crosshairs-gps" />
                    </div>
                    <div className="mt-3 text-xs">
                      &nbsp;&nbsp;&nbsp;
                      {card?.birth_place ? card?.birth_place : ""}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <button
          onClick={() => navigate(-1)}
          className="block w-full h-8 mt-4 text-xs text-white bg-gray-600 rounded-md"
        >
          &nbsp; &nbsp;{"<"}&nbsp;No Member ...&nbsp;&nbsp;{" "}
        </button>
      )}
    </div>
  );
};

export default CardTile;
