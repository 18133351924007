import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { indian_states } from "../../../components/Data/IndianStates";
import { countries } from "../../../components/Data/Countries";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const DioceseAddChild = () => {
  let navigate = useNavigate();
  const [dsegmentOption, setDsegmentOption] = useState();
  const [cityOptions, setCityOptions] = useState();
  const [cityStateInput, setCityStateInput] = useState(false);

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);
  const inputWidth = "230px";
  const [formData, setFormData] = useState({
    diocese_id: null,
    diocese_code: null,
    diocese_name: null,
    city: null,
    state: null,
    country: null,
    address: null,
    segment: null,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/dioceses/dcode`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      // data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        setFormData({
          ...formData,
          diocese_code: response.data.diocese_code,
        });
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "DioceseCode", "/dcode");
      });
  }, []);

  const handleDioceseRegistration = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/dioceses/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Diocese Created Successfully.");
        navigate("/dioceseslist");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "DioceseAddChild", "/dioceseadd");
        // console.log(error);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/dsegment/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.dsegments;
          const updatedData = oldData.map((data) => {
            return {
              value: data._id,
              label: data.segment_name,
            };
          });

          setDsegmentOption(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  //const indianCities = indian_states["Haryana"];

  //console.log(indianCities);
  const countriesOption = countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  return (
    <div className="flex justify-center w-full">
      <div class="p-4 w-[400px]">
        <form onSubmit={(e) => handleDioceseRegistration(e)} autoComplete="off">
          {/* {JSON.stringify(formData)} */}
          <div className="flex justify-end w-full mt-4">
            <button
              className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
              onClick={() => navigate(-1)}
            >
              Back
            </button>{" "}
            &nbsp;&nbsp;
            <button
              className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
              type="submit"
            >
              Add Diocese
            </button>
          </div>
          <h2 className="mb-3 text-bold">Add Diocese</h2>
          {/* {JSON.stringify(formData)} */}

          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="diocese_code"
              type="text"
              required
              placeholder="Enter Diocese Code"
              autoComplete="new-password"
              // onChange={handleChange}
              disabled
              value={formData.diocese_code}
            />
          </div>
          <div className="mt-4 ">
            <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="diocese_name"
              type="text"
              required
              placeholder="Enter Diocese Name"
              autoComplete="new-password"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4">
            <Select
              name="segment"
              onChange={dropDownChange}
              options={dsegmentOption}
              placeholder={placeHolderText("Select Diocese")}
              className="w-full outline-none"
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
          <div className="mt-4 ">
            {/* <input
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="country"
              type="text"
              required
              placeholder="Enter Country"
              autoComplete="new-password"
              onChange={handleChange}
            /> */}
            <Select
              name="country"
              onChange={(selected, dropdown) => {
                if (selected.value === "IN") {
                  setCityStateInput(true);
                  setFormData({
                    ...formData,
                    country: selected.value,
                    state: null,
                    city: null,
                  });
                } else {
                  setCityStateInput(false);
                  setFormData({
                    ...formData,
                    country: selected.value,
                    state: null,
                  });
                }
              }}
              options={countriesOption}
              className={`w-[${inputWidth}] outline-none h-11`}
              placeholder={placeHolderText("Select Country")}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
          {cityStateInput ? (
            <div className="mt-4 ">
              <Select
                name="state"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    [dropdown.name]: selected.value,
                    city: null,
                  });

                  //console.log(indian_states[selected.value]);
                  setCityOptions(
                    indian_states[selected.value].map((d, i) => {
                      return { value: d, label: d };
                    })
                  );
                }}
                placeholder="Select State"
                options={indianStatesOption}
                className="w-full outline-none"
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
            </div>
          ) : (
            <div className="mt-4 ">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="state"
                type="text"
                required
                placeholder="Enter State"
                autoComplete="new-password"
                onChange={handleChange}
              />
            </div>
          )}
          {cityStateInput ? (
            <div className="mt-4 ">
              <Select
                name="city"
                onChange={dropDownChange}
                options={cityOptions}
                className="w-full outline-none"
                placeholder="Select City"
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  formData.city && {
                    value: formData.city,
                    label: formData.city,
                  }
                }
              />
            </div>
          ) : (
            <div className="mt-4 ">
              <input
                className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                name="city"
                type="text"
                required
                placeholder="Enter City"
                autoComplete="new-password"
                onChange={handleChange}
              />
            </div>
          )}
          <div className="mt-4 ">
            <textarea
              className="w-full p-3 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
              name="address"
              type="textarea"
              row="4"
              required
              placeholder="Enter Address"
              autoComplete="new-password"
              onChange={handleChange}
            />
          </div>

          <div className="flex justify-end w-full mt-4"></div>
        </form>
      </div>
    </div>
  );
};

export default DioceseAddChild;
