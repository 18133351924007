import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import FamilyAddChild from "./FamilyAddChild";

const FamilyAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <FamilyAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default FamilyAdd;
