import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-hot-toast";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import NotificationList from "./NotificationList";

const ReceiptApprovalComponent = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showApproveTrModal, setShowApproveTrModal] = useState(false);

  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState(null);
  //Error Logging Service
  const [{ errorLogApi, user }] = useAtom(gbState);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleApproveTrModal = () => {
    setShowApproveTrModal(!showApproveTrModal);
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/receipt/pending_receipts/${user?.church_id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.users)); return;

        const oldData = response.data;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
          };
        });

        setData(updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleApprove = (notificationId) => {
    // Implement your approval logic here
    console.log(`Approving notification with ID: ${notificationId}`);
    approveTransaction(notificationId);
  };

  const approveTransaction = (notificationId) => {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      toast.error("User is not authenticated.");
      return;
    }

    const urlLink = `${process.env.REACT_APP_API_URL}/receipt/approve/`;

    const config = {
      method: "POST",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
      },
      data: { receipt_id: notificationId },
    };

    axios(config)
      .then((response) => {
        toast.success("The Receipt has been approved");

        // Optional: If you need to update the UI or state, do it here
        getDataFromServer();
      })
      .catch((err) => {
        console.error("Error API call: ", err);
        toast.error("Failed to approve the receipt.");
      });
  };

  return (
    <div>
      {/* {JSON.stringify(data)} */}
      <br />
      {data.length ? (
        <NotificationList notifications={data} onApprove={handleApprove} />
      ) : (
        <div className="flex flex-col items-center justify-center h-64 bg-gray-100 rounded-lg shadow-md">
          <svg
            className="w-16 h-16 mb-4 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
            />
          </svg>
          <p className="text-xl font-semibold text-gray-600">
            No new notifications
          </p>
          {/* <p className="mt-2 text-sm text-gray-500">
            Check back later for updates
          </p> */}
        </div>
      )}
    </div>
  );
};

export default ReceiptApprovalComponent;
