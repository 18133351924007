import React from "react";
import TimelineSection from "./TimelineSection";
import ChatBuddySection from "./ChatBuddySection";
import BuddyRequestsSection from "./BuddyRequestsSection";
import MessageComponent from "./MessageComponent";

import { useAtom } from "jotai";
import { gbMessageState } from "../../../components/shared/GlobalState";

const CenterStageSection = () => {
  const [showMessageComponent, setShowMessageComponent] =
    useAtom(gbMessageState);

  return (
    <>
      {showMessageComponent ? (
        <div className="w-full mt-3">
          <MessageComponent />
        </div>
      ) : (
        <>
          <div className="w-2/3 ">
            <TimelineSection />
          </div>
          <div className="w-1/3">
            <BuddyRequestsSection />
            <ChatBuddySection />
          </div>
        </>
      )}
    </>
  );
};

export default CenterStageSection;
