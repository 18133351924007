import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import EmployeeAddChild from "./EmployeeAddChild";
import CardBackground from "../../../components/MainTemplate/CardBackground";

const EmployeeAdd = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <EmployeeAddChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default EmployeeAdd;
