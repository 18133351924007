import React, { useState } from "react";
import ChurchAddChild from "./ChurchAddChild"; // Assume this is your component for adding a church
import FinancialYearForm from "./FinancialYearForm"; // This will be our form for adding a financial year
import UserAdd from "./UserAdd"; // Assume this is your component for adding a church admin user
import OnboardingCompleted from "./OnboardingCompleted";

const MultiStageForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [churchId, setChurchId] = useState("");
  const [onBoarding, setOnBoarding] = useState(false);

  const [savedRecordId, setSavedRecordId] = useState(false);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepIndicator = (stepNumber) => {
    return currentStep === stepNumber
      ? "bg-blue-500 text-white"
      : "bg-gray-300 text-black";
  };

  return (
    <div className="container px-4 mx-auto">
      <div className="flex items-center my-4 space-x-4">
        <div className={`px-4 py-2 rounded-full ${renderStepIndicator(1)}`}>
          1
        </div>
        <div className="flex-auto transition duration-500 ease-in-out border-t-2 border-blue-500"></div>
        <div className={`px-4 py-2 rounded-full ${renderStepIndicator(2)}`}>
          2
        </div>
        <div className="flex-auto transition duration-500 ease-in-out border-t-2 border-blue-500"></div>
        <div className={`px-4 py-2 rounded-full ${renderStepIndicator(3)}`}>
          3
        </div>
      </div>

      {onBoarding ? (
        <OnboardingCompleted
          churchId={churchId}
          setCurrentStep={setCurrentStep}
          setChurchId={setChurchId}
          setOnBoarding={setOnBoarding}
          setSavedRecordId={setSavedRecordId}
        />
      ) : (
        <>
          {currentStep === 1 && (
            <ChurchAddChild
              onSubmit={nextStep}
              setChurchId={setChurchId}
              setSavedRecordId={setSavedRecordId}
            />
          )}
          {currentStep === 2 && (
            <FinancialYearForm
              onSubmit={nextStep}
              churchId={churchId}
              setSavedRecordId={setSavedRecordId}
            />
          )}
          {currentStep === 3 && (
            <UserAdd
              churchId={churchId}
              setSavedRecordId={setSavedRecordId}
              setOnBoarding={setOnBoarding}
              onSubmit={() => alert("Completed!")}
            />
          )}

          <div className="flex justify-between mt-4">
            {currentStep < 3 && (
              <button
                onClick={nextStep}
                className={`px-4 py-2 font-bold text-white ${
                  savedRecordId ? "bg-blue-500" : "bg-blue-300"
                } rounded`}
                disabled={!savedRecordId}
              >
                Next
              </button>
            )}
          </div>
        </>
      )}
      {/* {JSON.stringify(churchId)} */}
    </div>
  );
};

export default MultiStageForm;
