import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import AssetSettlementHistoryChild from "./AssetSettlementHistoryChild";

const AssetSettlementHistory = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <AssetSettlementHistoryChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default AssetSettlementHistory;
