import React from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

const ChurchBank = ({
  handleChange,
  dropDownChange,
  formData,
  setFormData,
}) => {
  const inputWidth = "230px";
  const inputElementWidthStyle = `w-[${inputWidth}] p-3 h-10 font-sans text-sm font-light border border-gray-300 rounded-md outline-none`;

  const bankOptions = [
    { value: "Savings", label: "Savings" },
    { value: "Current", label: "Current" },
  ];

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );

  return (
    <div className="flex flex-col items-center whitespace-normal md:flex-row md:flex-wrap">
      <div className="m-1 overflow-x-auto">
        <Select
          name="account_type"
          onChange={dropDownChange}
          options={bankOptions}
          placeholder={placeHolderText("Select Account Type")}
          className={`w-[${inputWidth}] outline-none h-10`}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={style}
          value={
            formData?.account_type &&
            bankOptions.find((d) => d.value === formData.account_type)
          }
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="bank_name"
          type="text"
          required
          placeholder="Enter Bank Name"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.bank_name && formData?.bank_name}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="account_number"
          type="text"
          required
          placeholder="Enter Account Number"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.account_number && formData?.account_number}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="ifsc_code"
          type="text"
          required
          placeholder="Enter IFSC Code"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.ifsc_code && formData?.ifsc_code}
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <DatePicker
          className={`w-[${inputWidth}] outline-none h-10`}
          style={{ paddingLeft: "10px" }}
          name="opening_date"
          placeholder="Opening Date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              opening_date: dateString,
            });
          }}
          value={
            formData?.opening_date &&
            moment(formData?.opening_date, "YYYY-MM-DD")
          }
        />
      </div>
      <div className="m-1 overflow-x-auto">
        <input
          className={inputElementWidthStyle}
          name="opening_balance"
          type="number"
          required
          placeholder="Enter Opening Balance"
          autoComplete="new-password"
          onChange={handleChange}
          value={formData?.opening_balance}
        />
      </div>
    </div>
  );
};

export default ChurchBank;
