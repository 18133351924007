import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import DioceseSegmentListChild from "./DioceseSegmentListChild";

const DioceseSegmentList = ({ data, handleDelete }) => {
  return (
    <div>
      <DioceseSegmentListChild data={data} handleDelete={handleDelete} />
    </div>
  );
};

export default DioceseSegmentList;
