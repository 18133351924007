import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import DioceseUpdateChild from "./DioceseUpdateChild";
import { useLocation } from "react-router-dom";

const DioceseUpdate = () => {
  const { state } = useLocation();
  return (
    <MainTemplate>
      <CardBackground>
        <DioceseUpdateChild dioceseData={state} />
      </CardBackground>
    </MainTemplate>
  );
};

export default DioceseUpdate;
