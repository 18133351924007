export const incomeSourceOptions = [
  { value: 1, label: "Cash" },
  { value: 2, label: "Cheque" },
  { value: 3, label: "Online Transfer" },
  { value: 4, label: "Demand Draft" },
];

export const paymentStatusOption = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Settled" },
  { value: 3, label: "Declined" },
];
