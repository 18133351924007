import React, { useEffect, useState } from "react";
import Topbar from "../Topbar/Topbar";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
import { gbState, fYear } from "../../components/shared/GlobalState";

import CardBackground from "../../pages/Dashboard/CardBackground";

const MainTemplate = ({ children }) => {
  const [{ user, placeHolderText, errorLogApi }] = useAtom(gbState);
  const [menuState, setMenuState] = useState(true);
  const inputWidth = "230px";

  const [financialYear, setFinancialYear] = useAtom(fYear);
  const [financialYearOptions, setFinancialYearOptions] = useState();

  //Set financial year using the old record
  useEffect(() => {
    if (user) {
      //console.log("Financial Year Set: ", user?.current_financial_year);
      setFinancialYear(
        financialYearOptions?.find(
          (f) => f.value === user?.current_financial_year
        )
      );
    }
  }, [user]);

  //Pull FinancialYear for churchID
  useEffect(() => {
    if (user?.church_id) {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/financials/${user?.church_id}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        // data: { ...formData },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          const financials = response.data.financialYears;
          if (financials.length) {
            setFinancialYearOptions(
              financials.map((year) => ({
                value: year.financial_year,
                label: year.financial_year,
                default_status: year.default_status,
              }))
            );
          } else {
            const currentYear = new Date().getFullYear();
            const financialYears = {
              value: `${currentYear}-${currentYear + 1}`,
              label: `${currentYear}-${currentYear + 1}`,
            };
            financialYears.push(`${currentYear}-${currentYear + 1}`);
            setFinancialYear(financialYears);
            setFinancialYearOptions(financialYears);
          }
        })
        .catch(function (error) {
          toast.error(JSON.stringify(error.response.data));
          errorLogApi(error, "FYList", "/financials");
        });
    }
  }, []);

  const adminRoles = ["CHURCH_ADMIN"];
  return (
    <div className="flex flex-col h-screen">
      <Topbar menuState={menuState} setMenuState={setMenuState} />

      <div class="flex-grow bg-gray-100">
        {adminRoles.includes(user?.role) && (
          <div className="relative flex w-full p-1 mt-2 bg-white rounded-md">
            <div className="flex w-full mt-4 ml-4 text-bold text-start">
              {user?.church_name}
            </div>
            <div className="flex justify-end w-full">
              <label className="mt-4 mr-4 ">Financial Year</label>
              <Select
                name="account_type"
                onChange={(selected) => setFinancialYear(selected)}
                options={financialYearOptions}
                placeholder={placeHolderText("Select Financial Year")}
                className="pr-8 mt-3 outline-none h-11" // Added pr-8 for padding right
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={
                  (user?.current_financial_year &&
                    financialYearOptions?.find(
                      (fy) => fy.value === user?.current_financial_year
                    )) ||
                  financialYearOptions?.find((fy) => fy.default_status === true)
                }
                isDisabled
              />
            </div>
          </div>
        )}
        {/* {JSON.stringify(financialYearOptions)} */}
        {children}
      </div>
    </div>
  );
};

export default MainTemplate;
