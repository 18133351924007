import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import SalaryCreateChild from "./SalaryCreateChild";

const SalaryCreate = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <SalaryCreateChild />
      </CardBackground>
    </MainTemplate>
  );
};
export default SalaryCreate;
