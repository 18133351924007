import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import postImage from "../../../images/no-image.png";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useAtom } from "jotai";
import { gbState, fMember } from "../../../components/shared/GlobalState";

const FamilyMembersList = ({ familyMembers, headOfFamily, familyId }) => {
  const [imageEffect, setImageEffect] = useState(null);
  const [familyData, setFamilyData] = useState(null);
  const [hof, setHof] = useState(null);
  const [familyImage, setFamilyImage] = useState(null);

  const [familyMember] = useAtom(fMember);
  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/family/fm/${familyId}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.family;
        setHof(oldData.image);
        setFamilyImage(oldData.family_image);
        setFamilyData(oldData.family_member);
      })
      .catch(function (err) {
        console.log("error api call ", err);
        toast.error("Failed to fetch family data");
      });
  }, [imageEffect, familyId]);

  familyMembers = familyData ?? familyMembers;
  let family_ids = [
    {
      id: familyId,
      title: "Family Head",
      image: hof,
      family_image: familyImage,
    },
  ];
  familyMembers.forEach((item) => {
    family_ids.push({
      id: item._id,
      title: `${item.member_id.first_name} ${
        item.member_id.last_name || ""
      }`.trim(),
      image: item.image,
      family_image: item.family_image,
    });
  });

  const myRefs = useRef([]);
  const handleIconClick = (id, field) => {
    myRefs.current[`${id}-${field}`].click();
  };

  const handleFileChange = (event, id, family_type, field) => {
    if (id) {
      setImageEffect(null);
      const formData = new FormData();
      formData.append(field, event.target.files[0]);
      formData.append("family_id", id);
      formData.append("family_type", family_type);

      let userToken = localStorage.getItem("token");
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/family/member/addPhoto/${familyId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
        data: formData,
      };

      axios(config)
        .then(function (response) {
          setImageEffect("data-saved");
          toast.success("Family photo is updated Successfully.");
        })
        .catch(function (error) {
          toast.error(
            error.response?.data?.message || "Failed to update photo"
          );
        });
    }
  };

  return (
    <div className="w-full p-4 bg-gray-100">
      <div className="mx-auto max-w-7xl">
        <h2 className="mb-6 text-2xl font-bold text-center text-gray-800">
          Family Album
        </h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {family_ids.map((fData, index) => (
            <div key={index} className="flex flex-col space-y-4">
              <div className="overflow-hidden bg-white rounded-lg shadow-md">
                <div className="aspect-w-3 aspect-h-4">
                  <img
                    crossOrigin="anonymous"
                    src={
                      fData.image
                        ? `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${fData.image}`
                        : postImage
                    }
                    className="object-cover w-full h-full"
                    alt={fData.title}
                  />
                </div>
                <div className="p-4">
                  <h3 className="mb-2 text-lg font-semibold text-gray-800">
                    {fData.title}
                  </h3>
                  <button
                    onClick={() => handleIconClick(fData.id, "image")}
                    className="w-full px-4 py-2 text-sm text-white transition-colors bg-blue-500 rounded-md hover:bg-blue-600"
                  >
                    Upload Image
                  </button>
                  <input
                    type="file"
                    className="hidden"
                    ref={(el) => (myRefs.current[`${fData.id}-image`] = el)}
                    accept="image/*"
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fData.id,
                        fData.title.toLowerCase(),
                        "image"
                      )
                    }
                  />
                </div>
              </div>
              {fData.title === "Family Head" && (
                <div className="overflow-hidden bg-white rounded-lg shadow-md">
                  <div className="aspect-w-3 aspect-h-4">
                    <img
                      crossOrigin="anonymous"
                      src={
                        fData.family_image
                          ? `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/family/${fData.family_image}`
                          : postImage
                      }
                      className="object-cover w-full h-full"
                      alt="Family Image"
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="mb-2 text-lg font-semibold text-gray-800">
                      Family Image
                    </h3>
                    <button
                      onClick={() => handleIconClick(fData.id, "family_image")}
                      className="w-full px-4 py-2 text-sm text-white transition-colors bg-green-500 rounded-md hover:bg-green-600"
                    >
                      Upload Family Image
                    </button>
                    <input
                      type="file"
                      className="hidden"
                      ref={(el) =>
                        (myRefs.current[`${fData.id}-family_image`] = el)
                      }
                      accept="image/*"
                      onChange={(event) =>
                        handleFileChange(
                          event,
                          fData.id,
                          fData.title.toLowerCase(),
                          "family_image"
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FamilyMembersList;
