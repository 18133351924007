import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ScheduleComponent = () => {
  let navigate = useNavigate();
  let hoverTimer;
  const [reportMenuStatus, setReportMenuStatus] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseLeave = () => {
    clearTimeout(hoverTimer);
    setIsHovered(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => setReportMenuStatus(!reportMenuStatus)}
        className="block mt-4 mr-4 text-sm text-gray-800 cursor-pointer lg:inline-block lg:mt-0 hover:text-gray-500"
      >
        Schedule ▼
      </div>
      <div
        className={`absolute right-0 left-2 z-10 ${
          reportMenuStatus ? "hidden" : null
        } w-52 mt-6 bg-white rounded-md shadow-lg`}
        onMouseLeave={() => setReportMenuStatus(!reportMenuStatus)}
      >
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/notices");
          }}
        >
          Notice
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/events");
          }}
        >
          Events
        </button>
        <button
          className="w-full px-4 py-2 text-sm text-left text-gray-600 hover:bg-gray-100"
          onClick={() => {
            setReportMenuStatus(!reportMenuStatus);
            navigate("/newsletters");
          }}
        >
          Newsletter
        </button>
      </div>
    </div>
  );
};

export default ScheduleComponent;
