import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import "react-confirm-alert/src/react-confirm-alert.css";
import { DatePicker, Space } from "antd";
import Tooltip from "@mui/material/Tooltip";

import Modal from "../../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import useFetch from "../../../components/shared/useFetch";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import ExpenseView from "./ExpenseView";

const { RangePicker } = DatePicker;

const ExpenseReportsChild = () => {
  let navigate = useNavigate();
  const [{ errorLogApi, user }] = useAtom(gbState);
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [expenseData, setExpenseData] = useState(null);
  const [expenseDate, setExpenseDate] = useState(null);
  const [unfilteredData, setUnfilteredData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  let urlLink = `${process.env.REACT_APP_API_URL}/expense/${user.church_id}`;

  //Fetching data for the page
  const { data, loading, error, refetch } = useFetch(urlLink);

  const [result, setResult] = useState(null);
  const [durationResult, setDurationResult] = useState([]);

  //  date range code
  const [dates, setDates] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [dateInterval, setDateInterval] = useState({});
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 365;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    if (dateValue) {
      setDateInterval({
        sd: new Date(dateValue[0]).getTime(),
        ed: new Date(dateValue[1]).getTime(),
      });
    }
  }, [dateValue]);

  const searchRecordsBetweenDates = () => {
    if (dateInterval) {
      //alert("started");
      const data_res = expenseData.filter((d) => {
        var time = new Date(d.date).getTime();
        return dateInterval.sd <= time && time <= dateInterval.ed;
      });
      setDurationResult(data_res);
      setExpenseData(data_res);
      setFilteredData(data_res);
    }
  };
  useEffect(() => {
    let oldData = data?.expenses;
    const updatedData = oldData?.map((data) => {
      return {
        ...data,
        id: data?._id,
        category: data?.category_id?.category_name,
      };
    });
    setExpenseData(updatedData);
    setUnfilteredData(updatedData);
  }, [data]);

  if (loading) return <div>Loading...</div>;

  if (error) return console.log(error);

  const paymentStatusOption = ["Pending", "Settled", "Declined"];
  const statusTxtColor = ["text-white", "text-gray-800", "text-white"];
  const statusBgColor = ["bg-orange-400", "bg-green-400", "bg-red-400"];

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.row.date?.slice(0, 10),
    },
    {
      field: "category",
      headerName: "Category",
      width: 140,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "expense_title",
      headerName: "Expense Title",
      width: 180,
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 180,
      align: "center",
      field: "voucher_number",
      headerName: "Receipt Number",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "amount",
      headerName: "Amount",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      field: "transfer_source",
      headerName: "Recieve Type",
    },
    {
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <span
          className={`inline-block px-2 py-1 text-xs font-semibold ${
            params.row.status && statusBgColor[parseInt(params.row.status) - 1]
          } ${
            params.row.status && statusTxtColor[parseInt(params.row.status) - 1]
          } rounded-full`}
        >
          &nbsp;&nbsp;
          {params.row.status &&
            paymentStatusOption[parseInt(params.row.status) - 1]}
          &nbsp;&nbsp;
        </span>
      ),
    },
  ];

  const handleSearch = (e) => {
    // console.log("Search Value...", e);
    setSearchInput(e);
    if (e) {
      setExpenseData(
        expenseData.filter((item) => {
          return (
            item.category.toLowerCase().includes(e.toLowerCase()) ||
            item.voucher_number.toLowerCase().includes(e.toLowerCase()) ||
            item.expense_title.toLowerCase().includes(e.toLowerCase()) ||
            item.transfer_source.toLowerCase().includes(e.toLowerCase())
          );
        })
      );
    } else {
      filteredData?.length
        ? setExpenseData(filteredData)
        : setExpenseData(unfilteredData);
    }
  };

  const handleDateSearch = (e) => {
    if (e) {
      setExpenseData(
        expenseData.filter((item) => {
          return item.date.toLowerCase().includes(e.toLowerCase());
        })
      );
    } else {
      filteredData?.length
        ? setExpenseData(filteredData)
        : setExpenseData(unfilteredData);
    }
  };

  const clearSearchInput = () => {
    if (filteredData) {
      setExpenseData(filteredData);
    } else {
      if (unfilteredData) {
        setExpenseData(unfilteredData);
      } else {
        refetch();
      }
    }
    setSearchInput("");
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setExpenseDate(dateString);
    //setData(result);
    if (!date) {
      refetch();
    }
  };

  return (
    <div className="w-full">
      <h1 className="text-bold">Expense Reporting</h1> <br />
      <div className="col-sm-12">
        <Space direction="vertical" size={12}>
          <RangePicker
            value={dates || dateValue}
            style={{ height: "36px" }}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setDateValue(val)}
            onOpenChange={onOpenChange}
            format="YYYY-MM-DD"
          />
        </Space>{" "}
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 mr-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={() => {
            searchRecordsBetweenDates();
          }}
          disabled={dateInterval.sd ? false : true}
        >
          Search
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={() => navigate("/expense/report", { state: expenseData })}
        >
          Generate Report
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 mr-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => {
            // setResult([]);
            setDurationResult([]);
            setDateValue([]);
            //refetch();
            setFilteredData(null);
            setExpenseData(unfilteredData);
          }}
        >
          Reset Data
        </button>
      </div>
      <div className="pt-4 col-sm-6">
        <Space direction="vertical" size={12}>
          <DatePicker style={{ height: "36px" }} onChange={onChange} />
        </Space>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 mr-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          variant="dark"
          onClick={() => handleDateSearch(expenseDate)}
        >
          Search
        </button>{" "}
      </div>
      <div className="col-sm-6">
        <div class="mb-6">
          <label
            for="default-input"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Default input
          </label>
          <div class="relative">
            <input
              type="text"
              id="default-input"
              class="bg-gray-50 border border-gray-300 rounded-md text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:text-black pr-8"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchInput ? searchInput : ""}
            />
            <button
              class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={clearSearchInput}
            >
              &#10005; {/* This is a Unicode character for a cross */}
            </button>
          </div>
        </div>
      </div>
      <ErrorBoundaryRoutes route="/expense" page="ModalView">
        {showModal && (
          <Modal onClose={toggleModal}>
            <h2 className="mb-4 text-xl font-bold">Expense Detail</h2>
            <ExpenseView currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {expenseData ? (
        <div className="mt-4">
          <DataGrid
            rows={expenseData}
            disableSelectionOnClick
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            displayTitle="Expense List"
            checkboxSelection
            style={{ height: "40vh" }}
          />
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default ExpenseReportsChild;
