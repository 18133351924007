import React, { useEffect, useState } from "react";
import avatar from "../../../images/no-image.png";
import postImage from "../../../images/t-10.jpg";
import axios from "axios";
import CardBackground from "./CardBackground";
import dayjs from "dayjs";
import moment from "moment";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const PostComponent = () => {
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [data, setData] = useState([]);

  let userImage = localStorage.getItem("user_image");

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/post/${user.church_id}`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          const oldData = response.data.posts;
          const updatedData = oldData.map((data) => ({
            ...data,
            id: data._id,
            title: data.title,
            description: data.description,
            file_type: data.file_type,
            upload_file: data.upload_file,
            likes: 0, // Initialize likes state
          }));
          setData(updatedData);
          // Fetch likes for each post
          updatedData.forEach((post) => {
            getNumberOfLikes(post._id);
          });
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const getNumberOfLikes = (id) => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/like/${id}`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const likes = response?.data?.likes;
        setData((prevData) =>
          prevData.map((post) =>
            post.id === id ? { ...post, likes: likes } : post
          )
        );
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  const addLikeToPost = (id) => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/like/add/${id}`;

    const config = {
      method: "POST",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        getNumberOfLikes(id);
      })
      .catch(function (err) {
        console.log("error api call ", err);
      });
  };

  return (
    <>
      {data &&
        data.map((item, index) => (
          <CardBackground key={index}>
            <div className="w-full">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {item?.created_by?.image !== "undefined" || null ? (
                    <img
                      crossOrigin="anonymous"
                      src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/employee/${item?.created_by?.image}`}
                      style={{ height: "60px" }}
                      className="rounded-full w-[60px] p-0 mr-1"
                    />
                  ) : (
                    <img src={avatar} className="rounded-full w-[100px]" />
                  )}
                  <div className="">
                    <div className="text-sm">
                      {item?.created_by?.first_name +
                        " " +
                        item?.created_by?.last_name}{" "}
                      - Shared Event
                    </div>
                    <div className="text-sm text-gray-400">
                      {moment(item.createdAt).fromNow()}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center w-10 h-10 p-4 bg-gray-100 rounded-full">
                  <i className="mdi mdi-dots-horizontal" />
                </div>
              </div>
              <div className="mt-3">{item.title}</div>
              <p className="mt-3 mr-10 text-sm text-gray-400">
                {item.description}
              </p>
              <div className="flex flex-row mt-2">
                <div className="">
                  <i className="mdi mdi-calendar-blank" />
                </div>
                <div className="mt-1 ml-2 text-xs text-gray-500">
                  {dayjs(item.createdAt).format("MMMM DD, dddd YYYY")}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="">
                  <i className="mdi mdi-clock-outline" />
                </div>
                <div className="mt-1 ml-2 text-xs text-gray-500">
                  {dayjs(item.createdAt).format("hh:mm A")}
                </div>
              </div>
              <div className="mt-4">
                {item?.file_type === "image" && item?.upload_file && (
                  <img
                    src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/post/${item?.upload_file}`}
                    className="rounded-md"
                    alt=""
                    crossOrigin="anonymous"
                  />
                )}
                {item?.file_type === "video" && item?.upload_file && (
                  <video
                    controls
                    width="100%"
                    height="360"
                    crossOrigin="anonymous"
                  >
                    <source
                      src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/post/${item?.upload_file}`}
                      type="video/mp4"
                      crossOrigin="anonymous"
                    />
                  </video>
                )}
              </div>
              <div className="flex items-center justify-between mt-4">
                <div className="">
                  <i
                    className="mr-2 cursor-pointer mdi mdi-thumb-up-outline"
                    onClick={() => addLikeToPost(item.id)}
                  />
                  <i className="mdi mdi-heart-outline" />{" "}
                  <span className="mr-3 text-sm">{item.likes}</span>
                  {/* <i className="text-xl mdi mdi-chat-outline" />
                  <span className="text-sm">22 Comments</span> */}
                </div>
              </div>
            </div>
          </CardBackground>
        ))}
      {data.length === 0 && (
        <CardBackground>
          <span style={{ fontWeight: "bold" }}>No post available.</span>
        </CardBackground>
      )}
    </>
  );
};

export default PostComponent;
