import React from "react";

import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import EmployeesListChild from "./EmployeeListChild";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const EmployeesList = () => {
  return (
    <MainTemplate>
      <div className="relative flex flex-col p-10 m-3 space-y-10 bg-white rounded-md shadow md:flex-row md:space-y-0 md:m-3">
        <ErrorBoundaryRoutes route="/employees" page="EmployeesList">
          <EmployeesListChild />
        </ErrorBoundaryRoutes>
      </div>
    </MainTemplate>
  );
};

export default EmployeesList;
