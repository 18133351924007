import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { indian_states } from "../../../components/Data/IndianStates";
import { countries } from "../../../components/Data/Countries";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import ChurchForm from "./ChurchForm";
import useFetch from "../../../components/shared/useFetch";

const ChurchAddChild = () => {
  let navigate = useNavigate();
  const [dsegmentOption, setDsegmentOption] = useState();
  const [cityOptions, setCityOptions] = useState();
  const [dioceseOption, setDioceseOption] = useState();
  const [cityStateInput, setCityStateInput] = useState(false);
  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    diocese_id: null,
    church_code: null,
    church_name: null,
    city: null,
    state: null,
    country: null,
    address: null,
    segment_id: null,
    email: null,
    phone_number: null,
    mobile_number: null,
    website: null,
    no_of_member_licenses: null,
    subscription_status: "Active",
    subscription_start_date: null,
    subscription_end_date: null,
    account_type: null,
    account_number: null,
    bank_name: null,
    ifsc_code: null,
    opening_date: null,
    opening_balance: null,
  });

  //set url for fetching the church code
  let churchcode_url = `${process.env.REACT_APP_API_URL}/churches/ccode`;

  const { data } = useFetch(churchcode_url);
  // Get Church ID
  useEffect(() => {
    setFormData({
      ...formData,
      church_code: data?.church_code,
    });
  }, [data]);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleChurchRegistration = () => {
    // e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/churches/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Church Created Successfully.");
        navigate("/churches");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "ChurchAddChild", "/churchadd");
        console.log(error);
      });
  };

  //create dsegment link
  const dseg_link = `${process.env.REACT_APP_API_URL}/dsegment/`;

  //Fetch dsegment data
  const { data: dseg, error: dseg_error } = useFetch(dseg_link);

  // useEffect(() => {
  //   if (dseg?.length) {
  //     const dsegData = dseg.map((data) => {
  //       return {
  //         value: data?._id,
  //         label: data?.segment_name,
  //       };
  //     });
  //     setDsegmentOption(dsegData);
  //   }
  // }, [dseg]);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/dioceses/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.users));
          const oldData = response.data.dioceses;
          console.log(oldData);
          const updatedData = oldData.map((data) => {
            return {
              value: data._id,
              label: <DioceseDescription ddata={data} />,
              dsegment: data?.segment?.segment_name,
            };
          });

          setDioceseOption(updatedData);
        })
        .catch(function (err) {
          console.log("error api call ", err);
        });
    };
    getDataFromServer();
  }, []);

  const indianStates = Object.keys(indian_states);

  const indianStatesOption = indianStates.map((data, index) => {
    return { value: data, label: data };
  });

  const subscriptionOption = [
    { value: "Active", label: "Active" },
    { value: "Suspended", label: "Suspended" },
    { value: "Archived", label: "Archived" },
  ];

  const countriesOption = countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  return (
    <div className="flex w-full">
      <div class="p-4 w-full">
        <div className="flex justify-end w-full mt-4">
          <button
            className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
            onClick={() => navigate(-1)}
          >
            Back
          </button>{" "}
          &nbsp;&nbsp;
          <button
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-gray-900"
            onClick={handleChurchRegistration}
          >
            Save
          </button>
        </div>
        <fieldset className="p-2 whitespace-normal border w-100">
          <legend className="float-none w-auto p-2 fs-5">
            Register Church
          </legend>
          <div className="flex flex-col">
            <ChurchForm
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
              indianStatesOption={indianStatesOption}
              dropDownChange={dropDownChange}
              setCityOptions={setCityOptions}
              indian_states={indian_states}
              countriesOption={countriesOption}
              cityStateInput={cityStateInput}
              setCityStateInput={setCityStateInput}
              cityOptions={cityOptions}
              dsegmentOption={dsegmentOption}
              subscriptionOption={subscriptionOption}
              dioceseOption={dioceseOption}
            />
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default ChurchAddChild;

const DioceseDescription = ({ ddata }) => {
  return (
    <div>
      <span className="text-xs">{ddata.diocese_name}</span>
      <p className="text-[8px] text-gray-500 truncate text-light">
        {ddata.diocese_code} &nbsp; ({ddata.city},{ddata.state})
      </p>
    </div>
  );
};
