import React from "react";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import AssetInwardUpdateChild from "./AssetInwardUpdateChild";

import { useLocation } from "react-router-dom";

const AssetInwardUpdate = () => {
  const { state } = useLocation();
  const currentRecord = state;
  return (
    <MainTemplate>
      <CardBackground>
        <AssetInwardUpdateChild currentRecord={currentRecord} />
      </CardBackground>
    </MainTemplate>
  );
};

export default AssetInwardUpdate;
