import React from "react";

const MarriageView = ({ currentRecord }) => {
  return (
    <div className="mb-10">
      <InfoTable jsonData={currentRecord} />
    </div>
  );
};

export default MarriageView;

const InfoTable = ({ jsonData }) => {
  // Define the signature keys for special handling
  const signatureKeys = [
    "groom_signature",
    "groom_signature_witness",
    "bride_signature",
    "bride_signature_witness",
  ];

  // Function to format date strings
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return !isNaN(date.getTime())
      ? date.toLocaleDateString("en-US", options)
      : dateString;
  };

  // Renders a table for the provided keys
  const renderTable = (keys, title) => (
    <>
      <div className="my-2 overflow-y-auto text-lg font-semibold">{title}</div>
      <table className="w-full mb-4 border-collapse table-auto">
        <tbody>
          {keys.map((key, index) => {
            const value = jsonData[key];
            const isSignatureKey = signatureKeys.includes(key);
            const displayValue = isSignatureKey ? (
              value ? (
                <img
                  src={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/marriage/${value}`}
                  alt={`${key} signature`}
                  width="40px" // Fixed height for the image
                  crossOrigin="anonymous"
                  className="object-contain" // Tailwind class to maintain aspect ratio
                />
              ) : (
                "No signature"
              )
            ) : key.includes("date_of_birth") || key === "marriage_date" ? (
              formatDate(value)
            ) : typeof value === "object" && value !== null ? (
              value.label
            ) : (
              value
            );

            return (
              <tr
                key={key}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-200"}`}
              >
                <td className="px-2 py-1 text-sm text-black capitalize border">
                  {key
                    .replace(/groom_|bride_/g, "") // Remove 'groom' or 'bride' prefix
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </td>
                <td className="px-2 py-1 text-black border">{displayValue}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );

  return (
    <div className="overflow-x-auto">
      {renderTable(["record_no", "marriage_date", "marriage_location"], "")}
      {renderTable(
        [
          "groom_christian_name",
          "groom_surname",
          "groom_date_of_birth",
          "groom_caste",
          "groom_status",
          "groom_residential_address",
          "groom_parents_or_guardian",
          "groom_by_banns_or_license",
          "groom_signature",
          "groom_signature_witness",
          "solemnised_by",
        ],
        "Groom's Details"
      )}
      {renderTable(
        [
          "bride_christian_name",
          "bride_surname",
          "bride_date_of_birth",
          "bride_caste",
          "bride_status",
          "bride_residential_address",
          "bride_parents_or_guardian",
          "bride_by_banns_or_license",
          "bride_signature",
          "bride_signature_witness",
        ],
        "Bride's Details"
      )}
    </div>
  );
};
