import React from "react";

const TableComponent = ({ data }) => {
  // Calculate totals
  const totals = data.reduce(
    (accumulator, item) => {
      if (item.status === "1") {
        accumulator.totalPending += item.amount;
      } else if (item.status === "2") {
        accumulator.totalSettled += item.amount;
      } else if (item.status === "3") {
        accumulator.totalDeclined += item.amount;
      }
      accumulator.totalRegistered += item.amount;
      return accumulator;
    },
    {
      totalRegistered: 0,
      totalPending: 0,
      totalSettled: 0,
      totalDeclined: 0,
    }
  );

  return (
    <div>
      <table className="min-w-full border border-gray-300 ">
        <thead>
          <tr className="text-black bg-gray-400">
            <th className="px-4 py-2 border border-gray-300 text-end">
              Category
            </th>
            <th className="px-4 py-2 border border-gray-300 text-start">
              Amount
            </th>
            {/* <th className="px-4 py-2 border border-gray-300">Status</th> */}
          </tr>
        </thead>
        <tbody>
          {/* {data.map((item) => (
            <tr key={item._id}>
              <td className="px-4 py-2 border border-gray-300">
                {item.category}
              </td>
              <td className="px-4 py-2 border border-gray-300">
                {item.amount}
              </td>
              <td className="px-4 py-2 border border-gray-300">
                {item.status}
              </td>
            </tr>
          ))} */}
        </tbody>
        <tfoot>
          <tr>
            <th className="px-4 py-2 border border-gray-300 text-end">
              Total Registered
            </th>
            <th className="px-4 py-2 bg-yellow-500 border border-gray-300 text-start">
              {totals.totalRegistered}
            </th>
          </tr>
          <tr>
            <th className="px-4 py-2 border border-gray-300 text-end">
              Total Pending
            </th>
            <th className="px-4 py-2 bg-orange-500 border border-gray-300 text-start">
              {totals.totalPending}
            </th>
          </tr>
          <tr>
            <th className="px-4 py-2 border border-gray-300 text-end">
              Total Settled
            </th>
            <th className="px-4 py-2 bg-green-500 border border-gray-300 text-start">
              {totals.totalSettled}
            </th>
          </tr>
          <tr>
            <th className="px-4 py-2 border border-gray-300 text-end">
              Total Declined
            </th>
            <th className="px-4 py-2 bg-red-500 border border-gray-300 text-start">
              {totals.totalDeclined}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableComponent;
