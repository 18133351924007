import React from "react";
import ReactToPrint from "react-to-print";

class PrintableComponent extends React.PureComponent {
  render() {
    const data = this.props.data;
    return (
      <div className="print:pt-40 print:m-40">
        <table className="w-full bg-white border border-gray-300">
          <tbody>
            {Object.entries(data).map(([key, value], i) => (
              <tr
                key={i}
                className={`${i % 2 === 0 ? "bg-gray-100" : "border-b"}`}
              >
                <td className="p-2 font-bold capitalize">
                  {key.replace(/_/g, " ")}
                </td>
                <td className="p-2 text-right">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const ViewTemplate = ({ data }) => {
  let componentRef = React.useRef();

  return (
    <div className="mx-auto overflow-hidden bg-white ">
      <div className="flex justify-end">
        <ReactToPrint
          trigger={() => (
            <button className="px-4 py-2 mb-2 text-white bg-blue-500 rounded">
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>

      <PrintableComponent data={data} ref={componentRef} />
    </div>
  );
};

export default ViewTemplate;
