import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const NoticeUserList = ({ sendData, formData, setFormData }) => {
  const [data, setData] = useState([]);
  const [dataSelected, setSelected] = useState(false);
  const [{ errorLogApi, user }] = useAtom(gbState);
  let urlLink = "";
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      setData([]);
      setSelected([]);
      if (sendData === "families") {
        urlLink = `${process.env.REACT_APP_API_URL}/family/${user.church_id}`;
        const config = {
          method: "GET",
          url: urlLink,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
        axios(config)
          .then(function (response) {
            const oldData = response.data.families;
            setData(
              oldData.map((data) => ({
                value: data._id + "#_#" + data?.email,
                label: `${data.family_name}`,
              }))
            );
          })
          .catch(function (err) {
            console.log("error api call ", err.response.data.message);
          });
      } else if (sendData === "members") {
        let urlLink = `${process.env.REACT_APP_API_URL}/user/church/${user.church_id}`;
        const config = {
          method: "GET",
          url: urlLink,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
        axios(config)
          .then(function (response) {
            const oldData = response.data.users;
            setData(
              oldData.map((data) => ({
                value: data._id + "#_#" + data?.email,
                label: `${data.first_name} ${data.last_name}`,
              }))
            );
          })
          .catch(function (err) {
            console.log("error api call ", err.response.data.message);
          });
      } else if (sendData === "employees") {
        urlLink = `${process.env.REACT_APP_API_URL}/employee/`;
        const config = {
          method: "GET",
          url: urlLink,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
        axios(config)
          .then(function (response) {
            const oldData = response.data.employees;
            setData(
              oldData.map((data) => ({
                value: data._id + "#_#" + data?.email,
                label: `${data.first_name} ${data.last_name}`,
              }))
            );
          })
          .catch(function (err) {
            console.log("error api call ", err.response.data.message);
          });
      }
    };
    getDataFromServer();
  }, [sendData]);

  if (sendData === "all" || !sendData) return;

  const handleSelected = (selectedOptions) => {
    setSelected(selectedOptions);
    const userSelectedOpts = selectedOptions.map(({ value }) => value);
    setFormData((formData) => ({
      ...formData,
      users: userSelectedOpts,
    }));
  };
  return (
    <div className="flex justify-center w-full">
      <div class=" w-[900px]">
        {/* {JSON.stringify(sendData)} */}
        <div className="mt-0 ">
          <label htmlFor="sent_to" style={{ fontWeight: "bold" }}>
            Add {sendData}
          </label>
          <Select
            name="sent_to_user"
            onChange={handleSelected}
            isMulti
            options={data}
            menuPosition={"fixed"}
            value={data.filter((option) =>
              formData.users.includes(option.value)
            )}
            //styles={style}
            escapeClearsValue
            required
          />
        </div>
      </div>
    </div>
  );
};

export default NoticeUserList;
