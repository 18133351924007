import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AddMarriageChild = () => {
  let navigate = useNavigate();
  const inputWidth = "230px";
  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  //Error Logging Service & Santize Input
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [selectedGroomSignature, setSelectedGroomSignature] = useState(null);
  const [selectedGroomWitnessSignature, setSelectedGroomWitnessSignature] =
    useState(null);
  const [selectedBrideSignature, setSelectedBrideSignature] = useState(null);
  const [selectedBrideWitnessSignature, setSelectedBrideWitnessSignature] =
    useState(null);

  const [formData, setFormData] = useState({
    marriage_date: null,
    groom_christian_name: null,
    groom_surname: null,
    groom_date_of_birth: null,
    groom_caste: null,
    groom_status: null,
    groom_residential_address: null,
    groom_parents_or_guardian: null,
    groom_by_banns_or_license: null,
    groom_signature: null,
    groom_signature_witness: null,
    bride_christian_name: null,
    bride_surname: null,
    bride_date_of_birth: null,
    bride_caste: null,
    bride_status: null,
    bride_residential_address: null,
    bride_parents_or_guardian: null,
    bride_by_banns_or_license: null,
    bride_signature: null,
    bride_signature_witness: null,
    marriage_location: null,
    solemnised_by: null,
  });

  const casteListOptions = [
    { value: 1, label: "Assemblies of God in India" },
    { value: 2, label: "Baptist" },
    { value: 3, label: "Chaldean Syrian Church" },
    { value: 4, label: "Church of North India" },
    { value: 5, label: "Church of South India" },
    { value: 6, label: "India Pentecostal Church of God" },
    { value: 7, label: "Jacobite Syrian Orthodox" },
    { value: 8, label: "Lutheran" },
    { value: 9, label: "Malankara Mar Thoma Syrian" },
    { value: 10, label: "Malankara Orthodox Syrian" },
    { value: 11, label: "Methodist Church in India" },
    { value: 12, label: "Other Christian" },
    { value: 13, label: "Roman Catholic" },
    { value: 14, label: "Syro-Malabar Catholic" },
    { value: 15, label: "Syro-Malankara Catholic" },
    { value: 16, label: "The Pentecostal Mission" },
  ];

  const statusOptions = [
    { value: 1, label: "Bachelor" },
    { value: 2, label: "Spinster" },
    { value: 3, label: "Divorcee" },
  ];

  const bannsLicenseOptions = [
    { value: 1, label: "Banns" },
    { value: 2, label: "License" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected,
    });
  };

  function formatString(str) {
    // Remove underscores and split the string into words
    const words = str.replace(/_/g, " ").split(" ");

    // Capitalize the first letter of the first word
    const capitalizedWords = words.map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    });

    // Join the words back into a single string
    return capitalizedWords.join(" ");
  }

  const handleMarriageRegister = () => {
    const fieldToValidate = [
      "marriage_date",
      "groom_christian_name",
      "groom_date_of_birth",
      "groom_residential_address",
      "bride_christian_name",
      "bride_date_of_birth",
      "bride_residential_address",
      "marriage_location",
    ];

    // Identify empty fields and filter out any undefined entries
    const emptyFields = fieldToValidate
      .filter((f) => !formData[f])
      .map(formatString); // Assuming formatString formats the string as previously discussed

    // Provide feedback if there are any empty fields
    if (emptyFields.length > 0) {
      return toast.error(`${emptyFields.join(", ")} cannot be empty`);
    }

    // Configuration for axios request
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/marriage/add`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
        groom_signature: selectedGroomSignature,
        groom_signature_witness: selectedGroomWitnessSignature,
        bride_signature: selectedBrideSignature,
        bride_signature_witness: selectedBrideWitnessSignature,
      },
    };

    // Axios request
    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message);
        navigate("/marriages/list");
      })
      .catch(function (error) {
        toast.error(
          error.response?.data
            ? JSON.stringify(error.response.data)
            : "An error occurred"
        );
        errorLogApi(error, "AddMarriage", "/marriage/add");
      });
  };

  return (
    <div className="w-full">
      <div className="">
        <h1>Add Marriage</h1>
      </div>
      <div className="flex justify-end w-full mt-4">
        <button
          className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-900"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        &nbsp;&nbsp;
        <button
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-900"
          onClick={handleMarriageRegister}
        >
          Create Record
        </button>
      </div>
      <div className="flex flex-col md:flex-row">
        <fieldset className="w-full p-2 whitespace-normal border md:mr-2">
          <legend className="float-none w-auto p-2 fs-5">Groom Details</legend>
          <div className="p-5">
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Marriage date
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="marriage_date"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      marriage_date: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Groom christian name
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="groom_christian_name"
                  type="text"
                  required
                  placeholder="Groom christian name"
                  onChange={handleChange}
                  value={formData.groom_christian_name}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Groom Surname
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="groom_surname"
                  type="text"
                  required
                  placeholder="Groom surname"
                  onChange={handleChange}
                  value={formData.groom_surname}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Date of Birth
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="groom_date_of_birth"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      groom_date_of_birth: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Caste
                </label>
                <Select
                  name="groom_caste"
                  onChange={dropDownChange}
                  options={casteListOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Caste")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Groom Status
                </label>
                <Select
                  name="groom_status"
                  onChange={dropDownChange}
                  options={statusOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Status")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Residential Address
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="groom_residential_address"
                  type="text"
                  required
                  placeholder="Residential Address"
                  onChange={handleChange}
                  value={formData.groom_residential_address}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Parent / Guardian
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="groom_parents_or_guardian"
                  type="text"
                  required
                  placeholder="Parents / Guardian"
                  onChange={handleChange}
                  value={formData.groom_parents_or_guardian}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Groom Signature
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="groom_signature"
                  type="file"
                  required
                  placeholder="Signature"
                  onChange={(event) =>
                    setSelectedGroomSignature(event.target.files[0])
                  }
                  accept="image/gif, image/jpeg, image/png"
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Witness Signature
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="groom_signature_witness"
                  type="file"
                  required
                  placeholder="Signature"
                  onChange={(event) =>
                    setSelectedGroomWitnessSignature(event.target.files[0])
                  }
                  accept="image/gif, image/jpeg, image/png"
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Banns / License
                </label>
                <Select
                  name="groom_by_banns_or_license"
                  onChange={dropDownChange}
                  options={bannsLicenseOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Banns / License")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Solemnised By
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="solemnised_by"
                  type="text"
                  required
                  placeholder="Solemnised By"
                  onChange={handleChange}
                  value={formData.solemnised_by}
                />
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className="w-full p-2 whitespace-normal border md:ml-2">
          <legend className="float-none w-auto p-2">Bride Details</legend>
          <div className="p-5">
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Banns / License
                </label>
                <Select
                  name="bride_by_banns_or_license"
                  onChange={dropDownChange}
                  options={bannsLicenseOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Banns / License")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Bride christian name
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="bride_christian_name"
                  type="text"
                  required
                  placeholder="Bride christian name"
                  onChange={handleChange}
                  value={formData.bride_christian_name}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Bride Surname
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="bride_surname"
                  type="text"
                  required
                  placeholder="Bride surname"
                  onChange={handleChange}
                  value={formData.bride_surname}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Date of Birth
                </label>
                <DatePicker
                  className="h-[38px] w-full"
                  name="bride_date_of_birth"
                  onChange={(date, dateString) => {
                    setFormData({
                      ...formData,
                      bride_date_of_birth: dateString,
                    });
                  }}
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current > moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  showToday={true}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Caste
                </label>
                <Select
                  name="bride_caste"
                  onChange={dropDownChange}
                  options={casteListOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Caste")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Bride Status
                </label>
                <Select
                  name="bride_status"
                  onChange={dropDownChange}
                  options={statusOptions}
                  className={`w-[${inputWidth}] outline-none h-10`}
                  placeholder={placeHolderText("Select Status")}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  styles={style}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Residential Address
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="bride_residential_address"
                  type="text"
                  required
                  placeholder="Residential Address"
                  onChange={handleChange}
                  value={formData.bride_residential_address}
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Parent / Guardian
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="bride_parents_or_guardian"
                  type="text"
                  required
                  placeholder="Parents / Guardian"
                  onChange={handleChange}
                  value={formData.bride_parents_or_guardian}
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full mr-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Bride Signature
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="bride_signature"
                  type="file"
                  required
                  placeholder="Signature"
                  onChange={(event) =>
                    setSelectedBrideSignature(event.target.files[0])
                  }
                  accept="image/gif, image/jpeg, image/png"
                />
              </div>
              <div className="w-full ml-2">
                <label className="block mb-2 text-sm text-gray-500">
                  Witness Signature
                </label>
                <input
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="bride_signature_witness"
                  type="file"
                  required
                  placeholder="Signature"
                  onChange={(event) =>
                    setSelectedBrideWitnessSignature(event.target.files[0])
                  }
                  accept="image/gif, image/jpeg, image/png"
                />
              </div>
            </div>
            <div className="flex flex-row mb-3">
              <div className="w-full">
                <label className="block mb-2 text-sm text-gray-500">
                  Marriage Location
                </label>
                <textarea
                  className="w-full p-2 font-sans text-sm font-light border border-gray-300 rounded-md outline-none"
                  name="marriage_location"
                  type="textarea"
                  row={3}
                  required
                  placeholder="Marriage Location"
                  onChange={handleChange}
                  value={formData.marriage_location}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default AddMarriageChild;
