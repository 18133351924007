import React from "react";

const Card = ({ data, index }) => {
  // Determine the background color based on the index
  const backgroundColor = index % 2 === 0 ? "bg-gray-200" : "bg-white";

  return (
    <div
      className={`w-full max-w-xs shadow-md rounded-top-lg overflow-hidden `}
    >
      <div className={`px-6 py-4 bg-gray-200`}>
        <div className="mb-2 text-xl font-bold ">{data.name}</div>
        <p className="text-base text-gray-700">{data.role}</p>
      </div>
      {/* <div className={`px-6 py-4 bg-gray-50`}>
        
        <p className="text-sm text-gray-600">
          Date of Joining: &nbsp; {new Date(data.date).toDateString()}
        </p>
      </div> */}
      <div className="flex flex-row justify-between mt-3 ml-6 mr-6">
        <div className="text-sm text-gray-600">Date of Joining</div>
        <div className="justify-end ml-5 text-sm text-gray-600">
          {new Date(data.date).toDateString()}
        </div>
      </div>
    </div>
  );
};

export default Card;
