import React, { useState } from "react";

const ChurchListByCity = ({ data }) => {
  const recordsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  // Organize churches by city
  const churchesByCity = data.reduce((acc, item) => {
    const city = item.city;
    if (!acc[city]) {
      acc[city] = [];
    }
    acc[city].push({
      name: item.church_name,
      code: item.church_code,
      createdAt: item.createdAt,
    });
    return acc;
  }, {});

  // Flatten the churchesByCity object to get a single array of churches with city included
  const flattenedChurches = Object.entries(churchesByCity).flatMap(
    ([city, churches]) => churches.map((church) => ({ city, ...church }))
  );

  // Filter the churches based on the search term
  const filteredChurches = flattenedChurches.filter((church) =>
    church.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the index of the first and last records of the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredChurches.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredChurches.length / recordsPerPage);

  // Create a component to render the list of churches by city in a table
  const renderTableRows = currentRecords.map((church, index) => (
    <tr key={index} className="even:bg-silver odd:bg-white hover:bg-gray-200">
      <td className="px-4 py-2 border">{indexOfFirstRecord + index + 1}</td>
      <td className="px-4 py-2 border">{church.city}</td>
      <td className="px-4 py-2 border">{church.name}</td>
      <td className="px-4 py-2 border">{church.code}</td>
      <td className="px-4 py-2 border">
        {new Date(church.createdAt).toLocaleDateString()}
      </td>
    </tr>
  ));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="w-full">
      <h2 className="mb-4 text-2xl font-bold">
        List of Churches Registered by City
      </h2>
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Search by Church Name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 border rounded-l-md"
        />
        {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="px-4 py-2 bg-gray-300 border border-l rounded-r-md"
          >
            &#x2715;
          </button>
        )}
      </div>
      <table className="w-full border-collapse table-auto">
        <thead>
          <tr className="text-white bg-gray-800">
            <th className="px-4 py-2 border">#</th>
            <th className="px-4 py-2 border">City</th>
            <th className="px-4 py-2 border">Church Name</th>
            <th className="px-4 py-2 border">Church Code</th>
            <th className="px-4 py-2 border">Registration Date</th>
          </tr>
        </thead>
        <tbody>{renderTableRows}</tbody>
      </table>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-gray-400"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 mx-1 text-white rounded hover:bg-blue-700 ${
              currentPage === index + 1 ? "bg-blue-800" : "bg-blue-600"
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 mx-1 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-gray-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ChurchListByCity;
